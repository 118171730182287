export const getJsonDataFromServer = async (serverFile, next) => {
	let jwt = await sessionStorage.getItem("jwt");
	var raw = JSON.stringify({
		jwt: jwt,
		command: "get",
		fileName: serverFile,
	});

	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};

	fetch(
		"https://tetrice.co.za/REST/accountsapi/write_jsonlocal.php",
		requestOptions,
	)
		.then((response) => {
			console.log(response);
			if (response.status == "200") {
				response
					.json()
					.then((result) => {
						return next(200, result);
					})
					.catch((error) => {
						return next(error);
					});
			} else {
				return next(response.status, response);
			}
		})
		.catch((error) => next(400, error));
};

export const saveJsonDataToServer = async (serverFile, data, next) => {
	let jwt = await sessionStorage.getItem("jwt");
	var raw = JSON.stringify({
		jwt: jwt,
		command: "get",
		fileName: serverFile,
	});
	var raw = JSON.stringify({
		jwt: jwt, // "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
		json_data: data,
		command: "save",
		fileName: serverFile,
	});
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};

	fetch(
		"https://tetrice.co.za/REST/accountsapi/write_jsonlocal.php",
		requestOptions,
	)
		.then((response) => {
			// console.log(response);
			if (response.status == "200") {
				response
					.json()
					.then((result) => {
						return next(200, result);
					})
					.catch((error) => next(400, error));
			} else {
				return next(response.status, response);
			}
		})
		.catch((error) => next(400, []));
};
