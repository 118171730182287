import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	ActivityIndicator,
	Alert,
} from "react-native";
import {
	getJsonDataFromServer,
	saveJsonDataToServer,
} from "../ServerRequestHelper";

const BLUE = "#3F6EFF";
const RED = "#FF5F5F";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export default class Representative extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newRepName: "",
			newRepEmail: "",
			newRepCell: "",
			representatives: [],
		};
	}

	componentDidMount() {
		this.inti();
	}

	inti = () => {
		let sf = `clientRepresentatives`;

		this.setState({ loading: true });
		getJsonDataFromServer(sf, (status, data) => {
			if (status === 200) {
				this.setState({
					representatives: data,
				});
			} else {
				console.log("saveJsonDataToServer returned", status, data);
			}
			this.setState({
				loading: false,
			});
		});
	};

	addRepresentative = async () => {
		let uid = uuid();
		let obj = {
			id: uid,
			client_id: this.props.selectedClientId,
			name: this.state.newRepName,
			department: this.state.newRepDepartment,
			email: this.state.newRepEmail,
			cell: this.state.newRepCell,
		};

		let array = await this.state.representatives;
		const push_ = await [...array, obj];
		await this.setState({
			representatives: push_,
			newRepName: "",
			newRepEmail: "",
			newRepCell: "",
			addRepresentative: false,
		});
		this.saveState();
	};

	deleteRep = async (id) => {
		let array = this.state.representatives;
		const newarr = await array.filter((d) => d.id !== id);
		await this.setState({
			representatives: newarr,
		});
		this.saveState();
	};

	saveState = () => {
		let sf = `clientRepresentatives`;
		saveJsonDataToServer(sf, this.state.representatives, (status, data) => {
			if (status === 200) {
			} else {
				console.log("saveJsonDataToServer returned", status, data);
			}
		});
	};

	render() {
		return (
			<View
				style={{
					backgroundColor: "#191D24",
					// backgroundColor: '#FFF',
					borderRadius: 20,
					marginRight: 10,
					marginBottom: 15,
					height: "auto",
					padding: 10,
					flex: 1,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						paddingBottom: 10,
						marginBottom: 10,
						alignItems: "center",
						borderBottomColor: "#28282F",
						borderBottomWidth: 0.5,
					}}>
					<Text
						style={{
							fontSize: 15,
							color: "#D8D8FF",
							fontWeight: "700",
							fontFamily: "Avenir Next",
						}}>
						Representatives
					</Text>
					<TouchableOpacity
						onPress={() =>
							this.setState({
								addRepresentative: !this.state.addRepresentative,
							})
						}>
						<Image
							style={{
								width: 65,
								height: 35,
								resizeMode: "contain",
							}}
							source={require("../../../assets/images/reps.png")}
						/>
					</TouchableOpacity>
				</View>
				<View
					style={{
						flex: 1,
					}}>
					{this.state.representatives
						.filter((d) => d.client_id === this.props.selectedClientId)
						.map((rep, i) => (
							<View
								key={i}
								style={{
									flexDirection: "row",
									marginBottom: 10,
									alignItems: "center",
								}}>
								<Image
									style={{
										width: 35,
										height: 35,
										borderRadius: 15,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/female1.png")}
								/>

								<View
									style={{
										flex: 1,
										paddingLeft: 10,
										paddingTop: 8,
										justifyContent: "center",
									}}>
									<Text
										style={{
											fontSize: 14,
											color: "#FFF",
											fontWeight: "800",
											fontFamily: "Avenir Next",
											padding: 0,
										}}>
										{rep.name}
									</Text>
									<Text
										style={{
											fontSize: 10,
											color: "#FFF",
											fontWeight: "300",
											fontFamily: "Avenir Next",
											top: -2,
											padding: 0.8,
										}}>
										<Text
											style={{
												fontWeight: "500",
											}}>
											{rep.email}
										</Text>{" "}
										{rep.cell}
									</Text>
								</View>
								{this.state.addRepresentative ? (
									<TouchableOpacity
										onPress={() => this.deleteRep(rep.id)}>
										<Image
											style={{
												width: 16,
												height: 16,
												resizeMode: "contain",
											}}
											source={require("../../../assets/images/delete.png")}
										/>
									</TouchableOpacity>
								) : null}
							</View>
						))}

					{this.state.addRepresentative ? (
						<View
							style={{
								width: "100%",
								marginTop: 20,
								borderTopColor: "#FFF",
								borderTopWidth: 1,
							}}>
							<TextInput
								style={{
									width: "100%",
									marginTop: 10,
									height: 40,
									paddingLeft: 10,
									marginBottom: 5,
									fontSize: 14,
									borderRadius: 8,
									backgroundColor: "#434350",
									color: "#FFD76E",
									fontWeight: "400",
								}}
								placeholderTextColor={"#FFF"}
								autoCapitalize='words'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								placeholder={`Full name`}
								onChangeText={(text) =>
									this.setState({
										newRepName: text,
									})
								}
							/>
							<TextInput
								style={{
									width: "100%",
									marginTop: 10,
									height: 40,
									paddingLeft: 10,
									marginBottom: 5,
									fontSize: 14,
									borderRadius: 8,
									backgroundColor: "#434350",
									color: "#FFD76E",
									fontWeight: "400",
								}}
								placeholderTextColor={"#FFF"}
								autoCapitalize='words'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								placeholder={`Department`}
								onChangeText={(text) =>
									this.setState({
										newRepDepartment: text,
									})
								}
							/>
							<TextInput
								style={{
									width: "100%",
									marginTop: 10,
									height: 40,
									paddingLeft: 10,
									marginBottom: 5,
									fontSize: 14,
									borderRadius: 8,
									backgroundColor: "#434350",
									color: "#FFD76E",
									fontWeight: "400",
								}}
								placeholderTextColor={"#FFF"}
								clearButtonMode='while-editing'
								autoCapitalize='none'
								autoCompleteType='off'
								placeholder={`Email address`}
								onChangeText={(text) =>
									this.setState({
										newRepEmail: text,
									})
								}
							/>
							<TextInput
								style={{
									width: "100%",
									marginTop: 10,
									height: 40,
									paddingLeft: 10,
									marginBottom: 5,
									fontSize: 14,
									borderRadius: 8,
									backgroundColor: "#434350",
									color: "#FFD76E",
									fontWeight: "400",
								}}
								placeholderTextColor={"#FFF"}
								clearButtonMode='while-editing'
								autoCompleteType='off'
								autoCapitalize='none'
								keyboardType='decimal-pad'
								placeholder={`Cell number`}
								onChangeText={(text) =>
									this.setState({
										newRepCell: text,
									})
								}
							/>
							<TouchableOpacity onPress={() => this.addRepresentative()}>
								<View
									style={{
										width: "100%",
										height: 35,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: "#FF5F5F",
										marginTop: 20,
										borderRadius: 12,
									}}>
									<Text
										style={{
											color: "#FFF",
											fontWeight: "600",
										}}>
										Save
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					) : null}

					{this.state.loading ? (
						<View
							style={{
								width: "100%",
								padding: 30,
								borderRadius: 8,
							}}>
							<ActivityIndicator color={"#FFF"} />
						</View>
					) : null}
				</View>
			</View>
		);
	}
}
