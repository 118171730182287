import React, { Component, useReducer } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from "react-native";
import { APP_COLOURS } from "../APP_VARS";
import ModalChangeLog from "./TaskComponents/ModalChangeLog";
import ModalAddTask from "./TaskComponents/ModalAddTask";
import TaskItem from "./TaskComponents/TaskItem";
import addBtn from "../../assets/images/add_btn.png";
import debbie from "../../assets/images/cloud.png";
import gantt_icon from "../../assets/images/gantt_icon.png";
import kanban from "../../assets/images/DevTracker.png";
import search from "../../assets/images/search.png";
import todayList from "../../assets/images/todayList.png";
import appFeatures from "../../assets/images/appFeatures.png";

import moment from "moment";
import Calendar from "./TaskComponents/Calendar";

import {
	api_getTasks,
	api_updateTask,
	api_addTask,
	api_deleteTask,
} from "../Api";
import { ActivityIndicator } from "react-native-web";
import KanBan from "./TaskComponents/KanBan";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class Tasks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addNewTaskModal: false,
			showDelete: false,

			tasks: [
				// {
				// 	id: 1,
				// 	title: "First task",
				// 	assigned: ["1", "3"],
				// },
			],

			users: [],

			notes: [],

			filterTasks: this.props.user_data,

			filterSearch: "",

			showChangeLogModal: false,

			changeLog: [],

			showSearch: false,

			viewPage: "Tasks",
		};
	}

	componentDidMount() {
		this.getTasks();
	}

	showChangeLogModal = (event) => {
		event.preventDefault();
		const layout = event.nativeEvent;
		let x = layout.clientX;
		let y = layout.clientY;
		this.setState({
			xPos: x,
			yPos: y,
			showChangeLogModal: true,
		});
		// console.log(x, y);
	};

	render() {
		let avatars = {
			1: require("./../../assets/images/male2.png"),
			2: require("./../../assets/images/male1.png"),
			3: require("./../../assets/images/female1.png"),
		};
		// .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
		let tasksArray = this.state.tasks;

		// .filter((d) => {
		// 	let filterTasks = this.state.filterTasks;
		// 	if (filterTasks !== "") {
		// 		console.log(filterTasks.id);
		// 		const containsPerson = d.assignment.filter(
		// 			(dd) => dd.id === filterTasks.id,
		// 		);
		// 		if (containsPerson.length > 0) {
		// 			return d;
		// 		}
		// 	} else {
		// 		return d;
		// 	}
		// })

		// .filter((d_) => {
		// 	let filter = d_;

		// 	if (
		// 		typeof this.state.filterSearch !== "undefined" &&
		// 		this.state.filterSearch !== ""
		// 	) {
		// 		let text_ = this.state.filterSearch;
		// 		var outString = text_.replace(
		// 			/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
		// 			"",
		// 		);

		// 		let join_names = `${d_.title} ${d_.description} ${d_.status}`;

		// 		return join_names
		// 			.toLowerCase()
		// 			.includes(outString.toLowerCase());
		// 	}

		// 	return filter;
		// });
		return (
			<View
				style={{
					height: this.props.screenHeight - 10,
					// backgroundColor: "rgba(25, 25, 25, 0.9)",
				}}>
				<View
					style={{
						flex: 1,
					}}>
					{this.state.showSearch ? (
						<View
							style={{
								width: "100%",
								// height: "auto",
								// height: 55,
								padding: 3,
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								backgroundColor: APP_COLOURS.BG3,
								borderRadius: 12,
								margin: 5,
							}}>
							<TextInput
								style={{
									// width: 250,
									flex: 1,
									padding: 10,
									fontSize: 15,
									borderRadius: 12,
									color: "#FFF",
									fontWeight: "400",
									fontFamily: "Avenir",
									borderWidth: 0.5,
									borderColor: APP_COLOURS.BG3,
									backgroundColor: APP_COLOURS.BG2,
									marginBottom: 1,
								}}
								placeholderTextColor={APP_COLOURS.BG3}
								multiline
								// numberOfLines={10}
								autoCapitalize='sentences'
								autoFocus={true}
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={false}
								keyboardType='default'
								textContentType='none'
								placeholder={`  Search `}
								// defaultValue={this.state.user_data.email}
								defaultValue={this.state.filterSearch}
								onChangeText={(text) => {
									console.log(text);
									this.setState({
										filterSearch: text,
									});
								}}
							/>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										showSearch: !this.state.showSearch,
										filterSearch: "",
									});
								}}
								style={{
									// flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "center",
									marginBottom: 2,
									marginRight: 15,
									// backgroundColor: APP_COLOURS.BG2,
									borderRadius: 10,
									padding: 10,
								}}>
								<Image
									source={search}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>
					) : (
						<View
							style={{
								width: "100%",
								// height: "auto",
								flexDirection: "row",
								justifyContent: "flex-end",
								alignItems: "center",
								paddingLeft: 10,
								height: 59,
							}}>
							<TouchableOpacity
								style={{
									alignItems: "center",
									marginRight: 20,
								}}
								onPress={() => {
									this.getTasks();
								}}>
								<View
									style={{
										width: 30,
										height: 30,
										justifyContent: "center",
										alignItems: "center",
									}}>
									{this.state.loading ? (
										<ActivityIndicator
											color={APP_COLOURS.BLUE}
										/>
									) : (
										<Image
											source={debbie}
											style={{
												width: 30,
												height: 30,
												// borderRadius: 20,
												resizeMode: "contain",
											}}
										/>
									)}
								</View>
							</TouchableOpacity>
							<View style={{ flex: 1 }} />
							<TouchableOpacity
								onPress={() => {
									this.setState({
										viewPage:
											this.state.viewPage === "Kanban"
												? "Tasks"
												: "Kanban",
									});
								}}
								style={{
									// flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									marginBottom: 2,
									marginRight: 15,
									backgroundColor: APP_COLOURS.BG2,
									borderRadius: 10,
									padding: 10,
								}}>
								{this.state.viewPage !== "Kanban" ? (
									<Image
										source={kanban}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								) : (
									<Image
										source={appFeatures}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								)}
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										viewPage: "Gantt",
									});
								}}
								style={{
									// flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									marginBottom: 2,
									marginRight: 15,
									backgroundColor: APP_COLOURS.BG2,
									borderRadius: 10,
									padding: 10,
								}}>
								<Image
									source={gantt_icon}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
							{/* <View style={{ flex: 1 }}> */}
							<TouchableOpacity
								onPress={() =>
									this.setState({
										addNewTaskModal: true,
									})
								}
								style={{
									justifyContent: "flex-start",
									alignItems: "center",
									marginBottom: 2,
									marginRight: 15,
									backgroundColor: APP_COLOURS.BG2,
									borderRadius: 10,
									padding: 10,
								}}>
								<Image
									source={addBtn}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
										// backgroundColor: "#FFF",
										marginBottom: 5,
									}}
								/>
								{/* <View style={{}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 9,
											fontWeight: "400",
											color: APP_COLOURS.WHITE,
										}}>
										New task
									</Text>
								</View> */}
							</TouchableOpacity>
							{/* </View> */}
							{/* {this.state.filterTasks !== "" ? (
								<TouchableOpacity
									onPress={() => {
										this.setState({
											filterTasks: "",
										});
									}}
									style={{
										// flexDirection: "row",
										justifyContent: "flex-start",
										alignItems: "center",
										marginBottom: 2,
										marginRight: 15,
										backgroundColor: APP_COLOURS.BG2,
										borderRadius: 10,
										padding: 10,
									}}>
									<Image
										source={gantt_icon}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
											// backgroundColor: "#FFF",
											marginBottom: 5,
										}}
									/>
									<View style={{}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 9,
												fontWeight: "400",
												color: APP_COLOURS.WHITE,
											}}>
											View all tasks
										</Text>
									</View>
								</TouchableOpacity>
							) : (
								<TouchableOpacity
									onPress={() => {
										this.setState({
											filterTasks: this.props.user_data,
										});
									}}
									style={{
										// flexDirection: "row",
										justifyContent: "flex-start",
										alignItems: "center",
										marginBottom: 2,
										marginRight: 15,
										backgroundColor: APP_COLOURS.BG2,
										borderRadius: 10,
										padding: 10,
									}}>
									<Image
										source={avatars[this.props.user_data.avatar]}
										style={{
											width: 20,
											height: 20,
											borderRadius: 25,
											resizeMode: "contain",
											backgroundColor: "#FFF",
											marginBottom: 5,
										}}
									/>
									<View style={{}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 9,
												fontWeight: "400",
												color: APP_COLOURS.WHITE,
											}}>
											{this.props.user_data.first_name}
											's tasks
										</Text>
									</View>
								</TouchableOpacity>
							)} */}

							<TouchableOpacity
								activeOpacity={1}
								onPress={(e) => {
									if (this.state.changeLog.length > 0) {
										this.showChangeLogModal(e);
									} else {
										alert("No items to display");
									}
								}}
								style={{
									flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "center",
									marginBottom: 2,
									marginRight: 15,
								}}>
								<Image
									source={todayList}
									style={{
										width: 22,
										height: 22,
										// borderRadius: 25,
										resizeMode: "contain",
										marginRight: 5,
									}}
								/>
								<View
									style={{
										position: "absolute",
										display:
											this.state.changeLog.length > 0
												? "flex"
												: "none",
										right: 0,
										top: 15,
										backgroundColor: "red",
										borderRadius: 5,
										width: 10,
										height: 10,
									}}></View>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={() => {
									this.setState({
										showSearch: !this.state.showSearch,
										filterSearch: "",
									});
								}}
								style={{
									// flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									marginBottom: 2,
									marginRight: 15,
									// backgroundColor: APP_COLOURS.BG2,
									borderRadius: 10,
									padding: 10,
								}}>
								<Image
									source={search}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={() =>
									this.setState({
										showDelete: !this.state.showDelete,
									})
								}
								style={{
									flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "center",

									borderRadius: 10,
									margin: 5,
									marginRight: 15,
								}}>
								<View style={{}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 15,
											fontWeight: "400",
											color: APP_COLOURS.BLUE,
										}}>
										Edit
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					)}
					<View
						style={{
							flex: 1,
							padding: 10,
							borderRadius: 10,
							display:
								this.state.viewPage === "Gantt"
									? "flex"
									: "none",
						}}>
						<Calendar
							data={tasksArray}
							height={this.props.screenHeight - 120}
							screenWidth={this.props.screenWidth}
							hover={this.state.hover}
							setHover={(d) =>
								this.setState({
									hover: d,
								})
							}
							selectedItem={(t) => {
								this.setState({
									selectedItem: t,
								});
								console.log(t);
							}}
						/>
					</View>

					<View
						style={{
							flex: 1,
							padding: 10,
							borderRadius: 10,
							display:
								this.state.viewPage === "Kanban"
									? "flex"
									: "none",
						}}>
						<KanBan
							{...this.state}
							{...this.props}
							data={tasksArray}
							height={this.props.screenHeight - 120}
							hover={this.state.hover}
							setHover={(d) =>
								this.setState({
									hover: d,
								})
							}
							selectedItem={(t) => {
								this.setState({
									selectedItem: t,
								});
								console.log(t);
							}}
						/>
					</View>

					<View
						style={{
							flex: 1,
							display:
								this.state.viewPage === "Tasks"
									? "flex"
									: "none",
						}}>
						<ScrollView
							overScrollMode='never'
							contentContainerStyle={
								{
									// paddingRight: 10,
									// display: "none",
								}
							}>
							<View
								style={{
									flex: 1,
								}}>
								{tasksArray
									.filter((d, i) => {
										let due = 1;
										const date1 = moment();
										const date2 = moment(d.dueDate);
										due = date2.diff(date1, "days");

										if (d.status === "Inprogress") {
											return d;
										} else {
											if (
												due <= 0 &&
												d.status !== "Done"
											) {
												return d;
											}
										}
									})
									.map((task) => (
										<View
											key={task.id}
											style={{
												padding: 5,
												width: "100%",
												height: "auto",
												flexDirection: "row",
											}}>
											<TaskItem
												selectedTask={
													this.state.selectedItem
												}
												accessToken={
													this.props.accessToken
												}
												screenHeight={
													this.props.screenHeight
												}
												screenWidth={
													this.props.screenWidth
												}
												data={task}
												notes={this.state.notes.filter(
													(dm) =>
														dm.task_id == task.id,
												)}
												addNotes={(data) =>
													this.addNotes(data)
												}
												addChangeStatus={(d) =>
													this.addChangeStatus(d)
												}
												updateItem={(d) => {
													this.updateItem(d);
												}}
												user_data={this.props.user_data}
												users={this.props.users}
												showDelete={
													this.state.showDelete
												}
												deleteItem={(d) =>
													this.deleteTask(d)
												}
												hover={this.state.hover}
												setHover={(d) =>
													this.setState({
														hover: d,
													})
												}
											/>
										</View>
									))}
								<View
									style={{
										height: 40,
									}}
								/>
								{tasksArray
									.filter((d, i) => {
										let due = 1;
										const date1 = moment();
										const date2 = moment(d.dueDate);
										due = date2.diff(date1, "days");
										if (
											d.status !== "Inprogress" &&
											d.status !== "Done" &&
											due <= 0
										) {
											return d;
										}
									})
									.map((task) => (
										<View
											key={task.id}
											style={{
												padding: 5,
												width: "100%",
												height: "auto",
												flexDirection: "row",
											}}>
											<TaskItem
												selectedTask={
													this.state.selectedItem
												}
												accessToken={
													this.props.accessToken
												}
												screenHeight={
													this.props.screenHeight
												}
												screenWidth={
													this.props.screenWidth
												}
												data={task}
												notes={this.state.notes.filter(
													(dm) =>
														dm.task_id == task.id,
												)}
												addNotes={(data) =>
													this.addNotes(data)
												}
												addChangeStatus={(d) =>
													this.addChangeStatus(d)
												}
												updateItem={(d) => {
													this.updateItem(d);
												}}
												user_data={this.props.user_data}
												users={this.props.users}
												showDelete={
													this.state.showDelete
												}
												deleteItem={(d) =>
													this.deleteTask(d)
												}
												hover={this.state.hover}
												setHover={(d) =>
													this.setState({
														hover: d,
													})
												}
											/>
										</View>
									))}
								<View
									style={{
										height: 40,
									}}
								/>
								{tasksArray
									.filter((d, i) => {
										if (d.status === "Done") {
											return d;
										}
									})
									.map((task) => (
										<View
											key={task.id}
											style={{
												padding: 5,
												width: "100%",
												height: "auto",
												flexDirection: "row",
											}}>
											<TaskItem
												selectedTask={
													this.state.selectedItem
												}
												accessToken={
													this.props.accessToken
												}
												screenHeight={
													this.props.screenHeight
												}
												screenWidth={
													this.props.screenWidth
												}
												data={task}
												notes={this.state.notes.filter(
													(dm) =>
														dm.task_id == task.id,
												)}
												addNotes={(data) =>
													this.addNotes(data)
												}
												addChangeStatus={(d) =>
													this.addChangeStatus(d)
												}
												updateItem={(d) => {
													this.updateItem(d);
												}}
												user_data={this.props.user_data}
												users={this.props.users}
												showDelete={
													this.state.showDelete
												}
												deleteItem={(d) =>
													this.deleteTask(d)
												}
												hover={this.state.hover}
												setHover={(d) =>
													this.setState({
														hover: d,
													})
												}
											/>
										</View>
									))}
								<View
									style={{
										height: 90,
									}}
								/>
								{this.state.loading &&
								this.state.tasks.length === 0 ? (
									<View
										style={{
											// height: 30,
											// width: 60,
											padding: 10,
											justifyContent: "center",
											alignItems: "center",
											alignSelf: "center",
											backgroundColor: APP_COLOURS.BG2,
											borderRadius: 14,
										}}>
										<ActivityIndicator color='#FFF' />
										<Text
											style={{
												color: "#FFF",
												fontSize: 9,
												opacity: 0.7,
												marginTop: 5,
											}}>
											Loading tasks
										</Text>
									</View>
								) : null}
							</View>
						</ScrollView>
					</View>
				</View>

				{this.state.addNewTaskModal ? this.taskForm() : <View />}

				{this.state.showChangeLogModal &&
				this.state.changeLog.length > 0 ? (
					<ModalChangeLog
						xPos={this.state.xPos}
						yPos={this.state.yPos}
						screenHeight={this.props.screenHeight}
						user_data={this.props.user_data}
						changeLog={this.state.changeLog}
						logOut={() => this.props.logOut()}
						closeModal={() =>
							this.setState({
								showChangeLogModal: false,
							})
						}
						deleteLog={() => this.deleteChangeLog()}
					/>
				) : null}
			</View>
		);
	}

	taskForm = () => {
		return (
			<ModalAddTask
				{...this.props}
				users={this.props.users}
				close={(d) =>
					this.setState({
						addNewTaskModal: d,
					})
				}
				addNewTask={this.addNewTask.bind(this)}
			/>
		);
	};

	addNewTask = async (obj) => {
		let uuid = uuidv4();
		obj.id = uuid;
		obj.addedBy = this.props.user_data;
		delete obj.addedBy.accessToken;

		await this.setState({
			// tasks: this.state.tasks.concat(obj),

			addNewTaskModal: false,
			taskAssignment: [],
			taskName: "",
		});
		await this.addTask(obj);
		await this.addToChangeLog(`added task: ${obj.taskName}`);
	};

	addToChangeLog = async (title) => {
		let uuid = uuidv4();
		let data = {
			id: uuid,
			title: title,
			date_created: moment().toLocaleString(),
			addedBy: this.props.user_data,
		};

		await this.setState({
			changeLog: this.state.changeLog.concat(data),
		});

		// await this.updateTask();
	};

	deleteChangeLog = async () => {
		await this.setState({
			changeLog: [],
			showChangeLogModal: false,
		});
		// await this.updateTask();
	};

	addChangeStatus = async (d) => {
		console.log(d);
		let tasks = this.state.tasks.filter((task) => task.id !== d.id);
		let sorted = tasks.concat(d);
		await this.setState({
			tasks: sorted,
		});
		await this.addToChangeLog(`status changed to ${d.status}, ${d.title}`);
		this.updateTask(d);
	};

	updateItem = async (d) => {
		console.log(d);
		let tasks = this.state.tasks.filter((task) => task.id !== d.id);
		let sorted = tasks.concat(d);
		await this.setState({
			tasks: sorted,
		});
		await this.addToChangeLog(`updated, ${d.title}`);
		this.updateTask(d);
	};

	addNotes = async (d) => {
		// console.log(d);
		await this.setState({
			notes: this.state.notes.concat(d),
		});
		await this.addToChangeLog(
			`added note: ${d.title}, task id: ${d.task_id}`,
		);
	};

	getTasks = async () => {
		let url = api_getTasks;

		await this.setState({
			loading: true,
		});

		let requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("responseData", responseData);
							this.setState({
								tasks: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							// this.setState({
							// 	loading: false,
							// });
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					// this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				// this.setState({
				// 	loading: false,
				// });
			});
	};

	updateTask = (obj) => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify(obj);

		let requestOptions = {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
			body: raw,
		};

		// console.log(obj);
		// return;

		fetch(api_updateTask, requestOptions)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result", result);

							this.setState({
								tasks: result,
								loading: false,
							});
						})
						.catch((error) =>
							this.setState({
								loading: false,
							}),
						);
				} else {
					this.setState({
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	deleteTask = async (d) => {
		//  below isnt neccesary
		// const tasks = this.state.tasks.filter((d_) => d.id !== d_.id);
		// const notes = this.state.notes.filter((d_) => d.id !== d_.task_id);
		// await this.setState({
		// 	tasks: [...tasks],
		// 	notes: [...notes],
		// 	showDelete: false,
		// });
		await this.addToChangeLog(`deleted task, ${d.title}`);
		//  from server
		await this.deleteTaskReq(d.id);
	};

	deleteTaskReq = (id) => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			id: id,
		});

		let requestOptions = {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
			body: raw,
		};

		// console.log(obj);
		// return;

		fetch(api_deleteTask, requestOptions)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then(async (result) => {
							await console.log("result", result);

							await this.setState({
								tasks: result,
								loading: false,
								showDelete: false,
							});
						})
						.catch((error) =>
							this.setState({
								loading: false,
							}),
						);
				} else {
					this.setState({
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	addTask = (obj) => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify(obj);

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
			body: raw,
		};

		fetch(api_addTask, requestOptions)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result", result);

							this.setState({
								tasks: result,
								loading: false,
							});
						})
						.catch((error) =>
							this.setState({
								loading: false,
							}),
						);
				} else {
					this.setState({
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};
}

export default Tasks;
