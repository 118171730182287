import React, { Component } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import { APP_COLOURS } from "../APP_VARS";

class Companies extends Component {
	constructor(props) {
		super(props);
		this.state = {
			companies: [
				{
					id: 1,
					title: "Westonry SA (Pty) Ltd",
				},
				{
					id: 2,
					title: "Westonry LLC",
				},
				{
					id: 3,
					title: "WNB Trust",
				},
			],
			selectedCompany: "",
		};
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 5,
					flexDirection: "row",
				}}>
				<View
					style={{
						height: "100%",
						width: 200,
						padding: 5,
					}}>
					{this.state.companies.map((d, i) => (
						<View
							key={i}
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								height: "auto",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: 30,
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 45,
									marginBottom: 10,
									backgroundColor: APP_COLOURS.BUTTON2,
									flexDirection: "row",
								}}
								onPress={() =>
									this.setState({
										selectedCompany: d.title,
									})
								}>
								<View
									style={{
										width: 5,
										borderRadius: 4,
										height: "100%",
										backgroundColor:
											this.state.selectedCompany ==
											d.title
												? APP_COLOURS.BLUE
												: APP_COLOURS.WHITE_TRANSPARENT,
										marginRight: 12,
									}}
								/>
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										alignItems: "center",
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											// opacity: 0.8,
											color: APP_COLOURS.WHITE,
										}}>
										{d.title}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					))}
				</View>
				<View
					style={{
						flex: 1,
						padding: 5,
					}}>
					<View
						style={{
							flex: 1,
							backgroundColor: APP_COLOURS.BG2,
							borderRadius: 10,
							padding: 5,
						}}>
						<Text
							style={{
								color: APP_COLOURS.WHITE,
							}}>
							{this.state.selectedCompany}
						</Text>

						<ScrollView
							contentContainerStyle={{
								paddingRight: 10,
								height: this.props.screenHeight - 160,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								{this.state.companies.map((task, ind) => (
									<View
										style={{
											padding: 5,
											width: "100%",
											height: "auto",
											flexDirection: "row",
										}}></View>
								))}
								<View
									style={{
										height: 200,
										width: 100,
										// backgroundColor: "red",
									}}
								/>
							</View>
						</ScrollView>
					</View>
				</View>
			</View>
		);
	}
}

export default Companies;
