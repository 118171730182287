import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import send from "./../../../assets/images/send.png";
import pdf from "./../../../assets/images/pdf.png";
import bin from "./../../../assets/images/bin.png";
import moment from "moment";

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: false,
		};
	}

	addNewAccount = async (d) => {
		let uuid = uuidv4();
		if (this.state.noteData == "") {
			return;
		}
		let data = {
			id: uuid,
			task_id: this.props.data.id,
			data: this.state.noteData,
			date_added: moment().toLocaleString(),
			user: this.props.user_data,
		};
		await this.setState({
			noteData: "",
		});
		await console.log(this.props.user_data);
		await this.textinput.clear();
		await this.props.addNotes(data);
	};

	render() {
		let d = this.props.data;
		let users = this.props.users;
		return (
			<View
				style={{
					// flex: 1,
					width: "100%",
					height: "auto",
					borderWidth: this.state.showMore ? 0.3 : 0,
					borderColor: "#797979",
					borderRadius: 5,
					borderStyle: "dotted",
					marginBottom: 2,
				}}>
				<View
					onMouseEnter={() =>
						this.setState({
							hover: true,
						})
					}
					onMouseLeave={() =>
						this.setState({
							hover: false,
						})
					}
					style={{
						flex: 1,
						flexDirection: "row",
						borderRadius: 5,
						opacity: this.state.hover ? 1 : 0.9,
						// backgroundColor: "rgba(255, 255, 255, 0.1)",
						borderBottomColor: "rgba(255, 255, 255, 0.3)",
						borderBottomWidth: 0.5,
						width: "100%",
						minHeight: 10,
						padding: 3,
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<TouchableOpacity
						activeOpacity={0.8}
						style={{
							flex: 1,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							height: "100%",
							minHeight: 10,
						}}
						onPress={() => {
							this.setState({
								showMore: !this.state.showMore,
							});
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								height: "100%",
								minHeight: 10,
							}}>
							{/* <Image
								source={pdf}
								style={{
									width: 30,
									height: 30,
									// borderRadius: 25,
									resizeMode: "contain",
									marginRight: 10,
									marginLeft: 10,
								}}
							/> */}

							<View
								style={{
									flex: 1,
									flexDirection: "row",
									paddingLeft: 5,
								}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										fontWeight: "400",
										color: APP_COLOURS.WHITE,
									}}>
									{d.title}
								</Text>
							</View>

							{/* <View
								style={{
									flex: 1,
								}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 9,
										fontWeight: "300",
										color: APP_COLOURS.WHITE,
									}}>
									expiry date
								</Text>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										fontWeight: "600",
										color: APP_COLOURS.WHITE,
									}}>
									{d.expiry_date}
								</Text>
							</View> */}
						</View>
					</TouchableOpacity>

					{this.props.showDelete ? (
						<TouchableOpacity
							onPress={() => this.props.deleteItem(d)}
							style={{
								flexDirection: "row",
								justifyContent: "center",
								width: 40,
								alignItems: "center",
								padding: 5,
								// borderLeftWidth: 0.5,
								// borderLeftColor: "#FFF",
								marginLeft: 5,
							}}>
							<Image
								source={bin}
								style={{
									width: 22,
									height: 22,
									// borderRadius: 25,
									resizeMode: "contain",
									marginRight: 5,
								}}
							/>
						</TouchableOpacity>
					) : null}
				</View>
			</View>
		);
	}
}

export default index;
