import React, { Component } from "react";
import {
	View,
	Text,
	SafeAreaView,
	ScrollView,
	Dimensions,
	TextInput,
	Keyboard,
	TouchableOpacity,
	Image,
	LayoutAnimation,
	Alert,
	Modal,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class SideBar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let accountingMenu = [
			{
				id: "FormalLetter",
			},
		];
		return (
			<View
				style={{
					width: 150,
					padding: 5,
					height: 500,
				}}>
				<ScrollView>
					{accountingMenu.map((d, i) => (
						<View
							key={i}
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
							}}>
							<TouchableOpacity
								// onPress={() =>
								// 	this.props.history.push(
								// 		'/dashboard/Location',
								// 	)
								// }
								style={{
									width: "100%",
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									height: 35,
									marginBottom: 5,
									backgroundColor: APP_COLOURS.BUTTON2,
									flexDirection: "row",
								}}
								onPress={() => {
									this.setState({
										accountsPageSelected: d.id,
									});
									this.props.selectedPage(d.id);
								}}>
								<View
									style={{
										width: 5,
										borderRadius: 4,
										height: "100%",
										backgroundColor:
											this.state.accountsPageSelected == d.id
												? APP_COLOURS.BLUE
												: APP_COLOURS.WHITE_TRANSPARENT,
										marginRight: 12,
									}}
								/>
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										alignItems: "center",
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											// opacity: 0.8,
											color: APP_COLOURS.WHITE,
										}}>
										{d.id}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					))}
				</ScrollView>
			</View>
		);
	}
}
