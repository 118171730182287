import React, { Component } from "react";
import {
	View,
	Text,
	SafeAreaView,
	ScrollView,
	Dimensions,
	TextInput,
	Keyboard,
	TouchableOpacity,
	Image,
	ActivityIndicator,
	Alert,
	Modal,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import add_btn from "../../../assets/images/add_btn.png";
import ClientPage from "./ClientPage";
import ExportToExcel from "../ExportToExcel";
import {
	getJsonDataFromServer,
	saveJsonDataToServer,
} from "../ServerRequestHelper";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clients: [],
			exportableClients: [], // excluides profile pics
			selectedClientId: "",
			selectedClient: "",
			loading: true,
		};
	}

	componentDidMount() {
		this.inti();
	}

	componentDidUpdate(prevProps) {
		console.log(this.props.selectedEntityID);
		if (prevProps.selectedEntityID !== this.props.selectedEntityID) {
			this.inti();
		}
	}

	inti = () => {
		let sf = `clients_${this.props.selectedEntityID}`;
		if (
			typeof this.props.selectedEntityID !== "undefined" &&
			this.props.selectedEntityID !== ""
		) {
			this.setState({ loading: true });
			getJsonDataFromServer(sf, async (status, data) => {
				if (status === 200) {
					const d = data;
					await this.setState({
						clients: [...d],
					});

					const newArr = [...d];
					const newArray = newArr.map(({ logoImage, ...rest }) => rest);
					// console.log(newArray);
					this.setState({
						exportableClients: newArray,
					});
				} else {
					console.log("saveJsonDataToServer returned", status, data);
				}
				this.setState({
					loading: false,
				});
			});
		} else {
			alert("Select entity");
		}
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
				}}>
				<View
					style={{
						paddingTop: 15,
						paddingLeft: 15,
						paddingRight: 15,
						backgroundColor: "#191D24", // '#F6F8FE',
						borderTopRightRadius: 30,
						borderBottomRightRadius: 30,
						// width: 260,
					}}>
					<View
						style={{
							marginRight: 10,
							flexDirection: "row",
						}}>
						<Text
							style={{
								color: "#F6F8FE", // '#5A7BBA',
								fontSize: 25,
								fontWeight: "900",
								marginBottom: 10,
							}}>
							Clients
						</Text>
					</View>
					<View
						style={{
							flex: 1,

							paddingBottom: 20,
						}}>
						{this.state.loading ? (
							<View
								style={{
									flex: 1,
									width: 200,
									padding: 30,
									borderRadius: 8,
									backgroundColor: APP_COLOURS.WHITE_TRANSPARENT3,
									opacity: 0.3,
								}}>
								<ActivityIndicator color={"#FFF"} />
							</View>
						) : (
							<View
								style={{
									flex: 1,
									width: 200,
									paddingRight: 5,
								}}>
								<View
									style={{
										marginTop: 5,
										marginBottom: 5,
									}}>
									{this.state.addClient ? (
										<TextInput
											style={{
												width: "90%",
												// marginTop: 5,
												height: 40,
												paddingLeft: 10,
												// marginBottom: 40,
												fontSize: 16,
												borderRadius: 8,
												backgroundColor: "#434350",
												color: "#FFF",
											}}
											placeholderTextColor={"#828282"}
											clearButtonMode='while-editing'
											autoCompleteType='off'
											autoFocus
											placeholder={`New Client Name`}
											onChangeText={(text) =>
												this.setState({
													newclientName: text,
												})
											}
											onSubmitEditing={() =>
												this.addClient(this.state.newclientName)
											}
											ref={(ref) => {
												this.textInput1 = ref;
											}}
										/>
									) : (
										<View
											style={{
												flexDirection: "row",
											}}>
											<TouchableOpacity
												style={{
													// marginTop: 5,
													width: 25,
													height: 25,
													marginRight: 10,
												}}
												onPress={async () => {
													this.setState({
														addClient: true,
													});
												}}>
												<Image
													style={{
														width: 25,
														height: 25,
														resizeMode: "contain",
													}}
													source={add_btn}
												/>
											</TouchableOpacity>
											<ExportToExcel
												heightWidth={24}
												excel_doc_name={"clients"}
												data={this.state.exportableClients}
											/>
										</View>
									)}
								</View>
								<ScrollView>
									<View
										style={{
											height: 5,
											borderBottomColor: "rgba(255, 255, 255, 0.1)",
											borderBottomWidth: 0.5,
										}}
									/>
									{/* .filter(
										(d) => d.clientOf == this.props.selectedEntityID,
									) */}
									{this.state.clients
										.sort((a, b) => b.title < a.title)
										.map((d, i) => (
											<View
												key={i}
												style={{
													borderBottomColor:
														"rgba(255, 255, 255, 0.1)",
													borderBottomWidth: 0.5,
												}}>
												<TouchableOpacity
													onPress={() => {
														this.setState({
															selectedClientId: d.id,
															selectedClient: d,
															addClient: false,
														});
													}}>
													<View
														style={{
															width: "100%",
															// height: 55,
															padding: 7,
															paddingLeft: 0,
															paddingRight: 10,
															borderRadius: 3,
															// justifyContent:
															// 	"center",
															alignItems: "center",
															flexDirection: "row",
															// backgroundColor:
															// 	this
															// 		.state
															// 		.selectedClientId ==
															// 	d.id
															// 		? BLUE
															// 		: "transparent",
															// marginBottom: 10,
														}}>
														{/* <View
																	style={{
																		width: 5,
																		borderRadius: 4,
																		height: 15,
																		backgroundColor:
																			this
																				.state
																				.selectedClientId ==
																			d.id
																				? APP_COLOURS.BLUE
																				: APP_COLOURS.WHITE_TRANSPARENT,
																		marginRight: 12,
																	}}
																/> */}
														<Text
															style={{
																color: "#FFF",
																fontSize: 14,
																fontWeight:
																	this.state
																		.selectedClientId == d.id
																		? "600"
																		: "200",
															}}>
															{d.title}
														</Text>
													</View>
												</TouchableOpacity>
												<View>
													<View
														style={{
															width: "100%",
															alignItems: "flex-end",
														}}></View>
												</View>
											</View>
										))}
								</ScrollView>
							</View>
						)}
					</View>
				</View>
				<View
					style={{
						flex: 1,
						// paddingTop: 20,
						paddingLeft: 20,
					}}>
					{this.state.selectedClientId !== "" ? (
						<ClientPage
							screenWidth={this.state.screenWidth}
							screenHeight={this.state.screenHeight}
							// {...this.state}
							selectedClientId={this.state.selectedClientId}
							selectedClient={this.state.selectedClient}
							// addCoreDocument={this.addCoreDocument.bind(
							// 	this,
							// )}
							// removeCoreDocument={this.removeCoreDocument.bind(
							// 	this,
							// )}
							// addNote={this.addNote.bind(this)}
							// removeNote={this.removeNote.bind(this)}
							deleteClient={this.deleteClient.bind(this)}
							upadateClient={this.upadateClient.bind(this)}
							// addRepresentative={this.addRepresentative.bind(
							// 	this,
							// )}
							// removeRepresentative={this.removeRepresentative.bind(
							// 	this,
							// )}
							// addClientNote={this.addClientNote.bind(
							// 	this,
							// )}
							// removeClientNote={this.removeClientNote.bind(
							// 	this,
							// )}
							// addProject={this.addProject.bind(this)}
							// removeProject={this.removeProject.bind(
							// 	this,
							// )}
						/>
					) : null}
				</View>
			</View>
		);
	}

	addClient = async (newclientName) => {
		let uid = uuid();
		let obj = {
			id: uid,
			title: newclientName,
			representatives: [],
			companyDocuments: [],
			clientNotes: [],
			apps: [],
		};
		let array = await this.state.clients;
		const push_ = await array.push(obj);
		// console.log("addClient", array);
		await this.setState({
			clients: array,
			addClient: false,
			newclientName: "",
			selectedClientId: uid,
			selectedClient: obj,
		});

		this.saveState();
	};

	upadateClient = async (id, data) => {
		let arry = [...this.state.clients];
		console.log(arry);
		let newarr = await arry.filter((d) => d.id != id);
		console.log(newarr);
		let added = [...newarr, data];
		console.log(added);
		await this.setState({
			clients: added,
			selectedClientId: "",
			selectedClient: null,
		}); // This os neccisary becauser the states of the component were remaining the same and therefor the state.title for example wasnt going back to blank
		await this.setState({
			selectedClientId: id,
			selectedClient: data,
		});
		this.saveState();
	};

	deleteClient = async (id) => {
		let arry = [...this.state.clients];
		let newarr = await arry.filter((d) => d.id != id);
		console.log(newarr);
		await this.setState({
			clients: newarr,
			selectedClientId: "",
			selectedClient: null,
		});
		this.saveState();
	};

	saveState = () => {
		let sf = `clients_${this.props.selectedEntityID}`;
		saveJsonDataToServer(sf, this.state.clients, (status, data) => {
			if (status === 200) {
			} else {
				console.log("saveJsonDataToServer returned", status, data);
			}
		});
	};
}
