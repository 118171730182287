import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from "react-native";
import Slide from "react-reveal/Slide";
import moment from "moment";
// import add_btn from "./../../assets/images/add_btn.png";
import { APP_COLOURS } from "../../APP_VARS";
import "./input.css";

function isEven(n) {
	return n % 2 == 0;
}

class ModalTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenWidth: this.props.screenWidth,
			screenHeight: this.props.screenHeight,
			selectedTab: this.props.tabs[0].title,
			selectedTabID: this.props.tabs[0].id,
			showAddItemsForm: false,
		};
	}

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	showAddItemsFormFunc = (event) => {
		event.preventDefault();
		const layout = event.nativeEvent;
		let x = layout.clientX;
		let y = layout.clientY;
		this.setState({
			showAddItemsForm: true,
			xPos: x,
			yPos: y,
		});

		console.log(layout);
	};

	selectTab = (d) => {
		this.props.selectedTab(d.id);
		this.setState({
			selectedTab: d.title,
			selectedTabID: d.id,
			showAddItemsForm: false,
		});
	};

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		return (
			<View style={{}}>
				<TouchableOpacity
					onLayout={(e) => {
						this.setState({
							left: e.nativeEvent.layout.left,
							top: e.nativeEvent.layout.top,
						});
					}}
					style={{
						width: 200,
						height: 30,
						padding: 7,
						alignItems: "center",
						borderRadius: 10,
						minHeight: 40,
						backgroundColor: APP_COLOURS.BUTTON2,
						flexDirection: "row",
					}}
					onPress={(e) => this.showAddItemsFormFunc(e)}>
					<View
						style={{
							width: 5,
							borderRadius: 4,
							height: "100%",
							backgroundColor: APP_COLOURS.BLUE,
							marginRight: 12,
						}}
					/>
					<View
						style={{
							padding: 5,
							borderRadius: 10,
							flex: 1,
						}}>
						<Text
							style={{
								fontFamily: "Avenir",
								fontSize: 15,
								color: "#E8E8E8",
								fontWeight: "500",
							}}>
							{this.state.selectedTab}
						</Text>
					</View>
					<Image
						source={require("../../../assets/images/expand.png")}
						style={{
							width: 20,
							height: 20,
							// borderRadius: 20,
							resizeMode: "contain",
						}}
					/>
				</TouchableOpacity>

				<Modal
					transparent={true}
					visible={this.state.showAddItemsForm}
					animationType='fade'
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
					}}>
					<TouchableOpacity
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
						}}
						onPress={() =>
							this.setState({
								showAddItemsForm: false,
							})
						}>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "rgba(0, 0, 0, 0.6)",
								width: this.state.screenWidth,
								height: this.state.screenHeight,
							}}></View>
					</TouchableOpacity>

					<View
						style={{
							width: 200,
							maxHeight: 250,
							backgroundColor: APP_COLOURS.PRIMARY,
							position: "absolute",
							// top: this.state.yPos,
							top: this.state.top,
							// left: this.state.xPos,
							left: this.state.left,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.25,
							shadowRadius: 7.84,
							elevation: 12,
							borderRadius: 10,
							// padding: 10,
							zIndex: 999,
						}}>
						<ScrollView>
							<TouchableOpacity
								onLayout={(e) => {
									this.setState({
										left: e.nativeEvent.layout.left,
									});
								}}
								style={{
									width: 200,
									height: 30,
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 40,
									backgroundColor: "#F6F8FE",
									flexDirection: "row",
									marginBottom: 10,
								}}
								onPress={(e) => this.showAddItemsFormFunc(e)}>
								<View
									style={{
										width: 5,
										borderRadius: 4,
										height: "100%",
										backgroundColor: "#E8E8E8",
										marginRight: 12,
									}}
								/>
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										flex: 1,
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 15,
											color:
												this.state.selectedTab === "expenses"
													? "#E8E8E8"
													: APP_COLOURS.BLUE,
											fontWeight: "500",
										}}>
										{this.state.selectedTab}
									</Text>
								</View>
								<Image
									source={require("../../../assets/images/expand.png")}
									style={{
										width: 20,
										height: 20,
										// borderRadius: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
							{this.props.tabs.map((d, i) => (
								<View
									key={i}
									style={{
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
									}}>
									<TouchableOpacity
										style={{
											width: "100%",
											padding: 7,
											alignItems: "center",
											borderRadius: 10,
											height: 35,
											marginBottom: 5,
											backgroundColor: APP_COLOURS.BUTTON2,
											flexDirection: "row",
										}}
										onPress={() => this.selectTab(d)}>
										<View
											style={{
												width: 5,
												borderRadius: 4,
												height: "100%",
												backgroundColor:
													this.state.accountsPageSelected == d.id
														? APP_COLOURS.BLUE
														: APP_COLOURS.WHITE_TRANSPARENT,
												marginRight: 12,
											}}
										/>
										<View
											style={{
												padding: 5,
												borderRadius: 10,
												alignItems: "center",
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													// opacity: 0.8,
													color: APP_COLOURS.WHITE,
												}}>
												{d.title}
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							))}
						</ScrollView>
					</View>
				</Modal>
			</View>
		);
	}
}

export default ModalTabs;
