import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Modal,
} from "react-native";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./input.css";

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const BG = "#171B22";
const BG2 = "#20242B";
const FONT = "#F3F3F5";
const BORDER = "#383D43";

const BLUE = "#3F6EFF";
const WHITE = FONT;
const FONT_ALMOSTWHITE = "#D4D4D4";

const RED = "#FF5F5F";
const GREEN = "#3F6EFF";
const OPACITYGREY = "#2F2F39";

const FONT_DARK = "#141D31";
const BORDERBOTTOM = "#28282F";

export default class ModalAddEntry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allocation: "",
			type: "",

			docID: "",
			pdfID: "",
			title: "",
			description: "",
			vatable: "",
			vat: "",
			total: "",
			doc_date: new Date(),
		};
		this._timeout = 0;
	}

	render() {
		return (
			<>
				<Modal animationType='fade' transparent={true} visible={true}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(79, 84, 90, 0.8)",
						}}>
						<View
							style={{
								height: 40,
							}}
						/>
						<View
							style={{
								width: 400,
								// height: this.props.screenHeight / 2,
								height: "auto",
								maxHeight: this.props.screenHeight - 300,
								flex: 1,
								backgroundColor: BG,
								borderRadius: 10,
								padding: 20,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									borderBottomWidth: 0.5,
									paddingBottom: 10,
									borderBottomColor: FONT,
									marginBottom: 20,
								}}>
								<TouchableOpacity
									onPress={async () => {
										this.props.close();
									}}>
									<Image
										style={{
											width: 24,
											height: 24,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View style={{}}>
									<Text
										style={{
											fontSize: 14,
											color: "#FFF",
											fontWeight: "600",
											fontFamily: "Avenir Next",
										}}>
										Add Entry:{" "}
										<Text
											style={{
												fontWeight: "600",
											}}>
											{this.state.docID}
										</Text>
									</Text>
								</View>
							</View>

							{/* CONTENT    */}
							<View
								style={{
									flex: 1,
									width: "100%",
									maxHeight: this.props.screenHeight,
								}}>
								<ScrollView
									showsVerticalScrollIndicator={false}
									ref={(ref) => (this.scrollViewRef = ref)}>
									<View
										style={{
											flex: 1,
											width: "100%",
											// height: 500,
										}}>
										<View
											style={{
												marginBottom: 10,
												padding: 10,
												borderRadius: 10,
												backgroundColor: BG2,
											}}>
											<Text
												style={{
													fontSize: 10,
													color: FONT,
													fontWeight: "400",
													fontFamily: "Avenir Next",
													// opacity: 0.5,
													padding: 0,
													marginBottom: 3,
												}}>
												Documnent Type
											</Text>

											<View
												style={{
													flexDirection: "row",
													justifyContent: "space-evenly",
													marginBottom: 5,
													padding: 10,
													borderRadius: 10,
													backgroundColor: BG2,
												}}>
												<TouchableOpacity
													style={{
														flex: 1,
														marginRight: 3,
													}}
													onPress={() => {
														this.setState({
															type: "Payment",
														});
														this.input1.focus();
													}}>
													<View
														style={{
															flex: 1,
															justifyContent: "center",
															alignItems: "center",
															backgroundColor:
																this.state.type == "Payment"
																	? BLUE
																	: BG2,
															padding: 10,
															borderRadius: 7,
															borderColor:
																this.state.type == "Payment"
																	? BLUE
																	: BORDER,
															borderWidth: 1,
														}}>
														<Text
															style={{
																fontSize: 14,
																color: FONT,
																fontWeight: "500",
																fontFamily: "Avenir Next",
															}}>
															Payment
														</Text>
													</View>
												</TouchableOpacity>
												<TouchableOpacity
													style={{
														flex: 1,
														marginRight: 3,
													}}
													onPress={() =>
														this.setState({
															type: "Invoice",
														})
													}>
													<View
														style={{
															flex: 1,
															justifyContent: "center",
															alignItems: "center",
															backgroundColor:
																this.state.type == "Invoice"
																	? BLUE
																	: BG2,
															padding: 10,
															borderRadius: 7,
															borderColor:
																this.state.type == "Invoice"
																	? BLUE
																	: BORDER,
															borderWidth: 1,
														}}>
														<Text
															style={{
																fontSize: 14,
																color: FONT,
																fontWeight: "500",
																fontFamily: "Avenir Next",
															}}>
															Invoice
														</Text>
													</View>
												</TouchableOpacity>
												<TouchableOpacity
													style={{
														flex: 1,
													}}
													onPress={() =>
														this.setState({
															type: "Credit",
														})
													}>
													<View
														style={{
															flex: 1,
															justifyContent: "center",
															alignItems: "center",
															backgroundColor:
																this.state.type == "Credit"
																	? BLUE
																	: BG2,
															padding: 10,
															borderRadius: 7,
															borderColor:
																this.state.type == "Credit"
																	? BLUE
																	: BORDER,
															borderWidth: 1,
														}}>
														<Text
															style={{
																fontSize: 14,
																color: FONT,
																fontWeight: "500",
																fontFamily: "Avenir Next",
															}}>
															Credit note
														</Text>
													</View>
												</TouchableOpacity>
											</View>
										</View>

										<View
											style={{
												marginBottom: 10,
												padding: 10,
												borderRadius: 10,
												backgroundColor: BG2,
											}}>
											<Text
												style={{
													fontSize: 10,
													color: FONT,
													fontWeight: "400",
													fontFamily: "Avenir Next",
													marginBottom: 3,
												}}>
												Allocation
											</Text>

											<View style={{}}>
												<ScrollView horizontal>
													<View
														style={{
															flexDirection: "row",
															justifyContent: "space-evenly",
															marginBottom: 10,
															padding: 10,
														}}>
														{this.props.clients.map((d, i) => (
															<TouchableOpacity
																style={{
																	flex: 1,
																	marginRight: 3,
																}}
																onPress={() =>
																	this.setState({
																		allocation: d,
																	})
																}>
																<View
																	style={{
																		flex: 1,
																		justifyContent: "center",
																		alignItems: "center",
																		backgroundColor:
																			this.state
																				.allocation == d
																				? BLUE
																				: BG2,
																		padding: 15,
																		borderRadius: 7,
																		borderColor:
																			this.state
																				.allocation == d
																				? BLUE
																				: BORDER,
																		borderWidth: 1,
																	}}>
																	<Text
																		style={{
																			fontSize: 14,
																			color: FONT,
																			fontWeight: "400",
																			fontFamily:
																				"Avenir Next",
																		}}>
																		{d}
																	</Text>
																</View>
															</TouchableOpacity>
														))}
													</View>
												</ScrollView>
											</View>
										</View>

										<View
											style={{
												marginTop: 20,
											}}>
											<Text
												style={{
													fontWeight: "400",
													fontFamily: "Avenir Next",
													fontSize: 12,
													color: BORDER,
													marginBottom: 4,
													position: "absolute",
													top: -12,
													left: 9,
													backgroundColor: BG,
													padding: 4,
													borderRadius: 8,
												}}>
												INV # /REC #
											</Text>

											<TextInput
												style={{
													fontSize: 16,
													color: FONT,
													fontWeight:
														this.state.docID !== ""
															? "700"
															: "400",
													fontFamily: "Avenir Next",
													width: "100%",
													paddingLeft: 10,
													padding: 13,
													// backgroundColor:
													// 	this.state.docID !== "" ? BLUE : BG2,
													color: FONT,
													borderRadius: 7,
													borderColor: BORDER,
													borderWidth: 1,
												}}
												placeholderTextColor={FONT}
												autoCapitalize='words'
												autoCorrect={false}
												clearButtonMode='while-editing'
												autoCompleteType='off'
												placeholder={`INV# / REC#`}
												onChangeText={(text) =>
													this.setState({
														docID: text,
													})
												}
											/>
										</View>

										<View
											style={{
												marginTop: 20,
											}}>
											<Text
												style={{
													fontWeight: "400",
													fontFamily: "Avenir Next",
													fontSize: 12,
													color: BORDER,
													marginBottom: 4,
													position: "absolute",
													top: -12,
													left: 9,
													backgroundColor: BG,
													padding: 4,
													borderRadius: 8,
												}}>
												Document title
											</Text>

											<TextInput
												style={{
													fontSize: 16,
													color: FONT,
													fontWeight:
														this.state.title !== ""
															? "700"
															: "400",
													fontFamily: "Avenir Next",
													width: "100%",
													paddingLeft: 10,
													padding: 13,
													// backgroundColor:
													// 	this.state.docID !== "" ? BLUE : BG2,
													color: FONT,
													borderRadius: 7,
													borderColor: BORDER,
													borderWidth: 1,
												}}
												placeholderTextColor={FONT}
												autoCapitalize='words'
												autoCorrect={false}
												clearButtonMode='while-editing'
												autoCompleteType='off'
												placeholder={`Document title`}
												onChangeText={(text) =>
													this.setState({
														title: text,
													})
												}
											/>
										</View>

										<View
											style={{
												marginTop: 20,
											}}>
											<Text
												style={{
													fontWeight: "400",
													fontFamily: "Avenir Next",
													fontSize: 12,
													color: BORDER,
													marginBottom: 4,
													position: "absolute",
													top: -12,
													left: 9,
													backgroundColor: BG,
													padding: 4,
													borderRadius: 8,
													zIndex: 99,
												}}>
												Document date
											</Text>
											<View
												style={{
													borderRadius: 7,
													borderColor: BORDER,
													borderWidth: 1,
												}}>
												<View
													style={{
														width: "100%",
														padding: 13,
														// justifyContent: "center",
														// alignItems: "center",
														paddingLeft: 10,
													}}>
													<Text
														style={{
															color: FONT,
															fontWeight: "600",
															fontSize: 16,
														}}>
														{moment(this.state.doc_date).format(
															"DD/MM/YYYY",
														)}
													</Text>
												</View>

												<View
													style={{
														zIndex: 998,
														position: "absolute",
														width: "100%",
														justifyContent: "center",
														alignItems: "center",
													}}>
													<DatePicker
														portalId='root-portal'
														selected={this.state.doc_date}
														onChange={(date) =>
															this.setState({
																doc_date: date,
															})
														}
													/>
												</View>
												{/* )} */}
											</View>
										</View>

										<View
											style={{
												marginTop: 20,
											}}>
											<Text
												style={{
													fontWeight: "400",
													fontFamily: "Avenir Next",
													fontSize: 12,
													color: BORDER,
													marginBottom: 4,
													position: "absolute",
													top: -12,
													left: 9,
													backgroundColor: BG,
													padding: 4,
													borderRadius: 8,
													zIndex: 99,
												}}>
												Product Description / PO #
											</Text>

											<TextInput
												style={{
													fontSize: 16,
													color: FONT,
													fontWeight:
														this.state.description !== ""
															? "700"
															: "400",
													fontFamily: "Avenir Next",
													width: "100%",
													paddingLeft: 10,
													padding: 13,
													// backgroundColor:
													// 	this.state.docID !== "" ? BLUE : BG2,
													color: FONT,
													borderRadius: 7,
													borderColor: BORDER,
													borderWidth: 1,
												}}
												placeholderTextColor={FONT}
												autoCapitalize='words'
												autoCorrect={false}
												clearButtonMode='while-editing'
												autoCompleteType='off'
												placeholder={`PO # / Product Description `}
												onChangeText={(text) =>
													this.setState({
														description: text,
													})
												}
											/>
										</View>

										<View
											style={{
												marginTop: 30,
											}}>
											<Text
												style={{
													fontWeight: "400",
													fontFamily: "Avenir Next",
													fontSize: 12,
													color: BORDER,
													marginBottom: 4,
													position: "absolute",
													top: -20,
													left: 9,
													backgroundColor: BG,
													padding: 4,
													borderRadius: 8,
													zIndex: 99,
												}}>
												Price
											</Text>

											<View
												style={{
													marginTop: 20,
													flexDirection: "row",
													alignItems: "center",
												}}>
												{this.state.total !== "" ? (
													<View
														style={{
															flexDirection: "row",
															flex: 1,
														}}>
														<TouchableOpacity
															style={{
																flex: 1,
																justifyContent: "center",
																alignItems: "center",
																backgroundColor:
																	this.state.vatable ==
																	"no VAT"
																		? BLUE
																		: BG2,
																padding: 8,
																borderRadius: 7,
															}}
															onPress={async () => {
																await this.setState({
																	vatable: "no VAT",
																});
																this.scrollViewRef.scrollToEnd();
															}}>
															<Text
																style={{
																	fontSize: 13,
																	color: FONT,
																	fontWeight: "400",
																	fontFamily: "Avenir Next",
																}}>
																No VAT
															</Text>
														</TouchableOpacity>
														<TouchableOpacity
															style={{
																flex: 1,
																marginLeft: 4,
																justifyContent: "center",
																alignItems: "center",
																backgroundColor:
																	this.state.vatable == "excl"
																		? BLUE
																		: BG2,
																padding: 8,
																borderRadius: 7,
															}}
															onPress={async () => {
																await this.setState({
																	vatable: "excl",
																});
																this.scrollViewRef.scrollToEnd();
															}}>
															<Text
																style={{
																	fontSize: 13,
																	color: FONT,
																	fontWeight: "400",
																	fontFamily: "Avenir Next",
																}}>
																Excl
															</Text>
														</TouchableOpacity>
														<TouchableOpacity
															style={{
																flex: 1,
																marginLeft: 4,
																justifyContent: "center",
																alignItems: "center",
																backgroundColor:
																	this.state.vatable == "incl"
																		? BLUE
																		: BG2,
																padding: 8,
																borderRadius: 7,
															}}
															onPress={async () => {
																await this.setState({
																	vatable: "incl",
																});
																this.scrollViewRef.scrollToEnd();
															}}>
															<Text
																style={{
																	fontSize: 13,
																	color: FONT,
																	fontWeight: "400",
																	fontFamily: "Avenir Next",
																}}>
																Incl
															</Text>
														</TouchableOpacity>
													</View>
												) : (
													<View
														style={{
															flex: 1,
															backgroundColor: BG2,
															borderRadius: 7,
														}}
													/>
												)}
												<View
													style={{
														backgroundColor:
															this.state.total !== ""
																? BLUE
																: BG2,
														borderRadius: 8,
														marginLeft: 5,
														padding: 13,
														flexDirection: "row",
														alignItems: "center",
														justifyContent: "center",
														borderColor: BORDER,
														borderWidth: 1,
													}}>
													<Text
														style={{
															color: WHITE,
															fontFamily: "Avenir Next",

															fontSize: 16,
															fontWeight:
																this.state.total !== ""
																	? "700"
																	: "400",
														}}>
														R
													</Text>

													<TextInput
														style={{
															fontSize: 17,
															color: "#FFF",
															fontWeight:
																this.state.total !== ""
																	? "700"
																	: "400",
															fontFamily: "Avenir Next",
															textAlign: "right",
														}}
														// ref={(ref) => (this.total = ref)}
														placeholderTextColor={FONT}
														autoCapitalize='words'
														autoCorrect={false}
														clearButtonMode='while-editing'
														autoCompleteType='off'
														// placeholder={`Total incl`}
														// value={this.state.total}
														onChangeText={async (text) => {
															this.setState({
																total: text,
															});
														}}
													/>
												</View>
											</View>

											<View
												style={{
													flex: 1,
													paddingRight: 10,
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "space-between",
													// marginBottom: 10,
												}}>
												<Text
													selectable
													style={{
														fontSize: 16,
														color: FONT,
														fontFamily: "Avenir Next",

														marginTop: 10,
														height: "auto",
														paddingLeft: 10,
														borderRadius: 10,
														color: WHITE,
													}}>
													Total:
												</Text>
												<Text
													selectable
													style={{
														fontSize: 16,
														color: FONT,
														fontFamily: "Avenir Next",

														marginTop: 10,
														height: "auto",
														paddingLeft: 10,
														borderRadius: 10,
														color: WHITE,
													}}>
													R{" "}
													{this.state.vatable === "incl"
														? currencyFormat(
																(parseFloat(this.state.total) *
																	100) /
																	115,
														  )
														: currencyFormat(
																(parseFloat(this.state.total) *
																	100) /
																	100,
														  )}
												</Text>
											</View>

											<View
												style={{
													width: "100%",
													paddingRight: 10,
													display:
														this.state.vatable === ""
															? "none"
															: this.state.vatable === "no VAT"
															? "none"
															: "flex",
												}}>
												<View
													style={{
														width: "100%",
														flexDirection: "row",
														alignItems: "center",
														justifyContent: "space-between",
													}}>
													<Text
														selectable
														style={{
															fontSize: 16,
															color: FONT,
															fontFamily: "Avenir Next",

															marginTop: 10,
															height: "auto",
															paddingLeft: 10,
															borderRadius: 10,
															color: WHITE,
														}}>
														VAT:
													</Text>
													<Text
														selectable
														style={{
															fontSize: 16,
															color: FONT,
															fontFamily: "Avenir Next",

															marginTop: 10,
															height: "auto",
															paddingLeft: 10,
															borderRadius: 10,
															color: WHITE,
														}}>
														R{" "}
														{this.state.vatable === "incl"
															? currencyFormat(
																	(parseFloat(
																		this.state.total,
																	) *
																		15) /
																		115,
															  )
															: currencyFormat(
																	(parseFloat(
																		this.state.total,
																	) *
																		15) /
																		100,
															  )}
													</Text>
												</View>
												<View
													style={{
														width: "100%",
														flexDirection: "row",
														alignItems: "center",
														justifyContent: "space-between",
													}}>
													<Text
														selectable
														style={{
															fontSize: 17,
															color: FONT,
															fontFamily: "Avenir Next",
															fontWeight: "600",
															marginTop: 10,
															height: "auto",
															paddingLeft: 10,
															borderRadius: 10,
															color: WHITE,
														}}>
														Total incl.
													</Text>
													<Text
														selectable
														style={{
															fontSize: 17,
															color: FONT,
															fontFamily: "Avenir Next",
															fontWeight: "600",
															marginTop: 10,
															height: "auto",
															paddingLeft: 10,
															borderRadius: 10,
															color: WHITE,
														}}>
														R{" "}
														{this.state.vatable === "incl"
															? currencyFormat(
																	parseFloat(this.state.total),
															  )
															: currencyFormat(
																	(parseFloat(
																		this.state.total,
																	) *
																		115) /
																		100,
															  )}
													</Text>
												</View>
											</View>
										</View>

										{/* <View style={{ marginTop: 20 }}>
											{this.state.total !== "" ? (
												<View
													onLayout={(event) => {
														let y = event.nativeEvent.layout;
														// this.scrollViewRef.scrollTo({
														//   y: y.y,
														//   animated: true,
														// });
														this.scrollViewRef.scrollToEnd();
													}}
													style={{
														flexDirection: "row",
														justifyContent: "space-evenly",
														marginBottom: 15,
													}}>
													<TouchableOpacity
														style={{
															flex: 1,
															justifyContent: "center",
															alignItems: "center",
															backgroundColor:
																this.state.vatable == "no VAT"
																	? BLUE
																	: BG2,
															padding: 15,
															borderRadius: 7,
														}}
														onPress={() =>
															this.setState({
																vatable: "no VAT",
															})
														}>
														<Text
															style={{
																fontSize: 13,
																color: FONT,
																fontWeight: "400",
																fontFamily: "Avenir Next",
															}}>
															No VAT
														</Text>
													</TouchableOpacity>
													<TouchableOpacity
														style={{
															flex: 1,
															marginLeft: 4,
															justifyContent: "center",
															alignItems: "center",
															backgroundColor:
																this.state.vatable == "excl"
																	? BLUE
																	: BG2,
															padding: 15,
															borderRadius: 7,
														}}
														onPress={() =>
															this.setState({
																vatable: "excl",
															})
														}>
														<Text
															style={{
																fontSize: 13,
																color: FONT,
																fontWeight: "400",
																fontFamily: "Avenir Next",
															}}>
															Excl
														</Text>
													</TouchableOpacity>
													<TouchableOpacity
														style={{
															flex: 1,
															marginLeft: 4,
															justifyContent: "center",
															alignItems: "center",
															backgroundColor:
																this.state.vatable == "incl"
																	? BLUE
																	: BG2,
															padding: 15,
															borderRadius: 7,
														}}
														onPress={() =>
															this.setState({
																vatable: "incl",
															})
														}>
														<Text
															style={{
																fontSize: 13,
																color: FONT,
																fontWeight: "400",
																fontFamily: "Avenir Next",
															}}>
															Incl
														</Text>
													</TouchableOpacity>
												</View>
											) : null}
										</View> */}

										{this.state.vatable !== "" ? (
											<TouchableOpacity
												onPress={() => this.addEntry()}>
												<View
													style={{
														width: "auto",
														height: 35,
														justifyContent: "center",
														alignItems: "center",
														backgroundColor: "#FF5F5F",
														borderRadius: 7,
														padding: 3,
														marginTop: 15,
													}}>
													<Text
														selectable
														style={{
															color: FONT,
															fontWeight: "600",
															fontSize: 15,
														}}>
														Save Item
													</Text>
												</View>
											</TouchableOpacity>
										) : null}
									</View>
								</ScrollView>
							</View>
						</View>
						<View
							style={{
								height: 40,
							}}
						/>
					</View>
				</Modal>
			</>
		);
	}
}
