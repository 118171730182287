import React, { Component } from "react";
import { View, Text, TouchableOpacity, ScrollView, Image } from "react-native";
import Documents from "./PersonalComp/documents";

import add_btn from "./../../assets/images/add_btn.png";
import { APP_COLOURS } from "../APP_VARS";

class Fica extends Component {
	constructor(props) {
		super(props);
		this.state = {
			companies: [
				{
					id: 1,
					title: "Westonry SA (Pty) Ltd",
				},
				{
					id: 2,
					title: "Westonry LLC",
				},
			],
			trusts: [
				{
					id: 3,
					title: "WNB Trust",
				},
			],
			personal: [
				{
					id: 1,
					title: "Tony Cheia",
				},
				{
					id: 2,
					title: "Rose Cheia",
				},
				{
					id: 3,
					title: "Wesley Cheia",
				},
				{
					id: 4,
					title: "Clinton Cheia",
				},
				{
					id: 5,
					title: "Deidre Cheia",
				},
			],
			documents: [
				{
					id: 2,
					title: "Tony ID",
					allocation: 1,
					date_created: "2021/04/16",
					expiry_date: "2012/10/10",
				},
				{
					id: 3,
					title: "Passport Copy",
					allocation: 1,
					date_created: "2012/10/10",
					expiry_date: "2012/10/10",
				},
			],
			selectedCompanyID: 0,
			selectedCompany: "",
		};
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
				}}>
				<View
					style={{
						height: "100%",
						width: 200,
						padding: 5,
					}}>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 12,
							color: APP_COLOURS.BG2,
							fontWeight: "600",
							marginTop: 10,
							marginBottom: 5,
						}}>
						Companies
					</Text>
					{this.state.companies.map((d, i) => (
						<View
							key={i}
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								height: "auto",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: 30,
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 45,
									marginBottom: 10,
									backgroundColor:
										APP_COLOURS.WHITE_TRANSPARENT,
									flexDirection: "row",
								}}
								onPress={() =>
									this.setState({
										selectedCompany: d.title,
									})
								}>
								<View
									style={{
										width: 5,
										borderRadius: 4,
										height: "100%",
										backgroundColor: APP_COLOURS.BG2,
										opacity:
											this.state.selectedCompany ==
											d.title
												? 1
												: 0.2,
										marginRight: 12,
									}}
								/>
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										alignItems: "center",
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											color: APP_COLOURS.BG2,
										}}>
										{d.title}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					))}

					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 12,
							color: APP_COLOURS.BG2,
							fontWeight: "600",
							marginTop: 10,
							marginBottom: 5,
						}}>
						Trusts
					</Text>
					{this.state.trusts.map((d, i) => (
						<View
							key={i}
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								height: "auto",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: 30,
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 45,
									marginBottom: 10,
									backgroundColor:
										APP_COLOURS.WHITE_TRANSPARENT,
									flexDirection: "row",
								}}
								onPress={() =>
									this.setState({
										selectedCompany: d.title,
									})
								}>
								<View
									style={{
										width: 5,
										borderRadius: 4,
										height: "100%",
										backgroundColor: APP_COLOURS.BG2,
										opacity:
											this.state.selectedCompany ==
											d.title
												? 1
												: 0.2,
										marginRight: 12,
									}}
								/>
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										alignItems: "center",
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											color: APP_COLOURS.BG2,
										}}>
										{d.title}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					))}

					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 12,
							color: APP_COLOURS.BG2,
							fontWeight: "600",
							marginTop: 10,
							marginBottom: 5,
						}}>
						Personal
					</Text>
					{this.state.personal.map((d, i) => (
						<View
							key={i}
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								height: "auto",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: 30,
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 45,
									marginBottom: 10,
									backgroundColor:
										APP_COLOURS.WHITE_TRANSPARENT,
									flexDirection: "row",
								}}
								onPress={() =>
									this.setState({
										selectedCompany: d.title,
										selectedCompanyID: d.id,
									})
								}>
								<View
									style={{
										width: 5,
										borderRadius: 4,
										height: "100%",
										backgroundColor: APP_COLOURS.BG2,
										opacity:
											this.state.selectedCompany ==
											d.title
												? 1
												: 0.2,
										marginRight: 12,
									}}
								/>
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										alignItems: "center",
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											color: APP_COLOURS.BG2,
										}}>
										{d.title}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					))}
				</View>
				<View
					style={{
						flex: 1,
						padding: 5,
						display:
							this.state.selectedCompany !== "" ? "flex" : "none",
					}}>
					<View
						style={{
							flex: 1,
							backgroundColor: APP_COLOURS.WHITE_TRANSPARENT,
							borderRadius: 10,
							padding: 10,
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontFamily: "Avenir",
								fontWeight: "700",
								fontSize: 20,
							}}>
							{this.state.selectedCompany}
						</Text>

						<ScrollView
							contentContainerStyle={{
								height: this.props.screenHeight - 160,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										width: 500,
										height: "auto",
										borderRadius: 10,
										overflow: "hidden",
										backgroundColor: APP_COLOURS.BG2,
										margin: 10,
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT3,
											flexDirection: "row",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "700",
												color: "#FFF",
												fontSize: 17,
											}}>
											Identity Documents
										</Text>
										<TouchableOpacity
											style={{
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={() => this.addNewNote()}>
											<View>
												<Image
													source={add_btn}
													style={{
														width: 18,
														height: 18,
														// borderRadius: 25,
														resizeMode: "contain",
														marginRight: 5,
													}}
												/>
											</View>
										</TouchableOpacity>
									</View>
									<View
										style={{
											width: "100%",
											paddingLeft: 10,
											paddingRight: 10,
											paddingBottom: 5,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT3,
											flexDirection: "row",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "300",
												color: "#FFF",
												fontSize: 10,
											}}>
											Expiry date:
										</Text>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "500",
												color: "#FF9167",
												fontSize: 10,
											}}>
											2025/04/16
										</Text>
									</View>

									<View
										style={{
											width: "100%",
											padding: 10,
											backgroundColor: APP_COLOURS.BG2,
										}}>
										{this.state.documents
											.filter(
												(d_) =>
													d_.allocation ==
													this.state
														.selectedCompanyID,
											)
											.map((doc, ind) => (
												<Documents data={doc} />
											))}
									</View>
								</View>

								<View
									style={{
										width: 500,
										height: "auto",
										borderRadius: 10,
										overflow: "hidden",
										backgroundColor: APP_COLOURS.BG2,
										margin: 10,
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT3,
											flexDirection: "row",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "700",
												color: "#FFF",
												fontSize: 17,
											}}>
											Proof of address
										</Text>
										<TouchableOpacity
											style={{
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={() => this.addNewNote()}>
											<View>
												<Image
													source={add_btn}
													style={{
														width: 18,
														height: 18,
														// borderRadius: 25,
														resizeMode: "contain",
														marginRight: 5,
													}}
												/>
											</View>
										</TouchableOpacity>
									</View>
									<View
										style={{
											width: "100%",
											paddingLeft: 10,
											paddingRight: 10,
											paddingBottom: 5,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT3,
											flexDirection: "row",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "300",
												color: "#FFF",
												fontSize: 10,
											}}>
											Expiry date:
										</Text>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "500",
												color: "#FF9167",
												fontSize: 10,
											}}>
											2025/04/16
										</Text>
									</View>

									<View
										style={{
											width: "100%",
											padding: 10,
											backgroundColor: APP_COLOURS.BG2,
										}}>
										{this.state.documents
											.filter(
												(d_) =>
													d_.allocation ==
													this.state
														.selectedCompanyID,
											)
											.map((doc, ind) => (
												<Documents data={doc} />
											))}
									</View>
								</View>

								<View
									style={{
										height: 200,
										width: 100,
										// backgroundColor: "red",
									}}
								/>
							</View>
						</ScrollView>
					</View>
				</View>
			</View>
		);
	}
}

export default Fica;
