import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import send from "./../../../assets/images/send.png";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./field.css";

import bin from "./../../../assets/images/bin.png";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import moment from "moment";
import ModalTaskPreview from "./ModalTaskPreview";

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class TaskItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: false,
			showMore: false,
			noteData: "",

			doc_date: new Date(),

			dueDate: new Date(),

			columns: [
				{
					id: 1,
					status: "",
					color: "#151A30",
				},
				{
					id: 2,
					status: "URGENT",
					color: "#C52643",
				},
				{
					id: 3,
					status: "INPROGRESS",
					color: "#0676ED",
				},
				{
					id: 4,
					status: "Paused",
					color: "#AF7800",
				},
				{
					id: 5,
					status: "DONE",
					color: "#00CB66",
				},
			],
		};
	}

	render() {
		let d = this.props.data;
		let users = this.props.users;

		let avatars = {
			1: require("./../../../assets/images/male1.png"),
			2: require("./../../../assets/images/male2.png"),
			3: require("./../../../assets/images/female1.png"),
		};

		let avatar = avatars[parseInt(this.props.user_data.avatar)];

		let due = "NOW";

		const date1 = moment();
		const date2 = moment(d.dueDate);
		due = date2.diff(date1, "days");

		let morning = new Date();
		morning.setHours(0, 0, 1, 0);
		let evening = new Date();
		evening.setHours(23, 59, 59, 0);

		return (
			<View
				style={{
					// flex: 1,
					width: "100%",
					height: "auto",
					borderWidth: this.state.showMore ? 0.3 : 0,
					borderColor: "#797979",
					borderRadius: 5,
					borderStyle: "dotted",
				}}>
				<View
					onMouseEnter={() => {
						this.props.setHover(d.id);
					}}
					onMouseLeave={() => {
						this.props.setHover("");
					}}
					style={{
						flex: 1,
						flexDirection: "row",
						borderRadius: 5,
						opacity:
							this.props.hover === d.id
								? 1
								: d.status === "Done"
								? 0.3
								: 0.8,
						// backgroundColor: APP_COLOURS.BG2,
						backgroundColor: APP_COLOURS.BUTTON2,

						width: "100%",
						minHeight: 50,
						padding: 3,
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<TouchableOpacity
						activeOpacity={0.8}
						style={{
							flex: 1,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							height: "100%",
							minHeight: 50,
						}}
						onPress={() => {
							this.setState({
								showMore: !this.state.showMore,
							});
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								height: "100%",
								minHeight: 50,
							}}>
							<View
								style={{
									// width: 6,
									width: 70,
									paddingLeft: 3,
									height: "100%",
									minHeight: 50,
									backgroundColor:
										due <= 0
											? APP_COLOURS.RED
											: due <= 5
											? "#E0A749"
											: APP_COLOURS.BLUE,

									marginRight: 10,
									borderRadius: 2,
									alignItems: "center",
									justifyContent: "center",
								}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 10,
										fontWeight: "600",
										color: APP_COLOURS.WHITE,
									}}>
									{moment(d.dueDate).fromNow()}
								</Text>
							</View>

							<View
								style={{
									flex: 1,
									flexDirection: "row",
								}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										fontWeight: "600",
										color: APP_COLOURS.WHITE,
									}}>
									{d.title}
								</Text>
							</View>

							<View
								style={{
									width: 50,
								}}>
								<View
									style={{
										display:
											this.props.notes.length > 0 ? "flex" : "none",
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 6,
											fontWeight: "500",
											color: APP_COLOURS.WHITE,
										}}>
										Messages
									</Text>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 18,
											fontWeight: "700",
											color: APP_COLOURS.ORANGE,
										}}>
										{this.props.notes.length}
									</Text>
								</View>
							</View>

							<View
								style={{
									width: 70,
								}}>
								<View style={{}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 6,
											fontWeight: "500",
											color: APP_COLOURS.WHITE,
										}}>
										Due date
									</Text>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 10,
											fontWeight: "600",
											color: APP_COLOURS.WHITE,
										}}>
										{moment(d.dueDate).format("DD/MM/YYYY")}
									</Text>
								</View>
							</View>

							<View
								style={{
									width: 110,
								}}>
								<View style={{}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 6,
											fontWeight: "500",
											color: APP_COLOURS.WHITE,
										}}>
										Date created
									</Text>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 10,
											fontWeight: "600",
											color: APP_COLOURS.WHITE,
										}}>
										{moment(d.date_created).format(
											"DD/MM/YYYY HH:mm",
										)}
									</Text>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 8,
											fontWeight: "600",
											fontStyle: "italic",
											color: APP_COLOURS.WHITE,
										}}>
										added by {d.addedBy.first_name}...
									</Text>
								</View>
							</View>

							<View
								style={{
									width: 100,
								}}>
								{d.assignment.map((ass) => {
									return (
										<TouchableOpacity
											style={{
												flexDirection: "row",
												justifyContent: "flex-start",
												alignItems: "center",
												marginBottom: 2,
											}}>
											<Image
												source={avatars[parseInt(ass.avatar)]}
												style={{
													width: 20,
													height: 20,
													borderRadius: 25,
													resizeMode: "contain",
													backgroundColor: "#FFF",
													marginRight: 5,
												}}
											/>
											<View style={{}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 9,
														fontWeight: "600",
														color: APP_COLOURS.WHITE,
													}}>
													{ass.first_name}
												</Text>
											</View>
										</TouchableOpacity>
									);
								})}
							</View>

							<TouchableOpacity
								onPress={(e) => {
									console.log("touchMove", e.nativeEvent);
								}}
								style={{
									height: "100%",
									flexDirection: "row",
									justifyContent: "flex-start",
									width: 100,
									alignItems: "center",
									backgroundColor:
										d.status == "Pending"
											? "transparent"
											: d.status == "Done"
											? APP_COLOURS.GREEN
											: d.status == "Stuck"
											? APP_COLOURS.RED
											: APP_COLOURS.ORANGE,
									borderRadius: 2,
								}}>
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Text
										adjustsFontSizeToFit
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											fontWeight: "700",
											color:
												d.status == "Pending"
													? APP_COLOURS.WHITE
													: APP_COLOURS.BG2,
										}}>
										{d.status}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</TouchableOpacity>

					{this.props.showDelete ? (
						<TouchableOpacity
							onPress={() => this.props.deleteItem(d)}
							style={{
								flexDirection: "row",
								justifyContent: "center",
								width: 40,
								alignItems: "center",
								padding: 5,
								// borderLeftWidth: 0.5,
								// borderLeftColor: "#FFF",
								marginLeft: 5,
							}}>
							<Image
								source={bin}
								style={{
									width: 22,
									height: 22,
									// borderRadius: 25,
									resizeMode: "contain",
									marginRight: 5,
								}}
							/>
						</TouchableOpacity>
					) : null}
				</View>

				{this.state.showMore ? (
					<ModalTaskPreview
						data={d}
						users={users}
						user_data={this.props.user_data}
						close={() =>
							this.setState({
								showMore: !this.state.showMore,
							})
						}
						notes={this.props.notes}
						addNotes={(data) => this.props.addNotes(data)}
						updateItem={(data) => this.props.updateItem(data)}
						addChangeStatus={(data) => this.props.addChangeStatus(data)}
					/>
				) : (
					<View />
				)}

				{/* {this.state.showMore ? (
					<Fade>
						<View
							style={{
								// flex: 1,
								width: "100%",
								height: 300,
								flexDirection: "row",
								backgroundColor: APP_COLOURS.BG2,
							}}>
							<View
								style={{
									flex: 1,
									padding: 5,
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 12,
											fontWeight: "700",
											marginBottom: 2,
										}}>
										Notes ({this.props.notes.length})
									</Text>
									<View
										style={{
											borderTopColor: "#797979",
											borderTopWidth: 0.3,
										}}
									/>
									<ScrollView
										ref={(ref) => {
											this.scrollViewBookContent = ref;
										}}
										onContentSizeChange={() =>
											this.scrollViewBookContent.scrollToEnd({
												animated: true,
											})
										}
										contentContainerStyle={{
											paddingRight: 13,
										}}>
										{this.props.notes.map((notes, index) => (
											<View
												style={{
													width: "100%",
													marginBottom: 10,
													flexDirection: "row",
													backgroundColor:
														"rgba(212,212,212, 0.05)",
													borderRadius: 12,
													padding: 10,
												}}>
												<Image
													source={
														avatars[parseInt(notes.user.avatar)]
													}
													style={{
														width: 16,
														height: 16,
														borderRadius: 8,
														resizeMode: "contain",
														marginRight: 5,
													}}
												/>
												<View
													style={{
														flex: 1,
													}}>
													<View
														style={{
															flexDirection: "row",
															alignItems: "center",
															width: "100%",
															justifyContent: "space-between",
														}}>
														<Text
															style={{
																color: "#FFF",
																fontSize: 12,
																marginRight: 15,
																fontWeight: "700",
															}}>
															{notes.user.first_name}
														</Text>
														<Text
															style={{
																color: "#FFF",
																fontSize: 8,
																opacity: 0.5,
															}}>
															{notes.date_added}
														</Text>
													</View>
													<Text
														style={{
															color: "#FFF",
															fontSize: 12,
															opacity: 0.7,
															marginTop: 5,
														}}>
														{notes.data}
													</Text>
												</View>
											</View>
										))}
										<View
											style={{
												height: 20,
											}}
										/>
									</ScrollView>
								</View>
								<View
									style={{
										flexDirection: "row",
										width: "100%",
										alignItems: "center",
										borderTopColor: "#797979",
										borderTopWidth: 0.3,
										paddingTop: 5,
									}}>
									<TextInput
										ref={(ref) => {
											this.textinput = ref;
										}}
										style={{
											width: "80%",
											padding: 10,
											fontSize: 16,
											borderRadius: 9,
											color: APP_COLOURS.WHITE,
											fontWeight: "200",
											fontFamily: "Avenir",
											borderWidth: 0.3,
											borderColor: APP_COLOURS.BG2,
										}}
										placeholderTextColor={"#797979"}
										multiline
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={false}
										keyboardType='default'
										textContentType='none'
										placeholder={`Type something `}
										onChangeText={(text) => {
											this.setState({
												noteData: text,
											});
										}}
									/>
									<TouchableOpacity
										style={{
											width: "20%",
											alignItems: "center",
											justifyContent: "center",
										}}
										onPress={() => this.addNewNote()}>
										<View>
											<Image
												source={send}
												style={{
													width: 22,
													height: 22,
													// borderRadius: 25,
													resizeMode: "contain",
													marginRight: 5,
												}}
											/>
										</View>
									</TouchableOpacity>
								</View>
							</View>
							<View
								style={{
									flex: 1,
									padding: 5,
								}}>
								<Text
									style={{
										color: "#FFF",
										fontSize: 12,
										fontWeight: "400",
										marginBottom: 2,
									}}>
									Change due date{" "}
									<Text
										style={{
											color: "#FFF",
											fontSize: 12,
											fontWeight: "700",
										}}>
										{moment(d.dueDate).format("DD/MM/YYYY")}
									</Text>
								</Text>

								<View
									style={{
										borderTopColor: "#797979",
										borderTopWidth: 0.3,
										width: "100%",
										marginBottom: 5,
									}}
								/>

								<View
									style={{
										flex: 1,
										// height: "auto",
										// width: "100%",
										marginBottom: 25,
										marginTop: 25,
									}}>
									<View
										style={{
											width: "100%",
											backgroundColor: "#FFF",
											padding: 10,
											fontSize: 16,
											borderRadius: 9,
											color: APP_COLOURS.BG2,
											fontWeight: "200",
											fontFamily: "Avenir",
											borderWidth: 0.3,
											borderColor: APP_COLOURS.BG2,
											height: 50,
										}}>
										<DatePicker
											selected={
												this.state.dueDate == ""
													? evening
													: this.state.dueDate
											}
											onChange={(date) =>
												this.setState({
													dueDate: date,
												})
											}
										/>
									</View>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 10,
											fontFamily: "Avenir Next",
											margin: 5,
											paddingLeft: 10,
											paddingRight: 10,
											fontWeight: "400",
											backgroundColor: "#FFF",
											position: "absolute",
											borderRadius: 6,
											top: -13,
											left: 5,
										}}>
										Due date
									</Text>
									<Text
										style={{
											fontSize: 14,
											fontFamily: "Avenir Next",
											margin: 5,
											paddingLeft: 10,
											paddingRight: 10,
											fontWeight: "400",
											position: "absolute",
											top: 12,
											left: 5,
										}}>
										{moment(this.state.dueDate).format("DD/MM/YYYY")}
									</Text>

									<TouchableOpacity
										onPress={() =>
											this.updateItem(
												d,
												"dueDate",
												this.state.dueDate,
											)
										}
										style={{
											flexDirection: "row",
											justifyContent: "flex-start",
											alignItems: "center",
											marginBottom: 2,
											backgroundColor: APP_COLOURS.BLUE,
											width: 100,
											borderRadius: 10,
										}}>
										<View
											style={{
												padding: 10,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													fontWeight: "600",
													color: APP_COLOURS.WHITE,
												}}>
												save
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							</View>
							<View
								style={{
									flex: 1,
									alignItems: "flex-end",
									padding: 5,
								}}>
								<Text
									style={{
										color: "#FFF",
										fontSize: 12,
										fontWeight: "700",
										marginBottom: 2,
									}}>
									Change Status
								</Text>
								<View
									style={{
										borderTopColor: "#797979",
										borderTopWidth: 0.3,
										width: "100%",
										marginBottom: 5,
									}}
								/>
								{["Inprogress", "Done", "Stuck"].map((status) => (
									<TouchableOpacity
										onPress={() => this.addChangeStatus(d, status)}
										style={{
											flexDirection: "row",
											justifyContent: "flex-start",
											alignItems: "center",
											marginBottom: 2,
											backgroundColor:
												d.status == status
													? APP_COLOURS.BLUE
													: "transparent",
											width: 100,
											borderRadius: 10,
										}}>
										<View
											style={{
												padding: 10,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													fontWeight: "600",
													color: APP_COLOURS.WHITE,
												}}>
												{status}
											</Text>
										</View>
									</TouchableOpacity>
								))}
							</View>
						</View>
					</Fade>
				) : null} */}
			</View>
		);
	}

	// addNewNote = async (d) => {
	// 	let uuid = uuidv4();
	// 	if (this.state.noteData == "") {
	// 		return;
	// 	}
	// 	let data = {
	// 		id: uuid,
	// 		task_id: this.props.data.id,
	// 		data: this.state.noteData,
	// 		date_added: moment().toLocaleString(),
	// 		user: this.props.user_data,
	// 	};
	// 	await this.setState({
	// 		noteData: "",
	// 	});
	// 	await console.log(this.props.user_data);
	// 	await this.textinput.clear();
	// 	await this.props.addNotes(data);
	// };

	// addChangeStatus = async (d, status) => {
	// 	d.status = status;
	// 	await this.props.addChangeStatus(d);
	// };

	// updateItem = (d, field, val) => {
	// 	d[field] = val;
	// 	this.props.updateItem(d);
	// };
}

export default TaskItem;
