import React, { Component, useReducer } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from "react-native";
import { APP_COLOURS } from "../APP_VARS";
import ModalChangeLog from "./TaskComponents/ModalChangeLog";
import ModalAddTask from "./TaskComponents/ModalAddTask";
import TaskItem from "./TaskComponents/TaskItem";
import addBtn from "../../assets/images/add_btn.png";

import todayList from "../../assets/images/todayList.png";
import moment from "moment";
import CalendarGantt from "./TaskComponents/CalendarGantt";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

// let avatars = {
// 	1: require("./../../assets/images/male1.png"),
// 	2: require("./../../assets/images/male2.png"),
// 	3: require("./../../assets/images/female1.png"),
// };

class Tasks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addNewTaskModal: false,
			showDelete: false,

			tasks: [
				// {
				// 	id: 1,
				// 	title: "First task",
				// 	assigned: ["1", "3"],
				// },
			],

			users: [
				{
					id: 1,
					first_name: "Tony",
					last_name: "Cheia",
					avatar: 1,
				},
				{
					id: 2,
					first_name: "Rose",
					last_name: "Cheia",
					avatar: 3,
				},
				{
					id: 3,
					first_name: "Clinton",
					last_name: "Cheia",
					avatar: 2,
				},
				{
					id: 4,
					first_name: "Deidre",
					last_name: "Cheia",
					avatar: 3,
				},
				{
					id: 5,
					first_name: "Wesley",
					last_name: "Cheia",
					avatar: 2,
				},
			],

			tasks: [],
			notes: [],

			filterTasks: "",
			filterSearch: "",

			showChangeLogModal: false,

			changeLog: [],
		};
	}

	componentDidMount() {
		this.getJsonDataFromServer();
	}

	showChangeLogModal = (event) => {
		event.preventDefault();
		const layout = event.nativeEvent;
		let x = layout.clientX;
		let y = layout.clientY;
		this.setState({
			xPos: x,
			yPos: y,
			showChangeLogModal: true,
		});
		// console.log(x, y);
	};

	render() {
		let avatars = {
			1: require("./../../assets/images/male2.png"),
			2: require("./../../assets/images/male1.png"),
			3: require("./../../assets/images/female1.png"),
		};

		let tasksArray = this.state.tasks
			.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
			.filter((d) => {
				let filterTasks = this.state.filterTasks;
				if (filterTasks !== "") {
					console.log(filterTasks.id);
					const containsPerson = d.assignment.filter(
						(dd) => dd.id == filterTasks.id,
					);
					if (containsPerson.length > 0) {
						return d;
					}
				} else {
					return d;
				}
			})
			.filter((d) => {
				let filterSearch = this.state.filterSearch;
				if (filterSearch !== "") {
					return filterSearch === d.title;
				} else {
					return d;
				}
			});
		return (
			<View
				style={{
					height: this.props.screenHeight - 10,
				}}>
				<View
					style={{
						flex: 1,
					}}>
					<View
						style={{
							width: "100%",
							height: "auto",
							flexDirection: "row",
							justifyContent: "flex-start",
							paddingLeft: 10,
						}}>
						<TouchableOpacity
							activeOpacity={1}
							onPress={(e) => {
								if (this.state.changeLog.length > 0) {
									this.showChangeLogModal(e);
								} else {
									alert("No items to display");
								}
							}}
							style={{
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								marginBottom: 2,
								marginRight: 15,
							}}>
							<Image
								source={todayList}
								style={{
									width: 25,
									height: 25,
									// borderRadius: 25,
									resizeMode: "contain",
									marginRight: 5,
								}}
							/>
							<View
								style={{
									position: "absolute",
									display:
										this.state.changeLog.length > 0 ? "flex" : "none",
									right: 0,
									top: 15,
									backgroundColor: "red",
									borderRadius: 5,
									width: 10,
									height: 10,
								}}></View>
						</TouchableOpacity>
						{this.state.filterTasks !== "" ? (
							<TouchableOpacity
								onPress={() => {
									this.setState({
										filterTasks: "",
									});
								}}
								style={{
									flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "center",
									marginBottom: 2,
									marginRight: 15,
									backgroundColor: APP_COLOURS.BG2,
									borderRadius: 10,
									padding: 10,
								}}>
								<Image
									source={
										avatars[parseInt(this.props.user_data.avatar)]
									}
									style={{
										width: 20,
										height: 20,
										borderRadius: 25,
										resizeMode: "contain",
										backgroundColor: "#FFF",
										marginRight: 5,
									}}
								/>
								<View style={{}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 15,
											fontWeight: "400",
											color: APP_COLOURS.WHITE,
										}}>
										View all tasks
									</Text>
								</View>
							</TouchableOpacity>
						) : (
							<TouchableOpacity
								onPress={() => {
									this.setState({
										filterTasks: this.props.user_data,
									});
								}}
								style={{
									flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "center",
									marginBottom: 2,
									marginRight: 15,
									backgroundColor: APP_COLOURS.BG2,
									borderRadius: 10,
									padding: 10,
								}}>
								<Image
									source={avatars[this.props.user_data.avatar]}
									style={{
										width: 20,
										height: 20,
										borderRadius: 25,
										resizeMode: "contain",
										backgroundColor: "#FFF",
										marginRight: 5,
									}}
								/>
								<View style={{}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 15,
											fontWeight: "400",
											color: APP_COLOURS.WHITE,
										}}>
										Filter {this.props.user_data.first_name}
										's tasks
									</Text>
								</View>
							</TouchableOpacity>
						)}
						<TouchableOpacity
							onPress={() =>
								this.setState({
									addNewTaskModal: true,
								})
							}
							style={{
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",

								borderRadius: 10,
								margin: 5,
								marginRight: 15,
							}}>
							<Image
								source={addBtn}
								style={{
									width: 25,
									height: 25,
									resizeMode: "contain",
									marginRight: 5,
								}}
							/>
							<View style={{}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 15,
										fontWeight: "400",
										color: APP_COLOURS.WHITE,
									}}>
									New task
								</Text>
							</View>
						</TouchableOpacity>

						<View
							style={{
								height: "auto",
								margin: 10,
							}}>
							<TextInput
								style={{
									width: 250,
									padding: 10,
									fontSize: 15,
									borderRadius: 12,
									color: "#FFF",
									fontWeight: "600",
									fontFamily: "Avenir",
									borderWidth: 0.3,
									borderColor: APP_COLOURS.OFFWHITE,
								}}
								placeholderTextColor={APP_COLOURS.OFFWHITE}
								multiline
								// numberOfLines={10}
								autoCapitalize='sentences'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={false}
								keyboardType='default'
								textContentType='none'
								placeholder={`Search `}
								// defaultValue={this.state.user_data.email}
								defaultValue={this.state.filterSearch}
								onChangeText={(text) => {
									console.log(text);
									this.setState({
										filterSearch: text,
									});
								}}
							/>
						</View>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									showDelete: !this.state.showDelete,
								})
							}
							style={{
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",

								borderRadius: 10,
								margin: 5,
								marginRight: 15,
							}}>
							<View style={{}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 15,
										fontWeight: "400",
										color: APP_COLOURS.BLUE,
									}}>
									Edit Tasks
								</Text>
							</View>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flex: 1,
							padding: 10,
							borderRadius: 10,
						}}>
						<CalendarGantt
							data={tasksArray}
							height={this.props.screenHeight / 2 - 120}
							hover={this.state.hover}
							setHover={(d) =>
								this.setState({
									hover: d,
								})
							}
						/>
					</View>

					<View
						style={{
							flex: 1,
						}}>
						<ScrollView
							contentContainerStyle={{
								paddingRight: 10,

								// display: "none",
							}}>
							<View
								style={{
									flex: 1,
								}}>
								{tasksArray.map((task, ind) => (
									<View
										style={{
											padding: 5,
											width: "100%",
											height: "auto",
											flexDirection: "row",
										}}>
										<TaskItem
											data={task}
											notes={this.state.notes.filter(
												(dm) => dm.task_id == task.id,
											)}
											addNotes={(data) => this.addNotes(data)}
											addChangeStatus={(d) =>
												this.addChangeStatus(d)
											}
											updateItem={(d) => {
												this.updateItem(d);
											}}
											user_data={this.props.user_data}
											users={this.state.users}
											showDelete={this.state.showDelete}
											deleteItem={(d) => this.deleteTask(d)}
											hover={this.state.hover}
											setHover={(d) =>
												this.setState({
													hover: d,
												})
											}
										/>
									</View>
								))}
								<View
									style={{
										height: 90,
									}}
								/>
							</View>
						</ScrollView>
					</View>
				</View>

				{this.state.addNewTaskModal ? this.taskForm() : <View />}

				{this.state.showChangeLogModal &&
				this.state.changeLog.length > 0 ? (
					<ModalChangeLog
						xPos={this.state.xPos}
						yPos={this.state.yPos}
						screenHeight={this.props.screenHeight}
						user_data={this.props.user_data}
						changeLog={this.state.changeLog}
						logOut={() => this.props.logOut()}
						closeModal={() =>
							this.setState({
								showChangeLogModal: false,
							})
						}
						deleteLog={() => this.deleteChangeLog()}
					/>
				) : null}
			</View>
		);
	}

	taskForm = () => {
		return (
			<ModalAddTask
				{...this.props}
				users={this.state.users}
				close={(d) =>
					this.setState({
						addNewTaskModal: d,
					})
				}
				addNewTask={this.addNewTask.bind(this)}
			/>
		);
	};

	addNewTask = async (taskName, taskDescription, taskAssignment, dueDate) => {
		let uuid = uuidv4();
		let data = {
			id: uuid,
			assignment: [...taskAssignment],
			title: taskName,
			status: "Pending",
			priority: "Reg",
			dueDate: dueDate,
			date_created: moment().toLocaleString(),
			description: taskDescription,
			addedBy: this.props.user_data,
		};
		await this.setState({
			tasks: this.state.tasks.concat(data),

			addNewTaskModal: false,
			taskAssignment: [],
			taskName: "",
		});
		await this.addToChangeLog(`added task: ${taskName}`);
	};

	addToChangeLog = async (title) => {
		let uuid = uuidv4();
		let data = {
			id: uuid,
			title: title,
			date_created: moment().toLocaleString(),
			addedBy: this.props.user_data,
		};

		await this.setState({
			changeLog: this.state.changeLog.concat(data),
		});

		await this.saveJsonDataToServer();
	};

	deleteChangeLog = async () => {
		await this.setState({
			changeLog: [],
			showChangeLogModal: false,
		});
		await this.saveJsonDataToServer();
	};

	addChangeStatus = async (d) => {
		console.log(d);
		let tasks = this.state.tasks.filter((task) => task.id !== d.id);
		let sorted = tasks.concat(d);
		await this.setState({
			tasks: sorted,
		});
		await this.addToChangeLog(`status changed to ${d.status}, ${d.title}`);
	};

	updateItem = async (d) => {
		console.log(d);
		let tasks = this.state.tasks.filter((task) => task.id !== d.id);
		let sorted = tasks.concat(d);
		await this.setState({
			tasks: sorted,
		});
		await this.addToChangeLog(`status changed to ${d.status}, ${d.title}`);
	};

	deleteTask = async (d) => {
		const tasks = this.state.tasks.filter((d_) => d.id !== d_.id);
		const notes = this.state.notes.filter((d_) => d.id !== d_.task_id);
		await this.setState({
			tasks: [...tasks],
			notes: [...notes],
			showDelete: false,
		});
		await this.addToChangeLog(`deleted task, ${d.title}`);
	};

	addNotes = async (d) => {
		// console.log(d);
		await this.setState({
			notes: this.state.notes.concat(d),
		});
		await this.addToChangeLog(
			`added note: ${d.title}, task id: ${d.task_id}`,
		);
	};

	getJsonDataFromServer = () => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			command: "get",
			fileName: "tasks",
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/accountsapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result getJsonDataFromServer wes", result);

							this.setState({
								...result,
								loading: false,
							});
						})
						.catch((error) => this.setState({}));
				} else {
					this.setState({
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	saveJsonDataToServer = () => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			json_data: this.state,
			command: "save",
			fileName: "tasks",
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/accountsapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result", result);

							this.setState({
								...result,
								loading: false,
							});
						})
						.catch((error) =>
							this.setState({
								loading: false,
							}),
						);
				} else {
					this.setState({
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};
}

export default Tasks;
