import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import moment from "moment";
import XLSX from "xlsx";

import ExcelExport from "../../../assets/images/export_excel.png";

class ExportToExcel extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	exportExcel = () => {
		const array = this.props.data;

		let today = moment().format("DD MMM YYYY");
		var str = this.props.excel_doc_name;
		var name = str.split(" ").join("_");
		let date = today.split(" ").join("_");
		let document_name = "tetrice_" + name + "_" + date + ".xlsx";
		var ws = XLSX.utils.json_to_sheet(array);
		var wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Prova");
		const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
		const exportExcel = XLSX.writeFile(wb, document_name);
	};

	render() {
		return (
			<TouchableOpacity
				disabled={this.props.data.length > 0 ? false : true}
				style={{}}
				onPress={() => {
					this.exportExcel();
				}}>
				{/* <Text
					style={{
						color: "#0468FC",
						fontSize: 12,
					}}>
					Export
				</Text> */}
				<Image
					source={ExcelExport}
					style={{
						width: 40,
						height: 40,
						resizeMode: "contain",
						marginRight: 5,
					}}
				/>
			</TouchableOpacity>
		);
	}
}

export default ExportToExcel;
