import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";

import logo from "./../../assets/images/logo.png";
// import bg from "./../../assets/images/bg.png";
import workfromhome from "./../../assets/images/workfromhome.png";
// import male1 from "../../assets/images/male1.png";
import otp_sent from "../../assets/images/otp_sent.png";
import otp_valid from "../../assets/images/otp_valid.png";
import "./index.css";
import tick from "./../../assets/images/tick_red.png";
import { APP_COLOURS } from "../APP_VARS";
import { api_login } from "../Api";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			password: "",
			emailInput: "",
			emailValid: false,
			screenWidth: screenWidth,
			screenHeight: screenHeight,
			otpPinSent: false,
		};
	}

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	loginButton = async () => {
		this.setState({
			otpPinSent: true,
			errorLogin: false,
		});
		await sessionStorage.setItem("email", this.state.emailInput);
		await sessionStorage.setItem("password", this.state.password);
	};

	loginButton2 = async () => {
		this.setState({
			loading: true,
		});

		this.loginFuncWithOTP();
	};

	loginFuncWithOTP = async () => {
		// let url = "https://www.tetrice.co.za/REST/accountsapi/post_login.php";

		this.setState({
			loading: true,
		});
		let url = api_login;
		let password = this.state.password;
		let email = this.state.emailInput;

		var raw = {
			passcode: password,
			username: email,
		};

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log(
								"=============================",
								responseData,
							);
							let accessToken = await sessionStorage.setItem(
								"accessToken",
								responseData.accessToken,
							);
							let obj = {
								...responseData,
							};

							let user_data = await sessionStorage.setItem(
								"user_data",
								JSON.stringify(obj),
							);

							let permission = await sessionStorage.setItem(
								"permission",
								JSON.stringify(responseData.permission),
							);

							await this.props.navigate("/");
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
								password: "",
								emailInput: "",
								emailValid: false,
								otpPinSent: false,
							});
						});
				} else {
					alert("User has not been found");
					this.setState({
						errorLogin: true,
						loading: false,
						password: "",
						emailInput: "",
						emailValid: false,
						otpPinSent: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorLogin: true,
					loading: false,
					password: "",
					emailInput: "",
					emailValid: false,
					otpPinSent: false,
				});
			});
	};

	componentDidMount() {
		console.log("componentDidMount");
		let auth_token = sessionStorage.getItem("auth_token");

		console.log("Approved User", auth_token);
		// if (auth_token !== null) {
		// 	this.props.history.push("/dashboard/Home");
		// } else {
		// 	sessionStorage.clear();
		// 	this.setState({
		// 		loading: false,
		// 	});
		// }

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		let maxDimentions =
			this.state.screenWidth > this.state.screenHeight
				? this.state.screenHeight
				: this.state.screenWidth;

		if (this.props.screenWidth > 700) {
			return (
				<div
					style={{
						backgroundColor: APP_COLOURS.BACKGROUND,
						flex: 1,
					}}>
					<div
						style={{
							width: "100%",
							height: "100%",
							position: "absolute",
							right: 0,
							bottom: 0,
							// backgroundColor: APP_COLOURS.BACKGROUND,
						}}>
						<Image
							source={workfromhome}
							style={{
								width: "20%",
								height: "50%",
								resizeMode: "contain",
								position: "absolute",
								right: 0,
								bottom: 0,
							}}
						/>
					</div>

					<View
						style={{
							flex: 1,
							alignItems: "center",
							width: this.state.screenWidth,
							height: this.state.screenHeight,
							justifyContent: "center",
							position: "absolute",
							zIndex: 99,
							top: 0,
						}}>
						{/* <ScrollView
							contentContainerStyle={{
								width: this.state.screenWidth,
								height: this.state.screenHeight,
								justifyContent: "center",
								alignItems: "center",
							}}> */}
						<View
							style={{
								width: "100%",
								maxWidth: 400,
								height: "auto",
								alignItems: "center",
								padding: 10,
								borderRadius: 15,
								backgroundColor: APP_COLOURS.BG2,
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 8,
								},
								shadowOpacity: 0.44,
								shadowRadius: 10.32,

								elevation: 16,
							}}>
							<Image
								source={logo}
								style={{
									width: 180,
									height: 100,
									resizeMode: "contain",
								}}
							/>

							{this.state.errorLogin ? (
								<View
									style={{
										width: 300,
										marginTop: 10,
									}}>
									<Text
										style={{
											color: "red",
										}}>
										We are unable to verify your profile, please check
										your email again to confirm this is correct.
									</Text>
								</View>
							) : null}

							{!this.state.otpPinSent ? (
								<View
									style={{
										flex: 1,
										width: "100%",
										alignItems: "center",
										// justifyContent: 'center',
									}}>
									<View
										style={{
											flex: 1,
											width: "100%",
											alignItems: "center",
											// justifyContent: 'center',
										}}>
										<Fade>
											<div
												style={{
													height: "100%",
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<View
													style={{
														width: "100%",
														height: 52,
														marginRight: "auto",
														marginLeft: "auto",
														alignItems: "center",
														maxWidth: 350,
														marginTop: 20,
													}}>
													<TextInput
														style={{
															width: "100%",
															height: 52,
															paddingLeft: 40,
															marginBottom: 5,
															marginTop: 0,
															fontSize: 16,
															borderRadius: 20,
															// backgroundColor:
															// 	APP_COLOURS.WHITE,
															textAlign: "left",
															color: "#FFF",
															fontWeight: "200",
															fontFamily: "Avenir",
															letterSpacing: 0.26,
															borderWidth: 0.3,
															borderColor: APP_COLOURS.BLUE,
														}}
														placeholderTextColor={"#FFF"}
														autoCapitalize='none'
														clearButtonMode='while-editing'
														autoCompleteType='username'
														autoCorrect={false}
														autoFocus={true}
														keyboardType='email-address'
														textContentType='emailAddress'
														placeholder={` Email address`}
														value={this.state.emailInput}
														onChangeText={(text) => {
															let validated =
																this.validateEmail(text);
															this.setState({
																emailInput: text,
															});
														}}
													/>

													{this.state.emailValid ? (
														<Image
															source={tick}
															style={{
																width: 20,
																height: 20,
																resizeMode: "contain",
																position: "absolute",
																left: 15,
																top: 16,
															}}
														/>
													) : null}
												</View>
											</div>
										</Fade>

										<Fade>
											<div
												style={{
													height: "100%",
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
													// display: this.state.emailValid
													// 	? 'flex'
													// 	: 'none',
												}}>
												<View
													style={{
														width: "100%",
														height: 52,
														marginRight: "auto",
														marginLeft: "auto",
														alignItems: "center",
														maxWidth: 350,
														marginTop: 20,
													}}>
													<TextInput
														style={{
															width: "100%",
															height: 52,
															paddingLeft: 40,
															marginBottom: 5,
															marginTop: 0,
															fontSize: 16,
															borderRadius: 20,
															textAlign: "left",
															color: "#FFF",
															fontWeight: "200",
															fontFamily: "Avenir",
															letterSpacing: 0.26,
															borderWidth: 0.3,
															borderColor: APP_COLOURS.BLUE,
														}}
														placeholderTextColor={"#FFF"}
														autoCapitalize='none'
														clearButtonMode='while-editing'
														autoCompleteType='password'
														secureTextEntry={true}
														textContentType='password'
														autoCorrect={false}
														placeholder={` Password`}
														value={this.state.password}
														// defaultValue={
														// 	this.state.password
														// }
														onChangeText={(text) => {
															this.setState({
																password: text,
															});
														}}
														onSubmitEditing={() => {
															// this.loginButton();
															this.loginFuncWithOTP();
														}}
													/>

													{this.state.password.length <
													5 ? null : (
														<Image
															source={tick}
															style={{
																width: 20,
																height: 20,
																resizeMode: "contain",
																position: "absolute",
																left: 15,
																top: 16,
															}}
														/>
													)}
												</View>
											</div>
										</Fade>
										{/* ) : null} */}
									</View>

									<View
										style={{
											width: "100%",
											height: 60,
											// alignItems: "center",
											maxWidth: 350,
											marginTop: 30,
										}}>
										{this.state.emailValid &&
										this.state.password !== "" ? (
											<Fade>
												<div
													style={{
														height: "100%",
														width: "100%",
														alignItems: "center",
														justifyContent: "center",
													}}>
													<TouchableOpacity
														disabled={this.state.loading}
														onPress={() => {
															// this.loginButton();
															this.loginFuncWithOTP();
														}}>
														<View
															style={{
																backgroundColor:
																	APP_COLOURS.BLUE,
																flex: 1,
																padding: 13,
																justifyContent: "center",
																alignItems: "center",
																borderRadius: 10,
															}}>
															{this.state.loading ? (
																<ActivityIndicator color='#FFF' />
															) : (
																<Text
																	style={{
																		color: APP_COLOURS.WHITE,
																	}}>
																	Login
																</Text>
															)}
														</View>
													</TouchableOpacity>
												</div>
											</Fade>
										) : null}
									</View>
								</View>
							) : (
								<View
									style={{
										marginTop: 0,
										marginBottom: 5,
									}}>
									<Fade>
										<div
											style={{
												height: "100%",
												width: "100%",
												alignItems: "center",
												justifyContent: "center",
												// display: this.state.emailValid
												// 	? 'flex'
												// 	: 'none',
											}}>
											<View>
												{this.state.otpPin == "" ? (
													<Image
														source={otp_sent}
														style={{
															// width: 180,
															height: 100,
															resizeMode: "contain",
															marginBottom: 14,
														}}
													/>
												) : (
													<Image
														source={otp_valid}
														style={{
															// width: 180,
															height: 100,
															resizeMode: "contain",
															marginBottom: 14,
														}}
													/>
												)}
											</View>

											<View>
												<Text
													style={{
														fontSize: 20,
														textAlign: "center",
														color: "#FFF",
														fontWeight: "800",
														fontFamily: "Avenir",
														marginBottom: 4,
													}}>
													One-Time PIN
												</Text>
												<Text
													style={{
														textAlign: "center",
														color: "#FFF",
														fontWeight: "300",
														fontFamily: "Avenir",
														fontSize: 14,
														marginBottom: 10,
													}}>
													we've emailed you a one-time pin.
												</Text>
											</View>
											<View
												style={{
													width: "100%",
													// height: 52,
													// marginRight: 'auto',
													// marginLeft: 'auto',
													// alignItems: 'center',
													// maxWidth: 350,
													marginTop: 20,
													flexDirection: "row",
													justifyContent: "space-between",
												}}>
												<TextInput
													ref={(ref) => {
														this.digit1 = ref;
													}}
													style={{
														width: 46,
														height: 52,
														borderRadius: 9,
														fontSize: 18,

														textAlign: "center",
														color: "#FFF",
														fontWeight: "800",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLUE,
													}}
													placeholderTextColor={"#FFF"}
													autoCorrect={false}
													autoFocus
													// placeholder={`_`}
													value={this.state.digit1}
													// defaultValue={
													// 	this.state.password
													// }
													maxLength={1}
													onChangeText={(text) => {
														// this.setState({
														// 	digit1: text,
														// });
														this.digit2.focus();
													}}
												/>

												<TextInput
													ref={(ref) => {
														this.digit2 = ref;
													}}
													style={{
														width: 46,
														height: 52,
														borderRadius: 9,

														fontSize: 18,
														textAlign: "center",
														color: "#FFF",
														fontWeight: "800",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLUE,
													}}
													placeholderTextColor={"#FFF"}
													// clearButtonMode='while-editing'
													autoCorrect={false}
													// placeholder={`_`}
													value={this.state.digit2}
													maxLength={1}
													onChangeText={(text) => {
														// this.setState({
														// 	digit2: text,
														// });
														this.digit3.focus();
													}}
												/>
												<TextInput
													ref={(ref) => {
														this.digit3 = ref;
													}}
													style={{
														width: 46,
														height: 52,
														borderRadius: 9,

														fontSize: 18,
														textAlign: "center",
														color: "#FFF",
														fontWeight: "800",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLUE,
													}}
													placeholderTextColor={"#FFF"}
													// clearButtonMode='while-editing'
													autoCorrect={false}
													placeholder={``}
													value={this.state.digit3}
													// maxLength={1}
													onChangeText={(text) => {
														// this.setState({
														// 	digit3: text,
														// });
														this.digit4.focus();
													}}
												/>
												<TextInput
													ref={(ref) => {
														this.digit4 = ref;
													}}
													style={{
														width: 46,
														height: 52,
														borderRadius: 9,

														fontSize: 18,
														textAlign: "center",
														color: "#FFF",
														fontWeight: "800",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLUE,
													}}
													placeholderTextColor={"#FFF"}
													// clearButtonMode='while-editing'
													autoCorrect={false}
													// placeholder={``}
													value={this.state.digit4}
													maxLength={1}
													onChangeText={(text) => {
														let otpPin = `${this.digit1.value}${this.digit2.value}${this.digit3.value}${this.digit4.value}`;

														// alert(otpPin);

														if (otpPin.length < 4) {
															otpPin = "";
														}

														this.setState({
															otpPin: otpPin,
														});
													}}
												/>
											</View>

											<View
												style={{
													width: "100%",
													height: 60,
													// alignItems: "center",
													// maxWidth: 350,
													marginTop: 30,
												}}>
												{this.state.otpPin !== "" ? (
													<Fade>
														<div
															style={{
																height: "100%",
																width: "100%",
																alignItems: "center",
																justifyContent: "center",
															}}>
															<TouchableOpacity
																disabled={this.state.loading}
																onPress={() =>
																	this.loginButton2()
																}>
																<View
																	style={{
																		backgroundColor:
																			APP_COLOURS.BLUE,
																		flex: 1,
																		padding: 13,
																		justifyContent: "center",
																		alignItems: "center",
																		borderRadius: 10,
																	}}>
																	{this.state.loading ? (
																		<ActivityIndicator color='#FFF' />
																	) : (
																		<Text
																			style={{
																				color: APP_COLOURS.WHITE,
																			}}>
																			Login
																		</Text>
																	)}
																</View>
															</TouchableOpacity>
														</div>
													</Fade>
												) : null}
											</View>
										</div>
									</Fade>
								</View>
							)}
						</View>
						{/* </ScrollView> */}
					</View>
				</div>
			);
		} else {
			return (
				<View
					style={{
						// flex: 1,
						width: this.props.screenWidth,
						height: this.props.screenHeight,
						// borderRadius: 12,
						// backgroundColor: APP_COLOURS.BLUE,
						backgroundColor: "#FFF",
						overflow: "hidden",
					}}>
					<View
						style={{
							// height: "auto",
							hight: this.props.screenHeight * 0.7,
							alignItems: "center",
							paddingLeft: 30,
							paddingRight: 30,
							// borderRadius: 12,
							backgroundColor: APP_COLOURS.BG2,
							// shadowColor: "#000",
							// shadowOffset: {
							// 	width: 0,
							// 	height: 8,
							// },
							// shadowOpacity: 0.44,
							// shadowRadius: 10.32,
							// elevation: 16,
							overflow: "hidden",
							paddingTop: 30,
						}}>
						{!this.state.otpPinSent ? (
							<Image
								source={logo}
								style={{
									width: 180,
									height: 80,
									marginBottom: 20,
									resizeMode: "contain",
								}}
							/>
						) : (
							<View />
						)}

						{this.state.errorLogin ? (
							<View
								style={{
									width: 300,
									marginTop: 10,
								}}>
								<Text
									style={{
										color: "red",
									}}>
									We are unable to verify your profile, please check
									your email again to confirm this is correct.
								</Text>
							</View>
						) : null}

						{!this.state.otpPinSent ? (
							<View
								style={{
									flex: 1,
									width: "100%",
									alignItems: "center",
									// justifyContent: 'center',
								}}>
								<View
									style={{
										flex: 1,
										width: "100%",
										alignItems: "center",
										// justifyContent: 'center',
									}}>
									<Fade>
										<div
											style={{
												height: "100%",
												width: "100%",
												alignItems: "center",
												justifyContent: "center",
											}}>
											<View
												style={{
													width: "100%",
													height: 52,
													marginRight: "auto",
													marginLeft: "auto",
													alignItems: "center",
													maxWidth: 350,
													marginTop: 20,
												}}>
												<TextInput
													style={{
														width: "100%",
														height: 52,
														paddingLeft: 40,
														marginBottom: 5,
														marginTop: 0,
														fontSize: 16,
														borderRadius: 20,
														// backgroundColor:
														// 	APP_COLOURS.WHITE,
														textAlign: "left",
														color: "#FFF",
														fontWeight: "200",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLUE,
													}}
													placeholderTextColor={"#797979"}
													autoCapitalize='none'
													clearButtonMode='while-editing'
													autoCompleteType='username'
													autoCorrect={false}
													autoFocus={true}
													keyboardType='email-address'
													textContentType='emailAddress'
													placeholder={` Email address`}
													value={this.state.emailInput}
													onChangeText={(text) => {
														let validated =
															this.validateEmail(text);
														this.setState({
															emailInput: text,
														});
													}}
												/>

												{this.state.emailValid ? (
													<Image
														source={tick}
														style={{
															width: 20,
															height: 20,
															resizeMode: "contain",
															position: "absolute",
															left: 13,
															top: 13,
														}}
													/>
												) : null}
											</View>
										</div>
									</Fade>

									<Fade>
										<div
											style={{
												height: "100%",
												width: "100%",
												alignItems: "center",
												justifyContent: "center",
												// display: this.state.emailValid
												// 	? 'flex'
												// 	: 'none',
											}}>
											<View
												style={{
													width: "100%",
													height: 52,
													marginRight: "auto",
													marginLeft: "auto",
													alignItems: "center",
													maxWidth: 350,
													marginTop: 20,
												}}>
												<TextInput
													style={{
														width: "100%",
														height: 52,
														paddingLeft: 40,
														marginBottom: 5,
														marginTop: 0,
														fontSize: 16,
														borderRadius: 20,
														textAlign: "left",
														color: "#FFF",
														fontWeight: "200",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLUE,
													}}
													placeholderTextColor={"#797979"}
													autoCapitalize='none'
													clearButtonMode='while-editing'
													autoCompleteType='password'
													secureTextEntry={true}
													textContentType='password'
													autoCorrect={false}
													placeholder={` Password`}
													value={this.state.password}
													// defaultValue={
													// 	this.state.password
													// }
													onChangeText={(text) => {
														this.setState({
															password: text,
														});
													}}
													onSubmitEditing={() =>
														this.loginButton()
													}
												/>

												{this.state.password.length < 5 ? null : (
													<Image
														source={tick}
														style={{
															width: 20,
															height: 20,
															resizeMode: "contain",
															position: "absolute",
															left: 13,
															top: 13,
														}}
													/>
												)}
											</View>
										</div>
									</Fade>
									{/* ) : null} */}
								</View>

								<View
									style={{
										width: "100%",
										height: 60,
										// alignItems: "center",
										maxWidth: 350,
										marginTop: 10,
										marginBottom: 20,
									}}>
									{this.state.emailValid &&
									this.state.password !== "" ? (
										<Fade>
											<div
												style={{
													height: "100%",
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
													marginTop: 10,
												}}>
												<TouchableOpacity
													disabled={this.state.loading}
													onPress={() => {
														// this.loginButton();
														this.loginFuncWithOTP();
													}}>
													<View
														style={{
															backgroundColor: APP_COLOURS.BLUE,
															flex: 1,
															padding: 13,
															justifyContent: "center",
															alignItems: "center",
															borderRadius: 10,
														}}>
														{this.state.loading ? (
															<ActivityIndicator color='#FFF' />
														) : (
															<Text
																style={{
																	color: APP_COLOURS.WHITE,
																}}>
																Login
															</Text>
														)}
													</View>
												</TouchableOpacity>
											</div>
										</Fade>
									) : null}
								</View>
							</View>
						) : (
							<View
								style={{
									width: this.state.screenWidth,
									height: this.state.screenHeight,
									// paddingLeft: 30,
									padding: 10,
									alignItems: "center",
									// justifyContent: "center",
								}}>
								<Fade>
									<div
										style={{
											// height: "100%",
											width: "100%",
											maxWidth: 280,
											// alignItems: "center",
											justifyContent: "center",
											// display: this.state.emailValid
											// 	? 'flex'
											// 	: 'none',
										}}>
										<View>
											{this.state.otpPin == "" ? (
												<Image
													source={otp_sent}
													style={{
														// width: 180,
														height: 90,
														resizeMode: "contain",
														marginBottom: 14,
													}}
												/>
											) : (
												<Image
													source={otp_valid}
													style={{
														// width: 180,
														height: 90,
														resizeMode: "contain",
														marginBottom: 14,
													}}
												/>
											)}
										</View>

										<View>
											<Text
												style={{
													fontSize: 20,
													textAlign: "center",
													color: "#FFF",
													fontWeight: "800",
													fontFamily: "Avenir",
													marginBottom: 4,
												}}>
												One-Time PIN
											</Text>
											<Text
												style={{
													textAlign: "center",
													color: "#FFF",
													fontWeight: "300",
													fontFamily: "Avenir",
													fontSize: 14,
													marginBottom: 0,
												}}>
												we've emailed you a one-time pin.
											</Text>
										</View>
										<View
											style={{
												width: "100%",
												// height: 52,
												// marginRight: 'auto',
												// marginLeft: 'auto',
												// alignItems: 'center',
												// maxWidth: 350,
												marginTop: 20,
												flexDirection: "row",
												justifyContent: "space-between",
											}}>
											<TextInput
												ref={(ref) => {
													this.digit1 = ref;
												}}
												style={{
													width: 46,
													height: 52,
													borderRadius: 9,
													fontSize: 18,

													textAlign: "center",
													color: "#FFF",
													fontWeight: "800",
													fontFamily: "Avenir",
													letterSpacing: 0.26,
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BLUE,
												}}
												placeholderTextColor={"#FFF"}
												autoCorrect={false}
												autoFocus
												// placeholder={`_`}
												value={this.state.digit1}
												// defaultValue={
												// 	this.state.password
												// }
												maxLength={1}
												onChangeText={(text) => {
													// this.setState({
													// 	digit1: text,
													// });
													this.digit2.focus();
												}}
											/>

											<TextInput
												ref={(ref) => {
													this.digit2 = ref;
												}}
												style={{
													width: 46,
													height: 52,
													borderRadius: 9,

													fontSize: 18,
													textAlign: "center",
													color: "#FFF",
													fontWeight: "800",
													fontFamily: "Avenir",
													letterSpacing: 0.26,
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BLUE,
												}}
												placeholderTextColor={"#FFF"}
												// clearButtonMode='while-editing'
												autoCorrect={false}
												// placeholder={`_`}
												value={this.state.digit2}
												maxLength={1}
												onChangeText={(text) => {
													// this.setState({
													// 	digit2: text,
													// });
													this.digit3.focus();
												}}
											/>
											<TextInput
												ref={(ref) => {
													this.digit3 = ref;
												}}
												style={{
													width: 46,
													height: 52,
													borderRadius: 9,

													fontSize: 18,
													textAlign: "center",
													color: "#FFF",
													fontWeight: "800",
													fontFamily: "Avenir",
													letterSpacing: 0.26,
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BLUE,
												}}
												placeholderTextColor={"#FFF"}
												// clearButtonMode='while-editing'
												autoCorrect={false}
												placeholder={``}
												value={this.state.digit3}
												// maxLength={1}
												onChangeText={(text) => {
													// this.setState({
													// 	digit3: text,
													// });
													this.digit4.focus();
												}}
											/>
											<TextInput
												ref={(ref) => {
													this.digit4 = ref;
												}}
												style={{
													width: 46,
													height: 52,
													borderRadius: 9,

													fontSize: 18,
													textAlign: "center",
													color: "#FFF",
													fontWeight: "800",
													fontFamily: "Avenir",
													letterSpacing: 0.26,
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BLUE,
												}}
												placeholderTextColor={"#FFF"}
												// clearButtonMode='while-editing'
												autoCorrect={false}
												// placeholder={``}
												value={this.state.digit4}
												maxLength={1}
												onChangeText={(text) => {
													let otpPin = `${this.digit1.value}${this.digit2.value}${this.digit3.value}${this.digit4.value}`;

													// alert(otpPin);

													if (otpPin.length < 4) {
														otpPin = "";
													}

													this.setState({
														otpPin: otpPin,
													});
												}}
											/>
										</View>

										<View
											style={{
												width: "100%",
												height: 60,
												// alignItems: "center",
												// maxWidth: 350,
												marginTop: 30,
											}}>
											{this.state.otpPin !== "" ? (
												<Fade>
													<div
														style={{
															height: "100%",
															width: "100%",
															alignItems: "center",
															justifyContent: "center",
														}}>
														<TouchableOpacity
															disabled={this.state.loading}
															onPress={() =>
																this.loginButton2()
															}>
															<View
																style={{
																	backgroundColor:
																		APP_COLOURS.BLUE,
																	flex: 1,
																	padding: 13,
																	justifyContent: "center",
																	alignItems: "center",
																	borderRadius: 10,
																}}>
																{this.state.loading ? (
																	<ActivityIndicator color='#FFF' />
																) : (
																	<Text
																		style={{
																			color: APP_COLOURS.WHITE,
																		}}>
																		Login
																	</Text>
																)}
															</View>
														</TouchableOpacity>
													</div>
												</Fade>
											) : null}
										</View>
									</div>
								</Fade>
							</View>
						)}
					</View>

					<Image
						source={workfromhome}
						style={{
							width: maxDimentions * 0.4,
							height: maxDimentions * 0.4,

							resizeMode: "contain",
							position: "absolute",
							right: 10,
							bottom: 10,
						}}
					/>
				</View>
			);
		}
	}
}

export const withNavigation = (Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(index);
