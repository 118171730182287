import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import NewCompanyModal from "./NewCompanyModal";
import { APP_COLOURS } from "../../APP_VARS";

export default class heading extends Component {
	constructor(props) {
		super(props);
		this.state = { addNewCompany: false };
	}

	render() {
		return (
			<View
				style={{
					width: "100%",
					padding: 10,
					// backgroundColor: APP_COLOURS.WHITE_TRANSPARENT3,
					flexDirection: "row",
					justifyContent: "space-between",
					borderRadius: 7,
				}}>
				<Text
					style={{
						fontFamily: "Avenir",
						fontWeight: "900",
						color: "#FFF",
						fontSize: 17,
					}}>
					{this.props.title}
				</Text>
				<TouchableOpacity
					style={{
						alignItems: "center",
						justifyContent: "center",
					}}
					onPress={() => {
						this.setState({
							addNewCompany: !this.state.addNewCompany,
						});
					}}>
					<View
						style={{
							backgroundColor: APP_COLOURS.BLUE,
							borderRadius: 6,
							padding: 5,
						}}>
						<Text
							style={{
								color: "#FFF",
								fontSize: 13,
							}}>
							Add {this.props.title}
						</Text>
					</View>
				</TouchableOpacity>

				{this.state.addNewCompany ? (
					<NewCompanyModal
						close={() => {
							this.setState({
								addNewCompany: !this.state.addNewCompany,
							});
						}}
						addEntity={async (d) => {
							await this.props.addEntity(d);
							this.setState({
								addNewCompany: false,
							});
						}}
					/>
				) : null}
			</View>
		);
	}
}
