import React, { Component, useReducer } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
	ActivityIndicator,
} from "react-native";
import add_btn from "./../../assets/images/add_btn.png";
import { APP_COLOURS } from "../APP_VARS";
import Heading from "./Settings/heading";
import Font from "react-font";
import moment from "moment";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			entities: [],
			addNewCompany: false,
			editCompany: "",
			editCompanyData: "",
			edited: false,
		};
	}

	componentDidMount() {
		this.getJsonDataFromServer();
	}

	render() {
		return (
			<View
				style={{
					height: this.props.screenHeight - 65,
				}}>
				<ScrollView>
					<View
						style={{
							padding: 5,
							paddingTop: 70,
						}}>
						<Heading
							title={"Companies"}
							addEntity={(d) => {
								this.addItem(d, d.entityType);
							}}
						/>
						<ScrollView horizontal={true} contentContainerStyle={{}}>
							{this.state.entities
								.filter((dd) => dd.entityType === "company")
								.map((d, i) => (
									<View
										style={{
											width: 400,
											height: "auto",
											borderRadius: 10,
											overflow: "hidden",
											backgroundColor: "#FFF",
											margin: 10,
											borderRadius: 10,
										}}>
										<View
											style={{
												width: "100%",
												borderBottomColor: "#E8E8E8",
												borderBottomWidth: 0.5,
												backgroundColor:
													APP_COLOURS.WHITE_TRANSPARENT3,
												padding: 10,
											}}>
											<Image
												source={{
													uri: d.logoImage,
												}}
												style={{
													width: "100%",
													height: 45,
													resizeMode: "contain",
												}}
											/>
										</View>
										<View
											style={{
												width: "100%",
												paddingLeft: 10,
												paddingRight: 10,
												marginTop: 5,
												flexDirection: "row",
												justifyContent: "space-between",
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontWeight: "900",
													color: APP_COLOURS.BG,
													fontSize: 17,
												}}>
												{d.title}
											</Text>
											<TouchableOpacity
												style={{
													alignItems: "center",
													justifyContent: "center",
												}}
												onPress={() => {
													this.setState({
														editCompany: d.id,
														editCompanyData: d,
													});
												}}>
												<View>
													<Text
														style={{
															color: APP_COLOURS.BLUE,
														}}>
														Edit
													</Text>
												</View>
											</TouchableOpacity>
										</View>
										<View
											style={{
												padding: 10,
											}}>
											{Object.keys(this.state.entities[i]).map(
												(dd) => {
													let excl = [
														"logoImage",
														"id",
														"documents",
														"title",
														"entityType",
														"date_created",
													];
													if (excl.includes(dd)) {
														return;
													} else
														return (
															<View
																style={{
																	width: "100%",
																	// padding: 10,
																	// borderBottomColor: "#E8E8E8",
																	// borderBottomWidth: 0.5,
																	flexDirection: "row",
																	alignItems: "center",
																	justifyContent:
																		"space-between",
																}}>
																{this.state.editCompany ===
																d.id ? (
																	<TextInput
																		style={{
																			width: "100%",
																			fontSize: 12,
																			color: "#000",
																			fontWeight: "500",
																			fontFamily: "Avenir",
																			paddingTop: 8,
																			paddingBottom: 8,
																		}}
																		placeholderTextColor={
																			"#797979"
																		}
																		autoCapitalize='words'
																		clearButtonMode='while-editing'
																		autoCorrect={false}
																		defaultValue={
																			this.state
																				.editCompanyData[dd]
																		}
																		onChangeText={async (
																			text,
																		) => {
																			await this.setState(
																				(prevState) => ({
																					editCompanyData:
																						{
																							...prevState.editCompanyData,
																							[dd]: text,
																						},
																					edited: true,
																				}),
																			);
																		}}
																	/>
																) : (
																	<Text
																		style={{
																			color: APP_COLOURS.BG,
																			fontSize: 12,
																			minWidth: 250,
																		}}>
																		<Font
																			weight={400}
																			family='Montserrat'>
																			{d[dd]}
																		</Font>
																	</Text>
																)}

																<Text
																	style={{
																		color: APP_COLOURS.BG,
																		fontSize: 12,
																		opacity: 0.5,
																		display:
																			this.state
																				.editCompany ===
																			d.id
																				? "flex"
																				: d[dd] === ""
																				? "flex"
																				: "none",
																	}}>
																	<Font
																		weight={300}
																		family='Montserrat'>
																		{dd}
																	</Font>
																</Text>
															</View>
														);
												},
											)}
										</View>

										<TouchableOpacity
											style={{
												padding: 10,

												display: this.state.edited
													? "flex"
													: "none",
											}}
											onPress={async () => {
												await this.updateItem(
													this.state.editCompanyData,
													d.entityType,
												);
											}}>
											<View
												style={{
													backgroundColor: APP_COLOURS.BLUE,
													width: this.state.addingStaff
														? "auto"
														: "100%",
													padding: 7,

													borderRadius: 25,
													justifyContent: "center",
													alignItems: "center",
												}}>
												{!this.state.loading ? (
													<Text
														style={{
															alignItems: "center",
															justifyContent: "center",
															color: "#FFF",
															fontSize: 13,
														}}>
														<Font
															weight={500}
															family='Montserrat'>
															Update
														</Font>
													</Text>
												) : (
													<ActivityIndicator color={"#FFF"} />
												)}
											</View>
										</TouchableOpacity>

										<TouchableOpacity
											style={{
												padding: 10,
												position: "absolute",
												right: 0,
												zIndex: 999,
												display:
													this.state.editCompany === d.id
														? "flex"
														: "none",
											}}
											onPress={async () => {
												await this.deleteItem(d, d.entityType);
											}}>
											<View
												style={{
													backgroundColor: APP_COLOURS.RED,
													padding: 7,
													borderRadius: 25,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent: "center",
														color: "#FFF",
														fontSize: 13,
													}}>
													<Font weight={500} family='Montserrat'>
														Delete
													</Font>
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								))}
							<View
								style={{
									width: 100,
								}}
							/>
						</ScrollView>
					</View>

					{/* <View
						style={{
							padding: 5,
							paddingTop: 70,
						}}>
						<Heading
							title={"Trusts"}
							addEntity={(d) => {
								this.addItem(d, d.entityType);
							}}
						/>
						<ScrollView horizontal={true} contentContainerStyle={{}}>
							{this.state.entities.trust.map((d, i) => (
								<View
									style={{
										width: 400,
										height: "auto",
										borderRadius: 10,
										overflow: "hidden",
										backgroundColor: "#E8E8E8",
										margin: 10,
										borderRadius: 10,
									}}>
									<View
										style={{
											width: "100%",
											borderBottomColor: "#E8E8E8",
											borderBottomWidth: 0.5,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT3,
											padding: 10,
										}}>
										<Image
											source={{
												uri: d.logoImage,
											}}
											style={{
												width: "100%",
												height: 60,
												resizeMode: "contain",
											}}
										/>
									</View>
									<View
										style={{
											width: "100%",
											padding: 10,
											flexDirection: "row",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "900",
												color: APP_COLOURS.BG,
												fontSize: 17,
											}}>
											{d.title}
										</Text>
										<TouchableOpacity
											style={{
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={() => {
												this.setState({
													editCompany: d.id,
													editCompanyData: d,
												});
											}}>
											<View>
												<Text
													style={{
														color: APP_COLOURS.BLUE,
													}}>
													Edit
												</Text>
											</View>
										</TouchableOpacity>
									</View>
									{Object.keys(this.state.entities.company[i]).map(
										(dd) => {
											let excl = [
												"logoImage",
												"id",
												"documents",
												"title",
												"entityType",
												"date_created",
											];
											if (excl.includes(dd)) {
												return;
											} else
												return (
													<View
														style={{
															width: "100%",
															padding: 10,
															borderBottomColor: "#E8E8E8",
															borderBottomWidth: 0.5,
															flexDirection: "row",
															alignItems: "center",
															justifyContent: "space-between",
														}}>
														{this.state.editCompany === d.id ? (
															<TextInput
																style={{
																	width: "100%",
																	fontSize: 12,
																	color: "#000",
																	fontWeight: "500",
																	fontFamily: "Avenir",
																	paddingTop: 8,
																	paddingBottom: 8,
																}}
																placeholderTextColor={"#797979"}
																autoCapitalize='words'
																clearButtonMode='while-editing'
																autoCorrect={false}
																defaultValue={
																	this.state.editCompanyData[
																		dd
																	]
																}
																onChangeText={async (text) => {
																	await this.setState(
																		(prevState) => ({
																			editCompanyData: {
																				...prevState.editCompanyData,
																				[dd]: text,
																			},
																			edited: true,
																		}),
																	);
																}}
															/>
														) : (
															<Text
																style={{
																	color: APP_COLOURS.BG,
																	fontSize: 12,
																	minWidth: 250,
																}}>
																<Font
																	weight={400}
																	family='Montserrat'>
																	{d[dd]}
																</Font>
															</Text>
														)}

														<Text
															style={{
																color: APP_COLOURS.BG,
																fontSize: 12,
																opacity: 0.5,
																display:
																	this.state.editCompany ===
																	d.id
																		? "flex"
																		: d[dd] === ""
																		? "flex"
																		: "none",
															}}>
															<Font
																weight={300}
																family='Montserrat'>
																{dd}
															</Font>
														</Text>
													</View>
												);
										},
									)}
									<TouchableOpacity
										style={{
											padding: 10,

											display: this.state.edited ? "flex" : "none",
										}}
										onPress={async () => {
											await this.updateItem(
												this.state.editCompanyData,
												d.entityType,
											);
										}}>
										<View
											style={{
												backgroundColor: APP_COLOURS.BLUE,
												width: this.state.addingStaff
													? "auto"
													: "100%",
												padding: 7,

												borderRadius: 25,
												justifyContent: "center",
												alignItems: "center",
											}}>
											{!this.state.loading ? (
												<Text
													style={{
														alignItems: "center",
														justifyContent: "center",
														color: "#FFF",
														fontSize: 13,
													}}>
													<Font weight={500} family='Montserrat'>
														Update
													</Font>
												</Text>
											) : (
												<ActivityIndicator color={"#FFF"} />
											)}
										</View>
									</TouchableOpacity>

									<TouchableOpacity
										style={{
											padding: 10,
											position: "absolute",
											right: 0,
											zIndex: 999,
											display:
												this.state.editCompany === d.id
													? "flex"
													: "none",
										}}
										onPress={async () => {
											await this.deleteItem(d, d.entityType);
										}}>
										<View
											style={{
												backgroundColor: APP_COLOURS.RED,
												padding: 7,
												borderRadius: 25,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													alignItems: "center",
													justifyContent: "center",
													color: "#FFF",
													fontSize: 13,
												}}>
												<Font weight={500} family='Montserrat'>
													Delete
												</Font>
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							))}
							<View
								style={{
									width: 100,
								}}
							/>
						</ScrollView>
					</View>

					<View
						style={{
							padding: 5,
							paddingTop: 70,
						}}>
						<Heading
							title={"Personal"}
							addEntity={(d) => {
								this.addItem(d, d.entityType);
							}}
						/>
						<ScrollView horizontal={true} contentContainerStyle={{}}>
							{this.state.entities.personal.map((d, i) => (
								<View
									style={{
										width: 400,
										height: "auto",
										borderRadius: 10,
										overflow: "hidden",
										backgroundColor: "#E8E8E8",
										margin: 10,
										borderRadius: 10,
									}}>
									<View
										style={{
											width: "100%",
											borderBottomColor: "#E8E8E8",
											borderBottomWidth: 0.5,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT3,
											padding: 10,
										}}>
										<Image
											source={{
												uri: d.logoImage,
											}}
											style={{
												width: "100%",
												height: 60,
												resizeMode: "contain",
											}}
										/>
									</View>
									<View
										style={{
											width: "100%",
											padding: 10,
											flexDirection: "row",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "900",
												color: APP_COLOURS.BG,
												fontSize: 17,
											}}>
											{d.title}
										</Text>
										<TouchableOpacity
											style={{
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={() => {
												this.setState({
													editCompany: d.id,
													editCompanyData: d,
												});
											}}>
											<View>
												<Text
													style={{
														color: APP_COLOURS.BLUE,
													}}>
													Edit
												</Text>
											</View>
										</TouchableOpacity>
									</View>
									{Object.keys(this.state.entities.company[i]).map(
										(dd) => {
											let excl = [
												"logoImage",
												"id",
												"documents",
												"title",
												"entityType",
												"date_created",
											];
											if (excl.includes(dd)) {
												return;
											} else
												return (
													<View
														style={{
															width: "100%",
															padding: 10,
															borderBottomColor: "#E8E8E8",
															borderBottomWidth: 0.5,
															flexDirection: "row",
															alignItems: "center",
															justifyContent: "space-between",
														}}>
														{this.state.editCompany === d.id ? (
															<TextInput
																style={{
																	width: "100%",
																	fontSize: 12,
																	color: "#000",
																	fontWeight: "500",
																	fontFamily: "Avenir",
																	paddingTop: 8,
																	paddingBottom: 8,
																}}
																placeholderTextColor={"#797979"}
																autoCapitalize='words'
																clearButtonMode='while-editing'
																autoCorrect={false}
																defaultValue={
																	this.state.editCompanyData[
																		dd
																	]
																}
																onChangeText={async (text) => {
																	await this.setState(
																		(prevState) => ({
																			editCompanyData: {
																				...prevState.editCompanyData,
																				[dd]: text,
																			},
																			edited: true,
																		}),
																	);
																}}
															/>
														) : (
															<Text
																style={{
																	color: APP_COLOURS.BG,
																	fontSize: 12,
																	minWidth: 250,
																}}>
																<Font
																	weight={400}
																	family='Montserrat'>
																	{d[dd]}
																</Font>
															</Text>
														)}

														<Text
															style={{
																color: APP_COLOURS.BG,
																fontSize: 12,
																opacity: 0.5,
																display:
																	this.state.editCompany ===
																	d.id
																		? "flex"
																		: d[dd] === ""
																		? "flex"
																		: "none",
															}}>
															<Font
																weight={300}
																family='Montserrat'>
																{dd}
															</Font>
														</Text>
													</View>
												);
										},
									)}
									<TouchableOpacity
										style={{
											padding: 10,

											display: this.state.edited ? "flex" : "none",
										}}
										onPress={async () => {
											await this.updateItem(
												this.state.editCompanyData,
												d.entityType,
											);
										}}>
										<View
											style={{
												backgroundColor: APP_COLOURS.BLUE,
												width: this.state.addingStaff
													? "auto"
													: "100%",
												padding: 7,

												borderRadius: 25,
												justifyContent: "center",
												alignItems: "center",
											}}>
											{!this.state.loading ? (
												<Text
													style={{
														alignItems: "center",
														justifyContent: "center",
														color: "#FFF",
														fontSize: 13,
													}}>
													<Font weight={500} family='Montserrat'>
														Update
													</Font>
												</Text>
											) : (
												<ActivityIndicator color={"#FFF"} />
											)}
										</View>
									</TouchableOpacity>

									<TouchableOpacity
										style={{
											padding: 10,
											position: "absolute",
											right: 0,
											zIndex: 999,
											display:
												this.state.editCompany === d.id
													? "flex"
													: "none",
										}}
										onPress={async () => {
											await this.deleteItem(d, d.entityType);
										}}>
										<View
											style={{
												backgroundColor: APP_COLOURS.RED,
												padding: 7,
												borderRadius: 25,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													alignItems: "center",
													justifyContent: "center",
													color: "#FFF",
													fontSize: 13,
												}}>
												<Font weight={500} family='Montserrat'>
													Delete
												</Font>
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							))}
							<View
								style={{
									width: 100,
								}}
							/>
						</ScrollView>
					</View> */}
				</ScrollView>
			</View>
		);
	}

	getJsonDataFromServer = () => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			jwt: this.props.user_data.jwt,
			command: "get",
			fileName: "entities",
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/accountsapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result getJsonDataFromServer", result);

							this.setState({
								entities: result,
								loading: false,
							});
						})
						.catch((error) => this.setState({}));
				} else {
					this.setState({
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	saveJsonDataToServer = () => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			jwt: this.props.user_data.jwt,
			json_data: this.state.entities,
			command: "save",
			fileName: "entities",
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/accountsapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result", result);

							this.setState({
								entities: result,
								loading: false,
								edited: false,
							});
						})
						.catch((error) =>
							this.setState({
								loading: false,
							}),
						);
				} else {
					this.setState({
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	addItem = async (obj) => {
		let arr = [...this.state.entities];
		const pus_ = await arr.push(obj);
		await this.setState({
			entities: arr,
			addNewCompany: false,
			editCompany: "",
			editCompanyData: "",
		});

		await this.saveJsonDataToServer();
	};

	deleteItem = async (d) => {
		const item = this.state.entities.filter((d_) => d.id !== d_.id);
		await this.setState({
			entities: item,
		});
		await this.saveJsonDataToServer();
	};

	updateItem = async (d) => {
		let tasks = this.state.entities.filter((task) => task.id !== d.id);
		let item = tasks.concat(d);
		await this.setState({
			entities: item,
		});
		await this.saveJsonDataToServer();
	};
}

export default Settings;
