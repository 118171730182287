import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import send from "./../../../assets/images/send.png";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./field.css";

import bin from "./../../../assets/images/bin.png";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import moment from "moment";
import ModalTaskPreview from "./ModalTaskPreview";
import { ActivityIndicator } from "react-native-web";

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class TaskItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: false,
			showMore: false,
			noteData: "",

			doc_date: new Date(),

			dueDate: new Date(),

			columns: [
				{
					id: 1,
					status: "",
					color: "#151A30",
				},
				{
					id: 2,
					status: "URGENT",
					color: "#C52643",
				},
				{
					id: 3,
					status: "INPROGRESS",
					color: "#0676ED",
				},
				{
					id: 4,
					status: "Paused",
					color: "#AF7800",
				},
				{
					id: 5,
					status: "DONE",
					color: "#00CB66",
				},
			],
			showItem: "",
			deleting: false,
		};
	}

	selectedTask = (d) => {
		this.setState({
			showItem: d.id,
			showMore: !this.state.showMore,
		});
	};

	render() {
		let d = this.props.data;
		let users = this.props.users;

		let avatars = {
			1: require("./../../../assets/images/male1.png"),
			2: require("./../../../assets/images/male2.png"),
			3: require("./../../../assets/images/female1.png"),
		};

		let avatar = avatars[parseInt(this.props.user_data.avatar)];

		let due = "NOW";

		const date1 = moment();
		const date2 = moment(d.dueDate);
		due = date2.diff(date1, "days");

		let morning = new Date();
		morning.setHours(0, 0, 1, 0);
		let evening = new Date();
		evening.setHours(23, 59, 59, 0);

		return (
			<View
				style={{
					// flex: 1,
					width: "100%",
					height: "auto",
					borderWidth: this.state.showMore ? 0.3 : 0,
					borderColor: "#797979",
					borderRadius: 5,
					borderStyle: "dotted",
					// shadowColor: "#000",
					// shadowOffset: {
					// 	width: 0,
					// 	height: 8,
					// },
					// shadowOpacity: 0.24,
					// shadowRadius: 10,
					// elevation: 13,
				}}>
				<View
					onMouseEnter={() => {
						this.props.setHover(d.id);
					}}
					onMouseLeave={() => {
						this.props.setHover("");
					}}
					style={{
						flex: 1,
						flexDirection: "row",
						borderRadius: 5,
						opacity:
							this.props.hover === d.id
								? 1
								: d.status === "Done"
								? 0.3
								: 1,

						backgroundColor: APP_COLOURS.BG3,

						width: "100%",
						// minHeight: 50,
						padding: 3,
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<TouchableOpacity
						activeOpacity={0.8}
						style={{
							flex: 1,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							// height: "100%",
							// minHeight: 50,
						}}
						onPress={() => {
							this.selectedTask(d);
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								justifyContent: "space-between",
							}}>
							<Text
								style={{
									flex: 1,
									fontFamily: "Avenir",
									fontSize: 14,
									fontWeight: "400",
									color: APP_COLOURS.OFFWHITE,
									textAlign: "left",
									padding: 10,
									alignItems: "center",
									// opacity: 0.6,
								}}>
								{d.title}
							</Text>
							<View
								style={{
									width: 70,
									justifyContent: "space-between",
									alignItems: "flex-start",
									display: "none",
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										// justifyContent: "flex-start",
										// flexWrap: "wrap",
									}}>
									{d.assignment
										.filter(
											(dd) =>
												dd.id !==
												this.props.user_data.id,
										)
										.map((ass, ind) => {
											return (
												<View
													key={ind}
													style={{
														width: 20,
														height: 20,
													}}>
													<Image
														source={
															avatars[
																parseInt(
																	ass.avatar,
																)
															]
														}
														style={{
															left: -9 * ind,
															// zIndex: 10 + ind,
															width: 20,
															height: 20,
															borderRadius: 25,
															resizeMode:
																"contain",
															backgroundColor:
																"#FFF",
															shadowColor: "#000",
															shadowOffset: {
																width: 0,
																height: 8,
															},
															shadowOpacity: 0.84,
															shadowRadius: 10,
															elevation: 13,
														}}
													/>
												</View>
											);
										})}
								</View>
								<View
									style={{
										opacity: 0.3,
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "flex-end",
									}}>
									<Text
										adjustsFontSizeToFit
										style={{
											fontFamily: "Avenir",
											fontSize: 8,
											fontWeight: "400",
											fontStyle: "italic",
											color: APP_COLOURS.OFFWHITE,
										}}>
										added by {d.addedBy.first_name}...
									</Text>
								</View>
							</View>

							<View
								style={{
									// width: 6,
									width: 70,
								}}>
								<View
									style={{
										// width: 6,
										display:
											d.status === "Done"
												? "none"
												: "flex",
										width: 70,
										padding: 3,
										// height: 20,
										// minHeight: 50,
										backgroundColor:
											due <= 0
												? APP_COLOURS.RED
												: due <= 5
												? "#E0A749"
												: due <= 14
												? APP_COLOURS.BLUE
												: "transparent",

										// marginRight: 10,
										borderRadius: 2,
										alignItems: "center",
										justifyContent: "center",
									}}>
									<Text
										adjustsFontSizeToFit
										style={{
											fontFamily: "Avenir",
											fontSize: 8,
											fontWeight: "600",
											color: APP_COLOURS.OFFWHITE,
										}}>
										{moment(d.dueDate).fromNow()}
									</Text>
								</View>
								<View
									style={{
										marginTop: 3,
										padding: 3,
										display:
											d.status !== "Pending"
												? "flex"
												: "none",
										flexDirection: "row",
										justifyContent: "flex-start",
										// width: 100,
										alignItems: "center",
										backgroundColor:
											d.status == "Pending"
												? "transparent"
												: d.status == "Done"
												? APP_COLOURS.GREEN
												: d.status == "Stuck"
												? APP_COLOURS.RED
												: APP_COLOURS.ORANGE,
										borderRadius: 2,
										// marginBottom: 10,
										// height: 20,
									}}>
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											adjustsFontSizeToFit
											style={{
												fontFamily: "Avenir",
												fontSize: 8,
												fontWeight: "700",
												color:
													d.status == "Pending"
														? APP_COLOURS.WHITE
														: APP_COLOURS.BG2,
											}}>
											{d.status}
										</Text>
									</View>
								</View>
							</View>
						</View>
					</TouchableOpacity>

					{this.props.showDelete ? (
						!this.state.deleting ? (
							<TouchableOpacity
								onPress={() => {
									this.props.deleteItem(d);
									this.setState({
										deleting: true,
									});
								}}
								style={{
									flexDirection: "row",
									justifyContent: "center",
									width: 40,
									alignItems: "center",
									padding: 5,
									// borderLeftWidth: 0.5,
									// borderLeftColor: "#FFF",
									marginLeft: 5,
								}}>
								<Image
									source={bin}
									style={{
										width: 22,
										height: 22,
										// borderRadius: 25,
										resizeMode: "contain",
										marginRight: 5,
									}}
								/>
							</TouchableOpacity>
						) : (
							<View
								style={{
									flexDirection: "row",
									justifyContent: "center",
									width: 40,
									alignItems: "center",
									padding: 5,
									marginLeft: 5,
								}}>
								<ActivityIndicator color='#FFF' />
							</View>
						)
					) : null}
				</View>

				{this.state.showMore ? (
					<ModalTaskPreview
						screenHeight={this.props.screenHeight}
						screenWidth={this.props.screenWidth}
						data={d}
						accessToken={this.props.accessToken}
						users={users}
						user_data={this.props.user_data}
						close={() =>
							this.setState({
								showMore: !this.state.showMore,
							})
						}
						notes={this.props.notes}
						addNotes={(data) => this.props.addNotes(data)}
						updateItem={(data) => this.props.updateItem(data)}
						addChangeStatus={(data) =>
							this.props.addChangeStatus(data)
						}
					/>
				) : null}
			</View>
		);
	}
}

export default TaskItem;
