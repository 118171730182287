import React, { Component } from "react";
import {
	View,
	Text,
	SafeAreaView,
	ScrollView,
	Dimensions,
	TextInput,
	Keyboard,
	TouchableOpacity,
	Image,
	LayoutAnimation,
	Alert,
	Modal,
} from "react-native";
import Font from "react-font";
// import AccountsDashboard from "./AccountsFromApp/index";
import AccountsIncomeExpenses from "./AccountsFromApp/Statements";
import InvoiceQuoteTable from "./AccountsFromApp/InvoiceQuoteTable";
import Clients from "./Clients";
import ModalTabs from "./AccountsFromApp/ModalTabs";
import AccountProducts from "./AccountsFromApp/Products";
// import AccountsExpenses from "./AccountsFromApp/Expenses";
// import AccountsPurchaseOrders from "./AccountsFromApp/PurchaseOrders";
// import { APP_COLOURS } from "../APP_VARS";

// const BLUE = "#3F6EFF";
// const RED = "#FF5F5F";
// function uuid() {
// 	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
// 		var r = (Math.random() * 16) | 0,
// 			v = c == "x" ? r : (r & 0x3) | 0x8;
// 		return v.toString(16);
// 	});
// }

// const screenWidth = Math.round(Dimensions.get("window").width);
// const screenHeight = Math.round(Dimensions.get("window").height);

class DebtorsAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedClientId: "",
			selectedClient: null,

			fields: ["entities", "clients", "products"],
		};
	}

	async componentDidMount() {
		// await this.getClientData();
		// this.getJsonDataFromServer(this.state.serverFile);
	}

	// getClientData = () => {
	// 	var raw = JSON.stringify({
	// 		jwt: "1234567",
	// 	});
	// 	var myHeaders = new Headers();
	// 	myHeaders.append("Content-Type", "application/json");

	// 	var requestOptions = {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: raw,
	// 		redirect: "follow",
	// 	};

	// 	fetch(
	// 		"https://www.tetrice.co.za/REST/accountsapi/get_client_data.php",
	// 		requestOptions,
	// 	)
	// 		.then((response) => {
	// 			console.log(response);
	// 			if (response.status == "404") {
	// 				response.json().then((result) => {
	// 					console.log("result", result);
	// 					this.setState({
	// 						clients: [],
	// 					});
	// 				});
	// 			} else if (response.status == "200") {
	// 				response.json().then((result) => {
	// 					this.setState({
	// 						clients: result,
	// 					});
	// 				});
	// 			}
	// 		})
	// 		.catch((error) => console.log("error", error));
	// };

	// addDocument = async (file) => {
	// 	let saved_documents = this.state.saved_documents;

	// 	await saved_documents.push(file);

	// 	this.setState({
	// 		saved_documents: saved_documents,
	// 	});
	// 	// this.saveAppStates();
	// };

	// addCoreDocument = async (file) => {
	// 	const fileBase64 = file.fileBase64;
	// 	const docid = file.id;
	// 	await this.saveDocToAsync(fileBase64, docid);

	// 	file.fileBase64 = null;

	// 	let saved_documents = this.state.core_docs;

	// 	await saved_documents.push(file);

	// 	this.setState({
	// 		core_docs: saved_documents,
	// 	});
	// 	// this.saveAppStates();
	// };

	// removeDocument = async (id) => {
	// 	let array = this.state.saved_documents;
	// 	const newarr = await array.filter((d) => d.id !== id);
	// 	this.setState({
	// 		saved_documents: newarr,
	// 	});
	// 	// this.saveAppStates();
	// };

	// removeCoreDocument = async (id) => {
	// 	await this.removeAsyncItem(id);
	// 	let array = this.state.core_docs;
	// 	const newarr = await array.filter((d) => d.id !== id);
	// 	this.setState({
	// 		core_docs: newarr,
	// 	});
	// 	// this.saveAppStates();
	// };

	// addAccountItem = async (data) => {
	// 	let array = await this.state.accounts;
	// 	await array.push(data);
	// 	console.log("accounts", data);
	// 	this.setState({
	// 		accounts: array,
	// 	});
	// 	// this.saveAppStates();
	// };

	// updateAccountItem = async (id, data) => {
	// 	// THIS function removed the item and addes it back rather then just filding and replacing
	// 	console.log(id, data);

	// 	let arry = [...this.state.accounts];
	// 	console.log(arry);
	// 	let newarr = await arry.filter((d) => d.id !== id);
	// 	console.log(newarr);
	// 	let added = [...newarr, data];
	// 	console.log(added);
	// 	await this.setState({
	// 		accounts: added,
	// 	});
	// 	// This os neccisary becauser the states of the component were remaining the same and therefor the state.title for example wasnt going back to blank
	// 	// await this.saveAppStates();
	// };

	// removeAccountItem = async (id) => {
	// 	let array = this.state.accounts;
	// 	const newarr = await array.filter((d) => d.id !== id);
	// 	this.setState({
	// 		accounts: newarr,
	// 	});
	// 	// this.saveAppStates();
	// };

	//
	//

	deleteClient = async (id) => {
		let array = this.state.clients;
		const newarr = await array.filter((d) => d.id !== id);
		this.setState({
			clients: newarr,
			selectedClientId: "",
			selectedClient: null,
		});
		this.saveAppStates();
	};

	render() {
		let accountingMenu = [
			{
				id: 1,
				title: "Dashboard",
			},
			{
				id: 2,
				title: "Invoices",
			},
			{
				id: 3,
				title: "Expenses",
			},
			{
				id: 4,
				title: "Products",
			},
			{
				id: 5,
				title: "Statements",
			},
			{
				id: 6,
				title: "Clients",
			},
		];
		return (
			<View
				style={{
					flex: 1,
					// flexDirection: "row",
					paddingTop: 5,
				}}>
				<View
					style={{
						width: "100%",
						padding: 4,
						height: 48,
					}}>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							// backgroundColor: "#FFF",
							backgroundColor: "#191D24",
							borderRadius: 25,
							overflow: "hidden",
							padding: 5,
							marginBottom: 5,
							paddingLeft: 20,
						}}>
						<TouchableOpacity onPress={() => this.props.showSidebar()}>
							<View>
								<Text
									style={{
										fontSize: 16,
										color: "#F6F8FE",
										marginRight: 20,
									}}>
									<Font style={{}} family='Poppins' weight={800}>
										{this.props.selectedEntity}
									</Font>
								</Text>
							</View>
						</TouchableOpacity>

						<View
							style={{
								marginRight: 10,
								paddingLeft: 5,
							}}>
							<ModalTabs
								tabs={accountingMenu}
								selectedTab={(d) =>
									this.setState({
										accountsPageSelected: d,
									})
								}
							/>
						</View>
					</View>
				</View>

				<View
					style={{
						flex: 1,
						paddingTop: 20,
						flexDirection: "column",
						height: this.props.screenHeight,
					}}>
					{/* {this.state.accountsPageSelected == 5 ? (
						<AccountsIncomeExpenses
							screenWidth={this.props.screenWidth}
							screenHeight={this.props.screenHeight - 50}
							data={this.state.selectedProject}
							{...this.state}
							{...this.props}
							addCoreDocument={this.addCoreDocument.bind(this)}
							removeCoreDocument={this.removeCoreDocument.bind(this)}
							addAccountItem={this.addAccountItem.bind(this)}
							removeAccountItem={this.removeAccountItem.bind(this)}
							updateAccountItem={this.updateAccountItem.bind(this)}
						/>
					) : null} */}
					{this.state.accountsPageSelected == 2 ? (
						<InvoiceQuoteTable
							screenWidth={this.state.screenWidth}
							screenHeight={this.state.screenHeight}
							data={this.state.selectedProject}
							{...this.state}
							{...this.props}
						/>
					) : null}

					{this.state.accountsPageSelected == 6 ? (
						<Clients
							screenWidth={this.state.screenWidth}
							screenHeight={this.state.screenHeight}
							{...this.props}
						/>
					) : null}

					{this.state.accountsPageSelected == 4 ? (
						<AccountProducts
							screenWidth={this.state.screenWidth}
							screenHeight={this.state.screenHeight}
							{...this.state}
						/>
					) : null}

					{/* 

					{this.state.accountsPageSelected == "Expenses" ? (
						<AccountsExpenses
							screenWidth={this.state.screenWidth}
							screenHeight={this.state.screenHeight}
							data={this.state.selectedProject}
							project_phases={project_phases}
							{...this.state}
							addCoreDocument={this.addCoreDocument.bind(this)}
							removeCoreDocument={this.removeCoreDocument.bind(
								this,
							)}
							addAccountItem={this.addAccountItem.bind(this)}
							removeAccountItem={this.removeAccountItem.bind(
								this,
							)}
							updateAccountItem={this.updateAccountItem.bind(
								this,
							)}
						/>
					) : null} */}
				</View>
			</View>
		);
	}
}

export default DebtorsAccount;
