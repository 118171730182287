import React, { Component } from "react";
import { Dimensions } from "react-native";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import Home from "./Authed/Home";
import MobileHome from "./Mobile/Home";
import Login from "./Unauthed/Login";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenWidth: screenWidth,
			screenHeight: screenHeight,
		};
	}

	componentDidMount() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			console.log("Polly fill for smooth scroll in safari");
		} else {
			// production code
		}
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	render() {
		// if (this.state.screenWidth > 700) {
		return (
			<Router>
				<Routes>
					<Route
						path='/'
						element={
							<Home
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
							/>
						}
					/>
					<Route
						path='/login'
						element={
							<Login
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
							/>
						}
					/>

					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Router>
		);
		// } else {
		// return (
		// 	<Router>
		// 		<Routes>
		// 			<Route
		// 				path='/'
		// 				element={
		// 					<MobileHome
		// 						screenHeight={this.state.screenHeight}
		// 						screenWidth={this.state.screenWidth}
		// 					/>
		// 				}
		// 			/>

		// 			<Route
		// 				path='/login'
		// 				element={
		// 					<Login
		// 						screenHeight={this.state.screenHeight}
		// 						screenWidth={this.state.screenWidth}
		// 					/>
		// 				}
		// 			/>

		// 			<Route path='*' element={<Navigate to='/' />} />
		// 		</Routes>
		// 	</Router>
		// );
		// }
	}
}

export default index;
