import React, { useState } from "react";
import Pdf from "@mikecousins/react-pdf";

import "./canvas.css";

const MyPdfViewer = (props) => {
	const [page, setPage] = useState(1);

	// let ratio = 3508 / 2480;

	// let style = {
	// 	width: props.width,
	// 	height: props.width * ratio,
	// };

	return (
		<div>
			<Pdf
				// style={style}
				file={props.PdfData}
				page={page}
				scale={props.scale}>
				{({ pdfDocument, pdfPage, canvas }) => (
					<>
						{!pdfDocument && <span>Loading...</span>}
						{canvas}
						{Boolean(pdfDocument && pdfDocument.numPages) && (
							<nav>
								{/* <ul className='pager'>
								<li className='previous'>
									<button
										disabled={page === 1}
										onClick={() => setPage(page - 1)}>
										Previous
									</button>
								</li>
								<li className='next'>
									<button
										disabled={page === pdfDocument.numPages}
										onClick={() => setPage(page + 1)}>
										Next
									</button>
								</li>
							</ul> */}
							</nav>
						)}
					</>
				)}
			</Pdf>
		</div>
	);
};

export default MyPdfViewer;
