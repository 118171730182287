import React, { Component } from "react";
import { View, Text } from "react-native";
import FileBase64 from "react-file-base64";
import "./input.css";

class ImageUploader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: [],
		};
	}

	getFiles(files) {
		console.log("files", files);
		this.setState({ file: files });
		// WE USING A FOR LOOP TO SAVE THE FILES INCASE THEY ARE MULTIPLE
		for (var i = 0; i < files.length; i++) {
			// let replace_ = 'data:image/png;base64,';

			// let myStr = files[i].base64;
			// let cleanString = myStr.replace(replace_, '');
			// console.log(cleanString);
			this.props.base64String(files[i].base64);
			// const objectTosend = {
			// 	name: files[i].name,
			// 	fileBase64: files[i].base64,
			// 	size: files[i].size,
			// 	selectedFolder: folderid,
			// };
		}
	}

	SaveFile = (objectToSend) => {
		this.setState({ loading: true });
		let obj = JSON.stringify(objectToSend);
		// console.log(obj);
	};

	render() {
		return (
			<View
				style={{
					opacity: 0,
					width: this.props.width,
					height: this.props.height,
					zIndex: 99,
					position: "absolute",
				}}>
				<FileBase64 multiple={true} onDone={this.getFiles.bind(this)} />
			</View>
		);
	}
}

export default ImageUploader;
