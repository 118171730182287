import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Alert,
	Modal,
} from "react-native";
import moment from "moment";
// import DatePicker from "react-native-date-picker";
import InvoiceDocA4 from "./InvoiceDocA4";
// import QuoteDocA4 from "./QuoteDocA4";
import SearchArray from "./SearchArray";

import {
	getJsonDataFromServer,
	saveJsonDataToServer,
} from "../ServerRequestHelper";

const BLUE = "#3F6EFF";
const WHITE = "#FFF";
const FONT_ALMOSTWHITE = "#D4D4D4";
const FONTGREY = "#6E7074";
const RED = "#FF5F5F";
const YELLOW = "#FFD76E";
const OPACITYGREY = "#2F2F39";

const BG = "#131119";
const BG2 = "#191D24";
const BG3 = "#252B36";
const BORDERBOTTOM = "#28282F";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function isOdd(num) {
	return num % 2;
}

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newRepName: "",
			newRepEmail: "",
			newRepCell: "",
			newNote: "",
			addNote: false,

			newProjectName: "",
			newProjectIdentifier: "",
			newTeamID: "",
			addProject: false,

			expandItem: "",
			expandItemData: "",
			addClient: false,

			assignedClient: "",

			products: [],
			createQuote: false,
			showOptions: false,
			quoteNumber: 1,
			addQuoteItem: false,
			addDate: false,
			quoteTotal: 0,
			quoteDate: new Date(),
			quoteProducts: [],
			viewQuote: false,
			viewQuoteData: "",

			uploadPO: false,
			poNumber: "",
			poDate: new Date(),
			poDoc: "",

			invoiceNumber: 1,
			createInvoice: false,
			viewInvoiceData: "",
			addInvDate: false,
			invoiceDate: new Date(),

			accounts: [],
			clients: [],
		};
	}

	addAccountItem = async () => {
		let uid = await uuid();
		let obj = {
			id: uid,
			quote: {
				number: "",
				date: "",
				document: "",
				sent: false,
			},
			purchaseOrder: {
				number: "",
				document: "",
				date: "",
			},
			invoice: {
				number: "",
				date: "",
				document: "",
				sent: false,
			},
			products: [],
			products_qty: 0,
			notes: "",
			total: 0,
			client_id: "",
			client_data: "",
			date_added: moment(),
		};
		let array = await this.state.accounts;
		const push_ = await array.push(obj);

		await this.setState({
			accounts: array,
			addItem: false,
			expandItem: uid,
			expandItemData: obj,
		});
	};

	updateAssignedClient = async (data) => {
		let client_data = await data;
		let expandItemData = await this.state.expandItemData;
		let id = await this.state.expandItem;

		expandItemData.client_data = await client_data;
		expandItemData.client_id = await client_data.id;

		await console.log("updateAccountItem", id, expandItemData);
		// await this.props.updateAccountItem(id, expandItemData);

		await this.setState({
			addClient: false,
			expandItemData: expandItemData,
		});
	};

	quoteNumberIncrement = async (val) => {
		let number = this.props.quoteNumber + val;
		this.setState({
			quoteNumber: number,
		});
		// await this.props.quoteNumberIncrement(number);
	};

	invoiceNumberIncrement = async (val) => {
		let number = this.props.invoiceNumber + val;
		this.setState({
			invoiceNumber: number,
		});
		await this.props.invoiceNumberIncrement(number);
	};

	addClientNote = async () => {
		let uid = uuid();
		let obj = {
			id: uid,
			client_id: this.props.selectedClientId,
			note: this.state.newNote,
		};
		await this.props.addClientNote(obj);
		await this.setState({
			newNote: "",
			addNote: false,
		});
	};

	addRepresentative = async () => {
		let uid = uuid();
		let obj = {
			id: uid,
			client_id: this.props.selectedClientId,
			name: this.state.newRepName,
			email: this.state.newRepEmail,
			cell: this.state.newRepCell,
		};
		await this.props.addRepresentative(obj);
		await this.setState({
			addRepresentative: false,
		});
	};

	// updateItem = async () => {
	// 	let expandItemData = await this.state.expandItemData;
	// 	let client_data = await this.state.assignedClient;
	// 	let id = await this.state.expandItem;

	// 	await console.log("client_data", client_data);
	// 	// await this.props.updateAccountItem(id, assignedClient);
	// };

	removeProduct = (d) => {
		let prod = this.state.quoteProducts;
		const newArray = prod.filter((d_) => d_.id !== d.id);
		let newquoteTotal = newArray.reduce(function (acc, obj) {
			return acc + obj.totalPrice;
		}, 0);
		this.setState({
			quoteProducts: newArray,
			quoteTotal: newquoteTotal,
		});
	};
	addProductToQuote = async (data, qty) => {
		let quoteTotal = this.state.quoteTotal;
		let newquoteTotal = parseFloat(data.price.amount * qty) + quoteTotal;

		let product = {
			...data,
			qty: qty,
			ind_amount: data.price.amount,
			totalPrice: data.price.amount * qty,
		};
		let array = await [...this.state.quoteProducts];
		await array.push(product);
		await this.setState({
			quoteProducts: array,
			addQuoteItem: false,
			quoteTotal: newquoteTotal,
		});

		// console.log(data, qty);

		// var arr1 = [1, 2, 3, 4];
		// var arr2 = [2, 4];
		// let res = arr1.filter(item => !arr2.includes(item));
		// console.log(res);
	};

	createQuote = async () => {
		let expandItemData = await this.state.expandItemData;
		let id = await this.state.expandItem;
		//
		//
		let date_ = await moment(this.state.quoteDate).toISOString();

		let amount = this.state.quoteTotal;

		let quoteNumber = `QUO${this.state.quoteNumber}`;
		let quoteDate = date_;
		let quoteProducts = this.state.quoteProducts;

		let products_qty = 0;

		quoteProducts.forEach((d) => {
			products_qty = d.qty + products_qty;
		});

		console.log("products_qty", products_qty);

		expandItemData.quote.number = await quoteNumber;
		expandItemData.quote.date = await quoteDate;
		expandItemData.products = await quoteProducts;
		expandItemData.products_qty = await products_qty;
		expandItemData.total = await amount;

		await console.log("updateQuoteItem", id, expandItemData);

		//
		//
		await this.quoteNumberIncrement(1);
		// await this.props.updateAccountItem(id, expandItemData);

		await this.setState({
			createQuote: false,
			addQuoteItem: false,
			addDate: false,
			quoteTotal: 0,
			quoteDate: new Date(),
			viewQuote: false,
			viewQuoteData: "",
			quoteProducts: [],
		});
	};

	updateItemPO = async () => {
		let expandItemData = await this.state.expandItemData;
		let id = await this.state.expandItem;
		//
		//
		let date_ = await moment(this.state.poDate).toISOString();

		let poNumber = this.state.poNumber;
		let poDate = date_;
		let poDoc = this.state.poDoc;

		console.log("poDate", poDate, poNumber, poDate);

		expandItemData.purchaseOrder.number = await poNumber;
		expandItemData.purchaseOrder.date = await poDate;
		expandItemData.purchaseOrder.document = await poDoc;

		await console.log(expandItemData);

		// //
		// //
		// await this.props.updateAccountItem(id, expandItemData);

		await this.setState({
			uploadPO: false,
			poNumber: "",
			poDate: new Date(),
			poDoc: "",
		});
	};

	updateItemInvoice = async () => {
		let expandItemData = await this.state.expandItemData;
		let id = await this.state.expandItem;
		//
		//
		let date_ = await moment(this.state.invoiceDate).toISOString();

		let invoiceNumber = `INV${this.state.invoiceNumber}`;
		let invoiceDate = date_;

		expandItemData.invoice.number = await invoiceNumber;
		expandItemData.invoice.date = await invoiceDate;

		await console.log("updateQuoteItem", id, expandItemData);

		//
		//
		await this.invoiceNumberIncrement(1);
		// await this.props.updateAccountItem(id, expandItemData);

		await this.setState({
			createInvoice: false,
			viewInvoiceData: "",
			addInvDate: false,
			invoiceDate: new Date(),
		});
	};
	//
	//
	render() {
		// var CustomLayoutSpring = {
		//   duration: 600,
		//   create: {
		//     type: LayoutAnimation.Types.spring,
		//     property: LayoutAnimation.Properties.opacity,
		//     springDamping: 0.9,
		//   },
		//   update: {
		//     type: LayoutAnimation.Types.spring,
		//     springDamping: 0.9,
		//   },
		//   delete: {
		//     type: LayoutAnimation.Types.spring,
		//     property: LayoutAnimation.Properties.opacity,
		//     springDamping: 0.9,
		//   },
		// };
		// LayoutAnimation.configureNext(CustomLayoutSpring);
		return (
			<View
				style={{
					flex: 1,
					paddingLeft: 15,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						marginBottom: 10,
						paddingRight: 15,
					}}>
					<View>
						<Text
							style={{
								color: "#F6F8FE", // '#5A7BBA'
								fontSize: 30,
								fontWeight: "900",
							}}>
							Invoices
						</Text>
					</View>
					<TouchableOpacity
						onPress={() =>
							this.setState({
								showOptions: !this.state.showOptions,
							})
						}>
						<Image
							style={{
								width: 35,
								height: 35,
								resizeMode: "contain",
							}}
							source={require("../../../assets/images/options.png")}
						/>
					</TouchableOpacity>
					<TouchableOpacity onPress={() => this.addAccountItem()}>
						<View
							style={{
								width: "auto",
								height: 35,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#FF5F5F",
								borderRadius: 12,
								padding: 5,
								paddingLeft: 10,
								paddingRight: 10,
							}}>
							<Text
								style={{
									color: "#FFF",
									fontWeight: "600",
									fontSize: 13,
								}}>
								Add Item
							</Text>
						</View>
					</TouchableOpacity>
				</View>

				<View
					style={{
						flexDirection: "row",
						// justifyContent: 'space-between',
						alignItems: "center",
						marginBottom: 10,
						padding: 5,
						display: this.state.showOptions ? "flex" : "none",
						backgroundColor: "rgba(52, 52, 52, 0.05)",
					}}>
					<View
						style={{
							width: 150,
						}}>
						<Text
							style={{
								color: "#F6F8FE", // '#5A7BBA'
								fontSize: 15,
								fontWeight: "500",
							}}>
							Quote number
						</Text>
					</View>
					<TouchableOpacity onPress={() => this.quoteNumberIncrement(-1)}>
						<Image
							style={{
								width: 30,
								height: 30,
								resizeMode: "contain",
							}}
							source={require("../../../assets/images/up.png")}
						/>
					</TouchableOpacity>
					<View
						style={{
							padding: 5,
						}}>
						<Text
							style={{
								color: "#F6F8FE", // '#5A7BBA'
								fontSize: 15,
								fontWeight: "500",
							}}>
							{this.state.quoteNumber}
						</Text>
					</View>
					<TouchableOpacity onPress={() => this.quoteNumberIncrement(1)}>
						<Image
							style={{
								width: 30,
								height: 30,
								resizeMode: "contain",
							}}
							source={require("../../../assets/images/add_btn.png")}
						/>
					</TouchableOpacity>
				</View>
				<View
					style={{
						flexDirection: "row",
						// justifyContent: 'space-between',
						alignItems: "center",
						padding: 5,
						display: this.state.showOptions ? "flex" : "none",
						backgroundColor: "rgba(52, 52, 52, 0.05)",
					}}>
					<View
						style={{
							width: 150,
						}}>
						<Text
							style={{
								color: "#F6F8FE", // '#5A7BBA'
								fontSize: 15,
								fontWeight: "500",
							}}>
							Invoice number
						</Text>
					</View>

					<TouchableOpacity
						onPress={() => this.invoiceNumberIncrement(-1)}>
						<Image
							style={{
								width: 30,
								height: 30,
								resizeMode: "contain",
							}}
							source={require("../../../assets/images/up.png")}
						/>
					</TouchableOpacity>
					<View
						style={{
							padding: 5,
						}}>
						<Text
							style={{
								color: "#F6F8FE", // '#5A7BBA'
								fontSize: 15,
								fontWeight: "500",
							}}>
							{this.state.invoiceNumber}
						</Text>
					</View>
					<TouchableOpacity onPress={() => this.invoiceNumberIncrement(1)}>
						<Image
							style={{
								width: 30,
								height: 30,
								resizeMode: "contain",
							}}
							source={require("../../../assets/images/add_btn.png")}
						/>
					</TouchableOpacity>
				</View>

				{/* <ScrollView> */}
				<View
					style={{
						flex: 1,
						flexDirection: "row",
					}}>
					<View
						style={{
							backgroundColor: "#191D24",
							borderRadius: 20,
							marginRight: 17,
							// marginBottom: 15,
							flex: 1,
							padding: 10,
						}}>
						<View
							style={{
								flexDirection: "row",
								// justifyContent: 'space-between',
								paddingBottom: 10,
								// marginBottom: 10,
								alignItems: "center",
								borderBottomColor: "#28282F",
								borderBottomWidth: 0.5,
							}}>
							<Text
								style={{
									fontSize: 10,
									color: "#D8D8FF",
									fontWeight: "700",
									fontFamily: "Avenir Next",
									width: 70,
								}}>
								Date
							</Text>

							<Text
								style={{
									fontSize: 10,
									color: "#D8D8FF",
									fontWeight: "700",
									fontFamily: "Avenir Next",
									width: 200,
								}}>
								Client
							</Text>
							<View
								style={{
									width: 75,
								}}>
								<Text
									style={{
										fontSize: 10,
										color: "#D8D8FF",
										fontWeight: "700",
										fontFamily: "Avenir Next",
									}}>
									Quote
								</Text>
							</View>

							<View
								style={{
									width: 75,
								}}>
								<Text
									style={{
										fontSize: 10,
										color: "#D8D8FF",
										fontWeight: "700",
										fontFamily: "Avenir Next",
									}}>
									P.O.
								</Text>
							</View>
							<View
								style={{
									width: 75,
								}}>
								<Text
									style={{
										fontSize: 10,
										color: "#D8D8FF",
										fontWeight: "700",
										fontFamily: "Avenir Next",
									}}>
									Invoice
								</Text>
							</View>
							<Text
								style={{
									fontSize: 10,
									color: "#D8D8FF",
									fontWeight: "700",
									fontFamily: "Avenir Next",
									width: 80,
								}}>
								Qty
							</Text>
							<Text
								style={{
									fontSize: 10,
									color: "#D8D8FF",
									fontWeight: "700",
									fontFamily: "Avenir Next",
									width: 80,
								}}>
								Amount
							</Text>
						</View>
						<View
							style={{
								flex: 1,
							}}>
							<ScrollView>
								{this.state.accounts
									.sort(function (a, b) {
										let first = new Date(a.date_added);
										let last = new Date(b.date_added);
										return last - first;
									})
									.map((acc, i) => (
										<View
											key={i}
											style={{
												borderBottomWidth:
													this.state.expandItem !== acc.id ? 0 : 1,
												borderColor: FONT_ALMOSTWHITE,
											}}>
											{/* ROW ITEM  */}
											<View
												style={{
													flexDirection: "row",
													backgroundColor:
														this.state.expandItem == acc.id
															? BG3
															: "transparent",
													borderBottomColor: "#28282F",
													borderBottomWidth: 0.5,
													opacity:
														this.state.expandItem == acc.id
															? 1
															: this.state.expandItem == ""
															? 1
															: 0.3,
												}}>
												<View
													style={{
														flex: 1,
														flexDirection: "row",
														alignItems: "center",
														marginBottom: 2,
													}}>
													<Text
														style={{
															fontSize: 10,
															color: FONT_ALMOSTWHITE,
															fontWeight: "400",
															fontFamily: "Avenir Next",
															width: 70,
														}}>
														{moment(acc.date_added).format(
															"MMM, DD YYYY",
														)}
													</Text>

													<View
														style={{
															width: 200,
															paddingRight: 5,
														}}>
														<Text
															adjustsFontSizeToFit
															numberOfLines={1}
															style={{
																fontSize: 10,
																color: FONT_ALMOSTWHITE,
																fontWeight: "700",
																fontFamily: "Avenir Next",
																marginRight: 5,
															}}>
															{acc.client_id !== ""
																? acc.client_data.title
																: null}
														</Text>
													</View>

													{/* COLOR BARS   */}
													<View
														style={{
															width: 75,
															height: 24,
															justifyContent: "center",
															paddingLeft: 8,
															paddingRight: 8,
															backgroundColor: YELLOW,
															borderRadius: 3,
															opacity:
																acc.quote.number !== ""
																	? 1
																	: 0.1,
															marginRight: 3,
														}}>
														<Text
															adjustsFontSizeToFit
															numberOfLines={1}
															style={{
																fontSize: 10,
																color: BG2,
																fontWeight: "700",
																fontFamily: "Avenir Next",
															}}>
															{acc.quote.number !== ""
																? acc.quote.number
																: null}
														</Text>
													</View>

													<View
														style={{
															width: 75,
															height: 24,
															justifyContent: "center",
															paddingLeft: 8,
															paddingRight: 8,
															backgroundColor: RED,
															borderRadius: 3,
															opacity:
																acc.purchaseOrder.number !== ""
																	? 1
																	: 0.1,
															marginRight: 3,
														}}>
														<Text
															adjustsFontSizeToFit
															numberOfLines={1}
															style={{
																fontSize: 10,
																color: BG2,
																fontWeight: "700",
																fontFamily: "Avenir Next",
															}}>
															{acc.purchaseOrder.number !== ""
																? acc.purchaseOrder.number
																: null}
														</Text>
													</View>

													<View
														style={{
															width: 75,
															height: 24,
															justifyContent: "center",
															paddingLeft: 8,
															paddingRight: 8,
															backgroundColor: BLUE,
															borderRadius: 3,
															opacity:
																acc.invoice.number !== ""
																	? 1
																	: 0.1,
															marginRight: 3,
														}}>
														<Text
															adjustsFontSizeToFit
															numberOfLines={1}
															style={{
																fontSize: 10,
																color: BG2,
																fontWeight: "700",
																fontFamily: "Avenir Next",
															}}>
															{acc.invoice.number}
														</Text>
													</View>

													{/* COLOR BARS END  */}

													<View
														style={{
															width: 80,
														}}>
														<Text
															style={{
																fontSize: 10,
																color: FONT_ALMOSTWHITE,
																fontWeight: "700",
																fontFamily: "Avenir Next",
																marginRight: 5,
																opacity:
																	acc.products_qty > 0 ? 1 : 0,
															}}>
															{acc.products_qty !== ""
																? acc.products_qty
																: null}{" "}
															<Text
																style={{
																	fontWeight: "300",
																	fontSize: 10,
																}}>
																items
															</Text>
														</Text>
													</View>

													<View
														style={{
															width: 120,
															opacity: acc.total > 0 ? 1 : 0.1,
														}}>
														<Text
															style={{
																fontSize: 10,
																color: FONT_ALMOSTWHITE,
																fontWeight: "500",
																fontFamily: "Avenir Next",
																marginRight: 5,
															}}>
															R{" "}
															{acc.total > 0
																? currencyFormat(acc.total)
																: null}
														</Text>
													</View>
												</View>
												<TouchableOpacity
													onPress={() =>
														this.setState({
															expandItem:
																this.state.expandItem == acc.id
																	? ""
																	: acc.id,
															expandItemData: acc,
														})
													}>
													<Image
														style={{
															width: 30,
															height: 30,
															resizeMode: "contain",
														}}
														source={require("../../../assets/images/expand.png")}
													/>
												</TouchableOpacity>
											</View>
											{/* EXPENDED ROW  */}
											<View
												style={{
													display:
														this.state.expandItem == acc.id
															? "flex"
															: "none",
													flex: 1,
													minHeight: 300,
													// paddingTop: 20,s
													// backgroundColor: BG3,
													borderRadius: 5,
													padding: 0,
													marginTop: 1,
												}}>
												{/* BIN  */}
												<View
													style={{
														// flex: 1,
														flexDirection: "row",
														justifyContent: "space-between",
													}}>
													<TouchableOpacity
														onPress={async () => {
															await this.props.removeAccountItem(
																acc.id,
															);
															await this.setState({
																expandItem: "",
															});
														}}>
														<Image
															style={{
																width: 30,
																height: 30,
																resizeMode: "contain",
															}}
															source={require("../../../assets/images/bin.png")}
														/>
													</TouchableOpacity>
												</View>
												{/* BIN  END */}

												{/* ACTIONS  */}
												<View
													style={{
														flex: 1,
														flexDirection: "row",
														justifyContent: "space-between",
													}}>
													{/* CLIENT  */}
													<View
														style={{
															flex: 1,
															marginRight: 2,
														}}>
														<Text
															style={{
																fontSize: 12,
																color: FONTGREY,
																fontWeight: "700",
																fontFamily: "Avenir Next",
																marginBottom: 10,
																marginTop: 10,
															}}>
															Client
														</Text>

														{acc.client_id !== "" ? (
															<View
																style={{
																	flexDirection: "row",
																	alignItems: "center",
																	borderRadius: 10,
																	marginBottom: 5,
																	padding: 5,
																	backgroundColor: OPACITYGREY,
																	shadowColor: "#000",
																	shadowOffset: {
																		width: 0,
																		height: 2,
																	},
																	shadowOpacity: 0.25,
																	shadowRadius: 3.84,

																	elevation: 2,
																}}>
																{/* <Image
                                  style={{
                                    width: 45,
                                    height: 45,
                                    resizeMode: 'contain',
                                  }}
                                  source={require('../../../../assets/images/app_icon_frame.png')}
                                /> */}
																<View
																	style={{
																		padding: 5,
																	}}>
																	<Text
																		adjustsFontSizeToFit
																		numberOfLines={1}
																		style={{
																			fontWeight: "600",
																			fontSize: 13,
																			color: FONT_ALMOSTWHITE,
																		}}>
																		{acc.client_data.title}
																	</Text>
																	<Text
																		adjustsFontSizeToFit
																		numberOfLines={1}
																		style={{
																			fontWeight: "300",
																			fontSize: 10,
																			color: FONT_ALMOSTWHITE,
																			opacity: 0.6,
																		}}>
																		{
																			acc.client_data
																				.tradingName
																		}
																	</Text>
																</View>
															</View>
														) : (
															<TouchableOpacity
																onPress={() =>
																	this.setState({
																		addClient: true,
																	})
																}>
																<View
																	style={{
																		width: "auto",
																		height: 35,
																		justifyContent: "center",
																		alignItems: "center",
																		backgroundColor:
																			"#FF5F5F",
																		borderRadius: 12,
																		padding: 5,
																		shadowColor: "#000",
																		shadowOffset: {
																			width: 0,
																			height: 2,
																		},
																		shadowOpacity: 0.25,
																		shadowRadius: 3.84,

																		elevation: 2,
																	}}>
																	<Text
																		style={{
																			color: "#FFF",
																			fontWeight: "600",
																			fontSize: 13,
																		}}>
																		Assign Client
																	</Text>
																</View>
															</TouchableOpacity>
														)}
													</View>

													{/* QUOTE  */}

													<View
														style={{
															flex: 1,
															marginRight: 2,
														}}>
														<Text
															style={{
																fontSize: 12,
																color: FONTGREY,
																fontWeight: "700",
																fontFamily: "Avenir Next",
																marginBottom: 10,
																marginTop: 10,
															}}>
															Quote
														</Text>

														{acc.quote.number !== "" ? (
															<TouchableOpacity
																style={{
																	display:
																		acc.client_id !== ""
																			? "flex"
																			: "none",
																}}
																onPress={() =>
																	this.setState({
																		viewQuote: true,
																		viewQuoteData: acc,
																	})
																}>
																<View
																	style={{
																		flexDirection: "row",
																		alignItems: "center",

																		borderRadius: 10,
																		marginBottom: 5,
																		padding: 5,
																		backgroundColor:
																			OPACITYGREY,
																		shadowColor: "#000",
																		shadowOffset: {
																			width: 0,
																			height: 2,
																		},
																		shadowOpacity: 0.25,
																		shadowRadius: 3.84,

																		elevation: 2,
																	}}>
																	{/* <View
                                    style={{
                                      width: 45,
                                      height: 45,
                                      justifyContent: 'center',
                                      paddingLeft: 10,
                                      backgroundColor: YELLOW,
                                      borderRadius: 8,
                                    }}></View> */}
																	<View
																		style={{
																			padding: 5,
																		}}>
																		<Text
																			style={{
																				fontWeight: "600",
																				fontSize: 13,
																				color: FONT_ALMOSTWHITE,
																			}}>
																			{acc.quote.number}
																		</Text>
																		<Text
																			style={{
																				fontWeight: "300",
																				fontSize: 10,
																				color: FONT_ALMOSTWHITE,
																				opacity: 0.6,
																			}}>
																			{moment(
																				acc.quote.date,
																			).format("DD/MM/YYYY")}
																		</Text>
																	</View>
																</View>
															</TouchableOpacity>
														) : (
															<TouchableOpacity
																style={{
																	display:
																		acc.client_id !== ""
																			? "flex"
																			: "none",
																}}
																onPress={() => {
																	this.setState({
																		createQuote: true,
																	});

																	// console.log(this.state.expandItemData);
																}}>
																<View
																	style={{
																		width: "auto",
																		height: 35,
																		justifyContent: "center",
																		alignItems: "center",
																		backgroundColor:
																			"#FF5F5F",
																		borderRadius: 12,
																		padding: 5,
																		shadowColor: "#000",
																		shadowOffset: {
																			width: 0,
																			height: 2,
																		},
																		shadowOpacity: 0.25,
																		shadowRadius: 3.84,

																		elevation: 2,
																	}}>
																	<Text
																		style={{
																			color: "#FFF",
																			fontWeight: "600",
																			fontSize: 13,
																		}}>
																		Create Quote
																	</Text>
																</View>
															</TouchableOpacity>
														)}
													</View>

													{/* PURCHASE ORDER  */}
													<View
														style={{
															flex: 1,
															marginRight: 2,
														}}>
														<Text
															style={{
																fontSize: 12,
																color: FONTGREY,
																fontWeight: "700",
																fontFamily: "Avenir Next",
																marginBottom: 10,
																marginTop: 10,
															}}>
															Purchase Order
														</Text>

														<TouchableOpacity
															style={{
																display:
																	acc.client_id !== ""
																		? "flex"
																		: "none",
															}}
															onPress={() =>
																this.setState({
																	uploadPO: true,
																})
															}>
															{acc.purchaseOrder.number !==
															"" ? (
																<View
																	style={{
																		flexDirection: "row",
																		alignItems: "center",

																		borderRadius: 10,
																		marginBottom: 5,
																		padding: 5,
																		backgroundColor:
																			OPACITYGREY,
																		shadowColor: "#000",
																		shadowOffset: {
																			width: 0,
																			height: 2,
																		},
																		shadowOpacity: 0.25,
																		shadowRadius: 3.84,

																		elevation: 2,
																	}}>
																	{/* <View
                                    style={{
                                      width: 45,
                                      height: 45,
                                      justifyContent: 'center',
                                      paddingLeft: 10,
                                      backgroundColor: RED,
                                      borderRadius: 8,
                                    }}></View> */}
																	<View
																		style={{
																			padding: 5,
																		}}>
																		<Text
																			style={{
																				fontWeight: "600",
																				fontSize: 13,
																				color: FONT_ALMOSTWHITE,
																			}}>
																			{
																				acc.purchaseOrder
																					.number
																			}
																		</Text>
																		<Text
																			style={{
																				fontWeight: "300",
																				fontSize: 10,
																				color: FONT_ALMOSTWHITE,
																				opacity: 0.6,
																			}}>
																			{moment(
																				acc.purchaseOrder
																					.date,
																			).format("DD/MM/YYYY")}
																		</Text>
																	</View>
																</View>
															) : (
																<View
																	style={{
																		width: "auto",
																		height: 35,
																		justifyContent: "center",
																		alignItems: "center",
																		backgroundColor:
																			"#FF5F5F",
																		borderRadius: 12,
																		padding: 5,
																		shadowColor: "#000",
																		shadowOffset: {
																			width: 0,
																			height: 2,
																		},
																		shadowOpacity: 0.25,
																		shadowRadius: 3.84,

																		elevation: 2,
																	}}>
																	<Text
																		style={{
																			color: "#FFF",
																			fontWeight: "600",
																			fontSize: 13,
																		}}>
																		Upload P.O.
																	</Text>
																</View>
															)}
														</TouchableOpacity>
													</View>

													{/* INVOICE  */}
													<View
														style={{
															flex: 1,
															marginRight: 2,
														}}>
														<Text
															style={{
																fontSize: 12,
																color: FONTGREY,
																fontWeight: "700",
																fontFamily: "Avenir Next",
																marginBottom: 10,
																marginTop: 10,
															}}>
															Invoice
														</Text>

														{acc.invoice.number !== "" ? (
															<TouchableOpacity
																style={{
																	display:
																		acc.client_id !== ""
																			? "flex"
																			: "none",
																}}
																onPress={() =>
																	this.setState({
																		previewInvoice: true,
																		viewInvoiceData: acc,
																	})
																}>
																<View
																	style={{
																		flexDirection: "row",
																		alignItems: "center",

																		borderRadius: 10,
																		marginBottom: 5,
																		padding: 5,
																		backgroundColor:
																			OPACITYGREY,
																	}}>
																	{/* <View
                                    style={{
                                      width: 45,
                                      height: 45,
                                      justifyContent: 'center',
                                      paddingLeft: 10,
                                      backgroundColor: BLUE,
                                      borderRadius: 8,
                                    }}></View> */}
																	<View
																		style={{
																			padding: 5,
																		}}>
																		<Text
																			style={{
																				fontWeight: "600",
																				fontSize: 13,
																				color: FONT_ALMOSTWHITE,
																			}}>
																			{acc.invoice.number}
																		</Text>
																		<Text
																			style={{
																				fontWeight: "300",
																				fontSize: 10,
																				color: FONT_ALMOSTWHITE,
																				opacity: 0.6,
																			}}>
																			{moment(
																				acc.invoice.date,
																			).format("DD/MM/YYYY")}
																		</Text>
																	</View>
																</View>
															</TouchableOpacity>
														) : (
															<TouchableOpacity
																style={{
																	display:
																		acc.client_id !== ""
																			? "flex"
																			: "none",
																}}
																onPress={() =>
																	this.setState({
																		createInvoice: true,
																		viewInvoiceData: acc,
																	})
																}>
																<View
																	style={{
																		width: "auto",
																		height: 35,
																		justifyContent: "center",
																		alignItems: "center",
																		backgroundColor:
																			"#FF5F5F",
																		borderRadius: 12,
																		padding: 5,
																	}}>
																	<Text
																		style={{
																			color: "#FFF",
																			fontWeight: "600",
																			fontSize: 13,
																		}}>
																		Create invoice
																	</Text>
																</View>
															</TouchableOpacity>
														)}
													</View>
												</View>
												{/* QUOTE PRODUCTS */}
												<View>
													<Text
														style={{
															fontSize: 14,
															color: FONTGREY,
															fontWeight: "700",
															fontFamily: "Avenir Next",
															marginBottom: 10,
															marginTop: 10,
														}}>
														Items
													</Text>
													<View
														style={{
															width: "100%",
															borderBottomWidth: 1,
															borderBottomColor: BORDERBOTTOM,
														}}></View>
													{acc.products.map((d, i) => {
														return (
															<View
																key={i}
																style={{
																	width: "100%",
																	height: "auto",
																	borderBottomWidth: 0.5,
																	borderBottomColor:
																		BORDERBOTTOM,
																	flexDirection: "row",
																	paddingTop: 10,
																	paddingBottom: 10,
																	// backgroundColor: isOdd(i)
																	//   ? '#E8E8E8'
																	//   : '#FFF',
																}}>
																<View
																	style={{
																		flex: 1,
																		paddingLeft: 5,
																	}}>
																	<Text
																		style={{
																			flex: 1,
																			fontWeight: "500",
																			fontSize: 14,
																			color: FONT_ALMOSTWHITE,
																		}}>
																		{d.title}
																	</Text>
																	<Text
																		style={{
																			flex: 1,
																			fontWeight: "300",
																			fontSize: 12,
																			paddingLeft: 5,
																			color: FONT_ALMOSTWHITE,
																		}}>
																		{d.description}
																	</Text>
																</View>

																<Text
																	style={{
																		width: 100,
																		color: FONT_ALMOSTWHITE,
																	}}>
																	R{" "}
																	{currencyFormat(
																		parseFloat(d.ind_amount),
																	)}
																</Text>

																<Text
																	style={{
																		width: 50,
																		color: FONT_ALMOSTWHITE,
																	}}>
																	{d.qty}
																</Text>
																<Text
																	style={{
																		width: 120,
																		textAlign: "right",
																		color: FONT_ALMOSTWHITE,
																	}}>
																	R{" "}
																	{currencyFormat(
																		parseFloat(d.totalPrice),
																	)}
																</Text>
															</View>
														);
													})}
													<View
														style={{
															height: 10,
															width: "100%",
															borderBottomWidth: 1,
															borderBottomColor: BORDERBOTTOM,
														}}></View>
													<View
														style={{
															marginTop: 10,
															flexDirection: "row",
															justifyContent: "space-between",
															marginBottom: 5,
															paddingLeft: 7,
														}}>
														<Text
															style={{
																fontSize: 14,
																color: FONT_ALMOSTWHITE,
																fontWeight: "500",
																fontFamily: "Avenir Next",
															}}>
															Subtotal
														</Text>

														<Text
															style={{
																fontSize: 14,
																color: WHITE,
																fontWeight: "500",
																fontFamily: "Avenir Next",
															}}>
															R{" "}
															{acc.total > 0
																? currencyFormat(acc.total)
																: `0.00`}
														</Text>
													</View>
													<View
														style={{
															marginTop: 10,
															flexDirection: "row",
															justifyContent: "space-between",
															marginBottom: 5,
															paddingLeft: 7,
														}}>
														<Text
															style={{
																fontSize: 14,
																color: FONT_ALMOSTWHITE,
																fontWeight: "500",
																fontFamily: "Avenir Next",
															}}>
															VAT (15.00%)
														</Text>

														<Text
															style={{
																fontSize: 14,
																color: WHITE,
																fontWeight: "500",
																fontFamily: "Avenir Next",
															}}>
															R{" "}
															{acc.total > 0
																? currencyFormat(
																		(acc.total * 15) / 100,
																  )
																: `0.00`}
														</Text>
													</View>
													<View
														style={{
															marginTop: 10,
															flexDirection: "row",
															justifyContent: "space-between",
															marginBottom: 10,
															paddingLeft: 7,
														}}>
														<Text
															style={{
																fontSize: 14,
																color: FONT_ALMOSTWHITE,
																fontWeight: "700",
																fontFamily: "Avenir Next",
															}}>
															Total
														</Text>

														<Text
															style={{
																fontSize: 14,
																color: WHITE,
																fontWeight: "700",
																fontFamily: "Avenir Next",
															}}>
															R{" "}
															{acc.total > 0
																? currencyFormat(
																		(acc.total * 115) / 100,
																  )
																: `0.00`}
														</Text>
													</View>
												</View>
											</View>
										</View>
									))}
							</ScrollView>
						</View>
					</View>
				</View>

				<View
					style={{
						width: "100%",
						height: 50,
					}}
				/>
				{/* </ScrollView> */}

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.addClient}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<View
							style={{
								height: 80,
							}}
						/>
						<View
							style={{
								width: 400,
								height: this.props.screenHeight / 2,
								backgroundColor: "white",
								// alignItems: 'center',s
								borderRadius: 12,
								padding: 10,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									borderBottomWidth: 0.5,
									paddingBottom: 10,
									borderBottomColor: "#E8E8E8",
								}}>
								<TouchableOpacity
									onPress={async () => {
										await this.setState({
											addClient: false,
										});
										// await this.saveAppStates();
									}}>
									<Image
										style={{
											width: 30,
											height: 30,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View>
									<Text>Add Client</Text>
								</View>
							</View>
							<View
								style={{
									flex: 1,
									width: "100%",
									maxHeight: 500,
								}}>
								<ScrollView>
									<View
										style={{
											flex: 1,
											width: "100%",
											height: 500,
										}}>
										{/* .filter(
												(d) =>
													d.client_id ==
													this.props.selectedEntityID,
											) */}
										{this.state.clients

											.sort((a, b) => b.title < a.title)
											.map((d, i) => (
												<TouchableOpacity
													key={i}
													onPress={() =>
														this.updateAssignedClient(d)
													}>
													<View
														style={{
															flexDirection: "row",
															alignItems: "center",
															borderWidth: 0.5,
															borderRadius: 10,
															marginBottom: 5,
															padding: 5,
														}}>
														{/* <Image
															style={{
																width: 45,
																height: 45,
																resizeMode:
																	"contain",
															}}
															source={require("../../../assets/images/app_icon_frame.png")}
														/> */}
														<View
															style={{
																padding: 5,
															}}>
															<Text
																style={{
																	fontWeight: "600",
																	fontSize: 17,
																}}>
																{d.title}
															</Text>
															<Text
																style={{
																	fontWeight: "300",
																	fontSize: 15,
																	opacity: 0.6,
																}}>
																{d.tradingName}
															</Text>
														</View>
													</View>
												</TouchableOpacity>
											))}
									</View>
								</ScrollView>
							</View>
						</View>
					</View>
				</Modal>

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.createQuote}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<View
							style={{
								height: 80,
							}}
						/>
						<View
							style={{
								width: 600,
								height: this.props.screenHeight - 100,
								backgroundColor: "white",
								borderRadius: 12,
								padding: 10,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									borderBottomWidth: 0.5,
									paddingBottom: 10,
									borderBottomColor: "#E8E8E8",
								}}>
								<TouchableOpacity
									onPress={async () => {
										await this.setState({
											createQuote: false,
										});
										// await this.saveAppStates();
									}}>
									<Image
										style={{
											width: 30,
											height: 30,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View>
									<Text>
										Create Quote:{" "}
										<Text
											style={{
												fontWeight: "500",
											}}>
											QU {this.props.quoteNumber}
										</Text>
									</Text>
								</View>
							</View>
							<View
								style={{
									flex: 1,
									width: "100%",
									// maxHeight: 500,
								}}>
								<View style={{ padding: 5 }}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
											marginBottom: 10,
										}}>
										Quote Number {this.props.quoteNumber}
									</Text>
								</View>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										backgroundColor: this.state.addDate
											? "#E8E8E8"
											: "transparent",
										borderRadius: 5,
										padding: 5,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												color: "#292A4A",
												fontSize: 19,
												fontWeight: "800",
												marginBottom: 10,
											}}>
											Date
										</Text>
									</View>

									<View
										style={{
											flex: 1,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "flex-end",
										}}>
										{this.state.addDate ? (
											<View
												style={{
													flex: 1,
												}}>
												{/* <DatePicker
													date={this.state.quoteDate}
													onDateChange={(date) =>
														this.setState({
															quoteDate: date,
														})
													}
												/> */}
												<TouchableOpacity
													onPress={() =>
														this.setState({
															addDate: false,
														})
													}>
													<View
														style={{
															width: "auto",
															height: 35,
															justifyContent: "center",
															alignItems: "center",
															backgroundColor: "#FF5F5F",
															borderRadius: 12,
															padding: 5,
															marginTop: 10,
														}}>
														<Text
															style={{
																color: "#FFF",
																fontWeight: "600",
																fontSize: 13,
															}}>
															Done
														</Text>
													</View>
												</TouchableOpacity>
											</View>
										) : (
											<TouchableOpacity
												onPress={() =>
													this.setState({
														addDate: true,
													})
												}>
												<View
													style={{
														width: "auto",
														height: 35,
														justifyContent: "center",
														alignItems: "center",
														backgroundColor: "#FF5F5F",
														borderRadius: 12,
														padding: 5,
														paddingLeft: 30,
														paddingRight: 30,
													}}>
													<Text
														style={{
															color: "#FFF",
															fontWeight: "600",
															fontSize: 13,
														}}>
														{moment(this.state.quoteDate).format(
															"DD/MM/YYYY",
														)}
													</Text>
												</View>
											</TouchableOpacity>
										)}
									</View>
								</View>

								<View style={{ padding: 5 }}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
											// marginBottom: 10,
										}}>
										Item
									</Text>
								</View>
								<View
									style={{
										width: "100%",
										borderWidth: 0.5,
										borderColor: BORDERBOTTOM,
										borderRadius: 10,
										flexDirection: "row",
										marginBottom: 5,
									}}
								/>
								<ScrollView
									ref={(ref) => {
										this.scrollView = ref;
									}}
									onContentSizeChange={() =>
										this.scrollView.scrollToEnd({
											animated: true,
										})
									}>
									{this.state.quoteProducts.map((d, i) => {
										return (
											<TouchableOpacity
												onPress={() => this.removeProduct(d)}>
												<View
													key={i}
													style={{
														width: "100%",
														height: "auto",
														borderBottomWidth: 0.5,
														borderBottomColor: BORDERBOTTOM,
														flexDirection: "row",
														paddingTop: 10,
														paddingBottom: 10,
														backgroundColor: isOdd(i)
															? "#E8E8E8"
															: "#FFF",
														alignItems: "center",
													}}>
													<View
														style={{
															flex: 1,
															paddingLeft: 5,
														}}>
														<Text
															style={{
																flex: 1,
																fontWeight: "500",
																fontSize: 14,
															}}>
															{d.title}
														</Text>
														<Text
															style={{
																flex: 1,
																fontWeight: "300",
																fontSize: 12,
																paddingLeft: 5,
															}}>
															{d.description}
														</Text>
													</View>

													<Text
														style={{
															width: 100,
														}}>
														R{" "}
														{currencyFormat(
															parseFloat(d.ind_amount),
														)}
													</Text>

													<Text
														style={{
															width: 50,
														}}>
														{d.qty}
													</Text>
													<Text
														style={{
															width: 110,
															textAlign: "right",
														}}>
														R{" "}
														{currencyFormat(
															parseFloat(d.totalPrice),
														)}
													</Text>
												</View>
											</TouchableOpacity>
										);
									})}

									<View
										style={{
											width: "100%",
											height: 10,
										}}
									/>

									{this.state.addQuoteItem ? (
										<SearchArray
											array={this.props.products}
											selectedItem={(d, qty) =>
												this.addProductToQuote(d, qty)
											}
										/>
									) : (
										<TouchableOpacity
											onPress={() =>
												this.setState({
													addQuoteItem: true,
												})
											}>
											<View
												style={{
													width: "auto",
													height: 35,
													justifyContent: "center",
													alignItems: "center",
													backgroundColor: "#FF5F5F",
													borderRadius: 12,
													padding: 5,
													marginTop: 10,
													marginBottom: 50,
												}}>
												<Text
													style={{
														color: "#FFF",
														fontWeight: "600",
														fontSize: 13,
													}}>
													Add item
												</Text>
											</View>
										</TouchableOpacity>
									)}
								</ScrollView>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										borderTopWidth: 0.5,
										borderTopColor: BORDERBOTTOM,
										marginBottom: 5,
									}}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 17,
											fontWeight: "800",
										}}>
										Subtotal
									</Text>

									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										R {currencyFormat(this.state.quoteTotal)}
									</Text>
								</View>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										marginBottom: 5,
									}}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 17,
											fontWeight: "500",
										}}>
										VAT (15.00%)
									</Text>

									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										R{" "}
										{currencyFormat(
											(this.state.quoteTotal * 15) / 100,
										)}
									</Text>
								</View>
								<View
									style={{
										flexDirection: "row",
										height: 90,
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										Total
									</Text>

									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										R{" "}
										{currencyFormat(
											(this.state.quoteTotal * 115) / 100,
										)}
									</Text>
								</View>
								<TouchableOpacity onPress={() => this.createQuote()}>
									<View
										style={{
											width: "auto",
											height: 80,
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: BLUE,
											borderRadius: 12,
											padding: 5,
											marginTop: 10,
										}}>
										<Text
											style={{
												color: "#FFF",
												fontWeight: "600",
												fontSize: 13,
											}}>
											Done
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</Modal>

				{/* {this.state.viewQuoteData ? (
					<QuoteDocA4
						{...this.props}
						selectedClient={[this.state.viewQuoteData.client_data]}
						pdfName={this.state.viewQuoteData.quote.number}
						previewPDF={true}
						closePreview={() =>
							this.setState({ viewQuoteData: "" })
						}
						screenWidth={this.props.screenWidth}
						screenHeight={this.props.screenHeight - 170}
						documentTitle={`Quotation ${this.state.viewQuoteData.quote.number}`}
						documentDescription={
							"This quotation is valid for 14 days"
						}
						docDate={this.state.viewQuoteData.quote.date}
						totalPrice={`R ${currencyFormat(
							(this.state.viewQuoteData.total * 115) / 100,
						)}`}
						viewContent={
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										width: "100%",
										borderWidth: 0.5,
										borderColor: BORDERBOTTOM,
										borderRadius: 10,
										flexDirection: "row",
										marginBottom: 5,
									}}
								/>
								<ScrollView>
									{this.state.viewQuoteData.products.map(
										(d, i) => {
											return (
												<View
													key={i}
													style={{
														width: "100%",
														height: "auto",
														borderBottomWidth: 0.5,
														borderBottomColor:
															BORDERBOTTOM,
														flexDirection: "row",
														paddingTop: 10,
														paddingBottom: 10,
														backgroundColor: isOdd(
															i,
														)
															? "#E8E8E8"
															: "#FFF",
														alignItems: "center",
													}}>
													<Text
														style={{
															flex: 1,
															fontWeight: "500",
															fontSize: 17,
															paddingLeft: 5,
														}}>
														{d.title}
													</Text>
													<Text
														style={{
															flex: 1,
															fontWeight: "300",
															fontSize: 17,
															paddingLeft: 5,
														}}>
														{d.description}
													</Text>

													<Text
														style={{
															width: 100,
															fontSize: 17,
														}}>
														R{" "}
														{currencyFormat(
															parseFloat(
																d.ind_amount,
															),
														)}
													</Text>

													<Text
														style={{
															width: 50,
															fontSize: 17,
														}}>
														{d.qty}
													</Text>
													<Text
														style={{
															width: 110,
															textAlign: "right",
															fontSize: 17,
														}}>
														R{" "}
														{currencyFormat(
															parseFloat(
																d.totalPrice,
															),
														)}
													</Text>
												</View>
											);
										},
									)}

									<View
										style={{
											width: "100%",
											height: 10,
										}}
									/>
								</ScrollView>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										borderTopWidth: 0.5,
										borderTopColor: BORDERBOTTOM,
										marginBottom: 5,
									}}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 17,
											fontWeight: "800",
										}}>
										Subtotal
									</Text>

									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										R{" "}
										{currencyFormat(
											this.state.viewQuoteData.total,
										)}
									</Text>
								</View>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										marginBottom: 5,
									}}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 17,
											fontWeight: "500",
										}}>
										VAT (15.00%)
									</Text>

									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										R{" "}
										{currencyFormat(
											(this.state.viewQuoteData.total *
												15) /
												100,
										)}
									</Text>
								</View>
								<View
									style={{
										flexDirection: "row",
										height: 90,
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										Total
									</Text>

									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										R{" "}
										{currencyFormat(
											(this.state.viewQuoteData.total *
												115) /
												100,
										)}
									</Text>
								</View>
							</View>
						}
					/>
				) : null} */}

				{this.state.previewInvoice ? (
					<InvoiceDocA4
						{...this.props}
						selectedClient={[this.state.viewInvoiceData.client_data]}
						pdfName={this.state.viewInvoiceData.invoice.number}
						previewPDF={true}
						closePreview={() => this.setState({ previewInvoice: false })}
						screenWidth={this.props.screenWidth}
						screenHeight={this.props.screenHeight - 170}
						documentTitle={`Tax Invoice ${this.state.viewInvoiceData.invoice.number}`}
						docinvoiceNumber={this.state.viewInvoiceData.invoice.number}
						documentDescription={""}
						docDate={this.state.viewInvoiceData.invoice.date}
						totalPrice={`R ${currencyFormat(
							(this.state.viewInvoiceData.total * 115) / 100,
						)}`}
						viewContent={
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										width: "100%",
										borderWidth: 0.5,
										borderColor: BORDERBOTTOM,
										borderRadius: 10,
										flexDirection: "row",
										marginBottom: 5,
									}}
								/>
								<ScrollView>
									{this.state.viewInvoiceData.products.map((d, i) => {
										return (
											<View
												key={i}
												style={{
													width: "100%",
													height: "auto",
													borderBottomWidth: 0.5,
													borderBottomColor: BORDERBOTTOM,
													flexDirection: "row",
													paddingTop: 10,
													paddingBottom: 10,
													backgroundColor: isOdd(i)
														? "#E8E8E8"
														: "#FFF",
													alignItems: "center",
												}}>
												<Text
													style={{
														flex: 1,
														fontWeight: "500",
														fontSize: 15,
														paddingLeft: 5,
													}}>
													{d.title}
												</Text>
												<Text
													style={{
														flex: 1,
														fontWeight: "300",
														fontSize: 17,
														paddingLeft: 5,
													}}>
													{d.description}
												</Text>

												<Text
													style={{
														width: 100,
														fontSize: 17,
													}}>
													R{" "}
													{currencyFormat(
														parseFloat(d.ind_amount),
													)}
												</Text>

												<Text
													style={{
														width: 50,
														fontSize: 17,
													}}>
													{d.qty}
												</Text>
												<Text
													style={{
														width: 110,
														textAlign: "right",
														fontSize: 17,
													}}>
													R{" "}
													{currencyFormat(
														parseFloat(d.totalPrice),
													)}
												</Text>
											</View>
										);
									})}

									<View
										style={{
											width: "100%",
											height: 10,
										}}
									/>
									{this.state.viewInvoiceData.purchaseOrder.number !==
									"" ? (
										<View
											style={{
												width: "100%",
												height: "auto",
												marginTop: 20,
												paddingTop: 10,
												paddingBottom: 10,
												backgroundColor: "#FFF",
											}}>
											<Text
												style={{
													fontSize: 13,
													fontWeight: "300",
													opacity: 0.6,
												}}>
												Purchase Order Number
											</Text>
											<Text
												style={{
													flex: 1,
													fontWeight: "500",
												}}>
												{
													this.state.viewInvoiceData.purchaseOrder
														.number
												}
											</Text>
										</View>
									) : null}
								</ScrollView>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										borderTopWidth: 0.5,
										borderTopColor: BORDERBOTTOM,
										marginBottom: 5,
									}}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 17,
											fontWeight: "800",
										}}>
										Subtotal
									</Text>

									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										R{" "}
										{currencyFormat(this.state.viewInvoiceData.total)}
									</Text>
								</View>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										marginBottom: 5,
									}}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 17,
											fontWeight: "500",
										}}>
										VAT (15.00%)
									</Text>

									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										R{" "}
										{currencyFormat(
											(this.state.viewInvoiceData.total * 15) / 100,
										)}
									</Text>
								</View>
								<View
									style={{
										flexDirection: "row",
										height: 90,
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										Total
									</Text>

									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										R{" "}
										{currencyFormat(
											(this.state.viewInvoiceData.total * 115) / 100,
										)}
									</Text>
								</View>
							</View>
						}
					/>
				) : null}

				{/* {this.state.viewQuoteData !== '' ? (
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.viewQuote}>
            <View
              style={{
                flex: 1,
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: 'rgba(52, 52, 52, 0.6)',
              }}>
              <View
                style={{
                  height: 80,
                }}
              />
              <View
                style={{
                  width: 600,
                  height: this.props.screenHeight - 100,
                  backgroundColor: 'white',
                  borderRadius: 12,
                  padding: 10,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderBottomWidth: 0.5,
                    paddingBottom: 10,
                    borderBottomColor: '#E8E8E8',
                  }}>
                  <TouchableOpacity
                    onPress={async () => {
                      await this.setState({
                        viewQuote: false,
                        viewQuoteData: '',
                      });
                      // await this.saveAppStates();
                    }}>
                    <Image
                      style={{
                        width: 30,
                        height: 30,
                        resizeMode: 'contain',
                        marginRight: 10,
                      }}
                      source={require('../../../../assets/images/close.png')}
                    />
                  </TouchableOpacity>
                  <View>
                    <Text>
                      View Quote:{' '}
                      <Text
                        style={{
                          fontWeight: '500',
                        }}>
                        {this.state.viewQuoteData.quote.number}
                      </Text>
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flex: 1,
                    width: '100%',
                    // maxHeight: 500,
                  }}>
                  <View style={{padding: 5}}>
                    <Text
                      style={{
                        color: '#292A4A',
                        fontSize: 19,
                        fontWeight: '800',
                        marginBottom: 10,
                      }}>
                      Quote Number
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      backgroundColor: this.state.addDate
                        ? '#E8E8E8'
                        : 'transparent',
                      borderRadius: 5,
                      padding: 5,
                    }}>
                    <View
                      style={{
                        flex: 1,
                      }}>
                      <Text
                        style={{
                          color: '#292A4A',
                          fontSize: 19,
                          fontWeight: '800',
                          marginBottom: 10,
                        }}>
                        Date
                      </Text>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}>
                      <View
                        style={{
                          width: 'auto',
                          height: 35,
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: '#FF5F5F',
                          borderRadius: 12,
                          padding: 5,
                          paddingLeft: 30,
                          paddingRight: 30,
                        }}>
                        <Text
                          style={{
                            color: '#FFF',
                            fontWeight: '600',
                            fontSize: 13,
                          }}>
                          {this.state.viewQuoteData.quote.date !== ''
                            ? moment(
                                this.state.viewQuoteData.quote.date,
                              ).format('DD/MM/YYYY')
                            : null}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View style={{padding: 5}}>
                    <Text
                      style={{
                        color: '#292A4A',
                        fontSize: 19,
                        fontWeight: '800',
                        marginBottom: 10,
                      }}>
                      Items
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      borderWidth: 0.5,
                      borderColor: BORDERBOTTOM,
                      borderRadius: 10,
                      flexDirection: 'row',
                      marginBottom: 5,
                    }}
                  />
                  <ScrollView>
                    {this.state.viewQuoteData.products.map((d, i) => {
                      return (
                        <View
                          key={i}
                          style={{
                            width: '100%',
                            height: 'auto',
                            borderBottomWidth: 0.5,
                            borderBottomColor: BORDERBOTTOM,
                            flexDirection: 'row',
                            paddingTop: 10,
                            paddingBottom: 10,
                            backgroundColor: isOdd(i) ? '#E8E8E8' : '#FFF',
                          }}>
                          <Text
                            style={{
                              flex: 1,
                            }}>
                            {d.title}
                          </Text>

                          <Text
                            style={{
                              width: 30,
                            }}>
                            {d.qty}
                          </Text>
                          <Text
                            style={{
                              width: 120,
                              textAlign: 'right',
                            }}>
                            {currencyFormat(parseFloat(d.totalPrice))}
                          </Text>
                        </View>
                      );
                    })}

                    <View
                      style={{
                        width: '100%',
                        height: 10,
                      }}
                    />
                  </ScrollView>
                  <View
                    style={{
                      flexDirection: 'row',
                      height: 90,
                      justifyContent: 'space-between',
                      borderTopWidth: 0.5,
                      borderTopColor: BORDERBOTTOM,
                    }}>
                    <Text
                      style={{
                        color: '#292A4A',
                        fontSize: 19,
                        fontWeight: '800',
                      }}>
                      Items
                    </Text>

                    <Text
                      style={{
                        color: '#292A4A',
                        fontSize: 19,
                        fontWeight: '800',
                      }}>
                      R{' '}
                      {this.state.viewQuoteData.total > 0
                        ? currencyFormat(this.state.viewQuoteData.total)
                        : `0.00`}
                    </Text>
                  </View>
                </View>
                <TouchableOpacity onPress={() => this.sendQuote()}>
                  <View
                    style={{
                      width: 'auto',
                      height: 80,
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: BLUE,
                      borderRadius: 12,
                      padding: 5,
                      marginTop: 10,
                      flexDirection: 'row',
                    }}>
                    <Text
                      style={{
                        color: '#FFF',
                        fontWeight: '600',
                        fontSize: 16,
                      }}>
                      SEND QUOTE
                    </Text>

                    <Image
                      style={{
                        width: 20,
                        height: 20,
                        resizeMode: 'contain',
                        marginLeft: 10,
                      }}
                      source={require('../../../../assets/images/send.png')}
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        ) : null} */}

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.uploadPO}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<View
							style={{
								height: 80,
							}}
						/>
						<View
							style={{
								width: 500,
								height: 450,
								backgroundColor: "white",
								borderRadius: 12,
								padding: 10,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									borderBottomWidth: 0.5,
									paddingBottom: 10,
									borderBottomColor: "#E8E8E8",
								}}>
								<TouchableOpacity
									onPress={async () => {
										await this.setState({
											uploadPO: false,
										});
										// await this.saveAppStates();
									}}>
									<Image
										style={{
											width: 30,
											height: 30,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View>
									<Text>Upload Purchase Order: </Text>
								</View>
							</View>
							<View
								style={{
									flex: 1,
									width: "100%",
									// maxHeight: 500,
								}}>
								<View style={{ padding: 5 }}>
									<Text
										style={{
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
										}}>
										PO Number
									</Text>
									<TextInput
										style={{
											width: "100%",
											paddingTop: 10,
											paddingBottom: 10,
											paddingLeft: 5,
											fontSize: 24,
											borderRadius: 10,
											// backgroundColor: FONT_ALMOSTWHITE,
											color: BLUE,
											borderBottomColor: FONT_ALMOSTWHITE,
											borderBottomWidth: 0.5,
											marginBottom: 10,
											fontWeight: "800",
											fontFamily: "Avenir",
										}}
										placeholderTextColor={FONT_ALMOSTWHITE}
										clearButtonMode='while-editing'
										autoCompleteType='off'
										keyboardType='decimal-pad'
										placeholder={"PO Number"}
										defaultValue={"PO"}
										autoFocus
										onSubmitEditing={() => {}}
										ref={(ref) => {
											this.textInput = ref;
										}}
										onChangeText={(text) =>
											this.setState({
												poNumber: text,
											})
										}
									/>
								</View>

								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										backgroundColor: this.state.addDate_
											? "#E8E8E8"
											: "transparent",
										borderRadius: 5,
										padding: 5,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												color: "#292A4A",
												fontSize: 19,
												fontWeight: "800",
												marginBottom: 10,
											}}>
											Date
										</Text>
									</View>

									<View
										style={{
											flex: 1,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "flex-end",
										}}>
										{this.state.addDate_ ? (
											<View
												style={{
													flex: 1,
												}}>
												{/* <DatePicker
													date={this.state.poDate}
													onDateChange={(date) =>
														this.setState({
															poDate: date,
														})
													}
												/> */}
												<TouchableOpacity
													onPress={() =>
														this.setState({
															addDate_: false,
														})
													}>
													<View
														style={{
															width: "auto",
															height: 35,
															justifyContent: "center",
															alignItems: "center",
															backgroundColor: "#FF5F5F",
															borderRadius: 12,
															padding: 5,
															marginTop: 10,
														}}>
														<Text
															style={{
																color: "#FFF",
																fontWeight: "600",
																fontSize: 13,
															}}>
															Done
														</Text>
													</View>
												</TouchableOpacity>
											</View>
										) : (
											<TouchableOpacity
												onPress={() =>
													this.setState({
														addDate_: true,
													})
												}>
												<View
													style={{
														width: "auto",
														height: 35,
														justifyContent: "center",
														alignItems: "center",
														backgroundColor: "#FF5F5F",
														borderRadius: 12,
														padding: 5,
														paddingLeft: 30,
														paddingRight: 30,
													}}>
													<Text
														style={{
															color: "#FFF",
															fontWeight: "600",
															fontSize: 13,
														}}>
														{moment(this.state.poDate).format(
															"DD/MM/YYYY",
														)}
													</Text>
												</View>
											</TouchableOpacity>
										)}
									</View>
								</View>

								<View
									style={{
										padding: 5,
										flexDirection: "row",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											flex: 1,
											color: "#292A4A",
											fontSize: 19,
											fontWeight: "800",
											marginBottom: 10,
										}}>
										Document
									</Text>

									<Text
										style={{
											flex: 1,
											color: "#292A4A",
											fontSize: 14,
											fontWeight: "300",
											marginBottom: 10,
											fontStyle: "italic",
										}}>
										Unable to upload at this time, save the PO to file
										system and simply input PO number only.
									</Text>
								</View>
								<View
									style={{
										width: "100%",
										borderWidth: 0.5,
										borderColor: BORDERBOTTOM,
										borderRadius: 10,
										flexDirection: "row",
										marginBottom: 5,
									}}
								/>

								{this.state.poNumber !== "" ? (
									<TouchableOpacity
										onPress={() => this.updateItemPO()}>
										<View
											style={{
												width: "auto",
												height: 80,
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: BLUE,
												borderRadius: 12,
												padding: 5,
												marginTop: 10,
											}}>
											<Text
												style={{
													color: "#FFF",
													fontWeight: "600",
													fontSize: 13,
												}}>
												Done
											</Text>
										</View>
									</TouchableOpacity>
								) : null}
							</View>
						</View>
					</View>
				</Modal>

				{this.state.viewInvoiceData !== "" ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={this.state.createInvoice}>
						<View
							style={{
								flex: 1,
								justifyContent: "flex-start",
								alignItems: "center",
								backgroundColor: "rgba(52, 52, 52, 0.6)",
							}}>
							<View
								style={{
									height: 80,
								}}
							/>
							<View
								style={{
									width: 500,
									height: this.props.screenHeight - 100,
									backgroundColor: "white",
									borderRadius: 12,
									padding: 10,
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										borderBottomWidth: 0.5,
										paddingBottom: 10,
										borderBottomColor: "#E8E8E8",
									}}>
									<TouchableOpacity
										onPress={async () => {
											await this.setState({
												viewInvoice: false,
												viewInvoiceData: "",
											});
											// await this.saveAppStates();
										}}>
										<Image
											style={{
												width: 30,
												height: 30,
												resizeMode: "contain",
												marginRight: 10,
											}}
											source={require("../../../assets/images/close.png")}
										/>
									</TouchableOpacity>
									<View>
										<Text>
											Create Invoice:{" "}
											<Text
												style={{
													fontWeight: "500",
												}}>
												{this.state.invoiceNumber}
											</Text>
										</Text>
									</View>
								</View>
								<View
									style={{
										flex: 1,
										width: "100%",
										// maxHeight: 500,
									}}>
									<View
										style={{
											padding: 5,
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
											marginBottom: 10,
										}}>
										<Text
											style={{
												color: "#292A4A",
												fontSize: 19,
												fontWeight: "800",
											}}>
											Invoice Number
										</Text>

										<Text
											style={{
												color: "#292A4A",
												fontSize: 19,
												fontWeight: "600",
											}}>
											INV {this.state.invoiceNumber}
										</Text>
									</View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											backgroundColor: this.state.addDate
												? "#E8E8E8"
												: "transparent",
											borderRadius: 5,
											padding: 5,
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													color: "#292A4A",
													fontSize: 19,
													fontWeight: "800",
													marginBottom: 10,
												}}>
												Date
											</Text>
										</View>

										<View
											style={{
												flex: 1,
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "flex-end",
											}}>
											{this.state.addInvDate ? (
												<View
													style={{
														flex: 1,
													}}>
													{/* <DatePicker
														date={
															this.state
																.invoiceDate
														}
														onDateChange={(date) =>
															this.setState({
																invoiceDate:
																	date,
															})
														}
													/> */}
													<TouchableOpacity
														onPress={() =>
															this.setState({
																addInvDate: false,
															})
														}>
														<View
															style={{
																width: "auto",
																height: 35,
																justifyContent: "center",
																alignItems: "center",
																backgroundColor: "#FF5F5F",
																borderRadius: 12,
																padding: 5,
																marginTop: 10,
															}}>
															<Text
																style={{
																	color: "#FFF",
																	fontWeight: "600",
																	fontSize: 13,
																}}>
																Done
															</Text>
														</View>
													</TouchableOpacity>
												</View>
											) : (
												<TouchableOpacity
													onPress={() =>
														this.setState({
															addInvDate: true,
														})
													}>
													<View
														style={{
															width: "auto",
															height: 35,
															justifyContent: "center",
															alignItems: "center",
															backgroundColor: "#FF5F5F",
															borderRadius: 12,
															padding: 5,
															paddingLeft: 30,
															paddingRight: 30,
														}}>
														<Text
															style={{
																color: "#FFF",
																fontWeight: "600",
																fontSize: 13,
															}}>
															{moment(
																this.state.invoiceDate,
															).format("DD/MM/YYYY")}
														</Text>
													</View>
												</TouchableOpacity>
											)}
										</View>
									</View>

									<View style={{ padding: 5 }}>
										<Text
											style={{
												color: "#292A4A",
												fontSize: 19,
												fontWeight: "800",
												marginBottom: 10,
											}}>
											Items
										</Text>
									</View>
									<View
										style={{
											width: "100%",
											borderWidth: 0.5,
											borderColor: BORDERBOTTOM,
											borderRadius: 10,
											flexDirection: "row",
											marginBottom: 5,
										}}
									/>
									<ScrollView>
										{this.state.viewInvoiceData.products.map(
											(d, i) => {
												return (
													<View
														key={i}
														style={{
															width: "100%",
															height: "auto",
															borderBottomWidth: 0.5,
															borderBottomColor: BORDERBOTTOM,
															flexDirection: "row",
															paddingTop: 10,
															paddingBottom: 10,
															backgroundColor: isOdd(i)
																? "#E8E8E8"
																: "#FFF",
														}}>
														<Text
															style={{
																flex: 1,
															}}>
															{d.title}
														</Text>

														<Text
															style={{
																width: 100,
															}}>
															R{" "}
															{currencyFormat(
																parseFloat(d.ind_amount),
															)}
														</Text>

														<Text
															style={{
																width: 50,
															}}>
															{d.qty}
														</Text>
														<Text
															style={{
																width: 120,
																textAlign: "right",
															}}>
															{currencyFormat(
																parseFloat(d.totalPrice),
															)}
														</Text>
													</View>
												);
											},
										)}

										{this.state.viewInvoiceData.purchaseOrder
											.number !== "" ? (
											<View
												style={{
													width: "100%",
													height: "auto",
													marginTop: 20,
													paddingTop: 10,
													paddingBottom: 10,
													backgroundColor: "#FFF",
												}}>
												<Text
													style={{
														fontSize: 10,
														fontWeight: "300",
														opacity: 0.6,
													}}>
													Purchase Order Number
												</Text>
												<Text
													style={{
														flex: 1,
														fontWeight: "500",
													}}>
													{
														this.state.viewInvoiceData
															.purchaseOrder.number
													}
												</Text>
											</View>
										) : null}

										<View
											style={{
												width: "100%",
												height: 10,
											}}
										/>
									</ScrollView>
									<View
										style={{
											flexDirection: "row",
											height: 90,
											justifyContent: "space-between",
											borderTopWidth: 0.5,
											borderTopColor: BORDERBOTTOM,
										}}>
										<Text
											style={{
												color: "#292A4A",
												fontSize: 19,
												fontWeight: "800",
											}}>
											Items
										</Text>

										<Text
											style={{
												color: "#292A4A",
												fontSize: 19,
												fontWeight: "800",
											}}>
											R{" "}
											{this.state.viewInvoiceData.total > 0
												? currencyFormat(
														this.state.viewInvoiceData.total,
												  )
												: `0.00`}
										</Text>
									</View>

									<TouchableOpacity
										onPress={() => this.updateItemInvoice()}>
										<View
											style={{
												width: "auto",
												height: 80,
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: BLUE,
												borderRadius: 12,
												padding: 5,
												marginTop: 10,
											}}>
											<Text
												style={{
													color: "#FFF",
													fontWeight: "600",
													fontSize: 13,
												}}>
												CREATE INVOICE
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							</View>
						</View>
					</Modal>
				) : null}

				{/* {this.state.viewInvoiceData !== '' ? (
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.viewInvoice}>
            <View
              style={{
                flex: 1,
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: 'rgba(52, 52, 52, 0.6)',
              }}>
              <View
                style={{
                  height: 80,
                }}
              />
              <View
                style={{
                  width: 500,
                  height: this.props.screenHeight - 100,
                  backgroundColor: 'white',
                  borderRadius: 12,
                  padding: 10,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderBottomWidth: 0.5,
                    paddingBottom: 10,
                    borderBottomColor: '#E8E8E8',
                  }}>
                  <TouchableOpacity
                    onPress={async () => {
                      await this.setState({
                        viewInvoice: false,
                        viewInvoiceData: '',
                      });
                      // await this.saveAppStates();
                    }}>
                    <Image
                      style={{
                        width: 30,
                        height: 30,
                        resizeMode: 'contain',
                        marginRight: 10,
                      }}
                      source={require('../../../../assets/images/close.png')}
                    />
                  </TouchableOpacity>
                  <View>
                    <Text>
                      View Invoice:{' '}
                      <Text
                        style={{
                          fontWeight: '500',
                        }}>
                        {this.state.viewInvoiceData.invoice.number}
                      </Text>
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flex: 1,
                    width: '100%',
                    // maxHeight: 500,
                  }}>
                  <View
                    style={{
                      padding: 5,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 10,
                    }}>
                    <Text
                      style={{
                        color: '#292A4A',
                        fontSize: 19,
                        fontWeight: '800',
                      }}>
                      Invoice Number
                    </Text>

                    <Text
                      style={{
                        color: '#292A4A',
                        fontSize: 19,
                        fontWeight: '600',
                      }}>
                      {this.state.viewInvoiceData.invoice.number}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      backgroundColor: this.state.addDate
                        ? '#E8E8E8'
                        : 'transparent',
                      borderRadius: 5,
                      padding: 5,
                    }}>
                    <View
                      style={{
                        flex: 1,
                      }}>
                      <Text
                        style={{
                          color: '#292A4A',
                          fontSize: 19,
                          fontWeight: '800',
                          marginBottom: 10,
                        }}>
                        Date
                      </Text>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}>
                      {this.state.addInvDate ? (
                        <View
                          style={{
                            flex: 1,
                          }}>
                          <DatePicker
                            date={this.state.invoiceDate}
                            onDateChange={date =>
                              this.setState({
                                invoiceDate: date,
                              })
                            }
                          />
                          <TouchableOpacity
                            onPress={() =>
                              this.setState({
                                addInvDate: false,
                              })
                            }>
                            <View
                              style={{
                                width: 'auto',
                                height: 35,
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#FF5F5F',
                                borderRadius: 12,
                                padding: 5,
                                marginTop: 10,
                              }}>
                              <Text
                                style={{
                                  color: '#FFF',
                                  fontWeight: '600',
                                  fontSize: 13,
                                }}>
                                Done
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      ) : (
                        <TouchableOpacity
                          onPress={() =>
                            this.setState({
                              addInvDate: true,
                            })
                          }>
                          <View
                            style={{
                              width: 'auto',
                              height: 35,
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: '#FF5F5F',
                              borderRadius: 12,
                              padding: 5,
                              paddingLeft: 30,
                              paddingRight: 30,
                            }}>
                            <Text
                              style={{
                                color: '#FFF',
                                fontWeight: '600',
                                fontSize: 13,
                              }}>
                              {moment(this.state.invoiceDate).format(
                                'DD/MM/YYYY',
                              )}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>

                  <View style={{padding: 5}}>
                    <Text
                      style={{
                        color: '#292A4A',
                        fontSize: 19,
                        fontWeight: '800',
                        marginBottom: 10,
                      }}>
                      Items
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      borderWidth: 0.5,
                      borderColor: BORDERBOTTOM,
                      borderRadius: 10,
                      flexDirection: 'row',
                      marginBottom: 5,
                    }}
                  />
                  <ScrollView>
                    {this.state.viewInvoiceData.products.map((d, i) => {
                      return (
                        <View
                          key={i}
                          style={{
                            width: '100%',
                            height: 'auto',
                            borderBottomWidth: 0.5,
                            borderBottomColor: BORDERBOTTOM,
                            flexDirection: 'row',
                            paddingTop: 10,
                            paddingBottom: 10,
                            backgroundColor: isOdd(i) ? '#E8E8E8' : '#FFF',
                          }}>
                          <Text
                            style={{
                              flex: 1,
                            }}>
                            {d.title}
                          </Text>

                          <Text
                            style={{
                              width: 100,
                            }}>
                            R {currencyFormat(parseFloat(d.ind_amount))}
                          </Text>

                          <Text
                            style={{
                              width: 50,
                            }}>
                            {d.qty}
                          </Text>
                          <Text
                            style={{
                              width: 120,
                              textAlign: 'right',
                            }}>
                            {currencyFormat(parseFloat(d.totalPrice))}
                          </Text>
                        </View>
                      );
                    })}

                    {this.state.viewInvoiceData.purchaseOrder.number !== '' ? (
                      <View
                        style={{
                          width: '100%',
                          height: 'auto',
                          marginTop: 20,
                          paddingTop: 10,
                          paddingBottom: 10,
                          backgroundColor: '#FFF',
                        }}>
                        <Text
                          style={{
                            fontSize: 10,
                            fontWeight: '300',
                            opacity: 0.6,
                          }}>
                          Purchase Order Number
                        </Text>
                        <Text
                          style={{
                            flex: 1,
                            fontWeight: '500',
                          }}>
                          {this.state.viewInvoiceData.purchaseOrder.number}
                        </Text>
                      </View>
                    ) : null}

                    <View
                      style={{
                        width: '100%',
                        height: 10,
                      }}
                    />
                  </ScrollView>
                  <View
                    style={{
                      flexDirection: 'row',
                      height: 90,
                      justifyContent: 'space-between',
                      borderTopWidth: 0.5,
                      borderTopColor: BORDERBOTTOM,
                    }}>
                    <Text
                      style={{
                        color: '#292A4A',
                        fontSize: 19,
                        fontWeight: '800',
                      }}>
                      Items
                    </Text>

                    <Text
                      style={{
                        color: '#292A4A',
                        fontSize: 19,
                        fontWeight: '800',
                      }}>
                      R{' '}
                      {this.state.viewInvoiceData.total > 0
                        ? currencyFormat(this.state.viewInvoiceData.total)
                        : `0.00`}
                    </Text>
                  </View>

                  <TouchableOpacity onPress={() => this.sendInvoice()}>
                    <View
                      style={{
                        width: 'auto',
                        height: 80,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: BLUE,
                        borderRadius: 12,
                        padding: 5,
                        marginTop: 10,
                        flexDirection: 'row',
                      }}>
                      <Text
                        style={{
                          color: '#FFF',
                          fontWeight: '600',
                          fontSize: 16,
                        }}>
                        SEND INVOICE
                      </Text>

                      <Image
                        style={{
                          width: 20,
                          height: 20,
                          resizeMode: 'contain',
                          marginLeft: 10,
                        }}
                        source={require('../../../../assets/images/send.png')}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        ) : null} */}
			</View>
		);
	}
	//
	//

	saveState = () => {
		let sf = `accounts_${this.props.selectedEntityID}`;
		saveJsonDataToServer(sf, this.state.accounts, (status, data) => {
			if (status === 200) {
			} else {
				console.log("saveJsonDataToServer returned", status, data);
			}
		});
	};

	componentDidMount() {
		this.getClients();
	}

	getClients = () => {
		let sf = `clients_${this.props.selectedEntityID}`;
		getJsonDataFromServer(sf, (status, data) => {
			if (status === 200) {
				console.log(data);
				this.setState({
					clients: data,
				});
			} else {
				console.log("saveJsonDataToServer returned", status, data);
			}
			this.setState({
				loading: false,
			});
		});
	};
}

export default index;
