import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Modal,
	Dimensions,
} from "react-native";
import Slide from "react-reveal/Slide";
import moment from "moment";
// import add_btn from "./../../assets/images/add_btn.png";
import { APP_COLOURS } from "../APP_VARS";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

function isEven(n) {
	return n % 2 == 0;
}

class Accounts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenWidth: screenWidth,
			screenHeight: screenHeight,
			selectedDate: "",
			selectedDateID: -1,
			showAddItemsForm: false,
		};
	}

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	showAddItemsFormFunc = (event) => {
		event.preventDefault();
		const layout = event.nativeEvent;
		let x = layout.clientX;
		let y = layout.clientY;
		if (y > window.innerHeight - 250) {
			y = window.innerHeight - 250;
		}
		if (x > window.innerWidth - 200) {
			y = window.innerWidth - 200;
		}
		this.setState({
			showAddItemsForm: true,
			xPos: x,
			yPos: y,
		});

		console.log(layout);
	};

	selectDate = (d) => {
		this.props.selectedDate(d);
		this.setState({
			selectedDate: d,
			selectedDateID: d,
			showAddItemsForm: false,
		});
	};

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		return (
			<View
				style={
					{
						// width: 100,
						// height: 50,
					}
				}>
				<TouchableOpacity
					style={{
						borderRadius: 7,
						borderWidth: 0.5,
						borderColor: APP_COLOURS.BLUE,
						alignItems: "center",
						justifyContent: "center",
						height: 40,
						padding: 0,
						paddingLeft: 10,
						paddingRight: 10,
					}}
					onPress={(e) => this.showAddItemsFormFunc(e)}>
					<Text
						style={{
							// color:
							// 	this.state.selectedDateID == -1
							// 		? APP_COLOURS.RED
							// 		: "#FFF",

							color: APP_COLOURS.BLUE,
							fontWeight:
								this.state.doc_date == "" ? "400" : "600",
						}}>
						{this.state.selectedDate == ""
							? "Select date"
							: moment(this.state.selectedDate).format(
									"MMM YYYY",
							  )}
					</Text>
				</TouchableOpacity>

				<Modal
					transparent={true}
					visible={this.state.showAddItemsForm}
					animationType='fade'
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
					}}>
					<TouchableOpacity
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
						}}
						onPress={() =>
							this.setState({
								showAddItemsForm: false,
							})
						}>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "rgba(52, 52, 52, 0.6)",
								width: this.state.screenWidth,
								height: this.state.screenHeight,
							}}></View>
					</TouchableOpacity>

					<View
						style={{
							width: 120,
							maxHeight: 250,
							backgroundColor: APP_COLOURS.PRIMARY,
							position: "absolute",
							top: this.state.yPos,
							left: this.state.xPos,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.25,
							shadowRadius: 7.84,
							elevation: 12,
							borderRadius: 10,
							padding: 10,
							zIndex: 999,
						}}>
						<ScrollView>
							{this.props.data.map((d, i) => (
								<TouchableOpacity
									onPress={() => this.selectDate(d)}>
									<View
										style={{
											borderBottomColor:
												"rgba(255, 255, 255, 0.3)",
											borderBottomWidth: 0.5,
											padding: 8,
											// marginBottom: 8,
											borderRadius:
												this.state.selectedDateID == d
													? 5
													: 0,
											backgroundColor:
												this.state.selectedDateID == d
													? APP_COLOURS.BLUE
													: "transparent",
										}}>
										<Text
											style={{
												color: "#FFF",
												fontWeight:
													this.state.selectedDateID ==
													d
														? "600"
														: "200",
												fontFamily: "Avenir",
											}}>
											{moment(d).format("MMM YYYY")}
										</Text>
									</View>
								</TouchableOpacity>
							))}
						</ScrollView>
					</View>
				</Modal>
			</View>
		);
	}
}

export default Accounts;
