import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Alert,
	Modal,
	ActivityIndicator,
} from "react-native";
import moment from "moment";

import {
	getJsonDataFromServer,
	saveJsonDataToServer,
} from "../ServerRequestHelper";
import ExportToExcel from "../ExportToExcel";

const BLUE = "#3F6EFF";
const WHITE = "#FFF";
const FONT_ALMOSTWHITE = "#D4D4D4";
const FONTGREY = "#6E7074";
const RED = "#FF5F5F";
const OPACITYGREY = "#2F2F39";
const BG = "#131119";
const BG2 = "#191D24";
const BORDERBOTTOM = "#28282F";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class Products extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newTitle: "",
			newDescription: "",
			newPrice: "",
			newCurrency: "R",
			newVat: "excl",

			addProduct: false,
			skuNumber: 1,

			products: [],
		};
	}

	addProduct = async () => {
		const { newTitle, newDescription, newPrice, newCurrency, newVat } =
			this.state;

		let uid = await uuid();
		let obj = {
			id: uid,
			title: newTitle,
			// description: newDescription,
			price: {
				amount: newPrice,
				currency: newCurrency,
				vat: newVat,
			},
			date_added: moment(),
		};
		let array = await this.state.products;
		const push_ = await [...array, obj];
		await this.setState({
			products: push_,
			addProduct: false,
			newTitle: "",
			newDescription: "",
			newPrice: "",
			newCurrency: "R",
			newVat: "excl",
		});
		this.saveState();
	};

	componentDidMount() {
		this.getProds();
	}

	getProds = () => {
		let sf = `products`;
		this.setState({ loading: true });
		getJsonDataFromServer(sf, (status, data) => {
			if (status === 200) {
				this.setState({
					products: data,
				});
			} else {
				console.log("saveJsonDataToServer returned", status, data);
			}
			this.setState({
				loading: false,
			});
		});
	};

	deleteProduct = async (id) => {
		let array = this.state.products;
		const newarr = await array.filter((d) => d.id !== id);
		await this.setState({
			products: newarr,
		});
		this.saveState();
	};

	saveState = () => {
		let sf = `products`;
		saveJsonDataToServer(sf, this.state.products, (status, data) => {
			if (status === 200) {
			} else {
				console.log("saveJsonDataToServer returned", status, data);
			}
		});
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					paddingLeft: 15,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						marginBottom: 10,
						paddingRight: 15,
					}}>
					<View>
						<Text
							style={{
								color: "#F6F8FE", // '#5A7BBA'
								fontSize: 25,
								fontWeight: "900",
							}}>
							Products
						</Text>
					</View>

					<View
						style={{
							flexDirection: "row",
						}}>
						<ExportToExcel
							heightWidth={35}
							excel_doc_name={"products"}
							data={this.state.products}
						/>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									addProduct: !this.state.addProduct,
									newTitle: "",
									newDescription: "",
									newPrice: "",
									newCurrency: "R",
									newVat: "excl",
								})
							}>
							<View
								style={{
									width: "auto",
									height: 35,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#FF5F5F",
									borderRadius: 12,
									padding: 5,
									marginLeft: 15,
								}}>
								<Text
									style={{
										color: "#FFF",
										fontWeight: "600",
										fontSize: 13,
									}}>
									Add Product
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				</View>

				{/* <ScrollView> */}
				<View
					style={{
						flex: 1,
						flexDirection: "row",
					}}>
					<View
						style={{
							backgroundColor: BG2,
							borderRadius: 20,
							marginRight: 17,
							// marginBottom: 15,
							flex: 1,
							padding: 10,
						}}>
						<View
							style={{
								flexDirection: "row",
								// justifyContent: 'space-between',
								paddingBottom: 10,
								// marginBottom: 10,
								alignItems: "center",
								borderBottomColor: BORDERBOTTOM,
								borderBottomWidth: 0.5,
							}}>
							<Text
								style={{
									fontSize: 14,
									color: "#D8D8FF",
									fontWeight: "700",
									fontFamily: "Avenir Next",
									flex: 1,
								}}>
								Title
							</Text>

							<Text
								style={{
									fontSize: 14,
									color: "#D8D8FF",
									fontWeight: "700",
									fontFamily: "Avenir Next",
									width: 100,
								}}>
								Amount
							</Text>

							<Text
								style={{
									fontSize: 14,
									color: "#D8D8FF",
									fontWeight: "700",
									fontFamily: "Avenir Next",
									width: 100,
								}}>
								VAT
							</Text>

							<Text
								style={{
									fontSize: 14,
									color: "#D8D8FF",
									fontWeight: "700",
									fontFamily: "Avenir Next",
									width: 100,
								}}>
								Delete
							</Text>
						</View>
						<View
							style={{
								flex: 1,
							}}>
							<ScrollView>
								{this.state.products.map((prod, i) => (
									<View
										key={i}
										style={{
											flexDirection: "row",
											borderBottomColor: "#28282F",
											borderBottomWidth: 0.5,
											alignItems: "center",
											minHeight: 30,
										}}>
										<View
											style={{
												flex: 1,
												flexDirection: "row",
												alignItems: "center",
												marginBottom: 2,
											}}>
											<Text
												style={{
													fontSize: 14,
													color: FONT_ALMOSTWHITE,
													fontWeight: "700",
													fontFamily: "Avenir Next",
													marginRight: 5,
													flex: 1,
												}}>
												{prod.title}
											</Text>

											<Text
												style={{
													fontSize: 14,
													color: FONT_ALMOSTWHITE,
													fontWeight: "700",
													fontFamily: "Avenir Next",
													marginRight: 5,
													width: 100,
												}}>
												{prod.price.currency} {prod.price.amount}
											</Text>
											<Text
												style={{
													fontSize: 14,
													color: FONT_ALMOSTWHITE,
													fontWeight: "700",
													fontFamily: "Avenir Next",
													marginRight: 5,
													width: 80,
												}}>
												{prod.price.vat}
											</Text>

											<Text
												style={{
													fontSize: 12,
													color: FONT_ALMOSTWHITE,
													fontWeight: "400",
													fontFamily: "Avenir Next",
													width: 80,
												}}>
												{moment(prod.date_added).format(
													"MM/DD/YYYY",
												)}
											</Text>
										</View>
										<TouchableOpacity
											onPress={() => this.deleteProduct(prod.id)}>
											<Image
												style={{
													width: 24,
													height: 24,
													resizeMode: "contain",
												}}
												source={require("../../../assets/images/bin.png")}
											/>
										</TouchableOpacity>
									</View>
								))}

								{this.state.loading ? (
									<View
										style={{
											width: "100%",
											padding: 30,
											borderRadius: 8,
											// backgroundColor: "#797979",
											height: 200,
											opacity: 0.7,
										}}>
										<ActivityIndicator color={"#FFF"} />
									</View>
								) : null}
							</ScrollView>
						</View>
					</View>
				</View>

				<View
					style={{
						width: "100%",
						height: 80,
					}}
				/>
				{/* </ScrollView> */}

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.addProduct}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<View
							style={{
								height: 80,
							}}
						/>
						<View
							style={{
								width: 400,
								height: this.props.screenHeight / 2,
								backgroundColor: BG,
								borderRadius: 12,
								padding: 10,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									borderBottomWidth: 0.5,
									paddingBottom: 10,
									borderBottomColor: BORDERBOTTOM,
									marginBottom: 20,
								}}>
								<TouchableOpacity
									onPress={async () => {
										await this.setState({
											addProduct: false,
											newTitle: "",
											newDescription: "",
											newPrice: "",
											newCurrency: "R",
											newVat: "excl",
										});
										// await this.saveAppStates();
									}}>
									<Image
										style={{
											width: 30,
											height: 30,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View style={{}}>
									<Text
										style={{
											fontSize: 14,
											color: "#D8D8FF",
											fontWeight: "500",
											fontFamily: "Avenir Next",
										}}>
										Create Product:{" "}
										<Text
											style={{
												fontWeight: "600",
											}}>
											SKU {this.state.skuNumber}
										</Text>
									</Text>
								</View>
							</View>

							{/* CONTENT    */}
							<View
								style={{
									flex: 1,
									width: "100%",
									maxHeight: 500,
								}}>
								<ScrollView>
									<View
										style={{
											flex: 1,
											width: "100%",
											height: 500,
										}}>
										<View
											style={{
												padding: 5,
											}}>
											<Text
												style={{
													fontSize: 10,
													color: "#FFF",
													fontWeight: "300",
													fontFamily: "Avenir Next",
													opacity: 0.5,
													padding: 0,
												}}>
												Product Name
											</Text>

											<TextInput
												style={{
													fontSize: 14,
													color: "#FFF",
													fontWeight:
														this.state.newTitle !== ""
															? "700"
															: "400",
													fontFamily: "Avenir Next",
													width: "100%",
													marginTop: 10,
													height: 40,
													paddingLeft: 10,
													marginBottom: 5,
													borderRadius: 8,
													backgroundColor:
														this.state.newTitle !== ""
															? BLUE
															: BG2,
													color: WHITE,
												}}
												placeholderTextColor={"#FFF"}
												autoCapitalize='words'
												autoCorrect={false}
												autoFocus
												clearButtonMode='while-editing'
												autoCompleteType='off'
												placeholder={`Product name`}
												onChangeText={(text) =>
													this.setState({
														newTitle: text,
													})
												}
											/>
										</View>

										{/* <View
                      style={{
                        padding: 5,
                      }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: '#FFF',
                          fontWeight: '300',
                          fontFamily: 'Avenir Next',
                          opacity: 0.5,
                          padding: 0,
                        }}>
                        Product Description
                      </Text>

                      <TextInput
                        style={{
                          fontSize: 14,
                          color: '#FFF',
                          fontWeight:
                            this.state.newDescription !== '' ? '700' : '400',
                          fontFamily: 'Avenir Next',
                          width: '100%',
                          marginTop: 10,
                          height: 40,
                          paddingLeft: 10,
                          marginBottom: 5,
                          borderRadius: 8,
                          backgroundColor:
                            this.state.newDescription !== '' ? BLUE : BG2,
                          color: WHITE,
                        }}
                        placeholderTextColor={'#FFF'}
                        autoCapitalize="words"
                        autoCorrect={false}
                        clearButtonMode="while-editing"
                        autoCompleteType="off"
                        placeholder={`Product Description`}
                        onChangeText={text =>
                          this.setState({
                            newDescription: text,
                          })
                        }
                      />
                    </View> */}

										<View
											style={{
												padding: 5,
											}}>
											<Text
												style={{
													fontSize: 10,
													color: "#FFF",
													fontWeight: "300",
													fontFamily: "Avenir Next",
													opacity: 0.5,
													padding: 0,
												}}>
												Price
											</Text>

											<TextInput
												style={{
													fontSize: 22,
													color: "#FFF",
													fontWeight:
														this.state.newPrice !== ""
															? "700"
															: "400",
													fontFamily: "Avenir Next",
													width: "100%",
													marginTop: 10,
													height: 60,
													paddingLeft: 10,
													marginBottom: 5,
													borderRadius: 8,
													backgroundColor:
														this.state.newPrice !== ""
															? BLUE
															: BG2,
													color: WHITE,
												}}
												placeholderTextColor={"#FFF"}
												keyboardType='decimal-pad'
												autoCompleteType='off'
												placeholder={"Price"}
												onChangeText={(text) =>
													this.setState({
														newPrice: text,
													})
												}
											/>
										</View>

										<View
											style={{
												padding: 5,
											}}>
											<Text
												style={{
													fontSize: 10,
													color: "#FFF",
													fontWeight: "300",
													fontFamily: "Avenir Next",
													opacity: 0.5,
													padding: 0,
												}}>
												Price
											</Text>

											{/* <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-evenly',
                          marginBottom: 15,
                        }}>
                        <TouchableOpacity
                          onPress={() =>
                            this.setState({
                              newVat: 'excl',
                            })
                          }>
                          <View
                            style={{
                              width: 100,
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor:
                                this.state.newVat == 'excl' ? BLUE : BG2,
                              padding: 15,
                              borderRadius: 12,
                            }}>
                            <Text
                              style={{
                                fontSize: 10,
                                color: '#FFF',
                                fontWeight: '300',
                                fontFamily: 'Avenir Next',
                              }}>
                              excl
                            </Text>
                          </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() =>
                            this.setState({
                              newVat: 'incl',
                            })
                          }>
                          <View
                            style={{
                              width: 100,
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor:
                                this.state.newVat == 'incl' ? BLUE : BG2,
                              padding: 15,
                              borderRadius: 12,
                            }}>
                            <Text
                              style={{
                                fontSize: 10,
                                color: '#FFF',
                                fontWeight: '300',
                                fontFamily: 'Avenir Next',
                              }}>
                              Incl.
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View> */}
											<TouchableOpacity
												onPress={() => this.addProduct()}>
												<View
													style={{
														width: "auto",
														height: 35,
														justifyContent: "center",
														alignItems: "center",
														backgroundColor: "#FF5F5F",
														borderRadius: 12,
														padding: 5,
													}}>
													<Text
														style={{
															color: "#FFF",
															fontWeight: "600",
															fontSize: 13,
														}}>
														Save Item
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									</View>
								</ScrollView>
							</View>
						</View>
					</View>
				</Modal>
			</View>
		);
	}
}

export default Products;
