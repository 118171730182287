import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from "react-native";
import Slide from "react-reveal/Slide";
import moment from "moment";
import male1 from "./../../assets/images/male1.png";
import { APP_COLOURS } from "../APP_VARS";

function isEven(n) {
	return n % 2 == 0;
}

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class Accounts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenWidth: this.props.screenWidth,
			screenHeight: this.props.screenHeight,
		};
	}

	logOut = async () => {
		this.props.logOut();
	};

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		let avatars = {
			1: require("./../../assets/images/female1.png"),
			2: require("./../../assets/images/male1.png"),
			3: require("./../../assets/images/male2.png"),
		};
		return (
			<Modal
				transparent={true}
				visible={true}
				animationType='fade'
				shouldCloseOnOverlayClick={true}
				visible={this.state.ebookUpdated}
				style={{
					width: this.state.screenWidth,
					height: this.state.screenHeight,
				}}>
				<TouchableOpacity
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
					}}
					onPress={() => this.props.closeModal()}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
							width: this.state.screenWidth,
							height: this.state.screenHeight,
						}}></View>
				</TouchableOpacity>

				<View
					style={{
						// width: 200,
						// maxHeight: 250,
						backgroundColor: APP_COLOURS.PRIMARY,
						position: "absolute",
						top: 0, //this.props.yPos
						right: 0,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.25,
						shadowRadius: 7.84,
						elevation: 12,
						borderRadius: 10,
						padding: 10,
						zIndex: 999,
					}}>
					<ScrollView>
						{/* <Slide top cascade> */}
						<View
							style={
								{
									// height: 30,
								}
							}>
							<View
								style={{
									alignItems: "center",
									justifyContent: "center",
									width: 200,
									backgroundColor: APP_COLOURS.BUTTON2,
									borderRadius: 24,
									height: 48,
									// paddingLeft: 8,
									marginBottom: 20,
								}}>
								<TouchableOpacity
									onPress={() => this.props.closeModal()}
									style={{
										flexDirection: "row",
										justifyContent: "flex-start",
										width: "100%",
										alignItems: "center",
										paddingLeft: 5,
									}}>
									<Image
										source={avatars[this.props.user_data.avatar]}
										style={{
											width: 40,
											height: 40,
											borderRadius: 20,
											resizeMode: "contain",
											backgroundColor: "#FFF",
											marginRight: 5,
										}}
									/>
									<View
										style={
											{
												// padding: 5,
												// alignItems: "center",
												// height: 50,
												// alignItems: "flex-start",
											}
										}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 14,
												fontWeight: "600",
												color: APP_COLOURS.WHITE,
											}}>
											{this.props.user_data.first_name}{" "}
											{this.props.user_data.last_name}
										</Text>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 10,
												opacity: 0.8,
												color: APP_COLOURS.WHITE,
											}}>
											Admin
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
						<TouchableOpacity onPress={() => this.logOut()}>
							<View
								style={{
									// flex: 1,
									backgroundColor: APP_COLOURS.BLUE,
									borderRadius: 10,
									justifyContent: "center",
									flexDirection: "row",
									alignItems: "center",
									height: "auto",
									padding: 4,
								}}>
								{/* <Image
								source={add_btn}
								style={{
									width: 15,
									height: 15,
									marginRight: 4,
									marginLeft: 4,
								}}
							/> */}
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 10,
										color: "#FFF",
										fontWeight: "300",
									}}>
									Logout
								</Text>
							</View>
						</TouchableOpacity>
						{/* </Slide> */}
					</ScrollView>
				</View>
			</Modal>
		);
	}
}

export default Accounts;
