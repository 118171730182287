import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import moment from "moment";

function padLeadingZeros(num, size) {
	var s = num + "";
	while (s.length < size) s = "0" + s;
	return s;
}

function ordinal_suffix_of(i) {
	var j = i % 10,
		k = i % 100;
	if (j == 1 && k != 11) {
		return i + "st";
	}
	if (j == 2 && k != 12) {
		return i + "nd";
	}
	if (j == 3 && k != 13) {
		return i + "rd";
	}
	return i + "th";
}

function getISOWeekDates2(isoWeekNum = 1, year = new Date().getFullYear()) {
	let d = moment(
		String(year).padStart(4, "0") + "W" + String(isoWeekNum).padStart(2, "0"),
	);
	for (var dates = [], i = 0; i < 7; i++) {
		dates.push(d.format("DD/MM/YYYY"));
		d.add(1, "day");
	}
	return dates;
}

export default class Calendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDates: false,
			selectedDate: this.props.selectedDate,
			thismonth: moment(this.props.selectedDate, "DD/MM/YYYY"),
			currentMomnth: moment(),
			dates: Array.from(
				Array(
					moment(this.props.selectedDate, "DD/MM/YYYY").daysInMonth(),
				).keys(),
			),
			weekStart:
				moment(
					moment(this.props.selectedDate, "DD/MM/YYYY").startOf("month"),
				).format("w") - 1,
			weekEnd:
				moment(
					moment(this.props.selectedDate, "DD/MM/YYYY").endOf("month"),
				).format("w") + 1,
			totalWeeks:
				moment(
					moment(this.props.selectedDate, "DD/MM/YYYY").endOf("month"),
				).format("w") -
				moment(
					moment(this.props.selectedDate, "DD/MM/YYYY").startOf("month"),
				).format("w") +
				1,
		};
	}

	componentDidMount() {
		console.log(
			moment(moment(this.state.thismonth).endOf("month")).format("w"),
		);
	}

	changeMonth = async (change) => {
		let newMonth = "";
		if (change === "next") {
			newMonth = moment(this.state.thismonth).add(1, "month");
		} else {
			newMonth = moment(this.state.thismonth).subtract(1, "month");
		}
		await this.setState({
			thismonth: newMonth,
			currentMomnth: moment(),
			dates: Array.from(Array(moment(newMonth).daysInMonth()).keys()),
			weekStart: moment(moment(newMonth).startOf("month")).format("w") - 1,
			weekEnd: moment(moment(newMonth).endOf("month")).format("w") + 1,
			totalWeeks:
				moment(moment(newMonth).endOf("month")).format("w") -
				moment(moment(newMonth).startOf("month")).format("w") +
				1,
		});

		await console.log(
			moment(moment(this.state.thismonth).endOf("month")).format("w"),
		);
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					margin: 10,
					padding: 12,
					borderRadius: 10,
					borderWidth: 0.3,
					borderColor: "#000",
					backgroundColor: this.state.showOptions ? "#E8E8E8" : "#FFF",
				}}>
				<TouchableOpacity
					onPress={() =>
						this.setState({ showOptions: !this.state.showOptions })
					}>
					<View
						style={{
							flexDirection: "row",

							borderRadius: 12,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Text>
							{moment(this.state.selectedDate, "DD/MM/YYYY").format(
								"ddd DD MMM, YYYY",
							)}
						</Text>
					</View>
				</TouchableOpacity>
				<View
					style={{
						display: this.state.showOptions ? "flex" : "none",
						marginTop: 15,
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<TouchableOpacity onPress={() => this.changeMonth("prev")}>
							<View>
								<Text>Prev</Text>
							</View>
						</TouchableOpacity>
						<View>
							<Text>{moment(this.state.thismonth).format("MMMM")}</Text>
						</View>
						<TouchableOpacity onPress={() => this.changeMonth("next")}>
							<View>
								<Text>Next</Text>
							</View>
						</TouchableOpacity>
					</View>
					<View style={{}}>
						{this.heading()}
						{Array.from(Array(this.state.totalWeeks).keys()).map(
							(d, i) => (
								<View
									style={{
										marginTop: 6,
										flexDirection: "row",
										flexWrap: "wrap",
										alignItems: "center",
										// display:
										// 	moment(
										// 		parseFloat(this.state.weekStart) + d,
										// 		"w",
										// 	).format("MM") ===
										// 	moment(this.state.thismonth).format("MM")
										// 		? "flex"
										// 		: "none",
									}}>
									<Text
										style={{
											color: "#000",
											fontSize: 10,
											opacity: 0.5,
											width: 50,
										}}>
										{parseFloat(this.state.weekStart) + d}
									</Text>
									<View
										style={{
											flex: 1,
											flexDirection: "row",
											justifyContent: "center",
										}}>
										{getISOWeekDates2(
											parseFloat(this.state.weekStart) + d,
										).map((d, i) => {
											{
												/* let date = moment(d); */
											}

											return (
												<TouchableOpacity
													onPress={() => {
														this.setState({
															selectedDate: d,
															showOptions:
																!this.state.showOptions,
														});

														console.log(d);
													}}>
													<View
														key={i}
														style={{
															textAlign: this.props.textAlign,
															width: 40,
															height: 40,
															margin: 2,
															// padding: 10,
															justifyContent: "center",
															alignItems: "center",
															borderRadius: 8,
															backgroundColor:
																this.state.selectedDate === d
																	? "#141D31"
																	: "#FFF",
															borderWidth: 0.3,
															borderColor: "#E8E8E8",
															opacity:
																moment(d, "DD/MM/YYYY").format(
																	"MM",
																) ===
																moment(
																	this.state.thismonth,
																).format("MM")
																	? 1
																	: 0.3,
														}}>
														<Text
															style={{
																color:
																	this.state.selectedDate === d
																		? "#FFF"
																		: "#141D31",
																fontWeight: "300",
																fontSize: 13,
															}}>
															{moment(d, "DD/MM/YYYY").format(
																"DD",
															)}
														</Text>
													</View>
												</TouchableOpacity>
											);
										})}
									</View>
								</View>
							),
						)}
					</View>
				</View>
			</View>
		);
	}

	heading = () => {
		return (
			<View
				style={{
					marginTop: 6,
					flexDirection: "row",
					flexWrap: "wrap",
					alignItems: "center",
					// backgroundColor: "#E8E8E8",
				}}>
				<Text
					style={{
						color: "#000",
						fontSize: 10,
						opacity: 0.5,
						width: 50,
					}}>
					Weeks
				</Text>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "center",
					}}>
					{["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
						(d, i) => {
							return (
								<View
									key={i}
									style={{
										width: 40,
										height: 40,
										margin: 2,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 8,
										// backgroundColor: "#FFF",
										// borderWidth: 0.3,
										// borderColor: "#E8E8E8",
									}}>
									<Text
										style={{
											color: "#000",
											fontSize: 12,
										}}>
										{/* {ordinal_suffix_of(day)} */}
										{d}
									</Text>
								</View>
							);
						},
					)}
				</View>
			</View>
		);
	};
}
