import React, { Component } from "react";
import { api_getChats, api_addMessage } from "../../Api";
import Chat from "./Chat";

export default class ChatParent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: "main",
			// page: "newQuery",
			ticketRef: "",
			emailValid: false,
			checkingRVP: false,
			notInvited: false,
			rsvpHasBeenSubmitted: false,

			screenWidth: null,
			screenHeight: null,
			tabs: "New Query",
			queryFilter: "",
			queryTypeSelected: "",
			showchatMessages: true,

			userid: "",
			full_name: "",

			chatMessages: [],
		};

		this.currentQueryChild = React.createRef();
		this._timeout = 500;
	}

	async componentDidMount() {
		// await this.setState({
		// 	full_name: `${this.props.user_data.first_name} ${this.props.user_data.last_name}`,
		// 	userid: this.props.user_data.username,
		// });
		// await console.log(this.props.user_data);
		await this.getchatMessages(this.props.chat_id);
	}

	logOut = async () => {
		this.props.history.push("/");
		sessionStorage.clear();
	};

	getchatMessages = async (chat_id) => {
		let obj = {
			chat_id: chat_id,
		};
		console.log("getchatMessages");

		this.setState({
			loading: true,
		});

		await fetch(api_getChats, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			body: JSON.stringify(obj),
			redirect: "follow",
		})
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log(
								"=============================",
								responseData,
							);

							await this.setState({
								chatMessages: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else {
					this.setState({
						errorLogin: true,
						loading: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});

		return;
	};

	sendMessage = async (chatMessages) => {
		console.log("SEND MESSAGE =====", chatMessages);
		await this.setState({
			loading: true,
			sendingMessage: true,
		});
		await fetch(api_addMessage, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			body: JSON.stringify({
				...chatMessages,
			}),
		})
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							this.setState({
								chatMessages: responseData,
								sendingMessage: false,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else {
					this.setState({
						errorLogin: true,
						loading: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});

		clearTimeout(this._timeout);
		this._timeout = setTimeout(async () => {
			await this.currentQueryChild.current.triggerTimer();
		}, 500);
	};

	render() {
		return (
			<Chat
				ref={this.currentQueryChild}
				{...this.props}
				accessToken={this.props.accessToken}
				redirectBack={() => {
					// this.getchatMessages(this.props.user_data.username);
				}}
				userid={this.props.user_data.id}
				user_data={this.props.user_data}
				sendMessage={this.sendMessage.bind(this)}
				sendingMessage={this.state.sendingMessage}
				chatMessages={this.state.chatMessages}
				chat_id={this.props.chat_id}
				screenWidth={this.props.screenWidth}
				screenHeight={this.props.screenHeight}
				getchatMessages={async () => {
					clearTimeout(this._timeout);
					this._timeout = setTimeout(async () => {
						await this.getchatMessages(this.props.chat_id);

						await this.currentQueryChild.current.triggerTimer();
					}, 500);
				}}
			/>
		);
	}

	getJsonDataFromServer = () => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			command: "get",
			fileName: "tasks",
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/accountsapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result getJsonDataFromServer wes", result);

							this.setState({
								...result,
								loading: false,
							});
						})
						.catch((error) => this.setState({}));
				} else {
					this.setState({
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	saveJsonDataToServer = () => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			json_data: this.state,
			command: "save",
			fileName: "tasks",
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/accountsapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result", result);

							this.setState({
								...result,
								loading: false,
							});
						})
						.catch((error) =>
							this.setState({
								loading: false,
							}),
						);
				} else {
					this.setState({
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};
}
