export const APP_COLOURS = {
	PRIMARY: "#080808",
	BG2: "#0B0C0E",
	BG3: "#111316",
	BG4: "#151A30",

	SECONDARY: "#748969",
	BACKGROUND: "#F8FAFF",

	TEXTCOLOR: "#141D31",
	PLACEHOLDER: "#797979",
	TEXTINPUT: "#0720D5",
	BUTTON2: "#252B35",
	DESELECTEDCOLOR: "#EEF2FC",
	OFFWHITE: "#DBE7FF",
	WHITE: "#FFF",
	GREEN: "#07D55D",
	ORANGE: "#E0A749",
	YELLOW: "#FFD76E",
	RED: "#E35B5B",
	BLUE: "#0676ED",
	WHITE_TRANSPARENT: "rgba(212, 212, 212, 0.2)",
	WHITE_TRANSPARENT2: "rgba(25, 29, 36, 0.4)",
	WHITE_TRANSPARENT3: "#323A47",

	BLACK_TRANSPARENT2: "rgba(0, 0, 0, 0.15)",
};
