import React, { Component } from 'react';
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from 'react-native';
import Slide from 'react-reveal/Slide';
import moment from 'moment';
// import add_btn from "./../../assets/images/add_btn.png";
import { APP_COLOURS } from '../../APP_VARS';

function isEven(n) {
	return n % 2 == 0;
}

class Accounts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenWidth: this.props.screenWidth,
			screenHeight: this.props.screenHeight,
			selectedEntity: this.props.title,
			selectedEntityID: -1,
			showAddItemsForm: false,
		};
	}

	addItem = () => {
		let obj = {
			id: 1,
			allocation: this.state.selectedCompanyID,

			title: 'Wes',
			amount: 2040,
			type: 'income',
			currency: 'R',
			date: moment().format('YYYY-MM-DD HH:mm:ss'),
			vat: 123,
		};

		this.setState({
			ledger: this.props.data.concat(obj),
		});
	};

	componentDidMount() {
		window.addEventListener('resize', this.resize.bind(this));
		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	showAddItemsFormFunc = (event) => {
		event.preventDefault();
		const layout = event.nativeEvent;
		let x = layout.clientX;
		let y = layout.clientY;
		this.setState({
			showAddItemsForm: true,
			xPos: x,
			yPos: y,
		});

		console.log(layout);
	};

	selectEntity = (d) => {
		this.props.selectedEntity(d);
		this.setState({
			selectedEntity: d.title,
			selectedEntityID: d.id,
			showAddItemsForm: false,
		});
	};

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		window.removeEventListener('resize', this.resize.bind(this));
	}

	render() {
		return (
			<View
				style={
					{
						// width: 100,
						// height: 50,
					}
				}>
				<TouchableOpacity
					style={{
						alignItems: 'center',
						justifyContent: 'center',
					}}
					onPress={(e) => this.showAddItemsFormFunc(e)}>
					<Text
						style={{
							color:
								this.state.selectedEntityID == -1
									? APP_COLOURS.RED
									: '#FFF',
						}}>
						{this.state.selectedEntity}
					</Text>
				</TouchableOpacity>

				<Modal
					transparent={true}
					visible={this.state.showAddItemsForm}
					animationType='fade'
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
					}}>
					<TouchableOpacity
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
						}}
						onPress={() =>
							this.setState({
								showAddItemsForm: false,
							})
						}>
						<View
							style={{
								flex: 1,
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: 'rgba(52, 52, 52, 0.6)',
								width: this.state.screenWidth,
								height: this.state.screenHeight,
							}}></View>
					</TouchableOpacity>

					<View
						style={{
							width: 200,
							maxHeight: 250,
							backgroundColor: APP_COLOURS.PRIMARY,
							position: 'absolute',
							// top: this.state.yPos,
							bottom: 50,
							left:
								this.state.xPos + 200 > this.state.screenWidth
									? this.state.xPos - 200
									: this.state.xPos,

							shadowColor: '#000',
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.25,
							shadowRadius: 7.84,
							elevation: 12,
							borderRadius: 10,
							padding: 10,
							zIndex: 999,
						}}>
						<ScrollView>
							{this.props.data.map((d, i) => (
								<TouchableOpacity
									onPress={() => this.selectEntity(d)}>
									<View
										style={{
											borderBottomColor:
												'rgba(255, 255, 255, 0.3)',
											borderBottomWidth: 0.5,
											padding: 8,
											// marginBottom: 8,
											borderRadius: 5,
											// alignItems: "center",
											backgroundColor:
												this.state.selectedEntityID ==
												d.id
													? APP_COLOURS.BLUE
													: 'transparent',
										}}>
										<Text
											style={{
												color: '#FFF',
												fontWeight:
													this.state
														.selectedEntityID ==
													d.id
														? '600'
														: '200',
												fontFamily: 'Avenir',
											}}>
											{d.title}
										</Text>
									</View>
								</TouchableOpacity>
							))}
						</ScrollView>
					</View>
				</Modal>
			</View>
		);
	}
}

export default Accounts;
