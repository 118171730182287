import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import moment from "moment";

function padLeadingZeros(num, size) {
	var s = num + "";
	while (s.length < size) s = "0" + s;
	return s;
}

function ordinal_suffix_of(i) {
	var j = i % 10,
		k = i % 100;
	if (j == 1 && k != 11) {
		return i + "st";
	}
	if (j == 2 && k != 12) {
		return i + "nd";
	}
	if (j == 3 && k != 13) {
		return i + "rd";
	}
	return i + "th";
}

function getISOWeekDates2(isoWeekNum = 1, year = new Date().getFullYear()) {
	let d = moment(
		String(year).padStart(4, "0") +
			"W" +
			String(isoWeekNum).padStart(2, "0"),
	);
	for (var dates = [], i = 0; i < 7; i++) {
		dates.push(d.format("DD/MM/YYYY"));
		d.add(1, "day");
	}
	return dates;
}

export default class CalendarPicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDates: false,

			selectedDate: this.props.selectedDate,
			currentMonthInView: moment(this.props.selectedDate, "DD/MM/YYYY"),
			currentMomnth: moment(),
			dates: Array.from(
				Array(
					moment(this.props.selectedDate, "DD/MM/YYYY").daysInMonth(),
				).keys(),
			),
			weekStart:
				moment(
					moment(this.props.selectedDate, "DD/MM/YYYY").startOf(
						"month",
					),
				).format("w") - 1,
			weekEnd:
				moment(
					moment(this.props.selectedDate, "DD/MM/YYYY").endOf(
						"month",
					),
				).format("w") + 1,
			totalWeeks:
				moment(
					moment(this.props.selectedDate, "DD/MM/YYYY").endOf(
						"month",
					),
				).format("w") -
				moment(
					moment(this.props.selectedDate, "DD/MM/YYYY").startOf(
						"month",
					),
				).format("w") +
				1,

			datesArray: [],
			layoutWidth: 300,
			dayOfWeekArray: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		};
	}

	componentDidMount() {
		this.sideCalc();
	}

	changeMonth = async (change) => {
		let newMonth = "";
		if (change === "next") {
			newMonth = moment(this.state.currentMonthInView).add(1, "month");
		} else {
			newMonth = moment(this.state.currentMonthInView).subtract(
				1,
				"month",
			);
		}
		// let dArr = Array.from(Array(moment(newMonth).daysInMonth()).keys());
		// await this.setState({
		// 	currentMonthInView: newMonth,
		// 	currentMomnth: moment(),
		// 	dates: dArr,
		// 	weekStart:
		// 		moment(moment(newMonth).startOf("month")).format("w") - 1,
		// 	weekEnd: moment(moment(newMonth).endOf("month")).format("w") + 1,
		// 	totalWeeks:
		// 		moment(moment(newMonth).endOf("month")).format("w") -
		// 		moment(moment(newMonth).startOf("month")).format("w") +
		// 		1,
		// });

		// await console.log(
		// 	moment(moment(this.state.currentMonthInView).endOf("month")).format(
		// 		"w",
		// 	),
		// );

		await this.setState({
			currentMonthInView: newMonth,
		});

		this.sideCalc();
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					// margin: 10,
					padding: 12,
					borderRadius: 10,
					borderWidth: 0.3,
					borderColor: "#000",
					backgroundColor: this.state.showOptions
						? "#E8E8E8"
						: "#FFF",
				}}>
				<TouchableOpacity
					onPress={() =>
						this.setState({ showOptions: !this.state.showOptions })
					}>
					<View
						style={{
							flexDirection: "row",

							borderRadius: 12,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Text
							style={{
								fontWeight: "600",
							}}>
							{moment(
								this.state.selectedDate,
								"DD/MM/YYYY",
							).format("ddd DD MMM, YYYY")}
						</Text>
					</View>
				</TouchableOpacity>
				<View
					onLayout={(e) => {
						this.setState({
							layoutWidth: e.nativeEvent.layout.width,
						});
					}}
					style={{
						display: this.state.showOptions ? "flex" : "none",
						marginTop: 15,
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<TouchableOpacity
							onPress={() => this.changeMonth("prev")}>
							<View>
								<Text>Prev</Text>
							</View>
						</TouchableOpacity>
						<View>
							<Text>
								{moment(this.state.currentMonthInView).format(
									"MMMM YYYY",
								)}
							</Text>
						</View>
						<TouchableOpacity
							onPress={() => this.changeMonth("next")}>
							<View>
								<Text>Next</Text>
							</View>
						</TouchableOpacity>
					</View>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
						}}>
						{this.heading()}
						<View
							style={{
								width: "100%",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								{this.state.datesArray.map((d, i) => {
									let today = moment().format("DD/MM/YYYY");
									let layoutWidth =
										this.state.layoutWidth / 7;
									console.log(layoutWidth);
									return (
										<TouchableOpacity
											style={{
												width: layoutWidth,
												height: layoutWidth,
												padding: 2,
											}}
											onPress={() => {
												this.setState({
													selectedDate: d,
													// showOptions:
													// 	!this.state
													// 		.showOptions,
												});

												console.log(d);
												this.props.setDate(d);
											}}>
											<View
												key={i}
												style={{
													flex: 1,

													justifyContent: "center",
													alignItems: "center",
													borderRadius: 8,
													borderWidth: 0.3,

													textAlign:
														this.props.textAlign,
													backgroundColor:
														this.state
															.selectedDate === d
															? "#141D31"
															: "#FFF",
													borderColor:
														today === d
															? "red"
															: "#E8E8E8",
													opacity:
														moment(
															d,
															"DD/MM/YYYY",
														).format("MM") ===
														moment(
															this.state
																.currentMonthInView,
														).format("MM")
															? moment(
																	d,
																	"DD/MM/YYYY",
															  ).format(
																	"ddd",
															  ) === "Sun"
																? 0.5
																: moment(
																		d,
																		"DD/MM/YYYY",
																  ).format(
																		"ddd",
																  ) === "Sat"
																? 0.5
																: 1
															: 0.3,
												}}>
												<Text
													style={{
														color:
															this.state
																.selectedDate ===
															d
																? "#FFF"
																: "#141D31",
														fontWeight: "300",
														fontSize: 13,
													}}>
													{moment(
														d,
														"DD/MM/YYYY",
													).format("DD")}
												</Text>
											</View>
										</TouchableOpacity>
									);
								})}
							</View>
						</View>
					</View>
				</View>
				<Text
					allowFontScaling={false}
					style={{
						fontSize: 10,
						fontFamily: "Avenir Next",
						margin: 5,
						paddingLeft: 10,
						paddingRight: 10,
						fontWeight: "400",
						backgroundColor: "#FFF",
						display: this.state.showOptions ? "none" : "flex",
						position: "absolute",
						top: -13,
						left: 5,
						borderRadius: 5,
					}}>
					{this.props.title}
				</Text>
			</View>
		);
	}

	heading = () => {
		let layoutWidth = this.state.layoutWidth / 7;
		console.log(layoutWidth);
		return (
			<View
				style={{
					flex: 1,
					marginTop: 6,
					flexDirection: "row",
					justifyContent: "center",
				}}>
				{this.state.dayOfWeekArray.map((d, i) => {
					return (
						<View
							key={i}
							style={{
								width: layoutWidth,
								height: 37,
								margin: 2,
								justifyContent: "center",
								alignItems: "center",
								borderRadius: 8,
								// backgroundColor: "#FFF",
								// borderWidth: 0.3,
								// borderColor: "#E8E8E8",
							}}>
							<Text
								style={{
									color: "#000",
									fontSize: 12,
								}}>
								{/* {ordinal_suffix_of(day)} */}
								{d}
							</Text>
						</View>
					);
				})}
			</View>
		);
	};

	sideCalc = () => {
		// currentMonthInView: moment(this.props.selectedDate, "DD/MM/YYYY"),

		let datesArray = [];
		let curr = this.state.currentMonthInView;
		// let curr = moment("02/01/2023", "DD/MM/YYYY");
		console.log("curr", curr);
		// console.log(
		// 	this.state.totalWeeks,
		// 	Array.from(Array(this.state.totalWeeks).keys()).length,
		// 	"weeks",
		// );
		let startDateInMonth = moment(curr).startOf("month");
		let startDayInMonth = moment(curr).startOf("month").format("ddd");
		console.log("startDayInMonth", startDayInMonth);
		//
		//
		// Calculate the day of the week that is the start day of the mont
		// the number of days offset before the start
		let dayOfWeekArray = this.state.dayOfWeekArray;
		// let daysBeforeStartOfMonth = dayOfWeekArray.slice(
		// 	0,
		// 	dayOfWeekArray.indexOf(startDayInMonth),
		// );
		let arr = dayOfWeekArray.indexOf(startDayInMonth);
		console.log(arr);
		if (arr > 0) {
			let dLoop = Array.from(Array(arr).keys());
			for (const d of dLoop) {
				let doff = d + 1;
				let date = moment(startDateInMonth)
					.subtract(doff, "days")
					.format("DD/MM/YYYY");
				datesArray.push(date);
			}
		}

		//
		//
		//

		let dayesInMonth = Array.from(Array(moment(curr).daysInMonth()).keys());

		for (const d of dayesInMonth) {
			let date = moment(startDateInMonth)
				.add(d, "day")
				.format("DD/MM/YYYY");
			datesArray.push(date);
		}

		let datesSorted = datesArray.sort(
			(a, b) =>
				moment(a, "DD/MM/YYYY").unix() - moment(b, "DD/MM/YYYY").unix(),
		);
		console.log("datesArray", datesSorted);

		this.setState({
			datesArray: datesSorted,
		});
	};
}
