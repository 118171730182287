import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ActivityIndicator,
	ScrollView,
	Modal,
} from "react-native";
// import Font from "react-font";
import sentTicks from "./assets/images/sentMessage.svg";
import send from "./../../../assets/images/send.png";

import { APP_COLOURS } from "../../APP_VARS";

import moment from "moment";

function uuid() {
	return "xxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16).toUpperCase();
	});
}

function randomString(len, charSet) {
	charSet = charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	var randomString = "";
	for (var i = 0; i < len; i++) {
		var randomPoz = Math.floor(Math.random() * charSet.length);
		randomString += charSet.substring(randomPoz, randomPoz + 1);
	}
	return randomString;
}

class Chat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chatMessages: [
				{
					text: "Initial chat data",
					sender: "1234",
				},
			],

			secondsCountdown: 10,
			sentMessageData: "",
		};

		this.inputRef = React.createRef();
	}

	async componentDidMount() {
		await clearInterval(this.interval);
		this.interval = setInterval(
			() =>
				this.setState({
					secondsCountdown: this.state.secondsCountdown - 1,
				}),
			1000,
		);

		console.log("mounted");
	}

	triggerTimer = async () => {
		await clearInterval(this.interval);
		await this.setState({
			secondsCountdown: 10,
		});

		this.interval = await setInterval(
			() =>
				this.setState({
					secondsCountdown: this.state.secondsCountdown - 1,
				}),
			1000,
		);
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevState.secondsCountdown == 1) {
			await console.log("fetch messages");
			await this.setState({
				secondsCountdown: 0,
			});
			await clearInterval(this.interval);
			await this.props.getchatMessages();
		}
		console.log(this.state.secondsCountdown);
	}

	async componentWillUnmount() {
		await console.log("unmounted");
		await clearInterval(this.interval);
	}

	render() {
		let d = this.props.d;
		return (
			<View
				style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
					// backgroundColor: "red",
					// width: this.props.screenWidth,

					width: "100%",
				}}>
				<View
					style={{
						flex: 1,
						width: "100%",
						// backgroundColor: "#EFEDE9",
						backgroundColor: APP_COLOURS.BG3,
					}}>
					<View
						style={{
							flex: 1,
							borderTopColor: "#E8E8E8",
							borderTopWidth: 0.5,
						}}>
						<ScrollView
							ref={(ref) => {
								this.scrollViewBookContent = ref;
							}}
							onContentSizeChange={() =>
								this.scrollViewBookContent.scrollToEnd({
									animated: true,
								})
							}
							contentContainerStyle={{
								paddingRight: 5,
								paddingTop: 10,
							}}>
							<View
								style={{
									flex: 1,
									backgroundColor: "#FFF",
									borderRadius: 10,
									padding: 10,
									marginBottom: 20,
									// flexDirection: "row",
									borderRadius: 12,
									// opacity: 0.7,
									margin: 5,
								}}>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 15,
										marginTop: 5,
									}}>
									{this.props.description}
								</Text>

								<View
									style={{
										marginTop: 5,
										opacity: 0.7,
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 9,
											fontWeight: "500",
											color: APP_COLOURS.BG2,
										}}>
										{moment(d.date_created).format(
											"HH:mm DD/MM/YYYY",
										)}
									</Text>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 10,
											fontWeight: "400",
											fontStyle: "italic",
											color: APP_COLOURS.BG2,
										}}>
										added by {d.addedBy.first_name}...
									</Text>
								</View>
							</View>

							{this.props.chatMessages
								.sort(
									(a, b) =>
										moment(a.date_added).unix() >
										moment(b.date_added).unix(),
								)
								.map((chat, index) => (
									<View
										key={index}
										style={{
											// flex: 1,
											width: "100%",
											height: "auto",
											marginBottom: 3,
											flexDirection: "row",
											// backgroundColor: "rgba(212,212,212, 0.05)",
											borderRadius: 15,
											paddingLeft: 10,
											paddingRight: 10,

											justifyContent:
												chat.user.id !==
												this.props.user_data.id
													? "flex-start"
													: "flex-end",
										}}>
										<View
											style={{
												// flex: 1,
												// width:
												maxWidth: "80%",
												height: "auto",
												backgroundColor:
													chat.user.id !==
													this.props.user_data.id
														? "#FFF"
														: "#D9FECF",
												borderRadius: 15,
												borderBottomRightRadius:
													chat.user.id !==
													this.props.user_data.id
														? 15
														: 0,
												borderTopLeftRadius:
													chat.user.id !==
													this.props.user_data.id
														? 0
														: 15,

												padding: 10,

												shadowColor: "#797979",
												shadowOffset: {
													width: 0,
													height: 8,
												},
												shadowOpacity: 0.1,
												shadowRadius: 10.14,
												elevation: 1,
											}}>
											<View
												style={{
													flexDirection: "row",
													alignItems: "center",
													width: "100%",
													justifyContent:
														"space-between",
												}}>
												<Text
													style={{
														color: APP_COLOURS.BUTTON2,
														fontSize: 13,
														marginRight: 15,
														fontWeight: "700",
														display:
															chat.user.id !==
															this.props.user_data
																.id
																? "flex"
																: "none",
													}}>
													{chat.user.first_name}
												</Text>
											</View>
											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontSize: 15,
													// opacity: 0.7,
													marginTop: 5,
												}}>
												{chat.message}
											</Text>

											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontSize: 8,
													opacity: 0.5,
													alignSelf: "flex-end",
													marginTop: 8,
												}}>
												(
												{moment(
													chat.date_added,
												).fromNow()}
												){" "}
												{moment(chat.date_added).format(
													"HH:mm DD/MM/YY",
												)}
											</Text>
										</View>
										{/* <Image
											source={sentTicks}
											style={{
												width: 13 * (34 / 25),
												height: 13,
												resizeMode: "contain",
												// position: "absolute",
												right: 0,
												bottom: -10,
												marginBottom: 20,
												display:
													chat.user.id === this.props.userid
														? "flex"
														: "none",
											}}
										/> */}
									</View>
								))}
							{this.props.sendingMessage ? (
								<View
									style={{
										// flex: 1,
										width: "100%",
										height: "auto",
										marginBottom: 3,
										flexDirection: "row",
										// backgroundColor: "rgba(212,212,212, 0.05)",
										borderRadius: 15,
										paddingLeft: 10,
										paddingRight: 10,

										justifyContent: "flex-end",
										alignItems: "center",
									}}>
									<View
										style={{
											// flex: 1,
											// width:
											maxWidth: "80%",
											height: "auto",
											backgroundColor: "#D9FECF",
											borderRadius: 15,

											padding: 10,

											shadowColor: "#797979",
											shadowOffset: {
												width: 0,
												height: 8,
											},
											shadowOpacity: 0.1,
											shadowRadius: 10.14,
											elevation: 1,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												fontSize: 15,
												// opacity: 0.7,
												marginTop: 5,
											}}>
											{this.state.sentMessageData}
										</Text>
									</View>
									<View
										style={{
											// width: 12,
											// height: 12,
											// position: "absolute",
											bottom: -10,
											// right: 10,
											margin: 3,
										}}>
										<ActivityIndicator
											color={"#FFF"}
											size='small'
										/>
									</View>
								</View>
							) : null}
							<View
								style={{
									height: 50,
								}}
							/>
						</ScrollView>

						<View
							style={{
								flexDirection: "row",
								width: "100%",
								alignItems: "center",
								borderTopColor: "#797979",
								borderTopWidth: 0.3,
								paddingTop: 5,
								backgroundColor: "#FFF",
								padding: 10,
							}}>
							<TextInput
								ref={(ref) => (this.inputRef = ref)}
								style={{
									width: "85%",
									padding: 10,
									fontSize: 16,
									borderRadius: 9,
									color: APP_COLOURS.BG2,
									fontWeight: "200",
									fontFamily: "Avenir",

									borderWidth: 0.3,
									borderColor: APP_COLOURS.BG2,
									backgroundColor: "rgba(0,0,0, 0.05)",
								}}
								placeholderTextColor={"#797979"}
								multiline
								numberOfLines={3}
								autoCapitalize='sentences'
								clearButtonMode='while-editing'
								autoCorrect={true}
								keyboardType='default'
								placeholder={`  Type message `}
								onChangeText={(text) => {
									this.setState({
										noteData: text,
									});
								}}
							/>
							<TouchableOpacity
								style={{
									width: "14%",
									alignItems: "center",
									justifyContent: "center",
									backgroundColor: APP_COLOURS.BG3,
									borderRadius: 10,
									padding: 10,
									marginLeft: "1%",
								}}
								onPress={async () => {
									await clearInterval(this.interval);
									await this.setState({
										sentMessageData: this.inputRef.value,
										sendingMessage: true,
									});

									await this.props.sendMessage({
										chat_id: this.props.chat_id,
										image: "",
										message: this.inputRef.value,
										date_added: moment().toISOString(),
										user: {
											id: this.props.user_data.id,
											first_name:
												this.props.user_data.first_name,
											last_name:
												this.props.user_data.last_name,
											avatar: this.props.user_data.avatar,
											permission:
												this.props.user_data.permision,
										},
									});

									this.inputRef.clear();
								}}>
								<Image
									source={send}
									style={{
										width: 22,
										height: 22,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>
					</View>

					{/* <View
						style={{
							width: "100%",
							height: "auto",
							maxHeight: 100,
							marginRight: "auto",
							marginLeft: "auto",
							alignItems: "center",
							maxWidth: "100%",
							position: "absolute",
							bottom: 0,
							flexDirection: "row",
							borderRadius: 15,
							borderWidth: 0.5,
							borderColor: "#E8E8E8",
							padding: 5,
							// backgroundColor: "rgba(255, 255, 255, 0.8)",
							backgroundColor: "red",
						}}>
						<TextInput
							ref={(ref) => (this.inputRef = ref)}
							style={{
								flex: 1,
								paddingLeft: 10,
								paddingTop: 10,
								marginBottom: 5,
								marginTop: 0,

								fontSize: 12,
								color: APP_COLOURS.PRIMARY,
								minHeight: 50,

								marginRight: 10,
							}}
							multiline
							numberOfLines={1}
							placeholderTextColor={"#797979"}
							clearButtonMode='while-editing'
							autoCorrect={true}
							placeholder={`Type something`}
							// onChangeText={(text) => {
							// 	this.setState({
							// 		textData: text,
							// 	});
							// }}
						/>

						<TouchableOpacity
							onPress={async () => {
								await clearInterval(this.interval);
								await this.setState({
									sentMessageData: this.inputRef.value,
									sendingMessage: true,
								});

								await this.props.sendMessage({
									chat_id: this.props.chat_id,
									image: "",
									message: this.inputRef.value,
									date_added: moment().toISOString(),
									user: {
										id: this.props.user_data.id,
										first_name: this.props.user_data.first_name,
										last_name: this.props.user_data.last_name,
										avatar: this.props.user_data.avatar,
										permission: this.props.user_data.permision,
									},
								});

								this.inputRef.clear();
							}}>
							<View
								style={{
									width: 40,
									height: "auto",
									justifyContent: "center",
									alignItems: "center",
									opacity: 0.9,
								}}>
								<Image
									source={send}
									style={{
										width: 40,
										height: 40,
										resizeMode: "contain",
									}}
								/>
							</View>
						</TouchableOpacity>
					</View> */}
				</View>
			</View>
		);
	}
}

export default Chat;
