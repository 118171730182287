import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import close from "./../../../assets/images/close.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "../Calendar";
import "./field.css";
import moment from "moment";

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

class ModalAddTask extends Component {
	constructor(props) {
		super(props);
		this.state = {
			taskAssignment: [],

			doc_date: new Date(),

			dueDate: new Date(),
		};
	}

	render() {
		let morning = new Date();
		morning.setHours(0, 0, 1, 0);
		let evening = new Date();
		evening.setHours(23, 59, 59, 0);
		let avatars = {
			1: require("./../../../assets/images/male1.png"),
			2: require("./../../../assets/images/male2.png"),
			3: require("./../../../assets/images/female1.png"),
		};
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(21, 21, 21, 0.8)",
					}}>
					<ScrollView>
						<View
							style={{
								width: this.props.screenWidth,
								maxWidth: 500,
								height: "auto",
								backgroundColor: "white",
								borderRadius: 20,
								justifyContent: "center",
								alignItems: "center",
								padding: 10,
								marginTop: 30,
								marginBottom: 30,
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: 10,
									left: 10,
								}}>
								<TouchableOpacity
									onPress={() => this.props.close(false)}>
									<Image
										source={close}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									width: "100%",
									padding: 20,
									height: "100%",
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 15,
										fontFamily: "Avenir Next",
										margin: 5,
										paddingLeft: 10,
										fontWeight: "700",
										marginBottom: 15,
									}}>
									Add new task
								</Text>

								<View
									style={{
										height: "auto",
										width: "100%",
										marginBottom: 10,
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 13,
											fontFamily: "Avenir Next",
											margin: 5,
											paddingLeft: 10,
											fontWeight: "400",
										}}>
										Add person
									</Text>
									<View
										style={{
											width: "100%",
											// height: 50,
											// alignItems: "center",
											justifyContent: "center",
											paddingLeft: 20,
											borderTopColor: "#797979",
											borderBottomColor: "#797979",
											borderTopWidth: 0.5,
											borderBottomWidth: 0.5,
										}}>
										{this.props.users.map((user) => {
											let assigned =
												this.state.taskAssignment.filter(
													(dd) => dd.id == user.id,
												);
											return (
												<TouchableOpacity
													onPress={async () => {
														let taskAss =
															this.state.taskAssignment.filter(
																(ds) => ds.id !== user.id,
															);

														if (assigned.length > 0) {
															this.setState({
																taskAssignment: taskAss,
															});
														} else {
															this.setState({
																taskAssignment:
																	taskAss.concat(user),
															});
														}
													}}
													style={{
														flexDirection: "row",
														justifyContent: "flex-start",
														alignItems: "center",
														marginBottom: 2,
														width: 100,
														backgroundColor:
															assigned.length > 0
																? "#797979"
																: "transparent",
														borderRadius: 12,
													}}>
													<Image
														source={
															avatars[parseInt(user.avatar)]
														}
														style={{
															width: 25,
															height: 25,
															borderRadius: 25,
															resizeMode: "contain",
															backgroundColor: "#FFF",
															marginRight: 5,
														}}
													/>
													<View style={{}}>
														<Text
															style={{
																fontFamily: "Avenir",
																fontSize: 15,
																fontWeight: "600",
																color:
																	assigned.length > 0
																		? "#FFF"
																		: APP_COLOURS.BG2,
															}}>
															{user.first_name}
														</Text>
													</View>
												</TouchableOpacity>
											);
										})}
									</View>
								</View>

								<View
									style={{
										height: "auto",
										width: "100%",
										marginBottom: 25,
										marginTop: 20,
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 10,
											fontFamily: "Avenir Next",
											margin: 5,
											paddingLeft: 10,
											paddingRight: 10,
											fontWeight: "400",
											backgroundColor: "#FFF",
											position: "absolute",
											top: -13,
											left: 5,
										}}>
										Task title
									</Text>
									<TextInput
										style={{
											width: "100%",
											padding: 13,
											fontSize: 16,
											borderRadius: 9,
											color: APP_COLOURS.BG2,
											fontWeight: "700",
											fontFamily: "Avenir",
											borderWidth: 0.3,
											borderColor: APP_COLOURS.BG2,
										}}
										placeholderTextColor={"#797979"}
										multiline
										autoFocus
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={false}
										keyboardType='default'
										textContentType='none'
										placeholder={`Task title `}
										onChangeText={(text) => {
											let t = capitalizeName(text);
											this.setState({
												taskName: t,
											});
										}}
									/>
								</View>

								<View
									style={{
										height: "auto",
										width: "100%",
										marginBottom: 25,
										// marginTop: 20,
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 10,
											fontFamily: "Avenir Next",
											margin: 5,
											paddingLeft: 10,
											paddingRight: 10,
											fontWeight: "400",
											backgroundColor: "#FFF",
											position: "absolute",
											top: -13,
											left: 5,
										}}>
										Description
									</Text>
									<TextInput
										style={{
											width: "100%",
											padding: 13,
											fontSize: 16,
											borderRadius: 9,
											color: APP_COLOURS.BG2,
											fontWeight: "200",
											fontFamily: "Avenir",
											borderWidth: 0.3,
											borderColor: APP_COLOURS.BG2,
										}}
										placeholderTextColor={"#797979"}
										multiline
										numberOfLines={7}
										autoFocus
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={false}
										keyboardType='default'
										textContentType='none'
										placeholder={`Description `}
										onChangeText={(text) => {
											let t = capitalizeName(text);
											this.setState({
												taskDescription: t,
											});
										}}
									/>
								</View>

								<View
									style={{
										height: "auto",
										width: "100%",
										marginBottom: 25,
										zIndex: 100,
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											fontSize: 16,
											borderRadius: 9,
											color: APP_COLOURS.BG2,
											fontWeight: "200",
											fontFamily: "Avenir",
											borderWidth: 0.3,
											borderColor: APP_COLOURS.BG2,
											height: 50,
										}}>
										<DatePicker
											selected={
												this.state.dueDate == ""
													? evening
													: this.state.dueDate
											}
											onChange={(date) =>
												this.setState({
													dueDate: date,
												})
											}
										/>
										<Text
											allowFontScaling={false}
											style={{
												fontSize: 16,
												fontFamily: "Avenir Next",

												paddingLeft: 10,
												paddingRight: 10,
												fontWeight: "400",
												backgroundColor: "#FFF",
												position: "absolute",
												top: 10,
												left: 5,
											}}>
											{moment(this.state.dueDate).format(
												"DD/MM/YYYY",
											)}
										</Text>

										<Calendar
											selectedDate={moment().format("DD/MM/YYYY")}
										/>
									</View>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 10,
											fontFamily: "Avenir Next",
											margin: 5,
											paddingLeft: 10,
											paddingRight: 10,
											fontWeight: "400",
											backgroundColor: "#FFF",
											position: "absolute",
											top: -13,
											left: 5,
										}}>
										Due date{" "}
										{moment(this.state.dueDate).format("DD/MM/YYYY")}
									</Text>
								</View>

								<TouchableOpacity
									style={{
										zIndex: 99,
									}}
									onPress={() =>
										this.props.addNewTask(
											this.state.taskName,
											this.state.taskAssignment,
											this.state.dueDate,
										)
									}>
									<View
										style={{
											width: "100%",
											backgroundColor: APP_COLOURS.GREEN,
											padding: 9,
											justifyContent: "center",
											alignItems: "center",
											borderRadius: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.WHITE,
												fontSize: 11,
											}}>
											+ Add task
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	}
}

export default ModalAddTask;
