import React, { Component } from "react";
// import { Navigate } from "react-router-dom";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
// import Lottie from "react-lottie";
import moment from "moment";
import Fade from "react-reveal/Fade";
// import Font from 'react-font';

import ExportToExcel from "./ExportToExcel";

import { APP_COLOURS } from "../../APP_VARS";
// import * as animationData from '../../../assets/lottie/lf30_editor_t3bgbljb.json';
// import * as animationData2 from '../../../assets/lottie/lf20_q8buwK.json';

import ModalEntities from "./ModalEntities";

import bin from "../../../assets/images/bin.png";
// import male1 from './../../assets/images/male1.png';
// import close from './../../assets/images/close.png';
// import settings from './../../assets/images/settings.png';
import note from "../../../assets/images/note.png";
// import send from './../../assets/images/send.png';
import debbie from "../../../assets/images/cloud.png";
import add from "../../../assets/images/add_btn.png";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class Spreadsheet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: -1,
			tasks: [
				// {
				// 	id: 1,
				// 	title: "Feature 1",
				// 	status: "inprogress",
				// },
				// {
				// 	id: 2,
				// 	title: "Feature 2",
				// 	status: "inprogress",
				// },
			],

			categories: [
				{
					id: 1,
					title: "Accounting",
				},
				{
					id: 2,
					title: "Avex",
				},

				{
					id: 3,
					title: "SAMA",
				},
				{
					id: 4,
					title: "LOREAL/CRIS",
				},
				{
					id: 5,
					title: "AMKA",
				},
				{
					id: 6,
					title: "Other",
				},
				{
					id: 7,
					title: "Westonry",
				},
				{
					id: 8,
					title: "Personal",
				},
				{
					id: 9,
					title: "TRAQ",
				},
				{
					id: 10,
					title: "tetrice",
				},
				{
					id: 11,
					title: "Revlon",
				},
			],
			platforms: [
				{
					id: 1,
					title: "iOS only",
				},
				{
					id: 2,
					title: "Android only",
				},
				{
					id: 3,
					title: "iOS, Android",
				},

				{
					id: 5,
					title: "Admin Dashboard",
				},
				{
					id: 6,
					title: "Web",
				},
				{
					id: 4,
					title: "API",
				},
				{
					id: 7,
					title: "Database",
				},
			],

			loading: false,
			showDelete: true,
			selectedCategory: "",
			selectedPlatform: "",
			viewMore: "",
		};
	}

	componentDidMount() {
		this.getJsonDataFromServer();
	}

	addItem = async (text) => {
		// this.state.selectedPlatform === ""
		if (this.state.selectedCategory === "") {
			alert("select category/ selectedPlatform");
			return;
		}
		let obj = {
			id: uuidv4(),
			title: text,
			status: "pending",
			category: this.state.selectedCategory,
			// platform: this.state.selectedPlatform,
			priority: 10,
			sub_tasks: [
				// {
				// 	id: 1,
				// 	title: "First task",
				// 	complete: false,
				// },
			],
			description: "",
			notes: [],
		};
		await this.setState({
			tasks: this.state.tasks.concat(obj),
		});
		this.saveJsonDataToServer();
		this.textinput.clear();
	};

	addNewNote = async (d) => {
		// let uuid = uuidv4();
		// if (this.state.noteData == "") {
		// 	return;
		// }
		// let data = {
		// 	id: uuid,
		// 	task_id: this.props.data.id,
		// 	data: this.state.noteData,
		// 	date_added: moment().toLocaleString(),
		// 	user: this.props.user_data,
		// };
		// await this.setState({
		// 	noteData: "",
		// });
		// await console.log(this.props.user_data);
		// await this.textinput.clear();
		// await this.props.addNotes(data);
	};

	updateItem = async (obj, key, value) => {
		obj[key] = value;
		let filtered = this.state.tasks.filter((d) => d.id !== obj.id);
		await this.setState({
			tasks: filtered.concat(obj),
			descriptionData: "",
			viewMore: "",
		});
		this.saveJsonDataToServer();
	};

	removeItem = async (id) => {
		let filtered = this.state.tasks.filter((d) => d.id !== id);
		await this.setState({
			tasks: filtered,
		});
		this.saveJsonDataToServer();
	};

	render() {
		let col1 = 10,
			col2 = 200,
			col3 = 100,
			col4 = 100,
			col5 = 50,
			col6 = 100;

		let tasksArray = this.state.tasks
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterCategory !== "undefined" &&
					this.state.filterCategory !== ""
				) {
					let text_ = this.state.filterCategory;
					// var outString = text_.replace(
					// 	/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
					// 	"",
					// );

					let join_names = `${d_.title} ${d_.category} ${d_.platform}`;

					return join_names.toLowerCase().includes(text_.toLowerCase());
				}

				return filter;
			})
			.sort((a, b) => b.priority < a.priority);

		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",
					flex: 1,
					backgroundColor: APP_COLOURS.PRIMARY,
					padding: 5,
				}}>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						width: "100%",
					}}>
					<TouchableOpacity
						style={{
							flex: 1,
							height: "auto",
							maxHeight: 30,
							padding: 7,
							alignItems: "center",
							borderRadius: 10,
							minHeight: 45,
							marginBottom: 10,
							// backgroundColor:
							// 	APP_COLOURS.BUTTON2,
							flexDirection: "row",
						}}
						onPress={() => {
							this.saveJsonDataToServer();
						}}>
						<Image
							source={debbie}
							style={{
								width: 50,
								height: 32,
								// borderRadius: 20,
								resizeMode: "contain",
								marginRight: 10,
							}}
						/>

						<View
							style={{
								width: 30,
								height: 30,
								justifyContent: "center",
								alignItems: "center",
							}}>
							{this.state.loading ? (
								<ActivityIndicator color={APP_COLOURS.BLUE} />
							) : null}
						</View>

						{/* <View
							style={{
								padding: 5,
								borderRadius: 10,
								alignItems: "center",
							}}>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 16,
									// opacity: 0.8,
									color: APP_COLOURS.WHITE,
								}}>
								Project Deliverables
							</Text>
						</View> */}
					</TouchableOpacity>

					<TouchableOpacity
						style={{
							flex: 1,
							height: "auto",
							maxHeight: 30,
							padding: 7,
							alignItems: "center",
							borderRadius: 10,
							minHeight: 45,
							marginBottom: 10,
							// backgroundColor:
							// 	APP_COLOURS.BUTTON2,
							flexDirection: "row",
						}}
						onPress={() => {
							this.saveJsonDataToServer();
						}}>
						<ExportToExcel
							data={this.state.tasks}
							excel_doc_name={"milestones"}
						/>
					</TouchableOpacity>
				</View>
				<View
					style={{
						flexDirection: "row",
						width: "100%",
						alignItems: "center",
						borderTopColor: "#797979",
						borderTopWidth: 0.3,
						paddingTop: 5,
						marginBottom: 5,
					}}>
					<View
						style={{
							marginRight: 8,
						}}>
						<ModalEntities
							title={"App"}
							data={this.state.categories}
							selectedEntity={(d) => {
								this.setState({
									filterCategory: d.title,
								});
								this.filterCategory = d.title;
							}}
						/>
					</View>
					<TextInput
						ref={(ref) => {
							this.filterCategory = ref;
						}}
						style={{
							flex: 1,
							padding: 10,
							fontSize: 16,
							borderRadius: 9,
							color: APP_COLOURS.WHITE,
							fontWeight: "200",
							fontFamily: "Avenir",
							borderWidth: 0.3,
							borderColor: APP_COLOURS.WHITE_TRANSPARENT3,
							marginRight: 5,
						}}
						placeholderTextColor={"#797979"}
						multiline
						autoCapitalize='sentences'
						clearButtonMode='while-editing'
						autoCompleteType='off'
						editable={true}
						autoCorrect={false}
						keyboardType='default'
						textContentType='none'
						placeholder={`  Filter search `}
						onChangeText={(text) => {
							var outString = text.replace(
								/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
								"",
							);
							this.setState({
								filterCategory: outString,
							});
						}}
					/>
				</View>

				<View
					style={{
						height: this.props.screenHeight - 200,
					}}>
					<View
						style={{
							// minWidth: 800,
							flex: 1,
							// width: 800,
							// overflow: "scroll",
						}}>
						<ScrollView>
							{tasksArray.map((d, i) => (
								<View
									style={{
										// flex: 1,
										opacity:
											this.state.viewMore === ""
												? 1
												: this.state.viewMore === d.id
												? 1
												: 0.5,
									}}>
									<TouchableOpacity
										onPress={() =>
											this.setState({
												viewMore:
													this.state.viewMore === d.id ? "" : d.id,
											})
										}>
										<View
											key={i}
											onMouseEnter={() =>
												this.setState({
													hover: i,
												})
											}
											onMouseLeave={() =>
												this.setState({
													hover: -1,
												})
											}
											style={{
												flex: 1,
												opacity: this.state.hover === i ? 1 : 0.9,
												flexDirection: "row",
												backgroundColor: APP_COLOURS.BG2,
												borderBottomColor:
													APP_COLOURS.WHITE_TRANSPARENT3,
												borderBottomWidth: 0.5,
												alignItems: "center",
											}}>
											<View
												style={{
													width: col1,
													minHeight: 40,
													padding: 2,
													// opacity: 0.5,
													backgroundColor:
														d.status == "pending"
															? APP_COLOURS.WHITE_TRANSPARENT1
															: d.status == "Done"
															? APP_COLOURS.GREEN
															: d.status == "Stuck"
															? APP_COLOURS.RED
															: APP_COLOURS.ORANGE,
												}}></View>
											<View
												style={{
													flex: 1,
													padding: 2,
													flexDirection: "row",
												}}>
												<Text
													style={{
														color: "#FFF",
														opacity: 0.7,
														fontSize: 12,
													}}>
													{" "}
													{d.category} /{" "}
												</Text>
												<Text
													style={{
														color: "#FFF",
														fontSize: 12,
													}}>
													{d.title}
												</Text>
											</View>

											<View
												style={{
													width: 20,
													padding: 2,
													marginRight: 5,
												}}>
												{d.description !== "" ? (
													<Image
														source={note}
														style={{
															width: 20,
															height: 20,
															resizeMode: "contain",
														}}
													/>
												) : (
													<View />
												)}
											</View>

											{/* <View
													style={{
														width: col6,
														padding: 2,
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 9,
														}}>
														{d.platform}
													</Text>
												</View> */}
										</View>
									</TouchableOpacity>
									{this.state.viewMore === d.id ? (
										<View
											style={{
												width: "100%",
												height: "auto",
												marginBottom: 20,
											}}>
											<Fade>
												<View
													style={{
														// flex: 1,
														width: "100%",
														height: 300,
														flexDirection: "row",
														backgroundColor: APP_COLOURS.BG2,
														paddingTop: 10,
													}}>
													<View
														style={{
															flex: 1,
															padding: 5,
														}}>
														<Text
															style={{
																color: "#FFF",
																fontSize: 12,
																fontWeight: "700",
																marginBottom: 2,
															}}>
															Description for client
														</Text>
														<View
															style={{
																flex: 1,
																flexDirection: "row",
																width: "100%",
																// alignItems:
																// 	"center",
																borderTopColor: "#797979",
																borderTopWidth: 0.3,
																paddingTop: 5,
															}}>
															<TextInput
																style={{
																	flex: 1,
																	padding: 5,
																	fontSize: 16,
																	borderRadius: 9,
																	color: APP_COLOURS.WHITE,
																	fontWeight: "200",
																	fontFamily: "Avenir",
																	borderWidth: 0.3,
																	borderColor: APP_COLOURS.BG2,
																	marginBottom: 5,
																}}
																placeholderTextColor={"#797979"}
																multiline
																numberOfLines={5}
																autoCapitalize='sentences'
																clearButtonMode='while-editing'
																autoCompleteType='off'
																editable={true}
																autoCorrect={false}
																keyboardType='default'
																textContentType='none'
																placeholder={`Type something `}
																defaultValue={d.description}
																onChangeText={(text) => {
																	this.setState({
																		descriptionData: text,
																	});
																}}
															/>
														</View>
														<TouchableOpacity
															style={{
																height: 30,
																width: "100%",
															}}
															onPress={() =>
																this.updateItem(
																	d,
																	"description",
																	this.state.descriptionData,
																)
															}>
															<View
																style={{
																	flex: 1,
																	backgroundColor:
																		APP_COLOURS.BLUE,
																	borderRadius: 10,
																	justifyContent: "center",
																	alignItems: "center",
																}}>
																<Text
																	style={{
																		color: "#FFF",
																	}}>
																	Save desc.
																</Text>
															</View>
														</TouchableOpacity>
													</View>
													<View
														style={{
															flex: 1,
															maxWidth: 100,
															alignItems: "flex-end",
															padding: 5,
														}}>
														<Text
															style={{
																color: "#FFF",
																fontSize: 12,
																fontWeight: "700",
																marginBottom: 2,
															}}>
															Change Status
														</Text>
														<View
															style={{
																borderTopColor: "#797979",
																borderTopWidth: 0.3,
																width: "100%",
																marginBottom: 5,
															}}
														/>
														{[
															"pending",
															"Inprogress",
															"Done",
															"Paused",
															"Stuck",
														].map((status) => (
															<TouchableOpacity
																onPress={async () => {
																	let priority = 0;
																	if (
																		priority === "Inprogress"
																	) {
																		priority = 1;
																	}
																	if (priority === "Stuck") {
																		priority = 2;
																	}
																	if (priority === "pending") {
																		priority = 3;
																	}

																	if (priority === "pending") {
																		priority = 4;
																	}
																	if (priority === "Done") {
																		priority = 11;
																	}
																	//
																	//
																	//
																	await this.updateItem(
																		d,
																		"priority",
																		priority,
																	);

																	await this.updateItem(
																		d,
																		"status",
																		status,
																	);
																}}
																style={{
																	flexDirection: "row",
																	justifyContent: "flex-start",
																	alignItems: "center",
																	marginBottom: 2,
																	backgroundColor:
																		d.status == status
																			? APP_COLOURS.BLUE
																			: "transparent",
																	width: 100,
																	borderRadius: 10,
																}}>
																<View
																	style={{
																		padding: 10,
																	}}>
																	<Text
																		style={{
																			fontFamily: "Avenir",
																			fontSize: 12,
																			fontWeight: "600",
																			color: APP_COLOURS.WHITE,
																		}}>
																		{status}
																	</Text>
																</View>
															</TouchableOpacity>
														))}
														{this.state.showDelete ? (
															<TouchableOpacity
																onPress={() =>
																	this.removeItem(d.id)
																}
																style={{
																	flexDirection: "row",
																	width: 100,
																	// justifyContent: "center",
																	// width: 40,
																	// alignItems: "center",
																	padding: 5,
																	marginLeft: 5,
																}}>
																<Image
																	source={bin}
																	style={{
																		width: 22,
																		height: 22,
																		// borderRadius: 25,
																		resizeMode: "contain",
																		marginRight: 5,
																	}}
																/>
															</TouchableOpacity>
														) : null}
													</View>
												</View>
											</Fade>
										</View>
									) : (
										<View />
									)}
								</View>
							))}
						</ScrollView>
					</View>
					<View
						style={{
							flexDirection: "row",
							width: "100%",
							alignItems: "center",
							borderTopColor: "#797979",
							borderTopWidth: 0.3,
							paddingTop: 5,
						}}>
						<View
							style={{
								marginRight: 8,
							}}>
							<ModalEntities
								title={"Category"}
								data={this.state.categories}
								selectedEntity={(d) =>
									this.setState({
										selectedCategory: d.title,
										selectedCategoryID: d.id,
									})
								}
							/>
						</View>
						<TextInput
							ref={(ref) => {
								this.textinput = ref;
							}}
							style={{
								flex: 1,
								padding: 10,
								fontSize: 16,
								borderRadius: 9,
								color: APP_COLOURS.WHITE,
								fontWeight: "200",
								fontFamily: "Avenir",
								borderWidth: 0.3,
								borderColor: APP_COLOURS.BG2,
							}}
							placeholderTextColor={"#797979"}
							multiline
							autoCapitalize='sentences'
							clearButtonMode='while-editing'
							autoCompleteType='off'
							editable={true}
							autoCorrect={false}
							keyboardType='default'
							textContentType='none'
							placeholder={` Type something `}
							// onChangeText={(text) => {
							// 	this.setState({
							// 		textInputText: text,
							// 	});
							// }}
						/>
						{/* <View
							style={{
								marginRight: 8,
							}}>
							<ModalEntities
								title={"Platform"}
								data={this.state.platforms}
								selectedEntity={(d) =>
									this.setState({
										selectedPlatform: d.title,
										selectedPlaatformID: d.id,
									})
								}
							/>
						</View> */}
						<TouchableOpacity
							style={{
								width: 45,
								alignItems: "center",
								justifyContent: "center",
							}}
							onPress={() => this.addItem(this.textinput.value)}>
							<View>
								<Image
									source={add}
									style={{
										width: 22,
										height: 22,
										// borderRadius: 25,
										resizeMode: "contain",
										marginRight: 5,
									}}
								/>
							</View>
						</TouchableOpacity>
					</View>
				</View>
			</div>
		);
	}

	getJsonDataFromServer = () => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			command: "get",
			fileName: "tetrice_tasks",
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/tetrice_masterfileapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result", result);

							this.setState({
								tasks: result,
								loading: false,
							});
						})
						.catch((error) => this.setState({}));
				} else {
					this.setState({
						tasks: [],
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	saveJsonDataToServer = () => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			json_data: this.state.tasks,
			command: "save",
			fileName: "tetrice_tasks",
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/tetrice_masterfileapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result", result);

							this.setState({
								tasks: result,
								loading: false,
							});
						})
						.catch((error) =>
							this.setState({
								tasks: [],
							}),
						);
				} else {
					this.setState({
						tasks: [],
					});
				}
			})
			.catch((error) => console.log("error", error));
	};
}

export default Spreadsheet;
