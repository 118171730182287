import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
} from "react-native";
import moment from "moment";
// import bin from "../../assets/images/bin.png";
import { APP_COLOURS } from "../../APP_VARS";
import TaskItem from "./TaskItem";

export default class KanBan extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let cardWidth = this.props.screenWidth - 80;
		cardWidth = cardWidth > 350 ? 350 : cardWidth;
		return (
			<View
				style={{
					height: this.props.screenHeight - 150,
				}}>
				<ScrollView
					ref={(ref) => {
						this.scrollRef = ref;
					}}
					overScrollMode='never'
					horizontal
					showsHorizontalScrollIndicator={false}
					pagingEnabled={true}
					snapToAlignment='left'
					snapToInterval={cardWidth}
					decelerationRate='fast'
					scrollEventThrottle={160}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
						}}>
						<View
							style={{
								width: cardWidth,
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 10,
								// borderColor: "#797979",
								// borderStyle: "dotted",
								// borderWidth: 0.5,
								marginRight: 15,
								display:
									this.props.data.filter((d, i) => {
										let due = 1;
										const date1 = moment();
										const date2 = moment(d.dueDate);
										due = date2.diff(date1, "days");

										if (d.status === "Inprogress") {
											return d;
										} else {
											if (
												due <= 0 &&
												d.status !== "Done"
											) {
												return d;
											}
										}
									}).length > 0
										? "flex"
										: "none",
							}}>
							<View style={{ padding: 10 }}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										fontWeight: "300",
										color: "#FFF",
									}}>
									Urgent
								</Text>
							</View>
							<ScrollView>
								{this.props.data
									.filter((d, i) => {
										let due = 1;
										const date1 = moment();
										const date2 = moment(d.dueDate);
										due = date2.diff(date1, "days");

										if (d.status === "Inprogress") {
											return d;
										} else {
											if (
												due <= 0 &&
												d.status !== "Done"
											) {
												return d;
											}
										}
									})
									.map((task) => (
										<View
											key={task.id}
											style={{
												padding: 5,

												width: cardWidth,
												padding: 5,
											}}>
											<TaskItem
												selectedTask={
													this.props.selectedItem
												}
												accessToken={
													this.props.accessToken
												}
												screenHeight={
													this.props.screenHeight
												}
												screenWidth={
													this.props.screenWidth
												}
												data={task}
												notes={this.props.notes.filter(
													(dm) =>
														dm.task_id == task.id,
												)}
												addNotes={(data) =>
													this.addNotes(data)
												}
												addChangeStatus={(d) =>
													this.addChangeStatus(d)
												}
												updateItem={(d) => {
													this.updateItem(d);
												}}
												user_data={this.props.user_data}
												users={this.props.users}
												showDelete={
													this.props.showDelete
												}
												// deleteItem={(d) => this.deleteTask(d)}
												hover={this.props.hover}
												setHover={(d) =>
													this.setState({
														hover: d,
													})
												}
											/>
										</View>
									))}
							</ScrollView>
						</View>

						<View
							style={{
								width: cardWidth,
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 10,
								marginRight: 15,
							}}>
							<View style={{ padding: 10 }}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										fontWeight: "300",
										color: "#FFF",
									}}>
									Attention required
								</Text>
							</View>
							{this.props.data
								.filter((d, i) => {
									if (d.status === "Stuck") {
										return d;
									}
								})
								.map((task) => (
									<View
										key={task.id}
										style={{
											padding: 5,

											width: cardWidth,
											padding: 5,
										}}>
										<TaskItem
											selectedTask={
												this.props.selectedItem
											}
											accessToken={this.props.accessToken}
											screenHeight={
												this.props.screenHeight
											}
											screenWidth={this.props.screenWidth}
											data={task}
											notes={this.props.notes.filter(
												(dm) => dm.task_id == task.id,
											)}
											addNotes={(data) =>
												this.addNotes(data)
											}
											addChangeStatus={(d) =>
												this.addChangeStatus(d)
											}
											updateItem={(d) => {
												this.updateItem(d);
											}}
											user_data={this.props.user_data}
											users={this.props.users}
											showDelete={this.props.showDelete}
											// deleteItem={(d) => this.deleteTask(d)}
											hover={this.props.hover}
											setHover={(d) =>
												this.setState({
													hover: d,
												})
											}
										/>
									</View>
								))}

							<View style={{ padding: 10, marginTop: 25 }}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										fontWeight: "300",
										color: "#FFF",
									}}>
									Paused
								</Text>
							</View>
							{this.props.data
								.filter((d, i) => {
									if (d.status === "Paused") {
										return d;
									}
								})
								.map((task) => (
									<View
										key={task.id}
										style={{
											padding: 5,

											width: cardWidth,
											padding: 5,
										}}>
										<TaskItem
											selectedTask={
												this.props.selectedItem
											}
											accessToken={this.props.accessToken}
											screenHeight={
												this.props.screenHeight
											}
											screenWidth={this.props.screenWidth}
											data={task}
											notes={this.props.notes.filter(
												(dm) => dm.task_id == task.id,
											)}
											addNotes={(data) =>
												this.addNotes(data)
											}
											addChangeStatus={(d) =>
												this.addChangeStatus(d)
											}
											updateItem={(d) => {
												this.updateItem(d);
											}}
											user_data={this.props.user_data}
											users={this.props.users}
											showDelete={this.props.showDelete}
											// deleteItem={(d) => this.deleteTask(d)}
											hover={this.props.hover}
											setHover={(d) =>
												this.setState({
													hover: d,
												})
											}
										/>
									</View>
								))}
						</View>

						<View
							style={{
								width: cardWidth,
								// backgroundColor: APP_COLOURS.BG2,
								borderRadius: 10,
								borderColor: "#797979",
								borderStyle: "dotted",
								borderWidth: 0.5,
								marginRight: 15,
							}}>
							<View style={{ padding: 10 }}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										fontWeight: "300",
										color: "#FFF",
									}}>
									Inprogress
								</Text>
							</View>
							{this.props.data
								.filter((d, i) => {
									let due = 1;
									const date1 = moment();
									const date2 = moment(d.dueDate);
									due = date2.diff(date1, "days");
									if (d.status === "Inprogress" && due > 0) {
										return d;
									}
								})
								.map((task) => (
									<View
										key={task.id}
										style={{
											padding: 5,

											width: cardWidth,
											padding: 5,
										}}>
										<TaskItem
											selectedTask={
												this.props.selectedItem
											}
											accessToken={this.props.accessToken}
											screenHeight={
												this.props.screenHeight
											}
											screenWidth={this.props.screenWidth}
											data={task}
											notes={this.props.notes.filter(
												(dm) => dm.task_id == task.id,
											)}
											addNotes={(data) =>
												this.addNotes(data)
											}
											addChangeStatus={(d) =>
												this.addChangeStatus(d)
											}
											updateItem={(d) => {
												this.updateItem(d);
											}}
											user_data={this.props.user_data}
											users={this.props.users}
											showDelete={this.props.showDelete}
											// deleteItem={(d) => this.deleteTask(d)}
											hover={this.props.hover}
											setHover={(d) =>
												this.setState({
													hover: d,
												})
											}
										/>
									</View>
								))}
							{this.props.data.filter((d, i) => {
								let due = 1;
								const date1 = moment();
								const date2 = moment(d.dueDate);
								due = date2.diff(date1, "days");
								if (d.status === "Inprogress" && due <= 0) {
									return d;
								}
							}).length > 0 ? null : (
								<View
									style={{
										padding: 10,
										justifyContent: "center",
										alignItems: "center",
										minHeight: 200,
									}}>
									<Text
										style={{
											color: "#FFF",
											opacity: 0.8,
										}}>
										No tasks in progress
									</Text>
								</View>
							)}
						</View>

						<View
							style={{
								width: cardWidth,
								marginRight: 15,
							}}>
							<View style={{ padding: 10 }}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										fontWeight: "300",
										color: "#FFF",
									}}>
									To do
								</Text>
							</View>
							{this.props.data
								.filter((d, i) => {
									let due = 1;
									const date1 = moment();
									const date2 = moment(d.dueDate);
									due = date2.diff(date1, "days");
									if (due > 0) {
										if (
											d.status === "" ||
											d.status === "Pending"
										) {
											return d;
										}
									}
								})
								.map((task) => (
									<View
										key={task.id}
										style={{
											padding: 5,

											width: cardWidth,
											padding: 5,
										}}>
										<TaskItem
											selectedTask={
												this.props.selectedItem
											}
											accessToken={this.props.accessToken}
											screenHeight={
												this.props.screenHeight
											}
											screenWidth={this.props.screenWidth}
											data={task}
											notes={this.props.notes.filter(
												(dm) => dm.task_id == task.id,
											)}
											addNotes={(data) =>
												this.addNotes(data)
											}
											addChangeStatus={(d) =>
												this.addChangeStatus(d)
											}
											updateItem={(d) => {
												this.updateItem(d);
											}}
											user_data={this.props.user_data}
											users={this.props.users}
											showDelete={this.props.showDelete}
											// deleteItem={(d) => this.deleteTask(d)}
											hover={this.props.hover}
											setHover={(d) =>
												this.setState({
													hover: d,
												})
											}
										/>
									</View>
								))}
						</View>

						<View
							style={{
								width: cardWidth,
							}}>
							<View style={{ padding: 10 }}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										fontWeight: "300",
										color: "#FFF",
									}}>
									Completed
								</Text>
							</View>
							{this.props.data
								.filter((d, i) => {
									if (d.status === "Done") {
										return d;
									}
								})
								.map((task) => (
									<View
										key={task.id}
										style={{
											padding: 5,

											width: cardWidth,
											padding: 5,
										}}>
										<TaskItem
											selectedTask={
												this.props.selectedItem
											}
											accessToken={this.props.accessToken}
											screenHeight={
												this.props.screenHeight
											}
											screenWidth={this.props.screenWidth}
											data={task}
											notes={this.props.notes.filter(
												(dm) => dm.task_id == task.id,
											)}
											addNotes={(data) =>
												this.addNotes(data)
											}
											addChangeStatus={(d) =>
												this.addChangeStatus(d)
											}
											updateItem={(d) => {
												this.updateItem(d);
											}}
											user_data={this.props.user_data}
											users={this.props.users}
											showDelete={this.props.showDelete}
											// deleteItem={(d) => this.deleteTask(d)}
											hover={this.props.hover}
											setHover={(d) =>
												this.setState({
													hover: d,
												})
											}
										/>
									</View>
								))}
						</View>
					</View>
				</ScrollView>
			</View>
		);
	}
}
