import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	Image,
	Modal,
	TouchableOpacity,
	ActivityIndicator,
	Alert,
} from "react-native";
import moment from "moment";
import "./HTML-Sheets-of-Paper-gh-pages/css/sheets-of-paper-a4.css";
// import Share from "react-native-share";
// import ViewShot from "react-native-view-shot";
// import RNFS from "react-native-fs";
// import RNImageToPdf from "react-native-image-to-pdf";
// import { HtmlEmail } from "./HtmlEmail.js";

class A4Doc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageMargin: 29,
			loadingPdf: false,
			scale: 1,
			selectRep: false,
			selectedRep: "",
			viewshotOption: "",
		};
	}

	//
	///
	///
	//  PDF CREAT PROCESS
	//
	// screenShotPage = async () => {
	// 	this.setState({
	// 		loadingPdf: true,
	// 	});
	// 	this.refs.viewShot.capture().then((uri) => {
	// 		console.log("do something with ", uri);
	// 		// this.shareTo(uri);
	// 		// NBNBNB NB can add multiple imahges to a single PDF doc
	// 		this.pdfTo([uri]);
	// 	});
	// };

	// pdfTo = async (imagePathsArray) => {
	// 	try {
	// 		const options = {
	// 			imagePaths: imagePathsArray,
	// 			name: this.props.pdfName,
	//       };
	//       let pdf = ''
	// 		// const pdf = await RNImageToPdf.createPDFbyImages(options);

	// 		console.log(pdf.filePath);
	// 		console.log(pdf);

	// 		this.shareTo(pdf.filePath);
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// };

	// shareTo = (uri) => {
	// 	const shareOptions = {
	// 		title: "tetrice_quote",
	// 		url: uri,
	// 		failOnCancel: false,
	// 		filename: "tetrice_quote",
	// 	};

	// 	Share.open(shareOptions)
	// 		.then((res) => {
	// 			console.log(res);
	// 			// this.setState({
	// 			//   emailScorecard: false,
	// 			// });
	// 			// alert('Success');

	// 			this.setState({
	// 				loadingPdf: false,
	// 				viewPdf: true,
	// 				pdfDocument: uri,
	// 			});
	// 		})
	// 		.catch((err) => {
	// 			err && console.log(err);
	// 		});
	// };

	// saveToFS = () => {
	// 	var path = RNFS.DocumentDirectoryPath + "/test.txt";

	// 	// write the file
	// 	RNFS.writeFile(path, "Lorem ipsum dolor sit amet", "utf8")
	// 		.then((success) => {
	// 			console.log("FILE WRITTEN!");
	// 		})
	// 		.catch((err) => {
	// 			console.log(err.message);
	// 		});
	// };
	//
	///
	///
	//  PDF CREAT PROCESS END
	//

	// handleEmail = async () => {
	// 	await this.setState({
	// 		loadingPdf: true,
	// 	});
	// 	const base64 = await this.refs.viewShot.capture().then((uri) => {
	// 		console.log("do something with ", uri);
	// 		return uri;
	// 	});

	// 	const rep = this.state.selectedRep;

	// 	let dateFrom = moment(
	// 		this.props.docDate == "" ? new Date() : this.props.docDate,
	// 	).format("DD-MM-YYYY");

	// 	let dateRange = `${dateFrom}`;

	// 	let fileName = `tetrice_quote_${dateFrom}`;

	// 	const email = await this.emailTo(
	// 		rep.email,
	// 		fileName,
	// 		base64,
	// 		dateRange,
	// 	);
	// };

	emailTo = async (recipient, fileName, base64, dateRange) => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			jwt: "1234567",
			recipient: recipient,
			description: fileName,
			pdfString: base64,
			filename: `${fileName}.pdf`,
			subject: `${this.props.documentTitle} - ${dateRange}`,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/email_quote.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response.status);
				if (response.status == "404") {
					alert("Error Sending email!");
					// response.json().then(result => console.log('result', result));
				} else if (response.status == "200") {
					alert("Sent!");
					this.setState({
						loadingPdf: false,
					});
					this.props.closePreview();
					// response.json().then(result => {
					//   // console.log('result', result);
					//   alert('Sent!');
					// });
				}
			})
			.catch((error) => console.log("error", error));
	};

	render() {
		return (
			<div
				style={{
					overflow: "scroll",
				}}>
				<View
					style={{
						width: 595 * this.state.scale,
						height: 842 * this.state.scale,
					}}>
					<View
						style={{
							width: 595 * this.state.scale,
							height: 842 * this.state.scale,
							backgroundColor: "#FFF",
							padding: this.state.pageMargin * this.state.scale,
						}}>
						{/* Top Start  */}
						<View
							style={{
								width: "100%",
								height: "auto",
								flexDirection: "row",
							}}>
							<View
								style={{
									flex: 1,
									justifyContent: "flex-end",
									alignItems: "flex-end",
								}}>
								<Image
									style={{
										width: 200 * this.state.scale,
										height: 55 * this.state.scale,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/tetrice_logo.png")}
								/>

								<View
									style={{
										flexDirection: "row",
										marginBottom: 15 * this.state.scale,
										marginTop: 20 * this.state.scale,
									}}>
									<View
										style={{
											alignItems: "flex-end",
										}}>
										<Text
											style={{
												fontSize: 7 * this.state.scale,
												color: "#141D31",
											}}>
											<Text
												style={{
													fontFamily: "Poppins",
													fontWeight: "800",
												}}>
												tetrice
											</Text>{" "}
											(pty) ltd
										</Text>
										<Text
											style={{
												fontSize: 7 * this.state.scale,
												color: "#141D31",

												fontWeight: "300",
											}}>
											Reg. 2018/492074/07
										</Text>

										<Text
											style={{
												fontSize: 7 * this.state.scale,
												color: "#141D31",

												fontWeight: "200",
											}}>
											VAT: 4910296310
										</Text>
										<Text
											style={{
												fontSize: 7 * this.state.scale,
												color: "#141D31",

												fontWeight: "300",
											}}>
											159 Rivonia Road, Morningside Ext 2, Sandton,
											2191
										</Text>
										<Text
											style={{
												fontSize: 7 * this.state.scale,
												color: "#141D31",

												fontWeight: "300",
											}}>
											www.tetrice.co.za
										</Text>
									</View>
								</View>
							</View>
						</View>

						<View
							style={{
								flexDirection: "row",
								justifyContent: "flex-end",
							}}>
							<View
								style={{
									alignItems: "flex-end",
									marginTop: 4,
									width: 100 * this.state.scale,
								}}>
								<Text
									style={{
										fontSize: 7 * this.state.scale,
										color: "#141D31",
									}}>
									Document date
								</Text>
							</View>
							<View
								style={{
									alignItems: "flex-end",
									marginTop: 4,
									width: 100 * this.state.scale,
								}}>
								<Text
									style={{
										fontSize: 7 * this.state.scale,
										color: "#141D31",
									}}>
									{moment().format("DD/MM/YYYY")}
								</Text>
							</View>
						</View>

						<View
							style={{
								flex: 1,
								marginTop: 50,
							}}>
							<View
								style={{
									marginBottom: 9 * this.state.scale,
								}}>
								<Text
									style={{
										fontSize: 9 * this.state.scale,
										fontWeight: "700",
										color: "#141D31",
										letterSpacing: 0,
									}}>
									Resolution to allow Discovery Life Medical Aid
									premiums to be deducted from the business account for
									selected employees.
								</Text>
							</View>

							<View
								style={{
									flex: 1,
								}}>
								<Text
									style={{
										fontSize: 9 * this.state.scale,
										fontWeight: "400",
										color: "#141D31",
										letterSpacing: 0,
										marginBottom: 9 * this.state.scale,
									}}>
									tetrice (pty) and its share holders have determined
									it to be in its best interest to allow the payment of
									Discovery Life Medical Aid premiums from the business
									accunt.
								</Text>
								<Text
									style={{
										fontSize: 9 * this.state.scale,
										fontWeight: "400",
										color: "#141D31",
										letterSpacing: 0,
										marginBottom: 9 * this.state.scale,
									}}>
									BANK ACCOUNT:
								</Text>

								<Text
									style={{
										fontSize: 9 * this.state.scale,
										fontWeight: "400",
										color: "#141D31",
										letterSpacing: 0,
										marginBottom: 9 * this.state.scale,
									}}>
									The persons, whos signatures appear below is
									authorised to
								</Text>
							</View>

							<View
								style={{
									width: "100%",
									height: "auto",
									marginTop: 2,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										fontSize: 5 * this.state.scale,
										color: "#141D31",
										fontFamily: "Poppins",
									}}>
									<Text
										style={{
											fontWeight: "800",
										}}>
										tetrice
									</Text>{" "}
									(pty) ltd
								</Text>
								<Text
									style={{
										fontSize: 5 * this.state.scale,
										color: "#141D31",
									}}>
									v1.2.17
								</Text>
							</View>
						</View>
						{/* PAGE CONTENT */}
					</View>
					{/* </ViewShot> */}
				</View>
			</div>
		);
	}
}

export default A4Doc;
