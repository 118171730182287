import React, { Component } from "react";
import { View, Text, TouchableOpacity, ScrollView, Image } from "react-native";
import moment from "moment";

import add_btn from "../../../assets/images/add_btn.png";
import arrow_up from "../../../assets/images/arrow_up.png";
import arrow_down from "../../../assets/images/arrow_down.png";
import { APP_COLOURS } from "../../APP_VARS";

function isEven(n) {
	return n % 2 == 0;
}

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// ledger: [
			// 	{
			// 		id: 1,
			// 		title: "Wes",
			// 		amount: 2040,
			// 		type: "income",
			// 		currency: "R",
			// 		date: "2021/04/16",
			// 		vat: 123,
			// 	},
			// 	{
			// 		id: 2,
			// 		title: "Wes",
			// 		amount: 1500,
			// 		type: "expense",
			// 		currency: "R",
			// 		date: "2021/04/16",
			// 		vat: 234,
			// 	},
			// 	{
			// 		id: 3,
			// 		title: "Wes",
			// 		amount: 1500,
			// 		type: "expense",
			// 		currency: "R",
			// 		date: "2021/04/16",
			// 		vat: 234,
			// 	},
			// ],
			ledger: this.props.data,
		};
	}

	componentDidMount() {
		// this.setState({
		// 	ledger: this.props.data,
		// });
	}

	componentDidUpdate(prevProps, prevState) {
		// if (prevProps.data !== this.state.data) {
		// 	this.setState({
		// 		ledger: this.props.data,
		// 	});
		// }
	}

	addItem = () => {
		let obj = {
			id: 1,
			title: "Wes",
			amount: 2040,
			type: "income",
			currency: "R",
			date: moment().format("YYYY-MM-DD HH:mm:ss"),
			vat: 123,
		};

		this.setState({
			ledger: this.props.data.concat(obj),
		});
	};

	render() {
		let income = this.props.data.map((d) => {
			if (d.type == "income") {
				let rr = parseFloat(d.amount);
				return rr;
			} else {
				return 0;
			}
		});
		const totalIncome = income.reduce((prev, cs, index) => prev + cs, 0);
		let expense = this.props.data.map((d) => {
			if (d.type == "expense") {
				let rr = parseFloat(d.amount);
				return rr;
			} else {
				return 0;
			}
		});
		const totalExpense = expense.reduce((prev, cs, index) => prev + cs, 0);

		// console.log("totalIncome", totalIncome);
		let vat = this.props.data.map((d) => parseFloat(d.vat));
		const totalVAT = vat.reduce((prev, cs, index) => prev + cs, 0);

		let profitLoss = totalIncome - totalExpense;

		return (
			<View
				style={{
					flex: 1,
					height: "100%",
					width: "100%",
					padding: 5,
					backgroundColor: "#FFF",
					borderRadius: 5,
					// minHeight: 300,
				}}>
				<View
					style={{
						flexDirection: "row",
						// padding: 5,
						justifyContent: "space-between",
						paddingRight: 10,
						paddingLeft: 4,
					}}>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 15,
							color: profitLoss > 0 ? APP_COLOURS.BG2 : "#F24750",
							fontWeight: "900",
						}}>
						{profitLoss > 0 ? "Profit" : "Loss"}
					</Text>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 15,
							color: profitLoss > 0 ? APP_COLOURS.BG2 : "#F24750",
							fontWeight: "900",
						}}>
						R {currencyFormat(profitLoss)}
					</Text>
				</View>
				<View
					style={{
						flexDirection: "row",
						marginBottom: 5,
						justifyContent: "space-between",
						opacity: 0.6,
						paddingRight: 10,
						paddingLeft: 4,
					}}>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 11,
							color: APP_COLOURS.BG2,
							fontWeight: "500",
						}}>
						VAT
					</Text>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 11,
							color: APP_COLOURS.BG2,
							fontWeight: "500",
						}}>
						R {currencyFormat(totalVAT)}
					</Text>
				</View>

				<View
					style={{
						flexDirection: "row",
						paddingBottom: 2,
						marginBottom: 2,
						// borderBottomColor: "#797979",
						// borderBottomWidth: 0.5,
						borderRadius: 2,
						overflow: "hidden",
					}}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							alignItems: "center",
						}}>
						<Image
							source={arrow_up}
							style={{
								width: 30,
								height: 30,
								marginRight: 4,
								marginLeft: 4,
							}}
						/>
						<View>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 9,
									color: APP_COLOURS.BG2,
									fontWeight: "300",
								}}>
								Income
							</Text>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 13,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
								}}>
								R {totalIncome}
							</Text>
						</View>
					</View>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							alignItems: "center",
						}}>
						<Image
							source={arrow_down}
							style={{
								width: 30,
								height: 30,
								marginRight: 4,
								marginLeft: 4,
							}}
						/>
						<View>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 9,
									color: APP_COLOURS.BG2,
									fontWeight: "300",
								}}>
								Expense
							</Text>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 13,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
								}}>
								R {totalExpense}
							</Text>
						</View>
					</View>
				</View>

				<View
					style={{
						flexDirection: "row",
						paddingBottom: 2,
						marginBottom: 2,
						// borderBottomColor: "#797979",
						// borderBottomWidth: 0.5,
						borderRadius: 2,
						overflow: "hidden",
					}}>
					<View
						style={{
							width: 100,
							height: 5,
							backgroundColor: "#4DF1A1",
						}}></View>
					<View
						style={{
							flex: 1,
							height: 5,
							backgroundColor: "#F24750",
						}}></View>
				</View>

				<View
					style={{
						width: "100%",
						height: this.props.screenHeight - 200,
					}}>
					<ScrollView>
						{this.props.data.map((d, i) => (
							<View
								style={{
									flexDirection: "row",
									paddingBottom: 2,
									marginBottom: 2,
									// borderBottomColor: "#797979",
									// borderBottomWidth: 0.5,
									backgroundColor: isEven(i)
										? "#F0F0F0"
										: "transparent",
									padding: 5,
									borderRadius: 5,
								}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 12,
										color: APP_COLOURS.BG2,
										fontWeight: "600",
										// width: 100,
										flex: 1,
									}}>
									{d.title}
								</Text>

								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 12,
										color: APP_COLOURS.BG2,
										fontWeight: "600",
										width: 90,
										textAlign: "right",
									}}>
									{d.currency} {currencyFormat(d.amount)}
								</Text>
								<Image
									source={
										d.type == "income"
											? arrow_up
											: arrow_down
									}
									style={{
										width: 15,
										height: 15,
										marginRight: 4,
										marginLeft: 4,
									}}
								/>
							</View>
						))}
						<View
							style={{
								height: 10,
							}}
						/>
					</ScrollView>
				</View>

				{/* <TouchableOpacity onPress={() => this.addItem()}>
					<View
						style={{
							// flex: 1,
							backgroundColor: APP_COLOURS.BLUE,
							borderRadius: 10,
							justifyContent: "center",
							flexDirection: "row",
							alignItems: "center",
							height: "auto",
							padding: 3,
						}}>
						<Image
							source={add_btn}
							style={{
								width: 15,
								height: 15,
								marginRight: 4,
								marginLeft: 4,
							}}
						/>
						<Text
							style={{
								fontFamily: "Avenir",
								fontSize: 9,
								color: "#FFF",
								fontWeight: "300",
							}}>
							Add
						</Text>
					</View>
				</TouchableOpacity> */}
			</View>
		);
	}
}

export default index;
