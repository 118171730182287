import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Alert,
	Modal,
	RefreshControl,
	LayoutAnimation,
	ActivityIndicator,
} from "react-native";
// import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
// import DatePicker from "react-native-date-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalAddEntry from "./ModalAddEntry";
// import { TextInputMask, TextInputMaskMethods } from "react-native-masked-text";
// import A4Doc from "./A4Doc";
// import PDFViewer from "./PDFviewer";
// import Share from "react-native-share";
// import ViewShot from "react-native-view-shot";
// import RNFS from "react-native-fs";
// import RNImageToPdf from "react-native-image-to-pdf";

const BLUE = "#3F6EFF";
const WHITE = "#FFF";
const FONT_ALMOSTWHITE = "#D4D4D4";
const FONTGREY = "#6E7074";
const RED = "#FF5F5F";
const GREEN = "#3F6EFF";
const OPACITYGREY = "#2F2F39";
const BG = "#131119";
const BG2 = "#191D24";
const FONT_DARK = "#141D31";
const BORDERBOTTOM = "#28282F";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

class PaymentInvoices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			entries: [],
			clients: [],
			addEntry: false,
			loading: true,
			newTitle: "",
			newDescription: "",
			editEntries: false,
			jwt: "1234567",
			allocation: "",
			type: "",
			docID: "",
			pdfID: "",
			title: "",
			description: "",
			vatable: "",
			vat: "",
			total: "0",
			doc_date: new Date(),

			dateFrom: "",
			dateTill: "",
			dateAdjustShow: "",

			documentTitle: "",
			viewPdf: false,
			pdfDocument: "",
			loadingPdf: false,
			pdfName: "",

			scale: 2,
		};
	}

	getEntries = () => {
		var raw = JSON.stringify({
			jwt: "1234567",
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/get_bookentries.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response.status);
				if (response.status == "404") {
					response.json().then((result) => {
						console.log("result", result);
						this.setState({
							entries: [],
						});
					});
				} else if (response.status == "200") {
					response.json().then((result) => {
						// console.log('result', result);

						const uniqueClients = [
							...new Set(result.map((item) => item.allocation)),
						];

						console.log(uniqueClients);

						this.setState({
							entries: result,
							clients: uniqueClients,
							addEntry: false,
							loading: false,
							totalMasked: "",
							total: "",
							jwt: "1234567",
							allocation: "",
							type: "",
							docID: "",
							pdfID: "",
							title: "",
							description: "",
							vatable: "",
							vat: "",
							total: "0",
							doc_date: new Date(),
						});
						this.onValueChange("account_entries", JSON.stringify(result));
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	deleteEntry = (id) => {
		var raw = JSON.stringify({
			jwt: "1234567",
			id: id,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/delete_bookentry.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response.status);
				if (response.status == "404") {
					response.json().then((result) => console.log("result", result));
				} else if (response.status == "200") {
					response.json().then((result) => {
						console.log("result", result);
						this.setState({
							entries: result,
							addEntry: false,
							loading: false,
						});
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	addEntry = () => {
		let vat = 0;
		if (this.state.vatable == "incl") {
			let vv = (parseFloat(this.state.total) * 15) / 115;
			vat = vv.toFixed(2);
		}

		let obj = {
			jwt: this.state.jwt,
			allocation: this.state.allocation,
			type: this.state.type,
			docID: this.state.docID,
			pdfID: this.state.pdfID,
			title: this.state.title,
			description: this.state.description,
			vatable: this.state.vatable,
			vat: vat,
			total: this.state.total,
			doc_date: moment(this.state.doc_date).format("YYYY-MM-DD HH:mm:ss"),
		};

		console.log(obj);

		var raw = JSON.stringify(obj);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/post_bookentry.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response.status);
				if (response.status == "404") {
					alert("error adding entry");
				} else if (response.status == "200") {
					this.getEntries();
				}
			})
			.catch((error) => console.log("error", error));
	};

	getDocFromAsync = async (doc) => {
		// console.log("getDocFromAsync", doc);
		// try {
		// 	const value = await AsyncStorage.getItem(doc.docID);
		// 	let parse = JSON.parse(value);
		// 	if (value !== null) {
		// 		if (doc.doc == "application/pdf") {
		// 			await this.setState({
		// 				documentTitle: doc.title,
		// 				viewPdf: true,
		// 				pdfDocument: parse,
		// 			});
		// 		}
		// 		if (doc.doc == "image/png") {
		// 			await this.setState({
		// 				documentTitle: doc.title,
		// 				viewPNG: true,
		// 				pngImage: parse,
		// 			});
		// 		}
		// 	} else {
		// 		Alert.alert(`Document does not exist!`, "", [
		// 			{
		// 				text: "Ok",
		// 				onPress: () => console.log("Ok Pressed"),
		// 				style: "cancel",
		// 			},
		// 		]);
		// 		await this.setState({
		// 			documentTitle: "",
		// 			viewPdf: false,
		// 			pdfDocument: "",
		// 		});
		// 	}
		// } catch (error) {
		// 	// Error retrieving data
		// }
	};

	async componentDidMount() {
		// this.getEntries();
		await this.getAccountsLocal();
	}

	async onValueChange(item, selectedValue) {
		// try {
		// 	await AsyncStorage.setItem(item, selectedValue).then((x) => {
		// 		// console.log('onValueChange', item, selectedValue);
		// 	});
		// } catch (error) {
		// 	console.error(error);
		// }
	}

	getAccountsLocal = async () => {
		// try {
		// 	const value = await AsyncStorage.getItem("account_entries");
		// 	let parse = JSON.parse(value);
		// 	if (value !== null) {
		// 		this.setState({
		// 			entries: parse,
		// 			loading: false,
		// 		});
		// 	} else {
		// 		this.getEntries();
		// 	}
		// } catch (error) {
		// 	// Error retrieving data
		// }
		this.getEntries();
	};

	render() {
		let balance = 0;
		let balance_ = 0;
		let morning = new Date();
		morning.setHours(0, 0, 1, 0);
		let evening = new Date();
		evening.setHours(23, 59, 59, 0);

		//
		// BALANCE BF Calc
		const balances = this.state.entries
			.filter((d) => {
				let reValue = "";
				if (this.state.allocation == "") {
					reValue = d;
				} else {
					reValue = d.allocation == this.state.allocation;
				}
				return reValue;
			})
			.filter((data) => {
				if (this.state.dateFrom !== "") {
					let date = moment(data.doc_date).unix();
					let dateFrom = moment(this.state.dateFrom).unix();
					// console.log(date, dateFrom);
					return date <= dateFrom;
				}
			})
			.map((d) => parseFloat(d.total * (d.type == "Invoice" ? 1 : -1)));
		const balancebf = balances.reduce(
			(previousScore, currentScore, index) => previousScore + currentScore,
			0,
		);
		// console.log("balancebf", balancebf);

		if (balancebf > 0) {
			balance = balancebf;
			balance_ = balancebf;
		}
		//
		//
		const running = this.state.entries
			.filter((d) => {
				let reValue = "";
				if (this.state.allocation == "") {
					reValue = d;
				} else {
					reValue = d.allocation == this.state.allocation;
				}
				return reValue;
			})
			.map((d) => parseFloat(d.total * (d.type == "Invoice" ? 1 : -1)));
		const runningBalance = running.reduce(
			(previousScore, currentScore, index) => previousScore + currentScore,
			0,
		);
		// console.log("runningBalance", runningBalance);

		//
		//

		const scores = this.state.entries.map((d) => parseFloat(d.total));
		const totalScores = scores.reduce(
			(previousScore, currentScore, index) => previousScore + currentScore,
			0,
		);
		// console.log("totalScores", totalScores);

		return (
			<View
				style={{
					flex: 1,
					paddingLeft: 15,
					paddingRight: 10,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						marginBottom: 10,
					}}>
					<View>
						<Text
							style={{
								color: "#F6F8FE", // '#5A7BBA'
								fontSize: 30,
								fontWeight: "900",
							}}>
							Statements
						</Text>
					</View>

					<View
						style={{
							flexDirection: "row",
							// display: this.state.allocation == '' ? 'none' : 'flex',
						}}>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-end",
								marginRight: 10,
							}}>
							<Text
								style={{
									fontSize: 10,
									color: "#FFF",
									fontWeight: "300",
									fontFamily: "Avenir Next",
									opacity: 0.5,
									padding: 0,
									marginRight: 10,
								}}>
								Date filter
							</Text>

							{this.state.dateFrom !== "" ||
							this.state.dateTill !== "" ? (
								<TouchableOpacity
									onPress={async () => {
										await this.setState({
											dateFrom: "",
											dateTill: "",
											dateAdjustShow: "",
										});
									}}>
									<View
										style={{
											width: 100,
											height: 35,
											justifyContent: "center",
											alignItems: "center",
											borderRadius: 12,
											padding: 10,
											marginRight: 10,
										}}>
										<Text
											style={{
												color: "#FFF",
												fontWeight: "600",
												fontSize: 13,
											}}>
											Clear
										</Text>
									</View>
								</TouchableOpacity>
							) : null}

							<TouchableOpacity
								onPress={() =>
									this.setState({
										dateAdjustShow: "From",
										dateFrom: new Date(),
									})
								}>
								<View
									style={{
										width: 100,
										height: 35,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor:
											this.state.dateFrom == ""
												? "transpararent"
												: "#FF5F5F",

										borderRadius: 12,
										padding: 10,
										marginRight: 10,
									}}>
									<Text
										style={{
											color: "#FFF",
											fontWeight: "600",
											fontSize: 13,
										}}>
										{this.state.dateFrom == ""
											? "From"
											: moment(this.state.dateFrom).format(
													"YYYY/MM/DD",
											  )}
									</Text>
								</View>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={() =>
									this.setState({
										dateAdjustShow: "Till",
										dateTill: new Date(),
									})
								}>
								<View
									style={{
										width: 100,
										height: 35,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor:
											this.state.dateTill == ""
												? "transpararent"
												: "#FF5F5F",
										borderWidth: 0.5,

										borderRadius: 12,
										padding: 10,
									}}>
									<Text
										style={{
											color: "#FFF",
											fontWeight: "600",
											fontSize: 13,
										}}>
										{this.state.dateTill == ""
											? "Till"
											: moment(this.state.dateTill).format(
													"YY/MM/DD",
											  )}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
						<TouchableOpacity
							onPress={async () => {
								// await this.screenShotPage();
								this.setState({
									viewPdf: true,
								});
							}}>
							<View
								style={{
									width: "auto",
									height: "auto",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#FF5F5F",
									borderRadius: 12,
									padding: 10,
									marginRight: 2,
								}}>
								<Text
									style={{
										color: "#FFF",
										fontWeight: "600",
										fontSize: 13,
									}}>
									PDF
								</Text>
							</View>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									addEntry: !this.state.addEntry,
									newTitle: "",
									newDescription: "",
									total: "",
									newCurrency: "R",
									vatable: "Excl.",
								})
							}>
							<View
								style={{
									width: "auto",
									height: "auto",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#FF5F5F",
									borderRadius: 12,
									padding: 10,
									marginRight: 2,
								}}>
								<Text
									style={{
										color: "#FFF",
										fontWeight: "600",
										fontSize: 13,
									}}>
									Add Entry
								</Text>
							</View>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={() =>
								this.setState({
									editEntries: !this.state.editEntries,
								})
							}>
							<View
								style={{
									width: "auto",
									height: "auto",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: BLUE,
									borderRadius: 12,
									padding: 10,
								}}>
								<Text
									style={{
										color: "#FFF",
										fontWeight: "600",
										fontSize: 13,
									}}>
									Edit
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				</View>

				<View style={{}}>
					<ScrollView horizontal>
						<View
							style={{
								flexDirection: "row",
								marginBottom: 5,
							}}>
							<TouchableOpacity
								style={{}}
								onPress={() =>
									this.setState({
										allocation: "",
									})
								}>
								<View
									style={{
										width: "auto",
										height: 50,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor:
											this.state.allocation == "" ? BLUE : BG2,
										padding: 15,
										borderRadius: 12,
										marginRight: 5,
									}}>
									<Text
										style={{
											fontSize: 14,
											color: "#FFF",
											fontWeight: "400",
											fontFamily: "Avenir Next",
										}}>
										All
									</Text>
								</View>
							</TouchableOpacity>
							{this.state.clients.map((d, i) => (
								<TouchableOpacity
									style={{}}
									onPress={() =>
										this.setState({
											allocation: d,
											pdfName: `statement_${d.title}_${moment(
												this.state.dateFrom == ""
													? new Date()
													: this.state.dateFrom,
											).format("YYYY-MM-DD")}-${moment(
												this.state.dateTill == ""
													? new Date()
													: this.state.dateTill,
											).format("YYYY-MM-DD")}`,
										})
									}>
									<View
										style={{
											width: "auto",
											height: 50,
											justifyContent: "center",
											alignItems: "center",
											backgroundColor:
												this.state.allocation == d ? BLUE : BG2,
											padding: 15,
											borderRadius: 12,
											marginRight: 5,
										}}>
										<Text
											style={{
												fontSize: 14,
												color: "#FFF",
												fontWeight: "400",
												fontFamily: "Avenir Next",
											}}>
											{d}
										</Text>
									</View>
								</TouchableOpacity>
							))}
						</View>
					</ScrollView>
				</View>

				{/* <ScrollView> */}
				{/* <ViewShot
					style={{
						flex: 1,
						flexDirection: "row",
					}}
					options={{}}
					ref='viewShot'> */}
				<View
					style={{
						flex: 1,
						flexDirection: "row",
					}}>
					<View
						style={{
							backgroundColor: BG2,
							borderRadius: 20,
							flex: 1,
							padding: 10,
							marginTop: 2,
						}}>
						<View
							style={{
								flexDirection: "row",
								borderBottomColor: "#28282F",
								borderBottomWidth: 0.5,
								alignItems: "center",
								borderBottomColor: BORDERBOTTOM,
								borderBottomWidth: 0.5,
							}}>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									alignItems: "center",
									marginBottom: 2,
									marginTop: 5,
								}}>
								<View
									style={{
										width: 25,
										borderRightWidth: 0.5,
										borderRightColor: "#797979",
										marginRight: 10,
									}}>
									<Text
										selectable
										style={{
											fontSize: 10,
											color: "#D8D8FF",
											fontWeight: "400",
											fontFamily: "Avenir Next",
											marginRight: 5,
											opacity: 0.4,

											textAlign: "left",
										}}>
										Doc
									</Text>
								</View>

								<View
									style={{
										width: 80,
									}}>
									<Text
										selectable
										style={{
											fontSize: 12,
											color: "#D8D8FF",
											fontWeight: "600",
											fontFamily: "Avenir Next",
										}}>
										Doc Date
									</Text>
								</View>

								<View
									style={{
										width: 70,
									}}>
									<Text
										selectable
										style={{
											fontSize: 12,
											color: "#D8D8FF",
											fontWeight: "600",
											fontFamily: "Avenir Next",
											marginRight: 5,
										}}>
										INV/REC
									</Text>
								</View>

								<View
									style={{
										flex: 1,
									}}>
									<Text
										selectable
										style={{
											fontSize: 12,
											color: "#D8D8FF",
											fontWeight: "600",
											fontFamily: "Avenir Next",
											marginRight: 5,
										}}>
										Allocation
									</Text>
								</View>

								<View
									style={{
										flex: 1,
									}}>
									<Text
										selectable
										style={{
											fontSize: 12,
											color: "#D8D8FF",
											fontWeight: "600",
											fontFamily: "Avenir Next",
											marginRight: 5,
										}}>
										title
									</Text>
								</View>

								<View
									style={{
										width: 90,
									}}>
									<Text
										selectable
										style={{
											fontSize: 12,
											color: "#D8D8FF",
											fontWeight: "600",
											fontFamily: "Avenir Next",
											marginRight: 5,
										}}>
										Description
									</Text>
								</View>
								<View
									style={{
										width: 70,
									}}>
									<Text
										selectable
										style={{
											fontSize: 12,
											color: "#D8D8FF",
											fontWeight: "600",
											fontFamily: "Avenir Next",
											marginRight: 5,
										}}>
										VAT
									</Text>
								</View>

								<View
									style={{
										width: 100,
									}}>
									<Text
										selectable
										style={{
											fontSize: 12,
											color: "#D8D8FF",
											fontWeight: "700",
											fontFamily: "Avenir Next",
											marginRight: 5,
											textAlign: "center",
										}}>
										R
									</Text>
								</View>
								<View
									style={{
										width: 100,
									}}>
									<Text
										selectable
										style={{
											fontSize: 12,
											color: "#D8D8FF",
											fontWeight: "700",
											fontFamily: "Avenir Next",
											marginRight: 5,
											textAlign: "center",
										}}>
										R
									</Text>
								</View>
							</View>
						</View>

						<View
							style={{
								flex: 1,
							}}>
							<ScrollView>
								{this.state.dateFrom !== "" ? (
									<View
										style={{
											flexDirection: "row",
											borderBottomColor: "#28282F",
											borderBottomWidth: 0.5,
											alignItems: "center",
											marginBottom: 10,
											marginTop: 10,
										}}>
										<View
											style={{
												flex: 1,
												marginRight: 10,
											}}>
											<Text
												adjustsFontSizeToFit
												numberOfLines={1}
												selectable
												style={{
													fontSize: 12,
													color: "#FFF",
													fontFamily: "Avenir Next",
													marginRight: 5,
													textAlign: "right",
												}}>
												Balance b/f:{"  "}
												<Text
													style={{
														fontWeight: "700",
													}}>
													R{" "}
													{balancebf < 0
														? `${currencyFormat(
																balancebf * -1,
														  )} cr`
														: currencyFormat(balancebf)}
												</Text>
											</Text>
										</View>
									</View>
								) : null}

								{this.state.entries
									.sort(function (a, b) {
										let first = moment(a.doc_date).unix();
										let last = moment(b.doc_date).unix();
										return first - last;
									})
									.filter((d) => {
										let reValue = "";
										if (this.state.allocation == "") {
											reValue = d;
										} else {
											reValue =
												d.allocation == this.state.allocation;
										}
										return reValue;
									})
									.filter((data) => {
										if (this.state.dateFrom !== "") {
											let from = moment(data.doc_date).unix();
											let dateFrom = moment(
												this.state.dateFrom,
											).unix();
											{
												/* console.log(from, dateFrom); */
											}
											return from >= dateFrom;
										} else {
											return data;
										}
									})
									.filter((data) => {
										if (this.state.dateTill !== "") {
											{
												/* console.log(moment(this.state.dateTill).toISOString()); */
											}
											let till = moment(data.doc_date).unix();
											let dateTill = moment(
												this.state.dateTill,
											).unix();
											{
												/* console.log(till, dateTill); */
											}
											return till <= dateTill;
										} else {
											return data;
										}
									})
									.map((entry, i) => {
										let float = 0;
										if (entry.type == "Invoice") {
											float = parseFloat(entry.total);
										} else {
											float = -parseFloat(entry.total);
										}
										balance = float + balance;

										return (
											<TouchableOpacity
												activeOpacity={1}
												onPress={() =>
													this.setState({
														selectedRow:
															this.state.selectedRow == entry.id
																? ""
																: entry.id,
													})
												}>
												{/* ROW ITEM  */}
												<View
													key={i}
													style={{
														flexDirection: "row",
														borderBottomColor: "#28282F",
														borderBottomWidth: 0.5,
														alignItems: "center",
														backgroundColor:
															this.state.selectedRow == entry.id
																? "rgba(52, 52, 52, 0.6)"
																: entry.type == "Invoice"
																? "transparent"
																: "rgba(52, 52, 52, 0.2)",
													}}>
													<View
														style={{
															flex: 1,
															flexDirection: "row",
															alignItems: "center",
															marginBottom: 2,
															marginTop: 5,
														}}>
														<TouchableOpacity
															onPress={() =>
																this.getDocFromAsync(
																	entry.pdfID,
																)
															}
															disabled={entry.pdfID == ""}>
															<View
																style={{
																	width: 35,
																	height: 30,
																	// marginRight: 5,
																}}>
																<Image
																	style={{
																		width: 30,
																		height: 30,
																		resizeMode: "contain",
																		opacity:
																			entry.pdfID == ""
																				? 0.1
																				: 1,
																	}}
																	source={require("../../../assets/images/pdf.png")}
																/>
															</View>
														</TouchableOpacity>

														<View
															style={{
																width: 70,
															}}>
															<Text
																selectable
																style={{
																	fontSize: 12,
																	color: FONT_ALMOSTWHITE,
																	fontWeight: "500",
																	fontFamily: "Avenir Next",
																}}>
																{moment(entry.doc_date).format(
																	"MMM DD, YY",
																)}
															</Text>
														</View>

														<View
															style={{
																width: 70,
															}}>
															<Text
																selectable
																style={{
																	fontSize: 12,
																	color: FONT_ALMOSTWHITE,
																	fontWeight: "600",
																	fontFamily: "Avenir Next",
																	marginRight: 5,
																}}>
																{entry.docID}
															</Text>
														</View>

														<View
															style={{
																flex: 1,
															}}>
															<Text
																selectable
																style={{
																	fontSize: 12,
																	color: FONT_ALMOSTWHITE,
																	fontWeight: "600",
																	fontFamily: "Avenir Next",
																	marginRight: 5,
																}}>
																{entry.allocation}
															</Text>
														</View>

														<View
															style={{
																flex: 1,
															}}>
															<Text
																selectable
																style={{
																	fontSize: 12,
																	color: FONT_ALMOSTWHITE,
																	fontWeight: "600",
																	fontFamily: "Avenir Next",
																	marginRight: 5,
																}}>
																{entry.title}
															</Text>
														</View>

														<View
															style={{
																width: 90,
															}}>
															<Text
																selectable
																style={{
																	fontSize: 12,
																	color: FONT_ALMOSTWHITE,
																	fontWeight: "600",
																	fontFamily: "Avenir Next",
																	marginRight: 5,
																}}>
																{entry.description}
															</Text>
														</View>

														<View
															style={{
																width: 70,
																borderRightWidth: 1,
																borderRightColor:
																	entry.type == "Invoice"
																		? RED
																		: GREEN, // '#797979',
															}}>
															<Text
																selectable
																style={{
																	fontSize: 12,
																	color: FONT_ALMOSTWHITE,
																	fontWeight: "400",
																	fontFamily: "Avenir Next",
																	marginRight: 5,
																}}>
																{entry.vat > 0 ? entry.vat : ""}
															</Text>
														</View>

														<View
															style={{
																width: 100,
															}}>
															<Text
																selectable
																style={{
																	fontSize: 12,
																	// color: entry.type == 'Invoice' ? RED : GREEN,
																	color:
																		entry.type == "Invoice"
																			? "#FFF"
																			: BLUE,
																	fontWeight: "700",
																	fontFamily: "Avenir Next",
																	marginRight: 5,
																	textAlign: "right",
																}}>
																{currencyFormat(
																	parseFloat(entry.total),
																)}
															</Text>
														</View>

														<View
															style={{
																width: 100,
																marginRight: 10,
																flexDirection: "row",
																justifyContent: "space-between",
															}}>
															<Text
																selectable
																style={{
																	fontSize: 12,
																	color: "#FFF",
																	fontWeight: "300",
																	fontFamily: "Avenir Next",
																	marginRight: 5,
																	textAlign: "right",
																}}>
																{entry.type == "Invoice"
																	? ""
																	: "cr"}{" "}
															</Text>
															<Text
																adjustsFontSizeToFit
																numberOfLines={1}
																selectable
																style={{
																	fontSize: 12,
																	// color: FONT_ALMOSTWHITE,
																	color: "#FFF",
																	fontWeight: "700",
																	fontFamily: "Avenir Next",
																	marginRight: 5,
																	opacity:
																		balance > 0 ? 1 : 0.4,
																	textAlign: "right",
																}}>
																{currencyFormat(balance)}
															</Text>
														</View>
													</View>
													{this.state.editEntries ? (
														<TouchableOpacity
															onPress={() =>
																Alert.alert(
																	"Delete item",
																	`Are you sure you want to delete ${entry.id}`,
																	[
																		{
																			text: "Yes",
																			onPress: async () => {
																				await this.deleteEntry(
																					entry.id,
																				);
																			},
																		},
																		{
																			text: "Cancel",
																			onPress: () =>
																				console.log(
																					"Cancel Pressed",
																				),
																			style: "cancel",
																		},
																	],
																	{
																		cancelable: true,
																	},
																)
															}>
															<Image
																style={{
																	width: 20,
																	height: 20,
																	resizeMode: "contain",
																}}
																source={require("../../../assets/images/bin.png")}
															/>
														</TouchableOpacity>
													) : null}
												</View>
											</TouchableOpacity>
										);
									})}
								{this.state.loading ? (
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
											minHeight: 500,
										}}>
										<View
											style={{
												width: 35,
												height: 35,
												borderRadius: 20,
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: "#FFF",
												opacity: 0.2,
												position: "absolute",
											}}></View>
										<ActivityIndicator size='small' color='#FFF' />
									</View>
								) : null}
							</ScrollView>

							<View
								style={{
									flexDirection: "row",
									borderBottomColor: "#28282F",
									borderBottomWidth: 0.5,
									alignItems: "center",
									marginBottom: 10,
									marginTop: 10,
								}}>
								<View
									style={{
										flex: 1,
										marginRight: 10,
									}}>
									<Text
										adjustsFontSizeToFit
										numberOfLines={1}
										selectable
										style={{
											fontSize: 12,
											color: "#FFF",
											fontFamily: "Avenir Next",
											marginRight: 5,
											textAlign: "right",
										}}>
										{runningBalance > 0 ? "Amount Due" : "Balance"}:
										{"   "}
										<Text
											style={{
												fontWeight: "700",
											}}>
											{currencyFormat(runningBalance)}
										</Text>
									</Text>
								</View>
							</View>
						</View>
					</View>
				</View>
				{/* </ViewShot> */}

				<View
					style={{
						width: "100%",
						height: 20,
					}}
				/>

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.dateAdjustShow !== ""}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<View
							style={{
								height: 80,
							}}
						/>
						<View
							style={{
								padding: 10,
							}}>
							<View
								style={{
									width: "100%",
									height: "auto",
									backgroundColor: BG,
									borderRadius: 12,
									padding: 5,
									marginTop: 15,
									padding: 15,
									alignItems: "center",
									justifyContent: "center",
								}}>
								<Text
									style={{
										fontSize: 15,
										color: "#FFF",
										fontWeight: "300",
										fontFamily: "Avenir Next",
										padding: 0,
									}}>
									Date {this.state.dateAdjustShow}
								</Text>

								{this.state.dateAdjustShow == "From" ? (
									<DatePicker
										selected={
											this.state.dateFrom == ""
												? morning
												: this.state.dateFrom
										}
										onChange={(date) =>
											this.setState({
												dateFrom: date,
												dateAdjustShow: "",
											})
										}
									/>
								) : (
									<DatePicker
										selected={
											this.state.dateTill == ""
												? evening
												: this.state.dateTill
										}
										onChange={(date) =>
											this.setState({
												dateTill: date,
												dateAdjustShow: "",
											})
										}
									/>
								)}

								{/* <TouchableOpacity
									onPress={() =>
										this.setState({
											dateAdjustShow: "",
										})
									}>
									<View
										style={{
											width: 300,
											height: 35,
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: "#FF5F5F",
											borderRadius: 12,
											padding: 5,
											marginTop: 20,
										}}>
										<Text
											selectable
											style={{
												color: "#FFF",
												fontWeight: "600",
												fontSize: 13,
											}}>
											Save Item
										</Text>
									</View>
								</TouchableOpacity> */}
							</View>
						</View>
					</View>
				</Modal>

				{this.state.addEntry ? (
					<ModalAddEntry
						clients={this.state.clients}
						close={() => {
							this.setState({
								addEntry: false,
							});
						}}
					/>
				) : (
					<View />
				)}

				{/* {this.state.viewPdf ? (
					<A4Doc
						{...this.props}
						selectedClient={this.state.clients.filter(
							(d) => d.title == this.state.allocation,
						)}
						pdfName={this.state.pdfName}
						previewPDF={true}
						closePreview={() => this.setState({ viewPdf: false })}
						screenWidth={this.props.screenWidth}
						screenHeight={this.props.screenHeight - 170}
						dateFrom={this.state.dateFrom}
						dateTill={this.state.dateTill}
						viewContent={
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										flexDirection: "row",
										borderBottomColor: "#28282F",
										borderBottomWidth: 0.5,
										alignItems: "center",
										marginBottom: 10,
									}}>
									{this.state.dateFrom !== "" ? (
										<View
											style={{
												flex: 1,
											}}>
											<Text
												adjustsFontSizeToFit
												numberOfLines={1}
												selectable
												style={{
													fontSize:
														9 * this.state.scale,
													color: FONT_DARK,
													fontFamily: "Avenir Next",
													marginRight:
														5 * this.state.scale,
													textAlign: "right",
												}}>
												Balance b/f:{"   "}
												<Text
													style={{
														fontWeight: "700",
													}}>
													R{" "}
													{balancebf < 0
														? `${currencyFormat(
																balancebf * -1,
														  )} cr`
														: currencyFormat(
																balancebf,
														  )}
												</Text>
											</Text>
										</View>
									) : null}
								</View>

								<View
									style={{
										flexDirection: "row",
										borderBottomColor: "#28282F",
										borderBottomWidth: 0.5,
										alignItems: "center",
										borderBottomColor: BORDERBOTTOM,
										borderBottomWidth: 0.5,
									}}>
									<View
										style={{
											flex: 1,
											flexDirection: "row",
											alignItems: "center",
											marginBottom: 2 * this.state.scale,
											marginTop: 5 * this.state.scale,
											justifyContent: "space-between",
										}}>
										<View
											style={{
												width: 70 * this.state.scale,
												// borderRightWidth: 0.5,
												// borderRightColor: '#797979',
											}}>
											<Text
												selectable
												style={{
													fontSize:
														8 * this.state.scale,
													color: FONT_DARK,
													fontWeight: "700",
													fontFamily: "Avenir Next",
													marginRight:
														5 * this.state.scale,
													textAlign: "left",
												}}>
												Date
											</Text>
										</View>

										<View
											style={{
												width: 120,
											}}>
											<Text
												selectable
												style={{
													fontSize:
														8 * this.state.scale,
													color: FONT_DARK,
													fontWeight: "600",
													fontFamily: "Avenir Next",
												}}>
												INV/REC.
											</Text>
										</View>

										<View
											style={{
												flex: 1,
											}}>
											<Text
												selectable
												style={{
													fontSize:
														8 * this.state.scale,
													color: FONT_DARK,
													fontWeight: "600",
													fontFamily: "Avenir Next",
													marginRight:
														5 * this.state.scale,
												}}>
												Description
											</Text>
										</View>

										<View
											style={{
												width: 100 * this.state.scale,
											}}>
											<Text
												selectable
												style={{
													fontSize:
														8 * this.state.scale,
													color: FONT_DARK,
													fontWeight: "700",
													fontFamily: "Avenir Next",
													marginRight:
														5 * this.state.scale,
													textAlign: "right",
												}}>
												Amount
											</Text>
										</View>
										<View
											style={{
												width: 80 * this.state.scale,
											}}>
											<Text
												selectable
												style={{
													fontSize:
														8 * this.state.scale,
													color: FONT_DARK,
													fontWeight: "700",
													fontFamily: "Avenir Next",
													marginRight:
														5 * this.state.scale,
													textAlign: "right",
												}}>
												Balance
											</Text>
										</View>
									</View>
								</View>

								<View
									style={{
										flex: 1,
									}}>
									{this.state.entries
										.sort(function (a, b) {
											let first = moment(
												a.doc_date,
											).unix();
											let last = moment(
												b.doc_date,
											).unix();
											return first - last;
										})
										.filter((d) => {
											let reValue = "";
											if (this.state.allocation == "") {
												reValue = d;
											} else {
												reValue =
													d.allocation ==
													this.state.allocation;
											}
											return reValue;
										})
										.filter((data) => {
											if (this.state.dateFrom !== "") {
												let from = moment(
													data.doc_date,
												).unix();
												let dateFrom = moment(
													this.state.dateFrom,
												).unix();
												console.log(from, dateFrom);
												return from >= dateFrom;
											} else {
												return data;
											}
										})
										.filter((data) => {
											if (this.state.dateTill !== "") {
												let till = moment(
													data.doc_date,
												).unix();
												let dateTill = moment(
													this.state.dateTill,
												).unix();
												console.log(till, dateTill);
												return till <= dateTill;
											} else {
												return data;
											}
										})
										.map((entry, i) => {
											let float = 0;
											if (entry.type == "Invoice") {
												float = parseFloat(entry.total);
											} else {
												float = -parseFloat(
													entry.total,
												);
											}
											balance_ = float + balance_;

											return (
												<View
													key={i}
													style={{
														flexDirection: "row",
														borderBottomColor:
															"#E8E8E8",
														borderBottomWidth: 0.5,
														alignItems: "center",
													}}>
													<View
														style={{
															flex: 1,
															flexDirection:
																"row",
															justifyContent:
																"space-between",
															alignItems:
																"center",
															marginBottom:
																2 *
																this.state
																	.scale,
															marginTop:
																5 *
																this.state
																	.scale,
														}}>
														<View
															style={{
																width:
																	70 *
																	this.state
																		.scale,
															}}>
															<Text
																adjustsFontSizeToFit
																selectable
																style={{
																	fontSize:
																		8 *
																		this
																			.state
																			.scale,
																	color: FONT_DARK,
																	fontWeight:
																		"600",
																	fontFamily:
																		"Avenir Next",
																}}>
																{moment(
																	entry.doc_date,
																).format(
																	"YY/MM/DD",
																)}
															</Text>
														</View>

														<View
															style={{
																width: 120,
															}}>
															<Text
																selectable
																style={{
																	fontSize:
																		8 *
																		this
																			.state
																			.scale,
																	color: FONT_DARK,
																	fontWeight:
																		"600",
																	fontFamily:
																		"Avenir Next",
																	marginRight:
																		5 *
																		this
																			.state
																			.scale,
																}}>
																{entry.docID}
															</Text>
														</View>

														<View
															style={{
																flex: 1,
															}}>
															<Text
																selectable
																style={{
																	fontSize:
																		8 *
																		this
																			.state
																			.scale,
																	color: FONT_DARK,
																	fontWeight:
																		"600",
																	fontFamily:
																		"Avenir Next",
																	marginRight:
																		5 *
																		this
																			.state
																			.scale,
																}}>
																{entry.title}
															</Text>
														</View>

														<View
															style={{
																flex: 1,
															}}>
															<Text
																selectable
																style={{
																	fontSize:
																		8 *
																		this
																			.state
																			.scale,
																	color: FONT_DARK,
																	fontWeight:
																		"600",
																	fontFamily:
																		"Avenir Next",
																	marginRight:
																		5 *
																		this
																			.state
																			.scale,
																}}>
																{
																	entry.description
																}
															</Text>
														</View>

														<View
															style={{
																flex: 1,
															}}>
															<Text
																selectable
																style={{
																	fontSize:
																		8 *
																		this
																			.state
																			.scale,
																	color: FONT_DARK,
																	fontWeight:
																		"400",
																	fontFamily:
																		"Avenir Next",
																	marginRight:
																		5 *
																		this
																			.state
																			.scale,
																}}>
																{entry.vat > 0
																	? entry.vat
																	: ""}
															</Text>
														</View>

														<View
															style={{
																width:
																	100 *
																	this.state
																		.scale,
																flexDirection:
																	"row",
																justifyContent:
																	"space-between",
															}}>
															<Text
																selectable
																style={{
																	fontSize:
																		8 *
																		this
																			.state
																			.scale,
																	color: FONT_DARK,
																	fontWeight:
																		"300",
																	fontFamily:
																		"Avenir Next",
																	marginRight:
																		5 *
																		this
																			.state
																			.scale,
																	textAlign:
																		"right",
																}}>
																{entry.type ==
																"Invoice"
																	? ""
																	: "cr"}{" "}
															</Text>
															<Text
																selectable
																style={{
																	fontSize:
																		8 *
																		this
																			.state
																			.scale,
																	// color: entry.type == 'Invoice' ? RED : GREEN,
																	color: FONT_DARK,
																	fontWeight:
																		"700",
																	// fontFamily: 'Avenir Next',
																	marginRight:
																		5 *
																		this
																			.state
																			.scale,
																	textAlign:
																		"right",
																}}>
																{currencyFormat(
																	parseFloat(
																		entry.total,
																	),
																)}
															</Text>
														</View>

														<View
															style={{
																width:
																	80 *
																	this.state
																		.scale,
																flexDirection:
																	"row",
																justifyContent:
																	"flex-end",
																// opacity: 0.6,
															}}>
															<Text
																adjustsFontSizeToFit
																numberOfLines={
																	1
																}
																selectable
																style={{
																	fontSize:
																		8 *
																		this
																			.state
																			.scale,
																	// color: FONT_ALMOSTWHITE,
																	color: FONT_DARK,
																	fontWeight:
																		balance_ >
																		0
																			? "700"
																			: "300",
																	// fontFamily: 'Avenir Next',
																	marginRight:
																		5 *
																		this
																			.state
																			.scale,
																	opacity:
																		balance_ >
																		0
																			? 1
																			: 0.8,
																	textAlign:
																		"right",
																}}>
																{currencyFormat(
																	balance_,
																)}
															</Text>
														</View>
													</View>
												</View>
											);
										})}
									{this.state.loading ? (
										<View
											style={{
												flex: 1,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<View
												style={{
													width: 35,
													height: 35,
													borderRadius: 20,
													justifyContent: "center",
													alignItems: "center",
													backgroundColor: FONT_DARK,
													opacity: 0.2,
													position: "absolute",
												}}></View>
											<ActivityIndicator
												size='small'
												color='#FFF'
											/>
										</View>
									) : null}
								</View>

								<View
									style={{
										flexDirection: "row",
										borderBottomColor: "#28282F",
										borderBottomWidth: 0.5,
										alignItems: "center",
										marginBottom: 10 * this.state.scale,
										marginTop: 10 * this.state.scale,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											adjustsFontSizeToFit
											numberOfLines={1}
											selectable
											style={{
												fontSize: 12 * this.state.scale,
												color: FONT_DARK,
												fontFamily: "Avenir Next",
												marginRight:
													5 * this.state.scale,
												textAlign: "right",
											}}>
											{runningBalance > 0
												? "Amount Due"
												: "Balance"}
											:{"   "}
											<Text
												style={{
													fontWeight: "700",
												}}>
												R{" "}
												{currencyFormat(runningBalance)}
											</Text>
										</Text>
									</View>
								</View>
							</View>
						}
					/>
				) : null} */}

				{/* <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.viewPdf}>
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: 'rgba(52, 52, 52, 0.6)',
            }}>
            <View
              style={{
                flex: 1,
              }}>
              <View
                style={{
                  height: 80,
                }}
              />
              <View
                style={{
                  width: this.props.screenWidth - 10,
                  height: this.props.screenHeight - 100,
                  backgroundColor: '#191D24',
                  alignItems: 'center',
                  borderRadius: 12,
                  padding: 10,
                }}>
                <View
                  style={{
                    //   backgroundColor: 'red',
                    width: '100%',
                    height: 'auto',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderBottomWidth: 0.5,
                    paddingBottom: 10,
                    borderBottomColor: '#E8E8E8',
                  }}>
                  <TouchableOpacity
                    onPress={async () => {
                      await this.setState({
                        documentTitle: '',
                        viewPdf: false,
                        pdfDocument: '',
                      });
                    }}>
                    <Image
                      style={{
                        width: 30,
                        height: 30,
                        resizeMode: 'contain',
                        marginRight: 10,
                      }}
                      source={require('../../../assets/images/close.png')}
                    />
                  </TouchableOpacity>
                  <View>
                    <Text
                      style={{
                        fontWeight: '700',
                        fontSize: 22,
                        color: '#F6F8FE',
                      }}>
                      Preview
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flex: 1,
                    width: '100%',
                    // backgroundColor: 'red',
                  }}>
                  <ScrollView>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#191D24',
                      }}>
                      <PDFViewer
                        file={this.state.pdfDocument}
                        screenWidth={this.props.screenWidth}
                        screenHeight={this.props.screenHeight - 170}
                      />
                    </View>
                  </ScrollView>
                </View>
              </View>
            </View>
          </View>
        </Modal> */}
			</View>
		);
	}
}

export default PaymentInvoices;
