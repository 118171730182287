import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
	Dimensions,
} from "react-native";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import DatePicker from "react-datepicker";
import add_btn from "./../../assets/images/add_btn.png";
import { APP_COLOURS } from "../APP_VARS";
import debbieApp from "./../../assets/images/debbieApp.png";
import pdf from "./../../assets/images/pdf.png";
import male1 from "./../../assets/images/male1.png";
import male2 from "./../../assets/images/male2.png";
import TaskItem from "./TaskComponents/TaskItem";
import orientation2 from "../../assets/images/orientation2.png";
import close from "./../../assets/images/close.png";
import female1 from "./../../assets/images/female1.png";
import bin from "./../../assets/images/bin.png";
import arrow_down from "../../assets/images/arrow_down.png";
import checkBox5 from "../../assets/images/checkBox5.png";
import animationData1 from "../../assets/lottie/65681-3d-downloading-animation.json";
import PDFUploader from "./PDFUploader/PDFUploader";
import DateModal from "./ModalDate";
import MyPdfViewer from "./MyPdfViewer";

import Slider from "react-input-slider";

import Lottie from "lottie-react";

import "./App.css";
import ModalTabs from "./ModalTabs";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function isEven(n) {
	return n % 2 == 0;
}

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function downloadPDF(pdf, entity, title) {
	const linkSource = `data:application/pdf;base64,${pdf[0].base64Data}`;
	const downloadLink = document.createElement("a");
	const fileName = entity + "_" + title + ".pdf";
	downloadLink.href = linkSource;
	// downloadLink.download = encodeURI(fileName);
	downloadLink.download = fileName;
	downloadLink.click();
	return true;
}
function downloadPDFDirect(base64Data, title) {
	const linkSource = `data:application/pdf;base64,${base64Data}`;
	const downloadLink = document.createElement("a");
	const fileName = title + ".pdf";
	downloadLink.href = linkSource;
	// downloadLink.download = encodeURI(fileName);
	downloadLink.download = fileName;
	downloadLink.click();
	return true;
}

class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAddItemsForm: false,
			loading: false,
			loadingDocument: false,
			selectedTab: "expenses",
			xPos: 0,
			yPos: 0,
			debbieDocuments: [],
			accounts: [],
			accountsPerMonth: [],
			selectedCompanyID: 0,
			reqiredDocumentTitle: "",
			selectedCompanyTitle: "",

			selectedAccountID: 0,
			selectedAccountTitle: "",
			newPDFData: "",

			downloadedPDFs: [],

			doc_date: "",
			fileType: "",
			fileName: "",

			selectedAccountData: "",
			dateSelectorMonths: [],
			uniqueMonths: [],

			scalePDF: 0.5,
		};
	}

	async componentDidMount() {
		await this.setState({
			loading: true,
		});
		let month = moment();
		await this.getAccounts();
		await this.selectMonth(month);
	}

	selectCompany = async (d) => {
		await this.setState({
			selectedCompanyID: d.id,
			selectedCompanyTitle: d.title,
			selectedAccountID: 0,
			selectedAccountTitle: "",
		});
		let month = moment();
		await this.selectMonth(month);
	};

	selectMonth = async (month) => {
		await this.setState({
			month: month,
			loading: true,
			showChart: false,
		});
		await this.calculateCalendar();
	};

	calculateCalendar = async () => {
		// let month = this.state.month;
		// let data = this.state.debbieDocuments.filter(
		// 	(d_) => d_.entity == this.state.selectedCompanyTitle,
		// );
		// const startOfMonth = moment(month)
		// 	.clone()
		// 	.startOf("month")
		// 	.format("YYYY-MM-DD");
		// const endOfMonth = moment(month)
		// 	.clone()
		// 	.endOf("month")
		// 	.format("YYYY-MM-DD");

		// let daysInMonth = moment(month).daysInMonth();

		// console.log("initial filter dates ", startOfMonth, " to ", endOfMonth);

		// // console.log(daysInMonth);

		// let calendar = [];
		// let day = startOfMonth;

		// for (let index = 0; index < daysInMonth; index++) {
		// 	// console.log(day);
		// 	await calendar.push(day);
		// 	day = moment(day).add(1, "day").format("YYYY-MM-DD");
		// }

		// await this.setState({
		// 	calendar: calendar,
		// });
		// console.log("calendar", JSON.stringify(calendar));

		// let filteredData = data.filter((obj) => {
		// 	return (
		// 		moment(new Date(obj.date)).format("MM yyyy") >=
		// 			moment(month).format("MM yyyy") &&
		// 		moment(new Date(obj.date)).format("MM yyyy") <=
		// 			moment(month).format("MM yyyy")
		// 	);
		// });

		// console.log("filteredData", JSON.stringify(filteredData));
		// await this.setState({
		// 	ledgerByMonth: filteredData,
		// 	loading: false,
		// });

		// //
		// //
		// //
		// // CALCULATE each unique month and yesr for each account event
		// let dates = [];
		// await data.forEach((d) => {
		// 	let formatDate = moment(d.date).format("YYYY-MM-DD");
		// 	dates.push(formatDate);
		// });
		// const sortDates = await dates.sort(function (a, b) {
		// 	let first = new Date(a);
		// 	let last = new Date(b);
		// 	return last - first;
		// });
		// var accountsPerMonth = [];
		// for (const x of sortDates) {
		// 	let m = moment(x).format("MMM YY");
		// 	let index = await accountsPerMonth.findIndex((d) => d.date == m);
		// 	// console.log(index);
		// 	if (index < 0) {
		// 		await accountsPerMonth.push({
		// 			date: m,
		// 			date_: moment(x),
		// 			count: 1,
		// 		});
		// 	} else {
		// 		accountsPerMonth[index].count =
		// 			accountsPerMonth[index].count + 1;
		// 	}
		// }
		// console.log("accountsPerMonth ====", accountsPerMonth);
		// //
		// await this.setState({
		// 	accountsPerMonth: accountsPerMonth,
		// });

		// //
		// //
		// // GROUP EACH ACCOUNT BALANCE FOR THE MONTH

		// const uniqueAccounts = [
		// 	...new Set(filteredData.map((item) => item.title)),
		// ];

		// console.log(uniqueAccounts);
		// let groupedAccounts = [];
		// uniqueAccounts.forEach((el) => {
		// 	let array = this.state.ledger.filter((d) => d.title == el);
		// 	let amountTotal = array.reduce(
		// 		(prev, cs, index) => prev + cs.amount,
		// 		0,
		// 	);
		// 	let obj = {
		// 		title: el,
		// 		amount: amountTotal,
		// 	};
		// 	groupedAccounts.push(obj);
		// });

		// await this.setState({
		// 	groupedAccounts: groupedAccounts,
		// });
		// //
		//

		// let month = moment().add(-6, "months");

		// const endOfMonthSixMonthsAgo = await moment(month)
		// 	.clone()
		// 	.endOf("month")
		// 	.format("YYYY-MM-DD");
		// await console.log(endOfMonthSixMonthsAgo);

		// await calendar.concat(endOfMonthSixMonthsAgo);
		let calendar = [];
		let monthCount = moment().add(-6, "months");
		monthCount = await moment(monthCount)
			.clone()
			.endOf("month")
			.format("YYYY-MM-DD");

		for (let i of Array(7).keys()) {
			monthCount = moment(monthCount).add(1, "months").format("YYYY-MM-DD");
			monthCount = moment(monthCount)
				.clone()
				.endOf("month")
				.format("YYYY-MM-DD");
			let push_ = calendar.push(monthCount);
			// console.log(monthCount);
		}

		await console.log(calendar);

		await this.setState({
			dateSelectorMonths: calendar,
		});
	};

	// addItem = () => {
	// 	let obj = {
	// 		id: uuidv4(),
	// 		title: "Wes",
	// 		entity: "Westonry SA (Pty) ltd",
	// 		pdfUUID: "",
	// 		date_added: moment().format("YYYY-MM-DD HH:mm:ss"),
	// 	};

	// 	this.setState({
	// 		ledger: this.props.data.concat(obj),
	// 	});
	// };

	addRequiredDocument = () => {
		this.newAccountPOST();
	};

	newAccountPOST = async (obj) => {
		var raw = JSON.stringify({
			jwt: "1234567",
			id: uuidv4(),
			title: this.textinput.value,
			entity: this.state.selectedCompanyTitle,
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/post_new_account.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "404") {
					response.json().then((result) => {
						console.log("result", result);
						this.setState({
							accounts: [],
						});
						this.textinput.clear();
					});
				} else if (response.status === 200) {
					response.json().then((result) => {
						this.setState({
							accounts: result,
						});
						this.textinput.clear();
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	getAccounts = async () => {
		var raw = JSON.stringify({
			jwt: "1234567",
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/get_accounts.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "404") {
					response.json().then((result) => {
						console.log("result", result);
						this.setState({
							accounts: [],
						});
						this.textinput.clear();
					});
				} else if (response.status == "200") {
					response.json().then((result) => {
						this.setState({
							accounts: result,
						});
						this.textinput.clear();
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	newPDFFilePOST = async (obj) => {
		if (
			this.textinput2.value == "" ||
			this.state.newPDFData == "" ||
			this.state.account_id == "" ||
			this.state.doc_date == ""
		) {
			alert("Please complete all fields");
			return;
		}
		var raw = {
			jwt: "1234567",

			id: uuidv4(),
			title: this.textinput2.value,
			entity: this.state.selectedCompanyTitle,
			doc_type: "application/pdf",
			base64Data: this.state.newPDFData,
			entityID: this.state.selectedCompanyID,
			account_id: this.state.selectedAccountID,
			doc_date: moment(this.state.doc_date).toISOString(),
			date_added: moment().toISOString(),
		};

		this.setState({
			loading: true,
			loadingDocument: true,
		});

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/post_base64_pdf.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "404") {
					response.json().then((result) => {
						console.log("result", result);
						this.setState({
							accounts: [],
							loadingDocument: false,
						});
						this.textinput2.clear();
						alert("Upload error");
					});
				} else if (response.status == "200") {
					this.setState({
						debbieDocuments: this.state.debbieDocuments.concat(raw),
						newPDFData: "",
						doc_date: "",
						fileName: "",
						loading: true,
						loadingDocument: false,
					});
					this.textinput2.clear();
				}
			})
			.catch((error) => console.log("error", error));
	};

	getAccountPDFs = (d) => {
		this.setState({
			selectedAccountData: d,
			selectedAccountID: d.id,
			selectedAccountTitle: d.title,
			loading: true,
		});
		console.log(d.id);

		var raw = {
			jwt: "1234567",

			account_id: d.id,
		};

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/get_pdf_documents.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "404") {
					response.json().then((result) => {
						console.log("result", result);
						this.setState({
							debbieDocuments: [],
							loading: false,
							loadingDocument: false,
						});
					});
				} else if (response.status == "200") {
					response.json().then(async (result) => {
						await this.setState({
							debbieDocuments: result,
							loadingDocument: false,
						});
					});
				}
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({
					loadingDocument: false,
				});
			});
	};

	getBase64Doc = (d) => {
		this.setState({
			loading: true,
			showDocument: false,
			loadingDocument: true,
		});
		console.log(d);

		var raw = {
			jwt: "1234567",
			id: d.id,
		};

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/get_base64_doc.php",
			requestOptions,
		)
			.then((response) => {
				// console.log(response);
				if (response.status == "404") {
					response.json().then((result) => {
						console.log("result", result);
						this.setState({
							base64DocumentData: "",
							showDocument: false,
							loading: false,
							loadingDocument: false,
						});
					});
				} else if (response.status == "200") {
					response.json().then((result) => {
						console.log(result);

						// window.open(
						// 	"data:application/pdf;base64," +
						// 		result[0].base64Data,
						// );

						this.setState({
							base64DocumentData: result[0].base64Data,
							documentData: result[0],
							documentTitle: d.title,
							showDocument: true,
							loading: false,
							loadingDocument: false,
						});
					});
				}
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({
					loadingDocument: false,
				});
			});
	};

	getdownloadPDF = (d) => {
		this.setState({
			loading: true,
			showDocument: false,
			loadingDocument: true,
		});
		console.log(d.id);

		var raw = {
			jwt: "1234567",
			id: d.id,
		};

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/get_base64_doc.php",
			requestOptions,
		)
			.then((response) => {
				// console.log(response);
				if (response.status == "404") {
					response.json().then((result) => {
						console.log("result", result);
						this.setState({
							base64DocumentData: "",
							showDocument: false,
							loading: false,
							loadingDocument: false,
						});
					});
				} else if (response.status == "200") {
					response.json().then(async (result) => {
						// console.log(result);

						const download_ = await downloadPDF(
							result,
							d.entity,
							d.title,
						);

						if (download_) {
							this.setState({
								loadingDocument: false,
							});
						}

						this.setState({
							downloadedPDFs: this.state.downloadedPDFs.concat({
								id: result[0].id,
								loadingDocument: false,
							}),
						});
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	deleteAccountPDF = (d) => {
		this.setState({
			loading: true,
			loadingDocument: true,
		});

		var raw = {
			jwt: "1234567",
			id: d.id,
		};

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/delete_pdf_documents.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					this.getAccountPDFs(this.state.selectedAccountData);
				} else {
					alert("error deleting");
					this.setState({
						loading: false,
						loadingDocument: false,
					});
				}
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({
					loading: false,
					loadingDocument: false,
				});
			});
	};

	showAddItemsFormFunc = (event) => {
		event.preventDefault();
		const layout = event.nativeEvent;
		let x = layout.clientX;
		let y = layout.clientY;
		this.setState({
			showAddItemsForm: true,
			xPos: x,
			yPos: y,
		});

		console.log(layout);
	};

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		// let accounts = this.state.accounts.filter(
		// 	(d_) => d_.entity == this.state.selectedCompanyTitle,
		// );
		// let debbieDocuments = this.state.debbieDocuments.filter(
		// 	(d_) => d_.entity == this.state.selectedCompanyTitle,
		// );

		let accounts = this.state.accounts;
		let debbieDocuments = this.state.debbieDocuments;

		return (
			<View
				style={{
					flex: 1,

					padding: 0,
					height: this.props.screenHeight,
				}}>
				<View
					style={{
						height: "auto",
						width: "100%",
						padding: 4,
					}}>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							backgroundColor: "#FFF",
							borderRadius: 25,
							overflow: "hidden",
							padding: 5,
							marginBottom: 5,
							paddingLeft: 15,
						}}>
						<TouchableOpacity onPress={() => this.props.showSidebar()}>
							{/* <Image
								source={debbieApp}
								style={{
									width: 100,
									height: 40,
									// borderRadius: 20,
									resizeMode: "contain",
								}}
							/> */}
							<View>
								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										marginRight: 10,
									}}>
									<Font style={{}} family='Poppins' weight={800}>
										{this.props.selectedEntity}
									</Font>
								</Text>
							</View>
						</TouchableOpacity>

						<View
							style={{
								marginRight: 10,
								paddingLeft: 5,
							}}>
							<ModalTabs
								tabs={[
									{ id: 1, title: "Expenses" },
									{ id: 2, title: "Monthly" },
									{ id: 4, title: "Expenses" },
								]}
								selectedTab={(d) =>
									this.setState({
										tab: d,
									})
								}
							/>
						</View>
					</View>
				</View>

				<View
					style={{
						flexDirection: "row",
						flex: 1,
					}}>
					<View
						style={{
							flex: 1,
							padding: 5,
						}}>
						<View
							style={{
								flex: 1,
								borderRadius: 10,
								padding: 5,
								backgroundColor: "#E8E8E8",
							}}>
							<View
								style={{
									flex: 1,

									borderRadius: 10,
									padding: 5,
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<View
										style={{
											height: "auto",
											marginTop: 0,
											padding: 5,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												fontFamily: "Avenir",
												fontWeight: "700",
												fontSize: 17,
												marginBottom: 10,
												height: 20,
											}}>
											Expenses
										</Text>
									</View>
									<ScrollView
										contentContainerStyle={{
											paddingRight: 10,
										}}>
										{accounts.map((d, i) => (
											<TouchableOpacity
												onPress={() => {
													this.getAccountPDFs(d);
												}}>
												<View
													style={{
														backgroundColor:
															this.state.selectedAccountID ==
															d.id
																? "#FFF"
																: "#E8E8E8",
														padding: 9,

														flexDirection: "row",
														alignItems: "center",
														justifyContent: "center",
														marginBottom: 5,
														borderRadius: 9,
														borderWidth: 0.5,
														borderColor:
															this.state.selectedAccountID ==
															d.id
																? APP_COLOURS.BLUE
																: "#DDDDDD",
													}}>
													<View
														style={{
															flex: 1,
														}}>
														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontFamily: "Avenir",
																fontWeight: "400",
																fontSize: 15,
															}}>
															{d.title}
														</Text>
													</View>
													<Image
														source={orientation2}
														style={{
															width: 20,
															height: 20,
															// borderRadius: 25,
															resizeMode: "contain",
															marginRight: 5,
														}}
													/>
												</View>
											</TouchableOpacity>
										))}

										<View
											style={{
												height: 40,
												width: 40,
											}}
										/>
									</ScrollView>
								</View>
								<View
									style={{
										flexDirection: "row",
										width: "100%",
										alignItems: "center",
										borderTopColor: "#797979",
										borderTopWidth: 0.3,
										paddingTop: 10,
										padding: 5,
									}}>
									<TextInput
										ref={(ref) => {
											this.textinput = ref;
										}}
										style={{
											flex: 1,
											padding: 10,
											fontSize: 16,
											borderRadius: 9,
											color: APP_COLOURS.BG2,
											fontWeight: "200",
											fontFamily: "Avenir",
											borderWidth: 0.3,
											borderColor: APP_COLOURS.BG2,
										}}
										placeholderTextColor={"#797979"}
										multiline
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={false}
										keyboardType='default'
										textContentType='none'
										placeholder={`New account title `}
										// onChangeText={(text) => {
										// 	this.setState({
										// 		reqiredDocumentTitle: text,
										// 	});
										// }}
									/>
									<TouchableOpacity
										style={{
											alignItems: "center",
											justifyContent: "center",
										}}
										onPress={() => this.addRequiredDocument()}>
										<View>
											<Image
												source={add_btn}
												style={{
													width: 30,
													height: 30,
													resizeMode: "contain",
													marginLeft: 9,
												}}
											/>
										</View>
									</TouchableOpacity>
								</View>
							</View>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							padding: 5,
						}}>
						<View
							style={{
								flex: 1,
								backgroundColor: "#E8E8E8",
								borderRadius: 10,
								padding: 5,
								shadowColor: "#797979",
								shadowOffset: {
									width: 0,
									height: 8,
								},
								shadowOpacity: 0.44,
								shadowRadius: 18.32,
								elevation: 25,
							}}>
							<View
								style={{
									height: "auto",
									marginTop: 0,
									padding: 5,
								}}>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontFamily: "Avenir",
										fontWeight: "700",
										fontSize: 17,
										marginBottom: 10,
										height: 20,
									}}>
									{this.state.selectedAccountTitle}
								</Text>
							</View>

							<ScrollView
								contentContainerStyle={{
									paddingRight: 10,
								}}>
								<View
									style={{
										flex: 1,
									}}>
									{debbieDocuments
										.filter(
											(d_) =>
												d_.account_id ==
												this.state.selectedAccountID,
										)
										.sort(
											(a, b) =>
												new Date(a.doc_date) < new Date(b.doc_date),
										)
										.map((d, i) => (
											<View
												style={{
													backgroundColor: isEven(i)
														? APP_COLOURS.WHITE_TRANSPARENT
														: "#E8E8E8",
													padding: 9,
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "center",
													marginBottom: 5,
													// borderRadius: 9,
													// borderWidth: 0.5,
													// borderColor: "#DDDDDD",
												}}>
												<TouchableOpacity
													onPress={() => this.getBase64Doc(d)}>
													<Image
														source={pdf}
														style={{
															width: 20,
															height: 20,
															// borderRadius: 25,
															resizeMode: "contain",
															marginRight: 10,
														}}
													/>
												</TouchableOpacity>

												{this.state.downloadedPDFs.some(
													(dd) => dd.id == d.id,
												) ? (
													<Image
														source={checkBox5}
														style={{
															width: 18,
															height: 18,
															// borderRadius: 25,
															resizeMode: "contain",
															marginRight: 10,
														}}
													/>
												) : (
													<TouchableOpacity
														onPress={async () => {
															this.getdownloadPDF(d);
														}}>
														<Image
															source={arrow_down}
															style={{
																width: 18,
																height: 18,
																// borderRadius: 25,
																resizeMode: "contain",
																marginRight: 10,
															}}
														/>
													</TouchableOpacity>
												)}

												<View
													style={{
														flex: 1,
													}}>
													<Text
														style={{
															color: APP_COLOURS.BG2,
															fontFamily: "Avenir",
															fontWeight: "400",
															fontSize: 14,
														}}>
														{d.title}
													</Text>
												</View>
												<View
													style={{
														flex: 1,
													}}>
													<Text
														style={{
															color: APP_COLOURS.BG2,
															fontFamily: "Avenir",
															fontWeight: "400",
															fontSize: 14,
														}}>
														{moment(d.doc_date).format("MMM YY")}
													</Text>
												</View>

												{this.props.user_data.permission ===
												"tetrice" ? (
													<TouchableOpacity
														onPress={() =>
															this.deleteAccountPDF(d)
														}>
														<Image
															source={bin}
															style={{
																width: 20,
																height: 20,
																// borderRadius: 25,
																resizeMode: "contain",
																marginRight: 5,
															}}
														/>
													</TouchableOpacity>
												) : (
													<View />
												)}
											</View>
										))}

									<View
										style={{
											height: 10,
											width: 100,
											// backgroundColor: "red",
										}}
									/>
								</View>
							</ScrollView>

							{this.state.newPDFData !== "" ? (
								<View
									style={{
										position: "absolute",
										left: 10,
										bottom: 120,
										backgroundColor: "#E8E8E8",
										borderRadius: 12,
										padding: 5,
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 8,
										},
										shadowOpacity: 0.44,
										shadowRadius: 10.32,
										elevation: 16,
										zIndex: 99,
									}}>
									<View
										style={{
											position: "absolute",
											left: -10,
											top: -10,
											shadowColor: "#000",
											shadowOffset: {
												width: 0,
												height: 8,
											},
											shadowOpacity: 0.44,
											shadowRadius: 10.32,
											elevation: 16,
											zIndex: 99,
											borderRadius: 15,
										}}>
										<TouchableOpacity
											onPress={() =>
												this.setState({
													newPDFData: "",
												})
											}>
											<Image
												source={close}
												style={{
													width: 25,
													height: 25,
													// marginRight: 20,
													resizeMode: "contain",
												}}
											/>
										</TouchableOpacity>
									</View>

									{this.state.fileType == "image/png" ? (
										<Image
											source={{
												uri: `data:image/png;base64,${this.state.newPDFData}`,
											}}
											style={{
												width: 350,
												height: 300,
												resizeMode: "contain",
											}}
										/>
									) : null}

									{/* {this.state.fileType == "application/pdf" ? (
									<object
										width='350'
										height='300'
										data={`data:application/pdf;base64,${this.state.newPDFData}`}
										type='application/pdf'></object>
								) : null} */}

									{this.state.fileType == "application/pdf" ? (
										<div
											style={{
												width: 350,
												height: 300,
												overflow: "scroll",
											}}>
											<MyPdfViewer
												PdfData={`data:application/pdf;base64,${this.state.newPDFData}`}
												scale={0.2}
											/>
										</div>
									) : null}
								</View>
							) : null}

							<View
								style={{
									// flexDirection: "row",
									width: "100%",
									// alignItems: "center",
									borderTopColor: "#797979",
									borderTopWidth: 0.3,
									paddingTop: 5,
								}}>
								<View
									style={{
										padding: 5,
										flexDirection: "row",
										alignItems: "center",
									}}>
									<PDFUploader
										multiple={false}
										isFile={this.state.newPDFData}
										base64String={(base64String, data) => {
											// console.log(data);
											this.setState({
												newPDFData: base64String,
												fileType: data.type,
												fileName: data.name,
											});
										}}
									/>
								</View>
								{this.state.fileName === "" ? (
									<View />
								) : (
									<View
										style={{
											flex: 1,
											flexDirection: "row",
											alignItems: "center",
										}}>
										<DateModal
											data={this.state.dateSelectorMonths.sort(
												(a, b) => new Date(a) < new Date(b),
											)}
											selectedDate={(d) =>
												this.setState({
													doc_date: d,
												})
											}
										/>
										<TextInput
											ref={(ref) => {
												this.textinput2 = ref;
											}}
											style={{
												flex: 1,
												height: 40,
												padding: 10,
												fontSize: 16,
												borderRadius: 9,
												color: APP_COLOURS.BG2,
												fontWeight: "200",
												fontFamily: "Avenir",
												borderWidth: 0.5,
												borderColor: APP_COLOURS.BLUE,
												margin: 5,
											}}
											placeholderTextColor={"#797979"}
											autoCapitalize='sentences'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											// placeholder={this.state.fileName}
											defaultValue={this.state.fileName}

											// onChangeText={(text) => {
											// 	this.setState({
											// 		reqiredDocumentTitle: text,
											// 	});
											// }}
										/>

										{this.state.doc_date === "" ? (
											<View />
										) : (
											<TouchableOpacity
												style={{
													alignItems: "center",
													justifyContent: "center",
												}}
												onPress={() => this.newPDFFilePOST()}>
												<View>
													<Image
														source={add_btn}
														style={{
															width: 30,
															height: 30,
															resizeMode: "contain",
															marginLeft: 9,
														}}
													/>
												</View>
											</TouchableOpacity>
										)}
									</View>
								)}
							</View>
						</View>
					</View>
				</View>

				{this.state.showDocument
					? this.pdfViewModal(this.state.documentData)
					: null}

				{this.state.loadingDocument ? this.loadingModal() : <View />}

				{/* {this.state.isOpen ? (
					<View
						style={{
							position: "absolute",
							bottom: 50,
							left: this.state.xPos - 330,
						}}>
					
						<DatePicker
							selected={this.state.doc_date}
							onChange={(date) =>
								this.setState({
									doc_date: date,
									isOpen: false,
								})
							}
							closeOnScroll={true}
							dateFormat='MM/yyyy'
							showMonthYearPicker
							showFullMonthYearPicker
							inline
						/>
						
					</View>
				) : null} */}
			</View>
		);
	}

	pdfViewModal = (obj) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							// width: this.props.screenWidth,
							// maxWidth: 500,
							height: this.props.screenHeight,
							// maxHeight: 500,
							width: "auto",
							backgroundColor: "#E8E8E8",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								// justifyContent: "center",
								width: "100%",
								padding: 5,
								justifyContent: "space-between",
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											showDocument: false,
										})
									}>
									<Image
										source={close}
										style={{
											width: 20,
											height: 20,
											marginRight: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>

								<Text
									allowFontScaling={false}
									style={{
										fontSize: 15,
										fontFamily: "Avenir Next",
										margin: 5,
										paddingLeft: 10,
										fontWeight: "700",
									}}>
									{this.state.documentTitle}
								</Text>
							</View>

							<TouchableOpacity
								onPress={async () => {
									downloadPDFDirect(
										obj.base64Data,
										this.state.documentTitle,
									);
								}}>
								<Image
									source={arrow_down}
									style={{
										width: 25,
										height: 25,
										// borderRadius: 25,
										resizeMode: "contain",
										marginRight: 10,
									}}
								/>
							</TouchableOpacity>

							<Image
								source={debbieApp}
								style={{
									width: 140,
									height: 45,
									// borderRadius: 20,
									resizeMode: "contain",
									marginRight: 10,
								}}
							/>
						</View>

						<View
							style={{
								flexDirection: "row",
							}}>
							<Slider
								axis='x'
								x={this.state.scalePDF}
								xstep={0.01}
								xmax={2}
								xmin={0.05}
								onChange={({ x }) =>
									this.setState({
										scalePDF: x,
									})
								}
							/>
							<Text
								style={{
									marginLeft: 10,
								}}>
								{this.state.scalePDF.toFixed(2)}x
							</Text>
						</View>

						<View
							style={{
								flex: 1,
								// width: this.props.screenWidth - 20,
								width: "auto",
								height: this.props.screenHeight - 80,
								padding: 2,
								borderRadius: 5,
								borderWidth: 0.3,
								borderColor: "#C5C5C5",
								maxWidth: this.props.screenWidth - 20,
								alignItems: "center",
							}}>
							<ScrollView>
								<MyPdfViewer
									PdfData={`data:application/pdf;base64,${obj.base64Data}`}
									scale={this.state.scalePDF}
									// width={this.props.screenWidth}
								/>
							</ScrollView>

							{/* <embed
									type='application/pdf'
									src={`data:application/pdf;base64,${obj.base64Data}`}
									width={this.props.screenWidth}
									height='100%'></embed> */}
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	loadingModal = (obj) => {
		let width = Dimensions.get("window").width;
		let height = Dimensions.get("window").height;

		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							backgroundColor: "#E8E8E8",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
							padding: 40,
						}}>
						<Text
							allowFontScaling={false}
							style={{
								fontSize: 18,
								fontFamily: "Avenir Next",
								margin: 5,
								fontWeight: "700",
								marginBottom: 16,
							}}>
							Processing Document
						</Text>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								// justifyContent: "center",
								width: "100%",
								padding: 5,
								justifyContent: "space-between",
							}}>
							<Lottie
								animationData={animationData1}
								autoPlay={true}
								style={{
									width: 200,
									height: 200,
								}}
							/>
						</View>
						<View
							style={{
								// width: width,
								// height: height - 90,
								marginTop: 20,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										loadingDocument: false,
									})
								}>
								<View
									style={{
										width: "100%",
										backgroundColor: APP_COLOURS.BLUE,
										padding: 9,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.WHITE,
											fontSize: 11,
										}}>
										Cancel download
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	};
}

export default Settings;
