import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from "react-native";
// import Slide from "react-reveal/Slide";
// import moment from "moment";
// import male1 from "./../../../assets/images/male1.png";
import { APP_COLOURS } from "../../APP_VARS";
import moment from "moment";

function isEven(n) {
	return n % 2 == 0;
}

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class ModalChangeLog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenWidth: this.props.screenWidth,
			screenHeight: this.props.screenHeight,
			hover: -1,
		};
	}

	logOut = async () => {
		this.props.logOut();
	};

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		// let avatars = {
		// 	1: require("./../../../assets/images/male2.png"),
		// 	2: require("./../../assets/images/male1.png"),
		// 	3: require("./../../assets/images/female1.png"),
		// };
		return (
			<Modal
				transparent={true}
				visible={true}
				animationType='fade'
				shouldCloseOnOverlayClick={true}
				style={{
					width: this.state.screenWidth,
					height: this.state.screenHeight,
				}}>
				<TouchableOpacity
					activeOpacity={1}
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
					}}
					onPress={() => this.props.closeModal()}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.8)",
							width: this.state.screenWidth,
							height: this.state.screenHeight,
						}}></View>
				</TouchableOpacity>

				<View
					style={{
						// width: 200,
						maxHeight: this.props.screenHeight / 2,
						backgroundColor: APP_COLOURS.PRIMARY,
						position: "absolute",
						top: this.props.yPos + 20,
						left: this.props.xPos - 10,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.25,
						shadowRadius: 7.84,
						elevation: 12,
						borderRadius: 10,
						padding: 10,
						zIndex: 999,
					}}>
					<View
						style={{
							height: "auto",
							padding: 4,
						}}>
						<Text
							style={{
								fontFamily: "Avenir",
								fontSize: 10,
								color: "#FFF",
								fontWeight: "800",
							}}>
							Change log
						</Text>
					</View>
					<ScrollView>
						<View
							style={{
								flexDirection: "column",
							}}>
							{this.props.changeLog
								.sort(function (a, b) {
									let first = new Date(a.date_created);
									let last = new Date(b.date_created);
									return last - first;
								})
								.map((d, i) => (
									<View
										onMouseEnter={() => this.setState({ hover: i })}
										onMouseLeave={() => this.setState({ hover: -1 })}
										style={{
											alignItems: "center",
											justifyContent: "center",
											width: 250,
											backgroundColor:
												this.state.hover === i
													? APP_COLOURS.BUTTON2
													: "transparent",
											height: "auto",
											// marginBottom: 5,
											flexDirection: "row",
											borderRadius: 5,
											borderBottomColor: "rgba(52, 52, 52, 0.6)",
											borderBottomWidth: 0.5,
											padding: 5,
										}}>
										{/* <Image
										source={avatars[d.addedBy.avatar]}
										style={{
											width: 20,
											height: 20,
											borderRadius: 20,
											resizeMode: "contain",
											backgroundColor: "#FFF",
											marginRight: 5,
										}}
									/> */}
										<View
											style={{
												flex: 1,
												flexDirection: "row",
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 10,
													color: APP_COLOURS.WHITE,
													fontWeight: "800",
												}}>
												{d.addedBy.first_name}{" "}
												<Text
													style={{
														fontWeight: "400",
														opacity: 0.8,
													}}>
													{d.title}
												</Text>
											</Text>
										</View>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 8,
												fontWeight: "400",
												color: APP_COLOURS.WHITE,
											}}>
											{moment(d.date_created).format(
												"DD/MM/YY HH:mm",
											)}
										</Text>
									</View>
								))}
						</View>
						<View
							style={{
								height: 10,
							}}
						/>
					</ScrollView>
					<TouchableOpacity onPress={() => this.props.deleteLog()}>
						<View
							style={{
								// flex: 1,
								backgroundColor: APP_COLOURS.RED,
								borderRadius: 10,
								justifyContent: "center",
								flexDirection: "row",
								alignItems: "center",
								height: "auto",
								padding: 4,
							}}>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 10,
									color: "#FFF",
									fontWeight: "300",
								}}>
								Delete log
							</Text>
						</View>
					</TouchableOpacity>
				</View>
			</Modal>
		);
	}
}

export default ModalChangeLog;
