import React, { Component } from "react";
import { View, Text } from "react-native";

class Tickets extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: "red",
				}}>
				<Text> Tickets </Text>
			</View>
		);
	}
}

export default Tickets;
