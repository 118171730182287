import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
} from "react-native";
import moment from "moment";
import bin from "./../../../assets/images/bin.png";
import { APP_COLOURS } from "../../APP_VARS";

function firstAndLast(array) {
	var firstItem = array[0];
	var lastItem = array[array.length - 1];

	var objOutput = {
		start: firstItem,
		end: lastItem,
	};

	return objOutput;
}

export default class CalendarGantt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			new_start_date: new Date(),
			new_end_date: new Date(),
			calendar: [],
			calendarDays: [],
			// startweekNumber: moment().startOf("month").week(),
			// endweekNumber: moment().endOf("month").week(),
			startweekNumber: moment().subtract(2, "week").week(),
			endweekNumber: moment().add(3, "week").week(),
			calStartEnd: "",

			fontSize: 12,

			colours: ["#0468FC", "#E0A749", "#FF5F5F", "#45B88B", "#AF78FF"],
		};
	}

	calculateCalendar = async () => {
		const startWeek = this.state.startweekNumber;
		const endWeek = this.state.endweekNumber;

		// const startWeek = moment().startOf('month').week();
		// const endWeek = moment().endOf('month').week();

		let calendar = [];
		let calendarDays = [];
		for (var week = startWeek; week < endWeek; week++) {
			let days = await Array(7)
				.fill(0)
				.map((n, i) =>
					moment()
						.week(week)
						.startOf("week")
						.clone()
						.add(n + i, "day"),
				);
			// console.log(days);
			await calendar.push({
				week: week,
				days: days,
			});

			calendarDays = await [...calendarDays, ...days];
		}

		let sorted = await calendarDays.sort(function (a, b) {
			let first = new Date(a.date);
			let last = new Date(b.date);
			return first - last;
		});
		// console.log('sorted', sorted);
		let diff = await firstAndLast(sorted);

		await this.setState({
			calendar: calendar,
			calendarDays: calendarDays,
			calStartEnd: diff,
		});
		// await this.addTodayPreviewList();
		// console.log("caslendar", {
		// 	calendar: calendar,
		// 	calendarDays: calendarDays,
		// 	calStartEnd: diff,
		// });
	};

	componentDidMount() {
		this.calculateCalendar();
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					// height: 400,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<View
							style={{
								width: 150,
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<TouchableOpacity
								onPress={async () => {
									let start = this.state.startweekNumber;
									let eow = this.state.endweekNumber - 1;

									if (eow > start) {
										await this.setState({
											endweekNumber: eow,
										});
										await this.calculateCalendar();
										// await this.saveAppStates();
									}
								}}>
								<Image
									style={{
										width: 25,
										height: 25,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/min_btn.png")}
								/>
							</TouchableOpacity>

							<Text
								style={{
									color: "#F6F8FE", // '#5A7BBA'
									fontSize: 17,
									fontWeight: "900",
									paddingBottom: 4,
								}}>
								{this.state.endweekNumber - this.state.startweekNumber}{" "}
								{this.state.endweekNumber - this.state.startweekNumber >
								1
									? "weeks"
									: "week"}
							</Text>

							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										endweekNumber: this.state.endweekNumber + 1,
									});
									await this.calculateCalendar();
									// await this.saveAppStates();
								}}>
								<Image
									style={{
										width: 25,
										height: 25,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/add_btn.png")}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: 150,
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										startweekNumber: this.state.startweekNumber - 1,
										endweekNumber: this.state.endweekNumber - 1,
									});
									await this.calculateCalendar();
									// await this.saveAppStates();
								}}>
								<Image
									style={{
										width: 45,
										height: 45,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/left_btn.png")}
								/>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										startweekNumber: this.state.startweekNumber + 1,
										endweekNumber: this.state.endweekNumber + 1,
									});
									await this.calculateCalendar();
									// await this.saveAppStates();
								}}>
								<Image
									style={{
										width: 45,
										height: 45,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/right_btn.png")}
								/>
							</TouchableOpacity>
						</View>
					</View>
				</View>

				<View
					style={{
						flex: 1,
						// height: 400,
						flexDirection: "row",
					}}>
					{this.state.calendar.map((d, i) => (
						<View
							style={{
								flex: 1,
								marginleft: 1,
							}}>
							<View
								style={{
									height: 24,
									backgroundColor: "#026BFA",
									padding: 5,
									marginRight: 0.5,
								}}>
								<Text
									style={{
										color: "#FFF", // '#5A7BBA'
										fontSize: 12,
										fontWeight: "900",
										marginBottom: 16,
									}}>
									Week {d.week}, {moment(d.week, "week").format("MMM")}
								</Text>
							</View>

							<View
								style={{
									flex: 1,
									flexDirection: "row",
									borderRightWidth: 1,
									borderRightColor: "#28282F",
								}}>
								{d.days.map((d_, i_) => {
									let today = moment().format("DD MM");
									let day = moment(d_).format("DD MM");

									let dayName = moment(d_).format("ddd");

									let isWeekend = false;
									if (dayName == "Sat" || dayName == "Sun") {
										isWeekend = true;
									}

									let isToday = today == day;

									let startOfMonth = false;
									if (moment(d_).format("D") == 1) {
										startOfMonth = true;
									}

									return (
										<View
											key={i_}
											style={{
												flex: 1,
												borderRightWidth: 1,
												borderRightColor: "#28282F",
												borderLeftColor: startOfMonth
													? "#FFF"
													: "red",
												borderLeftWidth: isToday
													? 1
													: startOfMonth
													? 1
													: 0,
												backgroundColor: isToday
													? "rgba(255,0, 0, 0.1)"
													: isWeekend
													? "rgba(12,0, 0, 0.4)"
													: "transparent",
											}}>
											<Text
												adjustsFontSizeToFit
												numberOfLines={1}
												style={{
													color: "#FFF",
													fontSize: 8,
													fontWeight: "400",
												}}>
												{dayName}
											</Text>
											<Text
												adjustsFontSizeToFit
												numberOfLines={1}
												style={{
													color: "#FFF",
													fontSize: 10,
													fontWeight: "700",
												}}>
												{moment(d_).format("DD")}
											</Text>
											{/* <View
												style={{
													height: 10,
												}}>
												<Text
													style={{
														// display: startOfMonth ? 'flex' : 'none',
														color: "#FFF",
														fontSize: 8,
														fontWeight: "400",
													}}>
													{moment(d_).format("MMM")}
												</Text>
											</View> */}
										</View>
									);
								})}
							</View>
						</View>
					))}
				</View>

				<View
					style={{
						// flex: 1,
						width: "100%",
						position: "absolute",
						top: 100,
						height: this.props.height - 30,
					}}>
					<ScrollView>
						<View
							style={{
								height: 10,
							}}
						/>
						<View
							style={{
								flex: 1,
								width: "100%",
								flexDirection: "column",
							}}>
							{this.props.data
								.sort(function (a, b) {
									let first = new Date(a.dueDate);
									let last = new Date(b.dueDate);
									return first - last;
								})
								.map((main, index) => {
									let calendarDays = this.state.calendarDays;
									if (typeof calendarDays[0] !== "undefined") {
										let cs = moment(this.state.calStartEnd.start);
										let ce = moment(this.state.calStartEnd.end);
										let daysInViewCount =
											this.state.calendarDays.length;

										let leftStart = moment(main.dueDate).diff(
											cs,
											"days",
										);
										//
										// NOT MAKING IT SQUASH WHEN GOING LEFT
										if (leftStart >= daysInViewCount) {
											leftStart = daysInViewCount;
										}
										// NOT MAKING IT SQUASH WHEN GOING LEFT
										//

										let thisItemsDuration = 1;

										let rightEnd =
											daysInViewCount -
											thisItemsDuration -
											leftStart;
										if (leftStart < 0) {
											leftStart = 0;
										}
										//
										// NOT MAKING IT SQUASH WHEN GOING RIGHT
										if (rightEnd >= daysInViewCount) {
											rightEnd = daysInViewCount;
										}
										// NOT MAKING IT SQUASH WHEN GOING RIGHT
										//
										// OVERDUE
										let now = moment();
										let overdueColor = moment(main.dueDate).diff(
											now,
											"days",
										);

										let thisWeek = overdueColor < 5;

										return (
											<View
												onMouseEnter={() => {
													this.props.setHover(main.id);
												}}
												onMouseLeave={() => {
													this.props.setHover("");
												}}
												style={{
													width: "100%",
													// marginBottom: 40,
													// height: 30,
													opacity:
														main.status === "Done" ? 0.1 : 1,
												}}
												key={index}>
												<View
													style={{
														width: "100%",
														flexDirection: "row",
														alignItems: "center",
														backgroundColor:
															this.props.hover === main.id
																? "rgba(0,0, 0, 0.3)"
																: "transparent",
														marginBottom: 5,
													}}>
													<View
														style={{
															flex: 1,
															flexDirection: "row",
															marginBottom: 1,
															alignItems: "center",
														}}>
														<View
															style={{
																flex: leftStart,
																alignItems: "center",
																justifyContent: "flex-end",
																flexDirection: "row",
															}}
														/>

														<View
															style={{
																flex: 1,
																// flex: 20,
																backgroundColor:
																	overdueColor < 0
																		? APP_COLOURS.RED
																		: main.status ===
																		  "Pending"
																		? APP_COLOURS.ORANGE
																		: main.status === "Done"
																		? APP_COLOURS.GREEN
																		: main.status === "Stuck"
																		? APP_COLOURS.RED
																		: APP_COLOURS.ORANGE,
																marginBottom: 1,
																justifyContent: "center",
																alignItems: "center",
																flexDirection: "row",
																// padding: 14,
																borderRadius: 5,
																// width: 200,
																height: 27,
															}}>
															<Text
																style={{
																	color: "#FFF",

																	fontSize: 9,
																	fontWeight: "600",
																	position: "absolute",
																	// left: 5,
																	width: 300,
																	left: leftStart + 20,
																}}>
																{main.title}
															</Text>
														</View>

														<View
															style={{
																flex: rightEnd,
																alignItems: "center",
																justifyContent: "flex-start",
																flexDirection: "row",
															}}
														/>
													</View>
												</View>
											</View>
										);
									}
								})}
						</View>
						<View
							style={{
								height: 50,
							}}
						/>
					</ScrollView>
				</View>
			</View>
		);
	}
}
