import React, {Component} from 'react';
import {
  View,
  Text,
  ScrollView,
  TextInput,
  Keyboard,
  TouchableOpacity,
} from 'react-native';

function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const PRIMARY = '#2F2F39';
const SECONDARY = '#0F6EFF';
const GREY = '#797979';
const FONT = '#FFF';
const BORDERRADIUS = 20;

class searchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      array: this.props.array,
      backupArray: this.props.array,
      searchtext: '',
      qty: 0,
      description: '',
    };
  }

  filterSearch = async text => {
    if (text == '') {
      this.setState({
        array: this.state.backupArray,
      });
      return;
    } else {
      console.log(text);
      let array_ = await this.state.backupArray;
      console.log(array_);
      const newArr = array_.filter(d =>
        d.title.toLowerCase().includes(text.toLowerCase()),
      );

      console.log(newArr);

      await this.setState({
        array: newArr,
      });
    }
  };

  sendToParent = d => {
    let obj = {
      ...d,
      description: this.state.description,
    };
    this.props.selectedItem(obj, this.state.qty);
  };

  componentDidMount() {}

  render() {
    return (
      <View
        style={{
          flex: 1,
          minHeight: 300,
          backgroundColor: PRIMARY, // ,
          borderRadius: BORDERRADIUS,
          padding: 10,
        }}>
        <View
          style={{
            flex: 1,
            borderColor: GREY,
            borderWidth: 0.5,
            borderRadius: BORDERRADIUS * 0.6,
            padding: 5,
          }}>
          <TextInput
            style={{
              width: '100%',
              padding: 10,
              fontSize: 16,
              borderRadius: BORDERRADIUS / 2,
              // backgroundColor: FONT_ALMOSTWHITE,
              color: FONT,
              borderBottomColor: GREY,
              borderBottomWidth: 0.5,
              marginBottom: 10,
              fontWeight: '700',
              fontFamily: 'Avenir',
            }}
            placeholderTextColor={'#828282'}
            clearButtonMode="while-editing"
            // onFocus={() => this.setState({viewChart: false})}
            // onBlur={() => this.setState({viewChart: true})}
            autoCompleteType="off"
            // value={this.state.searchtext}
            keyboardType="decimal-pad"
            placeholder={'QTY'}
            onChangeText={text => this.setState({qty: parseFloat(text)})}
            // onSubmitEditing={() => Keyboard.dismiss()}
            ref={ref => {
              this.textInput = ref;
            }}
          />

          <TextInput
            style={{
              width: '100%',
              padding: 10,
              fontSize: 16,
              borderRadius: BORDERRADIUS / 2,
              color: FONT,
              borderBottomColor: GREY,
              borderBottomWidth: 0.5,
              marginBottom: 10,
              fontWeight: '700',
              fontFamily: 'Avenir',
            }}
            placeholderTextColor={'#828282'}
            clearButtonMode="while-editing"
            autoCompleteType="off"
            keyboardType="decimal-pad"
            placeholder={'Description'}
            onChangeText={text => this.setState({description: text})}
            // onSubmitEditing={() => Keyboard.dismiss()}
            ref={ref => {
              this.textInput = ref;
            }}
          />

          <TextInput
            style={{
              width: '100%',
              padding: 10,
              fontSize: 16,
              borderRadius: BORDERRADIUS / 2,
              // backgroundColor: FONT_ALMOSTWHITE,
              color: FONT,
              borderBottomColor: GREY,
              borderBottomWidth: 0.5,
              marginBottom: 10,
              fontWeight: '500',
              fontFamily: 'Avenir',
              marginTop: 30,
            }}
            placeholderTextColor={'#828282'}
            clearButtonMode="while-editing"
            // onFocus={() => this.setState({viewChart: false})}
            // onBlur={() => this.setState({viewChart: true})}
            autoCompleteType="off"
            // value={this.state.searchtext}
            placeholder={'Search'}
            onChangeText={text => this.filterSearch(text)}
            onSubmitEditing={() => Keyboard.dismiss()}
            ref={ref => {
              this.textInput = ref;
            }}
          />

          <ScrollView>
            <View
              style={{
                flex: 1,
              }}>
              {this.state.array.map((d, i) => (
                <TouchableOpacity key={i} onPress={() => this.sendToParent(d)}>
                  <View
                    style={{
                      width: '100%',
                      backgroundColor: SECONDARY,
                      borderRadius: BORDERRADIUS / 2,
                      flexDirection: 'row',
                      marginBottom: 8,
                      padding: 10,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      shadowColor: '#000',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.25,
                      shadowRadius: 3.84,

                      elevation: 2,
                    }}>
                    <Text
                      style={{
                        color: '#FFF',
                        fontSize: 16,
                        fontWeight: '500',
                      }}>
                      {d.title}
                    </Text>
                    <Text
                      style={{
                        color: '#FFF',
                        fontSize: 17,
                        fontWeight: '800',
                      }}>
                      {d.price.currency}{' '}
                      {currencyFormat(parseFloat(d.price.amount))}
                    </Text>
                  </View>
                </TouchableOpacity>
              ))}

              {this.state.array.length == 0 ? (
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Text
                    style={{
                      color: FONT,
                    }}>
                    No results found
                  </Text>
                </View>
              ) : null}
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

export default searchList;
