import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import Font from "react-font";
import moment from "moment";
import close from "../../../assets/images/close.png";
import tick from "../../../assets/images/tick.png";
import ImageUploader from "./ImageUploader";
function uuidv4() {
	return "xxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export default class NewDevice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: uuidv4(),
			entityType: "",
			title: "",
			logoImage: "",
			tradingName: "",
			coReg: "",
			vatNumber: "",
			taxRef: "",
			registeredAddress: "",
			website: "",
			mainEmail: "",
			phoneNumber: "",
			notes: "",
			documents: [],
			date_created: moment().toLocaleString(),
		};
	}

	render() {
		let form_fields = [
			{
				id: 2,
				editable: true,
				state: "title",
				title: "short title",
				autoCapitalize: false,
				validation: "name",
			},
			{
				id: 3,
				editable: true,
				state: "tradingName",
				title: "Registered Trading Name",
				autoCapitalize: false,
				validation: "name",
			},
			{
				id: 4,
				editable: true,
				state: "coReg",
				title: "CoReg number",
				autoCapitalize: false,
				validation: "none",
			},
			{
				id: 5,
				editable: true,
				state: "vatNumber",
				title: "VAT number",
				autoCapitalize: false,
				validation: "none",
			},
			{
				id: 5,
				editable: true,
				state: "taxRef",
				title: "Tax reference",
				autoCapitalize: false,
				validation: "none",
			},
			{
				id: 5,
				editable: true,
				state: "registeredAddress",
				title: "Registered address",
				autoCapitalize: false,
				validation: "none",
			},
			{
				id: 5,
				editable: true,
				state: "website",
				title: "website",
				autoCapitalize: false,
				validation: "none",
			},
			{
				id: 5,
				editable: true,
				state: "mainEmail",
				title: "Main email",
				autoCapitalize: false,
				validation: "none",
			},
			{
				id: 5,
				editable: true,
				state: "phoneNumber",
				title: "Phone number",
				autoCapitalize: false,
				validation: "none",
			},
		];
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView>
						<View
							style={{
								width: "100%",
								maxWidth: 500,
								minWidth: 450,
								height: "auto",
								backgroundColor: "white",
								borderRadius: 20,
								justifyContent: "center",
								alignItems: "center",
								marginTop: 30,
								marginBottom: 30,
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: 10,
									left: 10,
								}}>
								<TouchableOpacity onPress={() => this.props.close()}>
									<Image
										source={close}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									width: "100%",
									padding: 20,
									height: "auto",
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 17 + this.state.fontSize,
										fontFamily: "Avenir Next",
										textAlign: "center",
										fontWeight: "600",
										marginBottom: 20,
									}}>
									Add new entity
								</Text>

								<div
									style={{
										flex: 1,
										alignItems: "center",
										justifyContent: "center",
										marginBottom: 10,
									}}>
									<View
										style={
											{
												// justifyContent: "center",
											}
										}>
										<View
											style={{
												flexDirection: "row",
												marginTop: 5,
												// padding: 10,
												alignItems: "center",
												justifyContent: "center",
												// paddingLeft: 16,
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Entity type
												</Font>
											</Text>
										</View>
									</View>
									<View
										style={{
											width: "100%",
											flexDirection: "row",
										}}>
										<TouchableOpacity
											style={{
												flex: 1,
												margin: 10,
											}}
											onPress={async () => {
												this.setState({
													entityType: "company",
												});
											}}>
											<View
												style={{
													backgroundColor:
														this.state.entityType === "company"
															? APP_COLOURS.BG
															: "#FFF",
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BG,
													height: "auto",
													padding: 12,
													borderRadius: 12,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent: "center",
														color:
															this.state.entityType === "company"
																? "#FFF"
																: APP_COLOURS.BG,
														fontSize: 15,
													}}>
													<Font
														weight={
															this.state.entityType === "company"
																? 700
																: 500
														}
														family='Montserrat'>
														company
													</Font>
												</Text>
											</View>
										</TouchableOpacity>
										<TouchableOpacity
											style={{
												flex: 1,
												margin: 10,
											}}
											onPress={async () => {
												this.setState({
													entityType: "trust",
												});
											}}>
											<View
												style={{
													backgroundColor:
														this.state.entityType === "trust"
															? APP_COLOURS.BG2
															: "#FFF",
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BG,
													height: "auto",
													padding: 12,
													borderRadius: 12,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent: "center",
														color:
															this.state.entityType === "trust"
																? "#FFF"
																: APP_COLOURS.BG,
														fontSize: 15,
													}}>
													<Font
														weight={
															this.state.entityType === "trust"
																? 700
																: 500
														}
														family='Montserrat'>
														trust
													</Font>
												</Text>
											</View>
										</TouchableOpacity>
										<TouchableOpacity
											style={{
												flex: 1,
												margin: 10,
											}}
											onPress={async () => {
												this.setState({
													entityType: "personal",
												});
											}}>
											<View
												style={{
													backgroundColor:
														this.state.entityType === "personal"
															? APP_COLOURS.BG2
															: "#FFF",
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BG,
													height: "auto",
													padding: 12,
													borderRadius: 12,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent: "center",
														color:
															this.state.entityType ===
															"personal"
																? "#FFF"
																: APP_COLOURS.BG,
														fontSize: 15,
													}}>
													<Font
														weight={
															this.state.entityType ===
															"personal"
																? 700
																: 500
														}
														family='Montserrat'>
														personal
													</Font>
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</div>

								<div
									style={{
										flex: 1,
										alignItems: "center",
										justifyContent: "center",
										marginBottom: 10,
										padding: 10,
									}}>
									<View
										style={{
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
											borderWidth: 1,
											borderStyle: "dashed",
											borderColor: "#797979",
											borderRadius: 10,
											width: "100%",
											height: 100,
											overflow: "hidden",
										}}>
										{this.state.logoImage !== "" ? (
											<Image
												source={{
													uri: this.state.logoImage,
												}}
												style={{
													width: "100%",
													height: 100,
													resizeMode: "contain",
													backgroundColor: "#FFF",
												}}
											/>
										) : (
											<>
												<Text
													style={{
														color: APP_COLOURS.BG,
														fontSize: 12,
													}}>
													<Font weight={500} family='Montserrat'>
														Upload logo image
													</Font>
												</Text>
												<ImageUploader
													width={"100%"}
													height={100}
													base64String={(d) =>
														this.setState({
															logoImage: d,
														})
													}
												/>
											</>
										)}
									</View>
								</div>

								{form_fields.map((d, i) => {
									let validated = `${d.state}_valid`;
									return (
										<View
											key={i}
											style={{
												width: "auto",

												flexDirection: "row",
												alignItems: "center",
												margin: 10,
												marginBottom: 16,
											}}>
											<Text
												style={{
													opacity: this.state.unEditable ? 0.4 : 1,
													fontFamily: "Avenir",
													fontSize: 12,
													color: APP_COLOURS.PLACEHOLDER,
													fontWeight: "500",
													marginBottom: 4,
													position: "absolute",
													top: -12,
													left: 9,
													backgroundColor: "#FFF",
													padding: 4,
													borderRadius: 8,
													display:
														this.state[d.state] !== ""
															? "flex"
															: "none",
												}}>
												{d.title}
											</Text>
											<TextInput
												style={{
													width: "100%",
													opacity: this.state.unEditable ? 0.4 : 1,
													borderWidth: 1,
													borderColor:
														this.state[d.state] !== ""
															? "transparent"
															: typeof this.state[d.state] !==
															  "undefined"
															? "#000"
															: APP_COLOURS.RED,
													padding: 12,
													paddingLeft: 30,
													fontSize: 16,
													borderRadius: 8,
													backgroundColor: APP_COLOURS.WHITE,
													color: "#000",
													fontWeight: "600",
													fontFamily: "Avenir",
												}}
												placeholderTextColor={"#797979"}
												autoCapitalize='words'
												clearButtonMode='while-editing'
												autoCorrect={false}
												placeholder={d.title}
												value={this.state[d.state]}
												onChangeText={async (text) => {
													if (d.autoCapitalize) {
														text = await capitalizeName(text);
													}

													await this.setState({
														[d.state]: text,
													});
												}}
											/>

											{this.state.unEditable && !d.editable ? (
												<View
													style={{
														position: "absolute",
														width: "100%",
														height: "100%",
														backgroundColor: `rgba(52, 52, 52, 0.3)`,
														flex: 1,
														justifyContent: "center",
														alignItems: "center",
														borderRadius: 8,
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 12,
															color: APP_COLOURS.WHITE,
															fontWeight: "700",
														}}>
														Uneditable field
													</Text>
												</View>
											) : null}

											{this.state[validated] ? (
												<Image
													source={tick}
													style={{
														width: 16,
														height: 16,
														resizeMode: "contain",
														position: "absolute",
														left: 9,
														top: 16,
													}}
												/>
											) : null}
										</View>
									);
								})}
							</View>

							<TouchableOpacity
								style={{
									width: "100%",
									padding: 10,
									display:
										this.state.entityType !== "" ? "flex" : "none",
								}}
								onPress={async () => {
									await this.props.addEntity(this.state);
								}}>
								<View
									style={{
										backgroundColor: APP_COLOURS.BG,
										width: this.state.addingStaff ? "auto" : "100%",
										padding: 13,
										height: 45,
										borderRadius: 25,
										justifyContent: "center",
										alignItems: "center",
									}}>
									{!this.state.addingStaff ? (
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												color: "#FFF",
												fontSize: 15,
											}}>
											<Font weight={700} family='Montserrat'>
												Add entity
											</Font>
										</Text>
									) : (
										<ActivityIndicator color={"#FFF"} />
									)}
								</View>
							</TouchableOpacity>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	}
}
