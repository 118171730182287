import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Alert,
} from "react-native";
import Representative from "./Representative";
import ImageUploader from "./ImageUploader";
import { APP_COLOURS } from "../../APP_VARS";

const BLUE = "#3F6EFF";
const RED = "#FF5F5F";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class ClientPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultFields: [
				{
					title: "Alias Name",
					state: "title",
					fontWeight: "700",
					fontSize: 15,
				},
				{
					title: "Trading Name",
					state: "tradingName",
					fontWeight: "700",
					fontSize: 15,
				},
				{
					title: "Company Reg. Number",
					state: "regNumber",
					fontWeight: "300",
					fontSize: 12,
				},
				{
					title: "VAT Number",
					state: "vatNumber",
					fontWeight: "300",
					fontSize: 12,
				},
				{
					title: "Registered Address",
					state: "address",
					fontWeight: "300",
					fontSize: 12,
				},
				{
					title: "Website",
					state: "website",
					fontWeight: "300",
					fontSize: 12,
				},
				{
					title: "Main Email",
					state: "clientemail",
					fontWeight: "300",
					fontSize: 12,
				},
				{
					title: "Phone Number",
					state: "phone",
					fontWeight: "300",
					fontSize: 12,
				},
				{ title: "", state: "logoImage", display: "none" },
			],

			newNote: "",
			addNote: false,

			newProjectName: "",
			newRepDepartment: "",
			newProjectIdentifier: "",
			newTeamID: "",
			addProject: false,
			deleteConfirm: false,
			deleteConfirmed: false,
		};
	}

	upadateClient = async () => {
		let id = await this.props.selectedClientId;

		let obj = {
			id: id,
			...this.props.selectedClient,
		}; // First we add the selected ID
		for (const field of this.state.defaultFields) {
			// We loop through the dynamic fields
			const key = field.state;
			// Above is the state name
			// Below we check if the field was already set by props. If it hasnt been changed on the input field, replace the field with the props field
			const propIsSet =
				typeof this.props.selectedClient[key] !== "undefined"
					? this.props.selectedClient[key]
					: "";
			// iF the field is changed, the state will show up and then replace the props
			const value =
				typeof this.state[key] !== "undefined"
					? this.state[key]
					: propIsSet;
			if (value !== "" && value !== null) {
				obj[key] = value;
			} else {
			}
		}

		console.log("obj", obj);

		await this.props.upadateClient(id, obj);
	};

	deleteClient = (id) => {
		this.props.deleteClient(id);
	};

	addProject = async () => {
		let uid = uuid();
		let obj = {
			id: uid,
			client_id: this.props.selectedClientId,
			title: this.state.newProjectName,
			identifier: this.state.newProjectIdentifier,
			teamID: this.state.newTeamID,
		};
		await this.props.upadateClient(obj);
		await this.setState({
			newProjectName: "",
			newProjectIdentifier: "",
			newTeamID: "",
			addProject: false,
		});
	};

	addClientNote = async () => {
		let uid = uuid();
		let obj = {
			id: uid,
			client_id: this.props.selectedClientId,
			note: this.state.newNote,
		};
		await this.props.addClientNote(obj);
		await this.setState({
			newNote: "",
			addNote: false,
		});
	};

	render() {
		let detailsOptions = this.state.defaultFields;
		return (
			<View
				style={{
					flex: 1,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						marginBottom: 10,
					}}>
					<View>
						<Text
							style={{
								color: "#F6F8FE", // '#5A7BBA'
								fontSize: 25,
								fontWeight: "900",
							}}>
							{this.props.selectedClient.title}
						</Text>
					</View>

					<View
						style={{
							flexDirection: "row",
							paddingRight: 10,
						}}>
						<TouchableOpacity
							onPress={async () =>
								this.setState({
									deleteConfirm: !this.state.deleteConfirm,
									deleteConfirmed: false,
								})
							}>
							<Image
								style={{
									width: 35,
									height: 35,
									resizeMode: "contain",
									marginRight: 10,
								}}
								source={require("../../../assets/images/bin.png")}
							/>
						</TouchableOpacity>
					</View>
				</View>

				<ScrollView>
					{/* // */}
					<View
						style={{
							backgroundColor: "#191D24",
							borderRadius: 20,
							marginRight: 10,
							marginBottom: 17,
							height: "auto",
							padding: 10,
						}}>
						<View
							style={{
								height: "auto",
								flexDirection: "row",
								justifyContent: "space-between",
								paddingBottom: 10,
								marginBottom: 10,
								alignItems: "center",
								borderBottomColor: "#28282F",
								borderBottomWidth: 0.5,
							}}>
							<Text
								style={{
									fontSize: 15,
									color: "#D8D8FF",
									fontWeight: "700",
									fontFamily: "Avenir Next",
								}}>
								Company Details
							</Text>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										editDetails: !this.state.editDetails,
									})
								}>
								<Image
									style={{
										width: 35,
										height: 35,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/document_icon.png")}
								/>
							</TouchableOpacity>
						</View>

						<View
							style={{
								flexDirection: "row",
							}}>
							<View
								style={{
									flex: 2,
								}}>
								{detailsOptions.map((d, i) => (
									<View
										key={i}
										style={{
											display: d.display,
										}}>
										{this.state.editDetails ? (
											<TextInput
												style={{
													width: "90%",
													marginTop: 10,
													height: 40,
													paddingLeft: 10,
													marginBottom: 5,
													fontSize: 15,
													borderRadius: 8,
													backgroundColor: "#434350",
													color: "#D8D8FF",
													fontWeight: "600",
												}}
												placeholderTextColor={RED}
												clearButtonMode='while-editing'
												autoCompleteType='off'
												placeholder={`${d.title}`}
												defaultValue={
													this.props.selectedClient[d.state]
												}
												onChangeText={(text) =>
													this.setState({
														[d.state]: text,
													})
												}
												//   onSubmitEditing={() => this.upadateClient()}
												ref={(ref) => {
													this.textInput = ref;
												}}
											/>
										) : (
											<Text
												selectable
												style={{
													fontSize: 15,

													color:
														typeof this.props.selectedClient[
															d.state
														] == "undefined" ||
														this.props.selectedClient[d.state] ==
															""
															? "#FF5F5F"
															: "#D8D8FF",
													fontWeight: d.fontWeight,
													fontFamily: "Avenir Next",
													opacity:
														typeof this.props.selectedClient[
															d.state
														] == "undefined" ||
														this.props.selectedClient[d.state] ==
															""
															? 0.2
															: 1,
												}}>
												{typeof this.props.selectedClient[
													d.state
												] == "undefined" ||
												this.props.selectedClient[d.state] == ""
													? d.title
													: this.props.selectedClient[d.state]}
											</Text>
										)}
									</View>
								))}
							</View>

							{/* <View
								style={{
									flex: 1,
									alignItems: "center",
									borderRadius: 20,
									padding: 10,
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											editLogo: !this.state.editLogo,
										})
									}>
									<Image
										style={{
											width: 120,
											height: 120,
											resizeMode: "contain",
										}}
										source={require("../../../assets/images/app_icon_frame.png")}
									/>
								</TouchableOpacity>
							</View> */}

							<View
								style={{
									flex: 1,
									alignItems: "center",
									justifyContent: "center",
									marginBottom: 10,
									padding: 10,
								}}>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										borderWidth: 1,
										borderStyle: "dashed",
										borderColor: "#797979",
										borderRadius: 10,
										width: "100%",
										height: 100,
										overflow: "hidden",
									}}>
									{this.props.selectedClient.logoImage !== "" &&
									typeof this.props.selectedClient.logoImage !==
										"undefined" &&
									!this.state.editDetails ? (
										<Image
											source={{
												uri: this.props.selectedClient.logoImage,
											}}
											style={{
												width: "100%",
												height: 100,
												resizeMode: "contain",
												backgroundColor: "#FFF",
												opacity: 0.5,
											}}
										/>
									) : (
										<>
											<Text
												style={{
													color: "#D8D8FF",
													fontSize: 12,
												}}>
												Upload logo image
											</Text>
											<ImageUploader
												width={"100%"}
												height={100}
												base64String={async (d) => {
													await this.setState({
														logoImage: d,
													});
													this.upadateClient();
												}}
											/>
										</>
									)}
								</View>
							</View>
						</View>

						<TouchableOpacity onPress={() => this.upadateClient()}>
							<View
								style={{
									width: "90%",
									display: this.state.editDetails ? "flex" : "none",
									height: 35,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#FF5F5F",
									marginTop: 20,
									borderRadius: 12,
								}}>
								<Text
									style={{
										color: "#FFF",
										fontWeight: "600",
									}}>
									Save
								</Text>
							</View>
						</TouchableOpacity>
					</View>

					<View
						style={{
							flexDirection: "row",
							minHeight: 300,
						}}>
						<View
							style={{
								backgroundColor: "#191D24",
								// backgroundColor: '#FFF',
								borderRadius: 20,
								marginRight: 17,
								marginBottom: 15,
								flex: 1,

								padding: 10,
							}}>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									paddingBottom: 10,
									marginBottom: 10,
									alignItems: "center",
									borderBottomColor: "#28282F",
									borderBottomWidth: 0.5,
								}}>
								<Text
									style={{
										fontSize: 15,
										color: "#D8D8FF",
										fontWeight: "700",
										fontFamily: "Avenir Next",
									}}>
									Apps
								</Text>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											addProject: !this.state.addProject,
										})
									}>
									<Image
										style={{
											width: 35,
											height: 35,
											resizeMode: "contain",
										}}
										source={require("../../../assets/images/app_icon_frame.png")}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									flex: 1,
								}}>
								{/* <ScrollView>
									{this.props.projects
										.filter(
											(d) =>
												d.client_id ==
												this.props.selectedClientId,
										)
										.map((project, i) => (
											<View
												style={{
													flexDirection: "row",
													marginBottom: 20,
												}}>
												<Image
													style={{
														width: 90,
														height: 90,
														borderRadius: 20,
														resizeMode: "contain",
													}}
													source={require("../../../assets/images/app_icon_frame.png")}
												/>

												<View
													style={{
														flex: 1,
														paddingLeft: 10,
														paddingTop: 5,
														justifyContent:
															"center",
													}}>
													<View
														style={{
															flexDirection:
																"row",
															justifyContent:
																"space-between",
														}}>
														<Text
															style={{
																fontSize: 15,
																color: "#FFF",
																fontWeight:
																	"700",
																fontFamily:
																	"Avenir Next",
																top: -5,
																padding: 0,
															}}>
															{project.title}
														</Text>
														<TouchableOpacity
															onPress={() =>
																Alert.alert(
																	"Delete item",
																	`Are you sure you want to delete ${project.title}`,
																	[
																		{
																			text: "Yes",
																			onPress:
																				() =>
																					this.props.removeProject(
																						project.id,
																					),
																		},
																		{
																			text: "Cancel",
																			onPress:
																				() =>
																					console.log(
																						"Cancel Pressed",
																					),
																			style: "cancel",
																		},
																	],
																	{
																		cancelable: true,
																	},
																)
															}>
															<Image
																style={{
																	width: 16,
																	height: 16,
																	resizeMode:
																		"contain",
																}}
																source={require("../../../assets/images/delete.png")}
															/>
														</TouchableOpacity>
													</View>

													<Text
														style={{
															fontSize: 10,
															color: "#FFF",
															fontWeight: "300",
															fontFamily:
																"Avenir Next",
															opacity: 0.5,
															padding: 0,
														}}>
														Project identifier
													</Text>
													<Text
														style={{
															fontSize: 13,
															color: "#A6A6A6",
															fontWeight: "600",
															fontFamily:
																"Avenir Next",
															top: -3,
															padding: 0,
															opacity: 1,
														}}>
														{project.identifier}
													</Text>
													<Text
														style={{
															fontSize: 10,
															color: "#FFF",
															fontWeight: "300",
															fontFamily:
																"Avenir Next",
															opacity: 0.5,
															padding: 0,
															top: -5,
														}}>
														Team ID
													</Text>
													<Text
														style={{
															fontSize: 13,
															color: "#A6A6A6",
															fontWeight: "600",
															fontFamily:
																"Avenir Next",
															top: -10,
															padding: 0,
															opacity: 1,
														}}>
														{project.teamID}
													</Text>
												</View>
											</View>
										))}
								</ScrollView> */}

								{this.state.addProject ? (
									<View
										style={{
											marginTop: 20,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 20,
											}}>
											<Image
												style={{
													width: 80,
													height: 80,
													borderRadius: 20,
													resizeMode: "contain",
												}}
												source={require("../../../assets/images/app_icon_frame.png")}
											/>

											<View
												style={{
													flex: 1,
													paddingLeft: 10,
													paddingTop: 5,
													justifyContent: "center",
												}}>
												<Text
													style={{
														fontSize: 10,
														color: "#FFF",
														fontWeight: "300",
														fontFamily: "Avenir Next",
														opacity: 0.5,
														padding: 0,
													}}>
													Project Name
												</Text>

												<TextInput
													style={{
														fontSize: 15,
														color: "#FFF",
														fontWeight: "700",
														fontFamily: "Avenir Next",
														width: "100%",
														marginTop: 10,
														height: 40,
														paddingLeft: 10,
														marginBottom: 5,
														borderRadius: 8,
														backgroundColor: "#434350",
														color: "#FFD76E",
													}}
													placeholderTextColor={"#FFF"}
													autoCapitalize='words'
													autoCorrect={false}
													autoFocus
													clearButtonMode='while-editing'
													autoCompleteType='off'
													placeholder={`Project name`}
													onChangeText={(text) =>
														this.setState({
															newProjectName: text,
														})
													}
												/>

												<Text
													style={{
														fontSize: 10,
														color: "#FFF",
														fontWeight: "300",
														fontFamily: "Avenir Next",
														opacity: 0.5,
														padding: 0,
													}}>
													Project identifier
												</Text>
												<TextInput
													style={{
														fontSize: 15,
														color: "#FFF",
														fontWeight: "700",
														fontFamily: "Avenir Next",
														width: "100%",
														marginTop: 10,
														height: 40,
														paddingLeft: 10,
														marginBottom: 5,
														borderRadius: 8,
														backgroundColor: "#434350",
														color: "#FFD76E",
													}}
													placeholderTextColor={"#FFF"}
													clearButtonMode='while-editing'
													autoCapitalize='none'
													autoCorrect={false}
													autoCompleteType='off'
													defaultValue={`za.co.tetrice.`}
													placeholder={`Project identifier`}
													onChangeText={(text) =>
														this.setState({
															newProjectIdentifier: text,
														})
													}
												/>

												<Text
													style={{
														fontSize: 12,
														color: "#FFF",
														fontWeight: "300",
														fontFamily: "Avenir Next",
														opacity: 0.5,
														padding: 0,
													}}>
													Team ID
												</Text>
												<TextInput
													style={{
														fontSize: 15,
														fontWeight: "700",
														fontFamily: "Avenir Next",
														width: "100%",
														marginTop: 10,
														height: 40,
														paddingLeft: 10,
														marginBottom: 5,
														borderRadius: 8,
														backgroundColor: "#434350",
														color: "#FFD76E",
													}}
													placeholderTextColor={"#FFF"}
													clearButtonMode='while-editing'
													autoCompleteType='off'
													autoCapitalize='none'
													autoCorrect={false}
													keyboardType='decimal-pad'
													placeholder={`TeamID`}
													defaultValue={`za.co.tetrice.`}
													onChangeText={(text) =>
														this.setState({
															newTeamID: text,
														})
													}
												/>
											</View>
										</View>

										<TouchableOpacity
											onPress={() => this.addProject()}>
											<View
												style={{
													width: "100%",
													height: 35,
													justifyContent: "center",
													alignItems: "center",
													backgroundColor: "#FF5F5F",
													marginTop: 20,
													borderRadius: 12,
												}}>
												<Text
													style={{
														color: "#FFF",
														fontWeight: "600",
													}}>
													Add Project
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								) : null}
							</View>
						</View>
						<Representative
							selectedClientId={this.props.selectedClientId}
						/>
					</View>
					<View
						style={{
							flexDirection: "row",
							minHeight: 400,
						}}>
						<View
							style={{
								backgroundColor: "#191D24",
								// backgroundColor: '#FFF',
								borderRadius: 20,
								marginRight: 17,
								marginBottom: 15,
								flex: 1,
								minHeight: 400,
								padding: 10,
							}}>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									paddingBottom: 10,
									marginBottom: 10,
									alignItems: "center",
									borderBottomColor: "#28282F",
									borderBottomWidth: 0.5,
								}}>
								<Text
									style={{
										fontSize: 15,
										color: "#D8D8FF",
										fontWeight: "700",
										fontFamily: "Avenir Next",
									}}>
									Company documents
								</Text>
								<TouchableOpacity>
									<Image
										style={{
											width: 35,
											height: 35,
											resizeMode: "contain",
										}}
										source={require("../../../assets/images/document_icon.png")}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									flex: 1,
								}}>
								<ScrollView>
									{typeof this.props.selectedClient
										.companyDocuments !== "undefined"
										? this.props.selectedClient.companyDocuments
												.filter(
													(d) =>
														d.client_id ==
														this.props.selectedClientId,
												)
												.map(() => (
													<View
														style={{
															flexDirection: "row",
															marginBottom: 20,
														}}>
														<Image
															style={{
																width: 80,
																height: 80,
																borderRadius: 20,
																resizeMode: "contain",
															}}
															source={require("../../../assets/images/app_icon_frame.png")}
														/>

														<View
															style={{
																flex: 1,
																paddingLeft: 10,
																paddingTop: 5,
																justifyContent: "center",
															}}>
															<Text
																style={{
																	fontSize: 10,
																	color: "#FFF",
																	fontWeight: "300",
																	fontFamily: "Avenir Next",
																	opacity: 0.5,
																	padding: 0,
																}}>
																Project identifier
															</Text>
															<Text
																style={{
																	fontSize: 15,
																	color: "#FFF",
																	fontWeight: "700",
																	fontFamily: "Avenir Next",
																	top: -5,
																	padding: 0,
																}}>
																za.co.tetrice.cris
															</Text>
															<Text
																style={{
																	fontSize: 12,
																	color: "#FFF",
																	fontWeight: "300",
																	fontFamily: "Avenir Next",
																	opacity: 0.5,
																	padding: 0,
																	top: -5,
																}}>
																Team ID
															</Text>
															<Text
																style={{
																	fontSize: 13,
																	color: "#A6A6A6",
																	fontWeight: "600",
																	fontFamily: "Avenir Next",
																	top: -10,
																	padding: 0,
																	opacity: 1,
																}}>
																za.co.tetrice.avexapp20200801
															</Text>
														</View>
													</View>
												))
										: null}
								</ScrollView>
							</View>
						</View>
						<View
							style={{
								backgroundColor: "#191D24",
								// backgroundColor: '#FFF',
								borderRadius: 20,
								marginRight: 10,
								marginBottom: 15,
								height: "auto",
								padding: 10,
								flex: 1,
							}}>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									paddingBottom: 15,
									marginBottom: 10,
									alignItems: "center",
									borderBottomColor: "#28282F",
									borderBottomWidth: 0.5,
								}}>
								<Text
									style={{
										fontSize: 15,
										color: "#D8D8FF",
										fontWeight: "700",
										fontFamily: "Avenir Next",
									}}>
									Notes
								</Text>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											addNote: !this.state.addNote,
										})
									}>
									<Image
										style={{
											width: 35,
											height: 35,
											resizeMode: "contain",
										}}
										source={require("../../../assets/images/note.png")}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									flex: 1,

									justifyContent: "space-between",
								}}>
								<View
									style={{
										flex: 1,
										minHeight: 200,
									}}>
									{typeof this.props.selectedClient.clientNotes !==
									"undefined"
										? this.props.selectedClient.clientNotes
												.filter(
													(d) =>
														d.client_id ==
														this.props.selectedClientId,
												)
												.map((dd, index1) => (
													<View
														style={{
															paddingTop: 5,
															paddingBottom: 5,
															marginBottom: 5,
															flexDirection: "row",
															justifyContent: "space-between",
															paddingRight: 5,
														}}>
														<Text
															style={{
																paddingLeft: 5,
																fontSize: 14,
																flex: 1,
																color: "#7878A2",
															}}>
															{dd.note}
														</Text>

														<TouchableOpacity
															onPress={() =>
																this.props.removeClientNote(
																	dd.id,
																)
															}>
															<Image
																style={{
																	width: 16,
																	height: 16,
																	resizeMode: "contain",
																}}
																source={require("../../../assets/images/delete.png")}
															/>
														</TouchableOpacity>
													</View>
												))
										: null}
								</View>
								{this.state.addNote ? (
									<TextInput
										style={{
											width: "100%",
											marginTop: 10,
											minHeight: 70,
											padding: 15,
											marginBottom: 5,
											fontSize: 16,
											borderRadius: 8,
											backgroundColor: "#434350",
											color: "#FFD76E",
											fontWeight: "400",
										}}
										placeholderTextColor={"#FFF"}
										clearButtonMode='always'
										autoCompleteType='off'
										multiline
										autoFocus
										placeholder={`Note`}
										defaultValue={this.state.newNote}
										onChangeText={(text) =>
											this.setState({
												newNote: text,
											})
										}
										onSubmitEditing={() => this.addClientNote()}
									/>
								) : null}
							</View>
						</View>
					</View>
					<View
						style={{
							width: "100%",
							height: 80,
						}}
					/>
				</ScrollView>

				{this.state.deleteConfirm ? (
					<View
						style={{
							flex: 1,
							alignItems: "center",
							position: "absolute",
							top: 50,
							right: 20,
						}}>
						<View
							style={{
								backgroundColor: "#FFF",
								borderRadius: 15,
								padding: 15,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										deleteConfirm: false,
										deleteConfirmed: false,
									})
								}>
								<Image
									source={require("../../../assets/images/close.png")}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
										marginRight: 20,
										marginBottom: 15,
									}}
								/>
							</TouchableOpacity>
							<Text
								adjustsFontSizeToFit
								style={{
									color: "#000",
									fontSize: 15,
									marginBottom: 10,
								}}>
								Type 'delete' to confirm deletion
							</Text>
							<TextInput
								style={{
									marginBottom: 10,
									padding: 16,
									marginTop: 0,
									fontSize: 16,
									borderRadius: 15,
									textAlign: "left",
									color: APP_COLOURS.BLUE,
									fontWeight: "200",
									fontFamily: "Avenir",
									letterSpacing: 0.26,
									borderWidth: 0.3,
									backgroundColor: "#FFF",
									borderColor: APP_COLOURS.BLUE,
								}}
								placeholderTextColor={APP_COLOURS.BLUE}
								autoCapitalize='none'
								placeholder={`delete`}
								// value={this.state.first_name}
								onChangeText={(text) => {
									if (text === "delete") {
										this.setState({
											deleteConfirmed: true,
										});
									} else {
										this.setState({
											deleteConfirmed: false,
										});
									}
								}}
							/>

							<TouchableOpacity
								disabled={!this.state.deleteConfirmed}
								onPress={() => {
									this.deleteClient(this.props.selectedClientId);
								}}
								style={{
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									width: 200,
								}}>
								<View
									style={{
										backgroundColor: !this.state.deleteConfirmed
											? "transparent"
											: APP_COLOURS.RED,
										borderColor: APP_COLOURS.RED,
										borderWidth: 1,
										padding: 5,
										borderRadius: 9,
										justifyContent: "center",
										alignItems: "center",
										flexDirection: "row",
										paddingRight: 9,
									}}>
									<Image
										source={require("../../../assets/images/bin.png")}
										style={{
											width: 22,
											height: 22,
											// borderRadius: 25,
											resizeMode: "contain",
											marginRight: 5,
										}}
									/>
									<Text
										style={{
											alignItems: "center",
											justifyContent: "center",
											color: !this.state.deleteConfirmed
												? APP_COLOURS.RED
												: "#FFF",
											fontSize: 10,
										}}>
										Delete
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>
				) : null}
			</View>
		);
	}
}

export default ClientPage;
