import React, { Component } from "react";
import { View, ScrollView } from "react-native";
import FormalLetter from "./FormalLetter";
import SideBar from "./SideBar";

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPage: "",
		};
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
					backgroundColor: "#191D24",
				}}>
				<SideBar
					selectedPage={(d) =>
						this.setState({
							selectedPage: d,
						})
					}
				/>
				<View
					style={{
						height: this.props.screenHeight - 90,
						width: this.props.screenWidth - 400,
					}}>
					{this.state.selectedPage === "FormalLetter" ? (
						<FormalLetter />
					) : null}
				</View>
			</View>
		);
	}
}
