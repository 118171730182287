import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import close from "./../../../assets/images/close.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import send from "./../../../assets/images/send.png";
import "./field.css";
import moment from "moment";

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class ModalTaskPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignment: [],
			title: "",
			description: "",
			addedBy: "",
			dueDate: "",
			doc_date: new Date(),

			dueDate: new Date(),

			noteData: "",
		};
	}

	componentDidMount() {
		console.log(this.props.data);
		this.setState({
			...this.props.data,
		});
	}

	render() {
		let d = this.props.data;
		let users = this.props.users;

		let avatars = {
			1: require("./../../../assets/images/male1.png"),
			2: require("./../../../assets/images/male2.png"),
			3: require("./../../../assets/images/female1.png"),
		};

		let avatar = avatars[parseInt(this.props.user_data.avatar)];

		let due = "NOW";

		const date1 = moment();
		const date2 = moment(d.dueDate);
		due = date2.diff(date1, "days");

		let morning = new Date();
		morning.setHours(0, 0, 1, 0);
		let evening = new Date();
		evening.setHours(23, 59, 59, 0);

		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(21, 21, 21, 0.8)",
					}}>
					<ScrollView>
						<View
							style={{
								width: this.props.screenWidth,
								// maxWidth: 500,
								height: "auto",
								backgroundColor: "white",
								borderRadius: 20,
								justifyContent: "center",
								alignItems: "center",
								padding: 10,
								marginTop: 30,
								marginBottom: 30,
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: 10,
									left: 10,
								}}>
								<TouchableOpacity
									onPress={() => this.props.close(false)}>
									<Image
										source={close}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									width: "100%",
									padding: 10,
									height: "100%",
								}}>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										marginTop: 20,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<TextInput
											style={{
												width: "100%",
												padding: 5,
												fontSize: 19,
												borderRadius: 9,
												color: APP_COLOURS.BG2,
												fontWeight: "700",
												fontFamily: "Avenir",
												// borderWidth: 0.3,
												// borderColor: APP_COLOURS.BG2,
											}}
											placeholderTextColor={"#797979"}
											multiline
											autoFocus
											numberOfLines={2}
											autoCapitalize='sentences'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											placeholder={`Task title `}
											defaultValue={this.state.title}
											onChangeText={(text) => {
												let t = capitalizeName(text);
												this.setState({
													taskName: t,
												});
											}}
										/>
									</View>

									<View
										style={{
											flex: 1,
											alignItems: "flex-start",
											padding: 5,
											flexDirection: "row",
										}}>
										<Text
											style={{
												color: "#FFF",
												fontSize: 12,
												fontWeight: "700",
												marginBottom: 2,
											}}>
											Change Status
										</Text>

										<View
											style={{
												width: 300,
												flexDirection: "row",
											}}>
											{["Inprogress", "Stuck", "Done"].map(
												(status) => (
													<TouchableOpacity
														onPress={() =>
															this.addChangeStatus(d, status)
														}
														style={{
															flex: 1,
															flexDirection: "row",
															justifyContent: "center",
															alignItems: "center",
															marginBottom: 2,
															backgroundColor:
																d.status == status
																	? APP_COLOURS.BLUE
																	: "transparent",

															borderWidth: 0.3,
															borderColor: APP_COLOURS.BLUE,
															marginRight: 3,
															width: 100,
															borderRadius: 10,
														}}>
														<View
															style={{
																padding: 10,
															}}>
															<Text
																style={{
																	fontFamily: "Avenir",
																	fontSize: 12,
																	fontWeight: "600",
																	color:
																		d.status == status
																			? "#FFF"
																			: APP_COLOURS.BG2,
																}}>
																{status}
															</Text>
														</View>
													</TouchableOpacity>
												),
											)}
										</View>
									</View>
								</View>

								<View
									style={{
										flexDirection: "row",
									}}>
									<View
										style={{
											flex: 1,
											minWidth: 350,
											padding: 5,
										}}>
										<View
											style={{
												height: "auto",
												width: "100%",
												marginBottom: 25,
												marginTop: 3,
											}}>
											<TextInput
												style={{
													width: "100%",
													padding: 5,
													fontSize: 14,
													borderRadius: 9,
													color: APP_COLOURS.BG2,
													fontWeight: "200",
													fontFamily: "Avenir",
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BG2,
												}}
												placeholderTextColor={"#797979"}
												multiline
												numberOfLines={7}
												autoFocus
												autoCapitalize='sentences'
												clearButtonMode='while-editing'
												autoCompleteType='off'
												editable={true}
												autoCorrect={false}
												keyboardType='default'
												textContentType='none'
												placeholder={`Description `}
												defaultValue={this.state.description}
												onChangeText={(text) => {
													let t = capitalizeName(text);
													this.setState({
														taskDescription: t,
													});
												}}
											/>
										</View>

										<View
											style={{
												flex: 1,
												padding: 5,
											}}>
											<View
												style={{
													flex: 1,
													marginBottom: 25,
													marginTop: 25,
													zIndex: 999,
												}}>
												<View
													style={{
														width: "100%",
														backgroundColor: "#FFF",
														padding: 10,
														fontSize: 16,
														borderRadius: 9,
														color: APP_COLOURS.BG2,
														fontWeight: "200",
														fontFamily: "Avenir",
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BG2,
														height: 50,
													}}>
													<DatePicker
														selected={
															this.state.dueDate === ""
																? evening
																: new Date(this.state.dueDate)
														}
														popperPlacement='top-end'
														popperModifiers={{
															offset: {
																enabled: true,
																offset: "5px, 10px",
															},
															preventOverflow: {
																enabled: true,
																escapeWithReference: false,
																boundariesElement: "viewport",
															},
														}}
														onChange={(date) =>
															this.setState({
																dueDate: date,
															})
														}
													/>
												</View>
												<Text
													allowFontScaling={false}
													style={{
														fontSize: 10,
														fontFamily: "Avenir Next",
														margin: 5,
														paddingLeft: 10,
														paddingRight: 10,
														fontWeight: "400",
														backgroundColor: "#FFF",
														position: "absolute",
														borderRadius: 6,
														top: -13,
														left: 5,
													}}>
													Due date
												</Text>
												<Text
													style={{
														fontSize: 14,
														fontFamily: "Avenir Next",
														margin: 5,
														paddingLeft: 10,
														paddingRight: 10,
														fontWeight: "400",
														position: "absolute",
														top: 12,
														left: 5,
													}}>
													{moment(this.state.dueDate).format(
														"DD/MM/YYYY",
													)}
												</Text>

												{/* <TouchableOpacity
										onPress={() =>
											this.updateItem(
												d,
												"dueDate",
												this.state.dueDate,
											)
										}
										style={{
											flexDirection: "row",
											justifyContent: "flex-start",
											alignItems: "center",
											marginBottom: 2,
											backgroundColor: APP_COLOURS.BLUE,
											width: 100,
											borderRadius: 10,
										}}>
										<View
											style={{
												padding: 10,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													fontWeight: "600",
													color: APP_COLOURS.WHITE,
												}}>
												save
											</Text>
										</View>
									</TouchableOpacity> */}
											</View>
										</View>

										<View
											style={{
												height: "auto",
												width: "100%",
												marginBottom: 10,
												zIndex: 2,
											}}>
											<Text
												allowFontScaling={false}
												style={{
													fontSize: 9,
													fontFamily: "Avenir Next",
													margin: 5,
													// paddingLeft: 10,
													fontWeight: "400",
												}}>
												Assignment
											</Text>
											<View
												style={{
													width: "100%",
													// height: 50,
													// alignItems: "center",
													justifyContent: "center",
													// paddingLeft: 20,
													borderTopColor: "#797979",
													borderBottomColor: "#797979",
													borderTopWidth: 0.5,
													borderBottomWidth: 0.5,
												}}>
												{this.props.users.map((user) => {
													let assigned =
														this.state.assignment.filter(
															(dd) => dd.id == user.id,
														);
													return (
														<TouchableOpacity
															onPress={async () => {
																let taskAss =
																	this.state.assignment.filter(
																		(ds) => ds.id !== user.id,
																	);

																if (assigned.length > 0) {
																	this.setState({
																		assignment: taskAss,
																	});
																} else {
																	this.setState({
																		assignment:
																			taskAss.concat(user),
																	});
																}
															}}
															style={{
																flexDirection: "row",
																justifyContent: "flex-start",
																alignItems: "center",
																marginBottom: 2,
																width: 100,
																backgroundColor:
																	assigned.length > 0
																		? "#797979"
																		: "transparent",
																borderRadius: 12,
															}}>
															<Image
																source={
																	avatars[
																		parseInt(user.avatar)
																	]
																}
																style={{
																	width: 22,
																	height: 22,
																	borderRadius: 12,
																	resizeMode: "contain",
																	backgroundColor: "#FFF",
																	marginRight: 5,
																}}
															/>
															<View style={{}}>
																<Text
																	style={{
																		fontFamily: "Avenir",
																		fontSize: 13,
																		fontWeight: "600",
																		color:
																			assigned.length > 0
																				? "#FFF"
																				: APP_COLOURS.BG2,
																	}}>
																	{user.first_name}
																</Text>
															</View>
														</TouchableOpacity>
													);
												})}
											</View>
										</View>

										<View
											style={{
												width: 110,
											}}>
											<View style={{}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 6,
														fontWeight: "500",
														color: APP_COLOURS.BG2,
													}}>
													Date created
												</Text>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 10,
														fontWeight: "600",
														color: APP_COLOURS.BG2,
													}}>
													{moment(d.date_created).format(
														"DD/MM/YYYY HH:mm",
													)}
												</Text>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 8,
														fontWeight: "600",
														fontStyle: "italic",
														color: APP_COLOURS.BG2,
													}}>
													added by {d.addedBy.first_name}...
												</Text>
											</View>
										</View>
									</View>
									<View
										style={{
											flex: 1,
											minWidth: 350,

											backgroundColor: "#EFEDE9",
											margin: 10,
											borderTopLeftRadius: 10,
										}}>
										{/* <View
											style={{
												padding: 5,
												backgroundColor: "#FFF",
												borderBottomColor: "#797979",
												borderBottomWidth: 0.3,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontSize: 8,
													fontWeight: "400",
													marginBottom: 2,
												}}>
												Messages ({this.props.notes.length})
											</Text>
										</View> */}

										<ScrollView
											ref={(ref) => {
												this.scrollViewBookContent = ref;
											}}
											onContentSizeChange={() =>
												this.scrollViewBookContent.scrollToEnd({
													animated: true,
												})
											}
											contentContainerStyle={{
												paddingRight: 5,

												paddingTop: 10,
											}}>
											{this.props.notes.map((notes, index) => (
												<View
													style={{
														width: "100%",
														marginBottom: 5,
														flexDirection: "row",
														backgroundColor:
															"rgba(212,212,212, 0.05)",
														borderRadius: 12,

														paddingLeft:
															notes.user.id !==
															this.props.user_data.id
																? 10
																: 50,
													}}>
													{/* <Image
														source={
															avatars[
																parseInt(notes.user.avatar)
															]
														}
														style={{
															width: 16,
															height: 16,
															borderRadius: 8,
															resizeMode: "contain",
															marginRight: 5,
														}}
													/> */}
													<View
														style={{
															flex: 1,
															backgroundColor:
																notes.user.id !==
																this.props.user_data.id
																	? "#FFF"
																	: "#D9FECF",
															borderRadius: 12,
															padding: 10,

															shadowColor: "#797979",
															shadowOffset: {
																width: 0,
																height: 8,
															},
															shadowOpacity: 0.1,
															shadowRadius: 10.14,

															elevation: 1,
														}}>
														<View
															style={{
																flexDirection: "row",
																alignItems: "center",
																width: "100%",
																justifyContent: "space-between",
															}}>
															<Text
																style={{
																	color: APP_COLOURS.BUTTON2,
																	fontSize: 10,
																	marginRight: 15,
																	fontWeight: "700",
																	display:
																		notes.user.id !==
																		this.props.user_data.id
																			? "flex"
																			: "none",
																}}>
																{notes.user.first_name}
															</Text>
														</View>
														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontSize: 13,
																// opacity: 0.7,
																marginTop: 5,
															}}>
															{notes.data}
														</Text>

														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontSize: 8,
																opacity: 0.5,
																alignSelf: "flex-end",
															}}>
															{moment(notes.date_added).format(
																"HH:mm DD/MM/YY",
															)}
														</Text>
													</View>
												</View>
											))}
											<View
												style={{
													height: 90,
												}}
											/>
										</ScrollView>

										<View
											style={{
												flexDirection: "row",
												width: "100%",
												alignItems: "center",
												borderTopColor: "#797979",
												borderTopWidth: 0.3,
												paddingTop: 5,
												backgroundColor: "#FFF",
											}}>
											<TextInput
												ref={(ref) => {
													this.textinput = ref;
												}}
												style={{
													width: "85%",
													padding: 10,
													fontSize: 16,
													borderRadius: 9,
													color: APP_COLOURS.BG2,
													fontWeight: "200",
													fontFamily: "Avenir",
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BG2,
												}}
												placeholderTextColor={"#797979"}
												multiline
												autoCapitalize='sentences'
												clearButtonMode='while-editing'
												autoCompleteType='off'
												editable={true}
												autoCorrect={false}
												keyboardType='default'
												textContentType='none'
												placeholder={`Type message `}
												onChangeText={(text) => {
													this.setState({
														noteData: text,
													});
												}}
											/>
											<TouchableOpacity
												style={{
													width: "14%",
													alignItems: "center",
													justifyContent: "center",
													backgroundColor: APP_COLOURS.BG2,
													borderRadius: 10,
													padding: 10,
													marginLeft: "1%",
												}}
												onPress={() => this.addNewNote()}>
												<Image
													source={send}
													style={{
														width: 22,
														height: 22,
														resizeMode: "contain",
													}}
												/>
											</TouchableOpacity>
										</View>
									</View>
								</View>
								<TouchableOpacity
									style={{
										zIndex: 99,
										marginTop: 20,
									}}
									// onPress={() =>
									// 	this.props.addNewTask(
									// 		this.state.taskName,
									// 		this.state.taskDescription,
									// 		this.state.assignment,
									// 		this.state.dueDate,
									// 	)
									// }
								>
									<View
										style={{
											width: "100%",
											backgroundColor: APP_COLOURS.GREEN,
											padding: 9,
											justifyContent: "center",
											alignItems: "center",
											borderRadius: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.WHITE,
												fontSize: 13,
												fontWeight: "600",
											}}>
											Save changes
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	}

	addNewNote = async (d) => {
		let uuid = uuidv4();
		if (this.state.noteData == "") {
			return;
		}
		let data = {
			id: uuid,
			task_id: this.props.data.id,
			data: this.state.noteData,
			date_added: moment().toLocaleString(),
			user: this.props.user_data,
		};
		await this.setState({
			noteData: "",
		});
		await console.log(this.props.user_data);
		await this.textinput.clear();
		await this.props.addNotes(data);
	};

	addChangeStatus = async (d, status) => {
		d.status = status;
		await this.props.addChangeStatus(d);
	};

	updateItem = (d, field, val) => {
		d[field] = val;
		this.props.updateItem(d);
	};
}

export default ModalTaskPreview;
