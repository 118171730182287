import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import close from "./../../../assets/images/close.png";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import send from "./../../../assets/images/send.png";
import "./field.css";
import moment from "moment";
import ChatParent from "./ChatParent";
import Calendar from "./Calendar";
import CalendarPicker from "../CalendarPicker";

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class ModalTaskPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignment: [],
			title: "",
			description: "",
			addedBy: "",
			dueDate: "",
			doc_date: new Date(),

			dueDate: new Date(),

			noteData: "",

			tab: "chat",

			changesMade: false,
		};
	}

	componentDidMount() {
		console.log(this.props.data);
		this.setState({
			...this.props.data,
		});
	}

	render() {
		let d = this.props.data;
		let users = this.props.users;

		let avatars = {
			1: require("./../../../assets/images/male1.png"),
			2: require("./../../../assets/images/male2.png"),
			3: require("./../../../assets/images/female1.png"),
		};

		let avatar = avatars[parseInt(this.props.user_data.avatar)];

		let due = "NOW";

		const date1 = moment();
		const date2 = moment(d.dueDate);
		due = date2.diff(date1, "days");

		let morning = new Date();
		morning.setHours(0, 0, 1, 0);
		let evening = new Date();
		evening.setHours(23, 59, 59, 0);

		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						position: "absolute",
						zIndex: 99,
						justifyContent: "center",
						alignItems: "center",

						width: this.props.screenWidth,
						height: this.props.screenHeight,

						padding: 5,
						overflow: "hidden",
						backgroundColor: "rgba(21, 21, 21, 0.8)",
					}}>
					<View
						style={{
							flexDirection: "row",
							width: "100%",
							maxWidth: 550,
							maxHeight: 800,
							backgroundColor: "#E8E8E8",
							borderRadius: 15,
							overflow: "hidden",
						}}>
						<View
							style={{
								// position: "absolute",
								zIndex: 99,
								margin: 10,
							}}>
							<TouchableOpacity
								onPress={() => this.props.close(false)}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									tab: "chat",
								})
							}
							style={{
								flex: 1,
								display:
									d.addedBy.id === this.props.user_data.id
										? "flex"
										: "none",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								marginBottom: 2,
								backgroundColor:
									this.state.tab === "chat"
										? APP_COLOURS.BLUE
										: "transparent",

								borderWidth: 0.3,
								borderColor: APP_COLOURS.BLUE,
								marginRight: 3,

								borderRadius: 10,
							}}>
							<View
								style={{
									padding: 10,
								}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 12,
										fontWeight: "600",
										color:
											this.state.tab === "chat"
												? "#FFF"
												: APP_COLOURS.BG2,
									}}>
									Chat
								</Text>
							</View>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									tab: "edit",
								})
							}
							style={{
								flex: 1,
								display:
									d.addedBy.id === this.props.user_data.id
										? "flex"
										: "none",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								marginBottom: 2,
								backgroundColor:
									this.state.tab === "edit"
										? APP_COLOURS.BLUE
										: "transparent",

								borderWidth: 0.3,
								borderColor: APP_COLOURS.BLUE,
								marginRight: 3,

								borderRadius: 10,
							}}>
							<View
								style={{
									padding: 10,
								}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 12,
										fontWeight: "600",
										color:
											this.state.tab === "edit"
												? "#FFF"
												: APP_COLOURS.BG2,
									}}>
									Edit task
								</Text>
							</View>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flex: 1,
							width: "100%",
							maxWidth: 550,
							maxHeight: 800,
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
							// padding: 10,
							backgroundColor: "#FFF",
							borderRadius: 15,
							overflow: "hidden",
						}}>
						{/* {this.state.tab === "chat" ? ( */}
						<View
							style={{
								width: "100%",
								padding: 5,
								// flexDirection: "row",
								justifyContent: "space-between",
								marginTop: 5,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<TextInput
									style={{
										width: "100%",
										padding: 5,
										fontSize: 19,
										borderRadius: 9,
										color: APP_COLOURS.BG2,
										fontWeight: "700",
										fontFamily: "Avenir",
										borderWidth: 0.3,
										borderColor:
											this.state.tab === "edit"
												? APP_COLOURS.BG2
												: "#FFF",
									}}
									placeholderTextColor={"#797979"}
									// multiline
									// autoFocus
									numberOfLines={2}
									autoCapitalize='sentences'
									clearButtonMode='while-editing'
									// autoCompleteType=''
									editable={this.state.tab === "edit"}
									autoCorrect={true}
									keyboardType='default'
									// textContentType='none'
									placeholder={`Task title `}
									defaultValue={this.state.title}
									onChangeText={(text) => {
										let t = capitalizeName(text);
										this.setState({
											taskName: t,
											changesMade: true,
										});
										d.title = t;
									}}
								/>
							</View>

							<Text
								style={{
									color: "#797979",
									fontSize: 8,
									fontWeight: "500",
									margin: 2,
									marginTop: 4,
								}}>
								Change Status
							</Text>
							<View
								style={{
									// flex: 1,
									alignItems: "center",
									// justifyContent: 'center',
									// padding: 5,
									flexDirection: "row",
								}}>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
									}}>
									{[
										"Inprogress",
										"Paused",
										"Stuck",
										"Done",
									].map((status) => (
										<TouchableOpacity
											onPress={() =>
												this.addChangeStatus(d, status)
											}
											style={{
												flex: 1,
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center",
												marginBottom: 2,
												backgroundColor:
													d.status == status
														? APP_COLOURS.BLUE
														: "transparent",

												borderWidth: 0.3,
												borderColor: APP_COLOURS.BLUE,
												marginRight: 3,
												// width: 100,
												borderRadius: 10,
											}}>
											<View
												style={{
													padding: 10,
												}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 12,
														fontWeight: "600",
														color:
															d.status == status
																? "#FFF"
																: APP_COLOURS.BG2,
													}}>
													{status}
												</Text>
											</View>
										</TouchableOpacity>
									))}
								</View>
							</View>
						</View>
						{/* ) : null} */}

						{this.state.tab === "edit" ? (
							<View
								style={{
									flex: 1,
									width: "100%",
									padding: 10,
								}}>
								<ScrollView
									showsVerticalScrollIndicator={false}>
									<View
										style={{
											height: "auto",
											width: "100%",
											marginBottom: 5,
											marginTop: 3,
										}}>
										<TextInput
											style={{
												width: "100%",
												padding: 5,
												fontSize: 14,
												borderRadius: 9,
												color: APP_COLOURS.BG2,
												fontWeight: "200",
												fontFamily: "Avenir",
												borderWidth: 0.3,
												borderColor: APP_COLOURS.BG2,
											}}
											placeholderTextColor={"#797979"}
											multiline
											numberOfLines={7}
											// autoFocus
											autoCapitalize='sentences'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											placeholder={`Description `}
											defaultValue={
												this.state.description
											}
											onChangeText={(text) => {
												let t = capitalizeName(text);
												this.setState({
													taskDescription: t,
													changesMade: true,
												});
												d.description = t;
											}}
										/>
									</View>

									{/* <View
										style={{
											// flex: 1,
											padding: 5,
										}}>
										<View
											style={{
												// flex: 1,
												marginBottom: 10,
												marginTop: 25,
												zIndex: 999,
											}}>
											<View
												style={{
													width: "100%",
													backgroundColor: "#FFF",
													padding: 10,
													fontSize: 16,
													borderRadius: 9,
													color: APP_COLOURS.BG2,
													fontWeight: "200",
													fontFamily: "Avenir",
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BG2,
													height: 50,
												}}>
												<DatePicker
													selected={
														this.state.dueDate === ""
															? evening
															: new Date(this.state.dueDate)
													}
													popperPlacement='top-end'
													popperModifiers={{
														offset: {
															enabled: true,
															offset: "5px, 10px",
														},
														preventOverflow: {
															enabled: true,
															escapeWithReference: false,
															boundariesElement: "viewport",
														},
													}}
													onChange={(date) => {
														this.setState({
															dueDate: date,
															changesMade: true,
														});
														d.dueDate = date;
													}}
												/>
											</View>
											<Text
												allowFontScaling={false}
												style={{
													fontSize: 10,
													fontFamily: "Avenir Next",
													margin: 5,
													paddingLeft: 10,
													paddingRight: 10,
													fontWeight: "400",
													backgroundColor: "#FFF",
													position: "absolute",
													borderRadius: 6,
													top: -13,
													left: 5,
												}}>
												Due date
											</Text>
											<Text
												style={{
													fontSize: 14,
													fontFamily: "Avenir Next",
													margin: 5,
													paddingLeft: 10,
													paddingRight: 10,
													fontWeight: "400",
													position: "absolute",
													top: 12,
													left: 5,
												}}>
												{moment(d.dueDate).format("DD/MM/YYYY")}
											</Text>
										</View>
									</View> */}

									<CalendarPicker
										title='Due date'
										selectedDate={moment().format(
											"DD/MM/YYYY",
										)}
										setDate={(date) => {
											this.setState({
												dueDate: moment(
													date,
													"DD/MM/YYYY",
												).toLocaleString(),
												changesMade: true,
											});
											d.dueDate = moment(
												date,
												"DD/MM/YYYY",
											).toLocaleString();
										}}
									/>

									<View
										style={{
											height: "auto",
											width: "100%",
											marginBottom: 10,
											zIndex: 2,
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize: 9,
												fontFamily: "Avenir Next",
												margin: 5,
												// paddingLeft: 10,
												fontWeight: "400",
											}}>
											Assignment
										</Text>
										<View
											style={{
												width: "100%",
												// height: 50,
												// alignItems: "center",
												justifyContent: "center",
												// paddingLeft: 20,
												borderTopColor: "#797979",
												borderBottomColor: "#797979",
												borderTopWidth: 0.5,
												borderBottomWidth: 0.5,
												paddingTop: 3,
											}}>
											{this.props.users.map((user) => {
												let assigned =
													this.state.assignment.filter(
														(dd) =>
															dd.id == user.id,
													);
												return (
													<TouchableOpacity
														onPress={async () => {
															let taskAss =
																this.state.assignment.filter(
																	(ds) =>
																		ds.id !==
																		user.id,
																);

															if (
																assigned.length >
																0
															) {
																this.setState({
																	assignment:
																		taskAss,
																	changesMade: true,
																});
																d.assignment =
																	taskAss;
															} else {
																this.setState({
																	assignment:
																		taskAss.concat(
																			user,
																		),
																	changesMade: true,
																});
																d.assignment =
																	taskAss.concat(
																		user,
																	);
															}
														}}
														style={{
															flexDirection:
																"row",
															justifyContent:
																"flex-start",
															alignItems:
																"center",
															marginBottom: 2,
															width: 100,
															backgroundColor:
																assigned.length >
																0
																	? "#797979"
																	: "transparent",
															borderRadius: 12,
														}}>
														<Image
															source={
																avatars[
																	parseInt(
																		user.avatar,
																	)
																]
															}
															style={{
																width: 25,
																height: 25,
																borderRadius: 25,
																resizeMode:
																	"contain",
																backgroundColor:
																	"#FFF",
																marginRight: 5,
															}}
														/>
														<View style={{}}>
															<Text
																style={{
																	fontFamily:
																		"Avenir",
																	fontSize: 15,
																	fontWeight:
																		"600",
																	color:
																		assigned.length >
																		0
																			? "#FFF"
																			: APP_COLOURS.BG2,
																}}>
																{
																	user.first_name
																}
															</Text>
														</View>
													</TouchableOpacity>
												);
											})}
										</View>
									</View>

									<View
										style={{
											width: 110,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 6,
													fontWeight: "500",
													color: APP_COLOURS.BG2,
												}}>
												Date created
											</Text>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 10,
													fontWeight: "600",
													color: APP_COLOURS.BG2,
												}}>
												{moment(d.date_created).format(
													"DD/MM/YYYY HH:mm",
												)}
											</Text>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 8,
													fontWeight: "600",
													fontStyle: "italic",
													color: APP_COLOURS.BG2,
												}}>
												added by {d.addedBy.first_name}
												...
											</Text>
										</View>
									</View>
									<View
										style={{
											height: 90,
										}}
									/>
								</ScrollView>
							</View>
						) : null}

						{this.state.changesMade ? (
							<TouchableOpacity
								onPress={() => this.updateItem(d)}
								style={{
									zIndex: 99,

									width: "100%",
									paddinng: 5,
									justifyContent: "center",
									alignItems: "center",
									marginBottom: 20,
								}}>
								<View
									style={{
										width: "98%",
										backgroundColor: APP_COLOURS.GREEN,
										padding: 9,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 10,
										position: "absolute",
										bottom: 0,
									}}>
									<Text
										style={{
											color: APP_COLOURS.WHITE,
											fontSize: 13,
											fontWeight: "600",
										}}>
										Save changes
									</Text>
								</View>
							</TouchableOpacity>
						) : null}

						{this.state.tab === "chat" ? (
							<ChatParent
								chat_id={d.id}
								d={d}
								{...this.state}
								accessToken={this.props.accessToken}
								user_data={this.props.user_data}
								screenWidth={
									this.props.screenWidth > 700
										? 700
										: this.props.screenWidth
								}
								screenHeight={
									this.props.screenHeight > 700
										? 700
										: this.props.screenHeight
								}
							/>
						) : null}
					</View>
				</View>
			</Modal>
		);
	}

	// addNewNote = async (d) => {
	// 	let uuid = uuidv4();
	// 	if (this.state.noteData == "") {
	// 		return;
	// 	}
	// 	let data = {
	// 		id: uuid,
	// 		task_id: this.props.data.id,
	// 		data: this.state.noteData,
	// 		date_added: moment().toLocaleString(),
	// 		user: this.props.user_data,
	// 	};
	// 	await this.setState({
	// 		noteData: "",
	// 	});
	// 	await console.log(this.props.user_data);
	// 	await this.textinput.clear();
	// 	await this.props.addNotes(data);
	// };

	addChangeStatus = async (d, status) => {
		d.status = status;
		await this.props.addChangeStatus(d);
	};

	updateItem = async (d) => {
		console.log(d);
		// d[field] = val;
		await this.props.updateItem(d);
		await this.props.close(false);
	};
}

export default ModalTaskPreview;
