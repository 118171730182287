import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from "react-native";
import Slide from "react-reveal/Slide";
import moment from "moment";
// import add_btn from "./../../assets/images/add_btn.png";
import { APP_COLOURS } from "../../APP_VARS";

function isEven(n) {
	return n % 2 == 0;
}

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class Accounts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenWidth: this.props.screenWidth,
			screenHeight: this.props.screenHeight,
		};
	}

	addItem = () => {
		let obj = {
			id: 1,
			allocation: this.state.selectedCompanyID,

			title: "Wes",
			amount: 2040,
			type: "income",
			currency: "R",
			date: moment().format("YYYY-MM-DD HH:mm:ss"),
			vat: 123,
		};

		this.setState({
			ledger: this.props.data.concat(obj),
		});
	};

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		return (
			<Modal
				transparent={true}
				visible={true}
				animationType='fade'
				shouldCloseOnOverlayClick={true}
				visible={this.state.ebookUpdated}
				style={{
					width: this.state.screenWidth,
					height: this.state.screenHeight,
				}}>
				<TouchableOpacity
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
					}}
					onPress={() => this.props.closeModal()}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
							width: this.state.screenWidth,
							height: this.state.screenHeight,
						}}></View>
				</TouchableOpacity>

				<View
					style={{
						width: 200,
						maxHeight: 250,
						backgroundColor: APP_COLOURS.PRIMARY,
						position: "absolute",
						top: this.props.yPos,
						left: this.props.xPos,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.25,
						shadowRadius: 7.84,
						elevation: 12,
						borderRadius: 10,
						padding: 10,
						zIndex: 999,
					}}>
					<ScrollView>
						<Slide top cascade>
							<TextInput
								style={{
									color: "#FFF",
									fontWeight: "200",
									fontFamily: "Avenir",
									borderBottomColor:
										"rgba(255, 255, 255, 0.3)",
									borderBottomWidth: 0.5,
									padding: 8,
									marginBottom: 8,
									alignItems: "center",
								}}
								// ref={(ref) => {
								// 	this.addcontentTextInput = ref;
								// }}
								placeholderTextColor={"#797979"}
								multiline
								// numberOfLines={10}
								autoCapitalize='words'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={false}
								keyboardType='default'
								textContentType='none'
								placeholder={"Placeholder"}
								// value={this.state.newContent.data}
								// defaultValue={}
								// onChangeText={async (text) => {
								// 	// let validated = this.validateEmail(text);
								// 	this.setState((prevState) => ({
								// 		newContent: {
								// 			...prevState.newContent,
								// 			data: text,
								// 		},
								// 		changeContentMade: true,
								// 	}));
								// }}
							/>
							<TextInput
								style={{
									color: "#FFF",
									fontWeight: "200",
									fontFamily: "Avenir",
									borderBottomColor:
										"rgba(255, 255, 255, 0.3)",
									borderBottomWidth: 0.5,
									padding: 8,
									marginBottom: 8,
									alignItems: "center",
								}}
								// ref={(ref) => {
								// 	this.addcontentTextInput = ref;
								// }}
								placeholderTextColor={"#797979"}
								multiline
								// numberOfLines={10}
								autoCapitalize='words'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={false}
								keyboardType='default'
								textContentType='none'
								placeholder={`Content `}
								// value={this.state.newContent.data}
								// defaultValue={}
								// onChangeText={async (text) => {
								// 	// let validated = this.validateEmail(text);
								// 	this.setState((prevState) => ({
								// 		newContent: {
								// 			...prevState.newContent,
								// 			data: text,
								// 		},
								// 		changeContentMade: true,
								// 	}));
								// }}
							/>
							<TextInput
								style={{
									color: "#FFF",
									fontWeight: "200",
									fontFamily: "Avenir",
									borderBottomColor:
										"rgba(255, 255, 255, 0.3)",
									borderBottomWidth: 0.5,
									padding: 8,
									marginBottom: 8,
									alignItems: "center",
								}}
								// ref={(ref) => {
								// 	this.addcontentTextInput = ref;
								// }}
								placeholderTextColor={"#797979"}
								multiline
								// numberOfLines={10}
								autoCapitalize='words'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={false}
								keyboardType='default'
								textContentType='none'
								placeholder={`Content `}
								// value={this.state.newContent.data}
								// defaultValue={}
								// onChangeText={async (text) => {
								// 	// let validated = this.validateEmail(text);
								// 	this.setState((prevState) => ({
								// 		newContent: {
								// 			...prevState.newContent,
								// 			data: text,
								// 		},
								// 		changeContentMade: true,
								// 	}));
								// }}
							/>
							<TextInput
								style={{
									color: "#FFF",
									fontWeight: "200",
									fontFamily: "Avenir",
									borderBottomColor:
										"rgba(255, 255, 255, 0.3)",
									borderBottomWidth: 0.5,
									padding: 8,
									marginBottom: 8,
								}}
								// ref={(ref) => {
								// 	this.addcontentTextInput = ref;
								// }}
								placeholderTextColor={"#797979"}
								multiline
								// numberOfLines={10}
								autoCapitalize='words'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={false}
								keyboardType='default'
								textContentType='none'
								placeholder={`Content `}
								// value={this.state.newContent.data}
								// defaultValue={}
								// onChangeText={async (text) => {
								// 	// let validated = this.validateEmail(text);
								// 	this.setState((prevState) => ({
								// 		newContent: {
								// 			...prevState.newContent,
								// 			data: text,
								// 		},
								// 		changeContentMade: true,
								// 	}));
								// }}
							/>
							<TouchableOpacity onPress={() => this.addItem()}>
								<View
									style={{
										// flex: 1,
										backgroundColor: APP_COLOURS.BLUE,
										borderRadius: 10,
										justifyContent: "center",
										flexDirection: "row",
										alignItems: "center",
										height: "auto",
										padding: 4,
									}}>
									{/* <Image
								source={add_btn}
								style={{
									width: 15,
									height: 15,
									marginRight: 4,
									marginLeft: 4,
								}}
							/> */}
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 10,
											color: "#FFF",
											fontWeight: "300",
										}}>
										Save
									</Text>
								</View>
							</TouchableOpacity>
						</Slide>
					</ScrollView>
				</View>
			</Modal>
		);
	}
}

export default Accounts;
