import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { View, Modal, Image, TouchableOpacity, Text } from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import { APP_COLOURS } from "../APP_VARS";
// import Profile from "./Profile";
//
//
// import Tickets from "./Tickets";
import Tasks from "./Tasks";
// import Accounts from "./Accounts";
// import DebtorsAccount from "./DebtorsAccount";
// import IncomeExpenses from "./IncomeExpenses/index";
// import Settings from "./Settings";
// import Debbie from "./Debbie";

// import DocCreator from "./DocCreator/index";
import EntitiesModal from "./ModalEntities";
import ModalProfile from "./ModalProfile";
import Milestones from "./Milestones/Spreadsheet";
import TetriceTasks from "./TetriceTasks/Spreadsheet";
//
//
import logo from "./../../assets/images/logo.png";
import tetrice_logo from "./../../assets/images/menu.png";
import close from "./../../assets/images/close.png";
import settings from "./../../assets/images/settings.png";
import debbie from "./../../assets/images/cloud.png";
import moment from "moment";

import { api_getUsers } from "../Api";

class MobileHome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: "",
			email: "",
			auth_token: "",
			refresh_token: "",
			screenWidth: this.props.screenWidth,
			screenHeight: this.props.screenHeight,
			authenticating: true,
			showSidebar: false, //
			validated: true,
			currentPage: "tasks",
			currentPageTitle: "Tasks",
			pages: [
				// { title: "Calendar", page: "Calendar" },
				// {
				// 	title: "Dashboard",
				// 	page: "dashboard",
				// },
				// { title: "FICA", page: "fica" },
				{
					title: "Tasks",
					page: "tasks",
					img: require("./../../assets/images/gantt_icon.png"),
				},
				// {
				// 	title: "Tetrice Tasks",
				// 	page: "tetricetasks",
				// 	img: require("./../../assets/images/appFeatures.png"),
				// },
				// {
				// 	title: "Accounts",
				// 	page: "DebtorsAccount",
				// 	img: require("./../../assets/images/accounts.png"),
				// },
				// {
				// 	title: "Document Creator",
				// 	page: "DocCreator",
				// 	img: require("./../../assets/images/document.png"),
				// },
			],
			selectedEntity: "",
			selectedEntityID: "",
			entities: {},
			showProfileModal: false,
			clients: [
				{
					id: 1,
					clientOf: 1,
					title: "Client of Westonry SA (Pty) Ltd",
				},
			],
			users: [],
		};
	}

	async componentDidMount() {
		await this.validateSession();
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let accessToken = await sessionStorage.getItem("accessToken");
		let refresh_token = await sessionStorage.getItem("refresh_token");
		let sessionExpiry = await sessionStorage.getItem("sessionExpiry");

		// let mil =	miliseconds(0, )

		// var duration = moment.duration(end.diff(sessionExpiry));

		console.log(accessToken);

		var now = moment(); //todays date
		var end = moment(sessionExpiry); // another date
		var duration = moment.duration(now.diff(end));

		const dur = -1 * duration._milliseconds;

		if (accessToken !== "" && accessToken !== null) {
			await this.setState({
				user_data: JSON.parse(user_data),
				email,
				accessToken,
				refresh_token,
			});
			await window.addEventListener("resize", this.resize.bind(this));
			await this.resize();
			// await this.getEntitiesData();
			this.getUsers();
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);

			// setTimeout(() => {
			// 	sessionStorage.clear();
			// 	this.props.navigate("/");
			// }, dur);
		} else {
			this.props.navigate("/login");
			sessionStorage.clear();
		}
	};

	// getEntitiesData = () => {
	// 	this.setState({
	// 		loading: true,
	// 	});
	// 	var raw = JSON.stringify({
	// 		accessToken: this.state.accessToken,
	// 		command: "get",
	// 		fileName: "entities",
	// 	});
	// 	var myHeaders = new Headers();
	// 	myHeaders.append("Content-Type", "application/json");

	// 	var requestOptions = {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: raw,
	// 		redirect: "follow",
	// 	};

	// 	fetch(
	// 		"https://tetrice.co.za/REST/accountsapi/write_jsonlocal.php",
	// 		requestOptions,
	// 	)
	// 		.then((response) => {
	// 			console.log(response);
	// 			if (response.status == "200") {
	// 				response
	// 					.json()
	// 					.then((result) => {
	// 						console.log("result getJsonDataFromServer", result);

	// 						this.setState({
	// 							entities: result,
	// 							loading: false,
	// 						});
	// 					})
	// 					.catch((error) => this.setState({}));
	// 			} else {
	// 				this.setState({
	// 					loading: false,
	// 				});
	// 			}
	// 		})
	// 		.catch((error) => console.log("error", error));
	// };

	getUsers = async () => {
		let url = api_getUsers;

		await this.setState({
			loading: true,
		});

		let requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("responseData", responseData);
							this.setState({
								users: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							// this.setState({
							// 	loading: false,
							// });
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					// this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				// this.setState({
				// 	loading: false,
				// });
			});
	};

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	logOut = async () => {
		console.log("Log out");
		await sessionStorage.clear();
		await localStorage.clear();
		await this.props.navigate("/login");
	};

	showAddItemsFormFunc = (event) => {
		event.preventDefault();
		const layout = event.nativeEvent;
		let x = layout.clientX;
		let y = layout.clientY;
		this.setState({
			showAddItemsForm: true,
			xPos: x,
			yPos: y,
			showProfileModal: true,
		});

		console.log(layout);
	};

	render() {
		let w = this.props.screenWidth;
		let h = this.props.screenHeight;

		let avatars = {
			1: require("./../../assets/images/female1.png"),
			2: require("./../../assets/images/male1.png"),
			3: require("./../../assets/images/male2.png"),
		};

		if (this.state.authenticating) {
			return (
				<View
					style={{
						width: w,
						height: h,
						backgroundColor: APP_COLOURS.PRIMARY,
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<Image
							source={logo}
							style={{
								width: 160,
								height: 80,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>
			);
		} else
			return (
				<div
					style={{
						width: w,
						height: h,
						backgroundColor: APP_COLOURS.PRIMARY,

						marginLeft: "auto",
						marginRight: "auto",
						overflow: "hidden",
						position: "sticky",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							flex: 1,
							marginLeft: "auto",
							marginRight: "auto",
							flexDirection: "row",
						}}>
						<View
							style={{
								flex: 1,
								height: this.state.screenHeight,
							}}>
							<View
								style={{
									flexDirection: "row",
									padding: 5,
									justifyContent: "space-between",
								}}>
								<TouchableOpacity
									activeOpacity={1}
									onPress={() => {
										this.setState({
											showSidebar: !this.state.showSidebar,
										});
									}}>
									<Image
										source={logo}
										style={{
											width: 130,
											height: 38,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>

								<View
									style={{
										alignItems: "center",
										justifyContent: "center",
										backgroundColor: APP_COLOURS.BUTTON2,
										borderRadius: 24,
										// height: 48,
									}}>
									<TouchableOpacity
										style={{
											flexDirection: "row",
											justifyContent: "flex-start",
											width: "100%",
											alignItems: "center",
										}}
										onPress={(e) => this.showAddItemsFormFunc(e)}>
										<Image
											source={avatars[this.state.user_data.avatar]}
											style={{
												width: 40,
												height: 40,
												borderRadius: 20,
												resizeMode: "contain",
												backgroundColor: "#FFF",
											}}
										/>
									</TouchableOpacity>
								</View>
							</View>

							{this.state.currentPage === "tasks" ? (
								<Fade>
									<Tasks
										{...this.state}
										user_data={this.state.user_data}
										logOut={this.logOut.bind(this)}
										screenHeight={this.state.screenHeight}
										screenWidth={this.state.screenWidth}
									/>
								</Fade>
							) : null}
							{this.state.currentPage === "milestones" ? (
								<Fade>
									<Milestones
										{...this.state}
										user_data={this.state.user_data}
										logOut={this.logOut.bind(this)}
										screenHeight={this.state.screenHeight}
										screenWidth={this.state.screenWidth}
									/>
								</Fade>
							) : null}

							{this.state.currentPage === "tetricetasks" ? (
								<Fade>
									<TetriceTasks
										{...this.state}
										user_data={this.state.user_data}
										logOut={this.logOut.bind(this)}
										screenHeight={this.state.screenHeight}
										screenWidth={this.state.screenWidth}
									/>
								</Fade>
							) : null}
						</View>

						{this.state.showSidebar ? this.sideBarModal() : null}
					</View>

					{this.state.showProfileModal ? (
						<ModalProfile
							xPos={this.state.xPos}
							yPos={this.state.yPos}
							user_data={this.state.user_data}
							logOut={() => this.logOut()}
							closeModal={() =>
								this.setState({
									showProfileModal: false,
								})
							}
						/>
					) : null}
				</div>
			);
	}

	basicModal = (obj) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							width: this.props.screenWidth,
							maxWidth: 500,
							height: this.props.screenHeight,
							maxHeight: 500,
							backgroundColor: "white",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<View
							style={{
								position: "absolute",
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										addNewTaskModal: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: "100%",
								padding: 20,
								height: "100%",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 15,
									fontFamily: "Avenir Next",
									margin: 5,
									paddingLeft: 10,
									fontWeight: "700",
								}}>
								Add new task
							</Text>
							<View
								style={{
									// width: '100%',
									height: 60,
									borderRadius: 5,
									borderWidth: 0.3,
									borderColor: "#C5C5C5",
								}}></View>

							<TouchableOpacity
								onPress={() =>
									this.pushEditedContent(this.state.editItemsData)
								}>
								<View
									style={{
										width: "100%",
										backgroundColor: APP_COLOURS.BLUE,
										padding: 9,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.WHITE,
											fontSize: 11,
										}}>
										Done
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	sideBarModal = (obj) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						// justifyContent: "center",
						// alignItems: "center",
						backgroundColor: "rgba(40, 40, 40, 0.6)",
					}}>
					<TouchableOpacity
						activeOpacity={1}
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
						}}
						onPress={() =>
							this.setState({
								showSidebar: false,
							})
						}>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "rgba(52, 52, 52, 0.6)",
								width: this.state.screenWidth,
								height: this.state.screenHeight,
							}}></View>
					</TouchableOpacity>
					<View
						style={{
							position: "absolute",
							left: 0,
							zIndex: 999,
							width: 220,
							// maxWidth: 500,
							height: this.props.screenHeight,
							// maxHeight: 500,
							backgroundColor: APP_COLOURS.BG2,
							// borderRadius: 20,
							// justifyContent: "center",
							// alignItems: "center",
							padding: 5,
						}}>
						<Image
							source={tetrice_logo}
							style={{
								width: 38,
								height: 38,
								resizeMode: "contain",
								marginBottom: 25,
							}}
						/>
						{/* <TouchableOpacity
								onPress={() => {
									this.setState({
										showSidebar: !this.state.showSidebar,
									});
								}}>
								{this.state.showSidebar ? (
									<Image
										source={logo}
										style={{
											width: 160,
											height: 39,
											resizeMode: "contain",
											marginBottom: 25,
											marginTop: 5,
										}}
									/>
								) : (
									<Image
										source={tetrice_logo}
										style={{
											width: 38,
											height: 38,
											resizeMode: "contain",
											marginBottom: 25,
											marginTop: 5,
										}}
									/>
								)}
							</TouchableOpacity> */}
						{/* <View
								style={{
									width: 220,
									height: "auto",
									justifyContent: "center",
									padding: 5,
									paddingTop: 19,
									marginBottom: 10,
									display: this.state.showSidebar ? "flex" : "none",
								}}>
								<Text
									style={{
										fontSize: 11,
										color: "#FFF",
									}}>
									<Font
										style={{
											color: "#FFF",
										}}
										family='Poppins'
										weight={800}>
										{this.state.entities.length > 0 ? (
											<EntitiesModal
												data={this.state.entities}
												selectedEntity={(d) =>
													this.setState({
														selectedEntity: d.title,
														selectedEntityID: d.id,
													})
												}
											/>
										) : null}
										{"  "}
										<Text
											style={{
												opacity: 0.5,
											}}>
											{this.state.currentPageTitle}
										</Text>
									</Font>
								</Text>
							</View> */}

						<View
							style={{
								width: "100%",
								borderBottomColor: APP_COLOURS.BLUE,
								borderBottomWidth: 0.5,
								opacity: 0.3,
							}}
						/>

						<View
							style={{
								width: "100%",
								// flex: 1,
								// padding: 5,
								// display:
								// 	this.state.selectedEntityID !== ""
								// 		? "flex"
								// 		: "none",
							}}>
							{this.state.pages.map((d, i) => (
								<View
									key={i}
									style={{
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
									}}>
									<TouchableOpacity
										style={{
											width: "100%",
											height: "auto",
											maxHeight: 30,
											padding: 7,
											// alignItems: "center",
											borderRadius: 10,
											minHeight: 45,
											marginTop: 10,
											backgroundColor:
												this.state.currentPage === d.page
													? APP_COLOURS.BUTTON2
													: "transparent",
											flexDirection: "row",
											// alignItems: "center",
											justifyContent: "center",
										}}
										onPress={() => {
											this.setState({
												currentPage: d.page,
												currentPageTitle: d.title,
												showSidebar: false,
											});
										}}>
										<Image
											style={{
												width: 30,
												height: 30,
												resizeMode: "contain",
											}}
											source={d.img}
										/>
										<View
											style={{
												flex: 1,
												padding: 5,
												// flex: 1,
												// minWidth: 100,
												borderRadius: 10,
												// alignItems: "center",
												display: this.state.showSidebar
													? "flex"
													: "none",
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 14,
													// opacity: 0.8,
													color: APP_COLOURS.WHITE,
												}}>
												{d.title}
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							))}

							<View
								style={{
									marginTop: 10,
									width: "100%",
									borderBottomColor: APP_COLOURS.BLUE,
									borderBottomWidth: 0.5,
									opacity: 0.3,
								}}
							/>

							{this.state.user_data.username ===
							"wesley@tetrice.co.za" ? (
								<TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										maxHeight: 30,
										padding: 7,
										alignItems: "center",
										borderRadius: 10,
										minHeight: 45,
										marginBottom: 10,
										// backgroundColor: APP_COLOURS.BUTTON2,
										flexDirection: "row",
										marginTop: 20,
									}}
									onPress={() => {
										this.setState({
											currentPage: "tetricetasks",
											currentPageTitle: "tetricetasks",
											showSidebar: false,
										});
									}}>
									<Image
										source={require("./../../assets/images/appFeatures.png")}
										style={{
											width: 30,
											height: 30,
											// borderRadius: 20,
											resizeMode: "contain",
											marginRight: 10,
										}}
									/>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 14,
											// opacity: 0.8,
											color: APP_COLOURS.WHITE,
											display: this.state.showSidebar
												? "flex"
												: "none",
										}}>
										Tetrice tasks
									</Text>
								</TouchableOpacity>
							) : null}
							{/* <TouchableOpacity
								style={{
									width: "100%",
									height: "auto",
									maxHeight: 30,
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 45,
									marginBottom: 10,
									// backgroundColor: APP_COLOURS.BUTTON2,
									flexDirection: "row",
									marginTop: 20,
								}}
								onPress={() => {
									this.setState({
										currentPage: "debbie",
										currentPageTitle: "debbie",
										showSidebar: false,
									});
								}}>
								<Image
									source={debbie}
									style={{
										width: 30,
										height: 30,
										// borderRadius: 20,
										resizeMode: "contain",
										marginRight: 10,
									}}
								/>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										// opacity: 0.8,
										color: APP_COLOURS.WHITE,
										display: this.state.showSidebar ? "flex" : "none",
									}}>
									Debbie
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									width: "100%",
									height: "auto",
									maxHeight: 30,
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 45,
									marginBottom: 10,
									// backgroundColor: APP_COLOURS.BUTTON2,
									flexDirection: "row",
								}}
								onPress={() => {
									this.setState({
										currentPage: "settings",
										currentPageTitle: "settings",
									});
								}}>
								<Image
									source={settings}
									style={{
										width: 30,
										height: 30,
										// borderRadius: 20,
										resizeMode: "contain",
										marginRight: 10,
									}}
								/>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 14,
										// opacity: 0.8,
										color: APP_COLOURS.WHITE,
										display: this.state.showSidebar ? "flex" : "none",
									}}>
									Settings
								</Text>
							</TouchableOpacity> */}
						</View>
					</View>
				</View>
			</Modal>
		);
	};
}
export const withNavigation = (Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(MobileHome);
