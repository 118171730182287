let e = "";
// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
// 	e = `http://localhost:5001/tetricepm-5f972/us-central1/app`;
// } else {
e = `https://us-central1-tetricepm-5f972.cloudfunctions.net/app`;
// }

export const api_login = e + "/auth/login";

export const api_getTasks = e + "/dashboard/getTasks";

export const api_getChats = e + "/dashboard/getChats";

export const api_addMessage = e + "/dashboard/addMessage";

export const api_updateTask = e + "/dashboard/updateTask";

export const api_addTask = e + "/dashboard/addTask";

export const api_getUsers = e + "/dashboard/getUsers";

export const api_deleteTask = e + "/dashboard/deleteTask";

// export const api_getAllMemberships = e + "/staff/getAllMemberships";

// export const api_memberSignup = e + "/staff/memberSignup";

// export const api_grantAccess = e + "/staff/grantAccess";

// export const api_getMemberAccess = e + "/staff/getMemberAccess";

// export const api_uploadMemberProfilePic =
// 	e + "/staff/uploadMemberProfilePicture";

// export const api_searchmember = e + "/staff/searchMember";

// export const api_getinstallments = e + "/membermandatesstaff/installments";

// export const api_checkidexists = e + "/staff/checkidexists";

// export const api_newmembership = e + "/staff/newmembership";

// export const api_getaccessprofile = e + "/staff/getaccessprofile";

// export const api_manualpayment = e + "/staff/manualpayment";

// export const api_getaccessmodule = e + "/staff/getAccessModule";
