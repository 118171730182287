import React, { Component, useReducer } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
	Modal,
} from "react-native";

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					width: this.props.screenWidth,
					height: this.props.screenHeight,
					backgroundColor: "red",
				}}>
				<Text> index </Text>
			</View>
		);
	}
}
