import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	TextInput,
} from "react-native";
import RecurringAccounts from "./Accounts/RecurringAccounts";
import IncomeExpenses from "./IncomeExpenses/index";
import ModalForm from "./Accounts/ModalForm";
import moment from "moment";
import add_btn from "./../../assets/images/add_btn.png";
import { APP_COLOURS } from "../APP_VARS";

function isEven(n) {
	return n % 2 == 0;
}

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class Accounts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAddItemsForm: false,
			xPos: 0,
			yPos: 0,
			companies: [
				{
					id: 1,
					title: "Westonry SA (Pty) Ltd",
				},
				{
					id: 2,
					title: "Westonry LLC",
				},
				{
					id: 3,
					title: "tetrice pty ltd",
				},
			],
			trusts: [
				{
					id: 3,
					title: "WNB Trust",
				},
			],
			personal: [
				{
					id: 1,
					title: "Tony Cheia",
				},
				{
					id: 2,
					title: "Rose Cheia",
				},
				{
					id: 3,
					title: "Wesley Cheia",
				},
				{
					id: 4,
					title: "Clinton Cheia",
				},
				{
					id: 5,
					title: "Deidre Cheia",
				},
			],

			groupedAccounts: [],

			recurringAccounts: [
				{
					id: 2,
					title: "Vodacom",
					allocation: 1,
					date_created: "2021/04/16",
					expiry_date: "2012/10/10",
				},
				{
					id: 3,
					title: "NORD VPN",
					allocation: 1,
					date_created: "2012/10/10",
					expiry_date: "2012/10/10",
				},
			],

			ledger: [
				{
					id: 1,
					allocation: 1,
					title: "Vodacom cellphone",
					amount: 2040,
					type: "income",
					currency: "R",
					date: "2021/04/16",
					vat: 123,
				},
				{
					id: 1,
					allocation: 1,
					title: "Vodacom cellphone",
					amount: 2040,
					type: "income",
					currency: "R",
					date: "2021/04/16",
					vat: 123,
				},
				{
					id: 1,
					allocation: 1,
					title: "YTFytfgsdgyufsdguyf",
					amount: 10000,
					type: "income",
					currency: "R",
					date: "2019/06/10",
					vat: 123,
				},
				{
					id: 1,
					allocation: 1,
					title: "Vodacom cellphone",
					amount: 2040,
					type: "income",
					currency: "R",
					date: "2021/04/16",
					vat: 123,
				},
				{
					id: 1,
					allocation: 1,
					title: "Vodacom cellphone",
					amount: 2040,
					type: "income",
					currency: "R",
					date: "2021/04/16",
					vat: 123,
				},
				{
					id: 1,
					allocation: 1,
					title: "vv cellphone",
					amount: 2040,
					type: "income",
					currency: "R",
					date: "2021/04/16",
					vat: 123,
				},
				{
					id: 1,
					allocation: 1,
					title: "d cellphone",
					amount: 2040,
					type: "income",
					currency: "R",
					date: "2021/04/16",
					vat: 123,
				},
				{
					id: 1,
					allocation: 1,
					title: "vv cellphone",
					amount: 2040,
					type: "income",
					currency: "R",
					date: "2021/04/16",
					vat: 123,
				},
				{
					id: 2,
					title: "Godaddy",
					allocation: 1,
					amount: 1500,
					type: "expense",
					currency: "R",
					date: "2021/12/16",
					vat: 234,
				},
				{
					id: 3,
					title: "Nord VPN",
					allocation: 1,
					amount: 1500,
					type: "expense",
					currency: "R",
					date: "2020/04/16",
					vat: 234,
				},
			],

			ledgerByMonth: [],
			accountsPerMonth: [],
			selectedCompanyID: 0,
			selectedCompany: "",
		};
	}

	async componentDidMount() {
		await this.setState({
			loading: true,
		});
		let month = moment();
		await this.selectMonth(month);
	}
	selectCompany = async (d) => {
		await this.setState({
			selectedCompany: d.title,
			selectedCompanyID: d.id,
		});
		let month = moment();
		await this.selectMonth(month);
	};

	selectMonth = async (month) => {
		await this.setState({
			month: month,
			loading: true,
			showChart: false,
		});
		await this.calculateCalendar();
	};

	calculateCalendar = async () => {
		let month = this.state.month;
		let data = this.state.ledger.filter(
			(d_) => d_.allocation == this.state.selectedCompanyID,
		);
		const startOfMonth = moment(month)
			.clone()
			.startOf("month")
			.format("YYYY-MM-DD");
		const endOfMonth = moment(month)
			.clone()
			.endOf("month")
			.format("YYYY-MM-DD");

		let daysInMonth = moment(month).daysInMonth();

		console.log("initial filter dates ", startOfMonth, " to ", endOfMonth);

		// console.log(daysInMonth);

		let calendar = [];
		let day = startOfMonth;

		for (let index = 0; index < daysInMonth; index++) {
			// console.log(day);
			await calendar.push(day);
			day = moment(day).add(1, "day").format("YYYY-MM-DD");
		}

		await this.setState({
			calendar: calendar,
		});
		console.log("calendar", JSON.stringify(calendar));

		let filteredData = data.filter((obj) => {
			return (
				moment(new Date(obj.date)).format("MM yyyy") >=
					moment(month).format("MM yyyy") &&
				moment(new Date(obj.date)).format("MM yyyy") <=
					moment(month).format("MM yyyy")
			);
		});

		console.log("filteredData", JSON.stringify(filteredData));
		await this.setState({
			ledgerByMonth: filteredData,
			loading: false,
		});

		//
		//
		//
		// CALCULATE each unique month and yesr for each account event
		let dates = [];
		await data.forEach((d) => {
			let formatDate = moment(d.date).format("YYYY-MM-DD");
			dates.push(formatDate);
		});
		const sortDates = await dates.sort(function (a, b) {
			let first = new Date(a);
			let last = new Date(b);
			return last - first;
		});
		var accountsPerMonth = [];
		for (const x of sortDates) {
			let m = moment(x).format("MMM YY");
			let index = await accountsPerMonth.findIndex((d) => d.date == m);
			// console.log(index);
			if (index < 0) {
				await accountsPerMonth.push({
					date: m,
					date_: moment(x),
					count: 1,
				});
			} else {
				accountsPerMonth[index].count =
					accountsPerMonth[index].count + 1;
			}
		}
		console.log("accountsPerMonth ====", accountsPerMonth);
		//
		await this.setState({
			accountsPerMonth: accountsPerMonth,
		});

		//
		//
		// GROUP EACH ACCOUNT BALANCE FOR THE MONTH

		const uniqueAccounts = [
			...new Set(filteredData.map((item) => item.title)),
		];

		console.log(uniqueAccounts);
		let groupedAccounts = [];
		uniqueAccounts.forEach((el) => {
			let array = this.state.ledger.filter((d) => d.title == el);
			let amountTotal = array.reduce(
				(prev, cs, index) => prev + cs.amount,
				0,
			);
			let obj = {
				title: el,
				amount: amountTotal,
			};
			groupedAccounts.push(obj);
		});

		await this.setState({
			groupedAccounts: groupedAccounts,
		});
		//
		//
	};

	addItem = () => {
		let obj = {
			id: 1,
			allocation: this.state.selectedCompanyID,

			title: "Wes",
			amount: 2040,
			type: "income",
			currency: "R",
			date: moment().format("YYYY-MM-DD HH:mm:ss"),
			vat: 123,
		};

		this.setState({
			ledger: this.props.data.concat(obj),
		});
	};

	showAddItemsFormFunc = (event) => {
		event.preventDefault();
		const layout = event.nativeEvent;
		let x = layout.clientX;
		let y = layout.clientY;
		this.setState({
			showAddItemsForm: true,
			xPos: x,
			yPos: y,
		});

		console.log(layout);
	};

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		// let uniquMonths = this.state.ledger
		// 	.filter((d_) => d_.date)
		// 	.map((d) => d.date);

		// console.log(uniquMonths);

		let elementHeight = this.props.screenHeight - 200;

		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
				}}>
				<View
					style={{
						height: "100%",
						width: 200,
						padding: 5,
					}}>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 12,
							color: APP_COLOURS.BG2,
							fontWeight: "600",
							marginTop: 10,
							marginBottom: 5,
						}}>
						Companies
					</Text>
					{this.state.companies.map((d, i) => (
						<View
							key={i}
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								height: "auto",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: 30,
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 45,
									marginBottom: 10,
									backgroundColor:
										APP_COLOURS.WHITE_TRANSPARENT,
									flexDirection: "row",
								}}
								onPress={() => this.selectCompany(d)}>
								<View
									style={{
										width: 5,
										borderRadius: 4,
										height: "100%",
										backgroundColor: APP_COLOURS.BG2,
										opacity:
											this.state.selectedCompany ==
											d.title
												? 1
												: 0.2,
										marginRight: 12,
									}}
								/>
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										alignItems: "center",
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											color: APP_COLOURS.BG2,
										}}>
										{d.title}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					))}

					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 12,
							color: APP_COLOURS.BG2,
							fontWeight: "600",
							marginTop: 10,
							marginBottom: 5,
						}}>
						Trusts
					</Text>
					{this.state.trusts.map((d, i) => (
						<View
							key={i}
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								height: "auto",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: 30,
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 45,
									marginBottom: 10,
									backgroundColor:
										APP_COLOURS.WHITE_TRANSPARENT,
									flexDirection: "row",
								}}
								onPress={() => this.selectCompany(d)}>
								<View
									style={{
										width: 5,
										borderRadius: 4,
										height: "100%",
										backgroundColor: APP_COLOURS.BG2,
										opacity:
											this.state.selectedCompany ==
											d.title
												? 1
												: 0.2,
										marginRight: 12,
									}}
								/>
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										alignItems: "center",
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											color: APP_COLOURS.BG2,
										}}>
										{d.title}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					))}

					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 12,
							color: APP_COLOURS.BG2,
							fontWeight: "600",
							marginTop: 10,
							marginBottom: 5,
						}}>
						Personal
					</Text>
					{this.state.personal.map((d, i) => (
						<View
							key={i}
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								height: "auto",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: 30,
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 45,
									marginBottom: 10,
									backgroundColor:
										APP_COLOURS.WHITE_TRANSPARENT,
									flexDirection: "row",
								}}
								onPress={() => this.selectCompany(d)}>
								<View
									style={{
										width: 5,
										borderRadius: 4,
										height: "100%",
										backgroundColor: APP_COLOURS.BG2,
										opacity:
											this.state.selectedCompany ==
											d.title
												? 1
												: 0.2,
										marginRight: 12,
									}}
								/>
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										alignItems: "center",
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											color: APP_COLOURS.BG2,
										}}>
										{d.title}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					))}
				</View>
				<View
					style={{
						flex: 1,
						padding: 5,
						display:
							this.state.selectedCompany !== "" ? "flex" : "none",
					}}>
					<View
						style={{
							flex: 1,
							backgroundColor: APP_COLOURS.WHITE_TRANSPARENT,
							borderRadius: 10,
							padding: 10,
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontFamily: "Avenir",
								fontWeight: "700",
								fontSize: 17,
							}}>
							{this.state.selectedCompany}
						</Text>
						<View
							style={{
								height: this.props.screenHeight - 120,
								paddingBottom: 10,
							}}>
							<ScrollView
								contentContainerStyle={{
									flexDirection: "row",
									flexWrap: "wrap",
									justifyContent: "flex-start",
								}}>
								<View
									style={{
										// flex: 1,
										width: "100%",
										maxWidth: 600,
										height: elementHeight,
										borderRadius: 10,
										overflow: "hidden",
										backgroundColor: APP_COLOURS.BG2,
										margin: 10, // used to space each item
									}}>
									<View
										style={{
											width: "100%",
											padding: 7,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT3,
											flexDirection: "row",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "700",
												color: "#FFF",
												fontSize: 17,
											}}>
											Monthly expenses
										</Text>
										<TouchableOpacity
											style={{
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={(e) =>
												this.showAddItemsFormFunc(e)
											}>
											<View>
												<Image
													source={add_btn}
													style={{
														width: 22,
														height: 22,
														// borderRadius: 25,
														resizeMode: "contain",
														marginRight: 5,
													}}
												/>
											</View>
										</TouchableOpacity>
									</View>

									<View
										style={{
											// width: 500,
											padding: 5,
											backgroundColor: APP_COLOURS.BG2,
										}}>
										<View
											style={{
												height: "auto",
												marginTop: 2,
											}}>
											<ScrollView horizontal>
												<View
													style={{
														flexDirection: "row",
														paddingBottom: 10,
													}}>
													{this.state.accountsPerMonth.map(
														(d, i) => {
															return (
																<TouchableOpacity
																	onPress={() =>
																		this.selectMonth(
																			d.date_,
																		)
																	}>
																	<View
																		style={{
																			padding: 10,
																			backgroundColor:
																				"#0676ED",
																			opacity:
																				moment(
																					this
																						.state
																						.month,
																				).format(
																					"YYYY MM",
																				) ==
																				moment(
																					d.date_,
																				).format(
																					"YYYY MM",
																				)
																					? 1
																					: 0.3,
																			borderRadius: 10,
																			marginRight: 10,
																		}}>
																		<Text
																			style={{
																				color: "#FFF",
																				fontWeight:
																					"700",
																				fontSize: 18,
																			}}>
																			{moment(
																				d.date_,
																			).format(
																				"MMM",
																			)}{" "}
																			<Text
																				style={{
																					color: "#FFF",
																					fontWeight:
																						"200",
																				}}>
																				{
																					" '"
																				}
																				{moment(
																					d.date_,
																				).format(
																					"YY",
																				)}
																			</Text>
																		</Text>

																		<Text
																			style={{
																				color: "#FFF",
																				fontSize: 10,
																				fontWeight:
																					"700",
																				opacity: 0.5,
																				marginTop: 5,
																			}}>
																			{
																				d.count
																			}
																		</Text>
																	</View>
																</TouchableOpacity>
															);
														},
													)}
												</View>
											</ScrollView>
										</View>

										<IncomeExpenses
											user_data={this.props.user_data}
											data={this.state.ledgerByMonth}
											screenHeight={elementHeight - 44}
											screenWidth={this.state.screenWidth}
										/>
									</View>
								</View>

								<View
									style={{
										width: "100%",
										maxWidth: 350,
										maxHeight: elementHeight,
										height: "auto",
										borderRadius: 10,
										overflow: "hidden",
										backgroundColor: APP_COLOURS.BG2,
										margin: 10,
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT3,
											flexDirection: "row",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "700",
												color: "#FFF",
												fontSize: 17,
											}}>
											Grouped accounts
										</Text>
									</View>

									<View
										style={{
											width: "100%",
											padding: 10,
											backgroundColor: APP_COLOURS.BG2,
										}}>
										<ScrollView>
											{this.state.groupedAccounts.map(
												(d, i) => (
													<View
														style={{
															flexDirection:
																"row",
															alignItems:
																"center",
															justifyContent:
																"space-between",
														}}>
														<Text
															style={{
																fontFamily:
																	"Avenir",
																fontSize: 14,
																fontWeight:
																	"400",
																color: APP_COLOURS.WHITE,
															}}>
															{d.title}
														</Text>
														<Text
															style={{
																fontFamily:
																	"Avenir",
																fontSize: 14,
																fontWeight:
																	"400",
																color: APP_COLOURS.WHITE,
															}}>
															R{" "}
															{currencyFormat(
																d.amount,
															)}
														</Text>
													</View>
												),
											)}
										</ScrollView>
									</View>
								</View>

								<View
									style={{
										width: "100%",
										maxWidth: 350,
										height: "auto",
										borderRadius: 10,
										overflow: "hidden",
										backgroundColor: APP_COLOURS.BG2,
										margin: 10,
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT3,
											flexDirection: "row",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontWeight: "700",
												color: "#FFF",
												fontSize: 17,
											}}>
											Recurring accounts
										</Text>
										<TouchableOpacity
											style={{
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={() => this.addNewNote()}>
											<View>
												<Image
													source={add_btn}
													style={{
														width: 18,
														height: 18,
														// borderRadius: 25,
														resizeMode: "contain",
														marginRight: 5,
													}}
												/>
											</View>
										</TouchableOpacity>
									</View>

									<View
										style={{
											width: "100%",
											padding: 10,
											backgroundColor: APP_COLOURS.BG2,
										}}>
										{this.state.recurringAccounts
											.filter(
												(d_) =>
													d_.allocation ==
													this.state
														.selectedCompanyID,
											)
											.map((doc, ind) => (
												<RecurringAccounts data={doc} />
											))}
									</View>
								</View>
								<View
									style={{
										height: 200,
										width: 100,
										// backgroundColor: "red",
									}}
								/>
							</ScrollView>
						</View>
					</View>
				</View>

				{this.state.showAddItemsForm ? (
					<ModalForm
						xPos={this.state.xPos}
						yPos={this.state.yPos}
						closeModal={() =>
							this.setState({
								showAddItemsForm: false,
							})
						}
					/>
				) : null}
			</View>
		);
	}
}

export default Accounts;
