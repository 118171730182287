import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	Image,
	Modal,
	TouchableOpacity,
	ActivityIndicator,
	Alert,
} from "react-native";
import moment from "moment";
// import Share from "react-native-share";
// import ViewShot from "react-native-view-shot";
// import RNFS from "react-native-fs";
// import RNImageToPdf from "react-native-image-to-pdf";
import { HtmlEmail } from "./HtmlEmail.js";

class A4Doc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageMargin: 29,
			loadingPdf: false,
			scale: 2,
			selectRep: false,
			selectedRep: "",
			viewshotOption: "",
		};
	}

	//
	///
	///
	//  PDF CREAT PROCESS
	//
	screenShotPage = async () => {
		this.setState({
			loadingPdf: true,
		});
		// this.refs.viewShot.capture().then((uri) => {
		// 	console.log("do something with ", uri);
		// 	// this.shareTo(uri);
		// 	// NBNBNB NB can add multiple imahges to a single PDF doc
		// 	this.pdfTo([uri]);
		// });
	};

	pdfTo = async (imagePathsArray) => {
		// try {
		// 	const options = {
		// 		imagePaths: imagePathsArray,
		// 		name: this.props.pdfName,
		// 	};
		// 	const pdf = await RNImageToPdf.createPDFbyImages(options);
		// 	console.log(pdf.filePath);
		// 	console.log(pdf);
		// 	this.shareTo(pdf.filePath);
		// } catch (e) {
		// 	console.log(e);
		// }
	};

	shareTo = (uri) => {
		const shareOptions = {
			title: "tetrice_invoice",
			url: uri,
			failOnCancel: false,
			filename: "tetrice_invoice",
		};

		// Share.open(shareOptions)
		// 	.then((res) => {
		// 		console.log(res);
		// 		// this.setState({
		// 		//   emailScorecard: false,
		// 		// });
		// 		// alert('Success');

		// 		this.setState({
		// 			loadingPdf: false,
		// 			viewPdf: true,
		// 			pdfDocument: uri,
		// 		});
		// 	})
		// 	.catch((err) => {
		// 		err && console.log(err);
		// 	});
	};

	saveToFS = () => {
		// var path = RNFS.DocumentDirectoryPath + "/test.txt";
		// // write the file
		// RNFS.writeFile(path, "Lorem ipsum dolor sit amet", "utf8")
		// 	.then((success) => {
		// 		console.log("FILE WRITTEN!");
		// 	})
		// 	.catch((err) => {
		// 		console.log(err.message);
		// 	});
	};
	//
	///
	///
	//  PDF CREAT PROCESS END
	//

	handleEmail = async () => {
		// await this.setState({
		// 	loadingPdf: true,
		// });
		// const base64 = await this.refs.viewShot.capture().then((uri) => {
		// 	console.log("do something with ", uri);
		// 	return uri;
		// });
		// const rep = this.state.selectedRep;
		// let dateFrom = moment(
		// 	this.props.docDate == "" ? new Date() : this.props.docDate,
		// ).format("DD-MM-YYYY");
		// let dateRange = `${dateFrom}`;
		// let fileName = `tetrice_invoice_${dateFrom}`;
		// const email = await this.emailTo(
		// 	rep.email,
		// 	fileName,
		// 	base64,
		// 	dateRange,
		// );
	};

	emailTo = async (recipient, fileName, base64, dateRange) => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			jwt: "1234567",
			recipient: recipient,
			description: fileName,
			pdfString: base64,
			filename: `${fileName}.pdf`,
			subject: `Tax Invoice: ${dateRange}`,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://www.tetrice.co.za/REST/accountsapi/email_invoice.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response.status);
				if (response.status == "404") {
					alert("Error Sending email!");
					// response.json().then(result => console.log('result', result));
				} else if (response.status == "200") {
					alert("Sent!");
					this.setState({
						loadingPdf: false,
					});
					this.props.closePreview();
					// response.json().then(result => {
					//   // console.log('result', result);
					//   alert('Sent!');
					// });
				}
			})
			.catch((error) => console.log("error", error));
	};

	render() {
		return (
			<>
				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.viewPdf}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							// alignItems: 'center',
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<View
							style={{
								flex: 1,
								display: this.state.loadingPdf
									? "none"
									: "flex",
								alignItems: "center",
							}}>
							<View
								style={{
									height: 30,
								}}
							/>
							<View
								style={{
									flex: 1,
									backgroundColor: "#191D24",
									alignItems: "center",
									borderRadius: 12,
									padding: 10,
									flexDirection: "column",
								}}>
								<View
									style={{
										//   backgroundColor: 'red',
										width: "100%",
										height: "auto",
										flexDirection: "row",
										alignItems: "center",
										borderBottomWidth: 0.5,
										paddingBottom: 10,
										borderBottomColor: "#E8E8E8",
										// justifyContent: 'space-between',
									}}>
									<View
										style={{
											flex: 1,
											flexDirection: "row",
											alignItems: "center",
										}}>
										<TouchableOpacity
											onPress={async () => {
												await this.setState({
													documentTitle: "",
													viewPdf: false,
													pdfDocument: "",
												});
												await this.props.closePreview();
											}}>
											<Image
												style={{
													width: 30,
													height: 30,
													resizeMode: "contain",
													marginRight: 10,
												}}
												source={require("../../../assets/images/close.png")}
											/>
										</TouchableOpacity>
										<View>
											<Text
												style={{
													fontWeight: "700",
													fontSize: 17,
													color: "#F6F8FE",
												}}>
												Preview
											</Text>
										</View>
									</View>
									{this.state.selectedRep == "" ? (
										<TouchableOpacity
											onPress={async () => {
												this.setState({
													selectRep: true,
												});
											}}>
											<View
												style={{
													width: "auto",
													height: "auto",
													justifyContent: "center",
													alignItems: "center",
													backgroundColor: "blue",
													borderRadius: 12,
													padding: 10,
													marginRight: 10,
												}}>
												<Text
													style={{
														color: "#FFF",
														fontWeight: "600",
														fontSize: 13,
													}}>
													Email
												</Text>
											</View>
										</TouchableOpacity>
									) : (
										<>
											<TouchableOpacity
												onPress={() =>
													this.setState({
														selectRep: true,
													})
												}>
												<View
													style={{
														flexDirection: "row",
														marginBottom: 0,
														alignItems: "center",
														width: "auto",
														marginRight: 15,
													}}>
													<Image
														style={{
															width: 35,
															height: 35,
															borderRadius: 15,
															resizeMode:
																"contain",
														}}
														source={require("../../../assets/images/female1.png")}
													/>

													<View
														style={{
															// flex: 1,
															paddingLeft: 10,
															paddingTop: 8,
															justifyContent:
																"center",
														}}>
														<Text
															style={{
																fontSize: 14,
																color: "#FFF",
																fontWeight:
																	"800",
																fontFamily:
																	"Avenir Next",
																padding: 0,
															}}>
															{
																this.state
																	.selectedRep
																	.name
															}
														</Text>
														<Text
															style={{
																fontSize: 10,
																color: "#FFF",
																fontWeight:
																	"300",
																fontFamily:
																	"Avenir Next",
																top: -2,
																padding: 0.8,
															}}>
															{
																this.state
																	.selectedRep
																	.email
															}
														</Text>
													</View>
												</View>
											</TouchableOpacity>
											<TouchableOpacity
												onPress={async () => {
													await this.setState({
														viewshotOption:
															"base64",
													});
													await this.handleEmail();
												}}>
												<View
													style={{
														width: "auto",
														height: "auto",
														justifyContent:
															"center",
														alignItems: "center",
														backgroundColor: "blue",
														borderRadius: 12,
														padding: 10,
														marginRight: 10,
													}}>
													<Text
														style={{
															color: "#FFF",
															fontWeight: "600",
															fontSize: 13,
														}}>
														Email to{" "}
														{
															this.state
																.selectedRep
																.name
														}
													</Text>
												</View>
											</TouchableOpacity>
										</>
									)}

									<TouchableOpacity
										onPress={async () => {
											await this.setState({
												viewshotOption: "tmpfile",
											});
											await this.screenShotPage();
										}}>
										<View
											style={{
												width: "auto",
												height: "auto",
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: "#FF5F5F",
												borderRadius: 12,
												padding: 10,
												marginRight: 2,
											}}>
											<Text
												style={{
													color: "#FFF",
													fontWeight: "600",
													fontSize: 13,
												}}>
												Save PDF
											</Text>
										</View>
									</TouchableOpacity>
								</View>

								{this.state.selectRep ? (
									<View
										style={{
											flex: 1,
											position: "absolute",
											marginTop: 60,
											zIndex: 99,
											backgroundColor:
												"rgba(52, 52, 52, 0.6)",
											width: "100%",
											height: "100%",
											borderRadius: 20,
											alignItems: "center",
										}}>
										{this.props.representatives
											.filter(
												(d) =>
													d.client_id ==
													this.props.selectedClient[0]
														.id,
											)
											.map((rep, i) => (
												<TouchableOpacity
													onPress={() =>
														this.setState({
															selectedRep: rep,
															selectRep: false,
														})
													}>
													<View
														style={{
															flexDirection:
																"row",
															marginBottom: 10,
															alignItems:
																"center",
															width: "100%",
															maxWidth: 300,
														}}>
														<Image
															style={{
																width: 35,
																height: 35,
																borderRadius: 15,
																resizeMode:
																	"contain",
															}}
															source={require("../../../assets/images/female1.png")}
														/>

														<View
															style={{
																flex: 1,
																paddingLeft: 10,
																paddingTop: 8,
																justifyContent:
																	"center",
															}}>
															<Text
																style={{
																	fontSize: 14,
																	color: "#FFF",
																	fontWeight:
																		"800",
																	fontFamily:
																		"Avenir Next",
																	padding: 0,
																}}>
																{rep.name}
															</Text>
															<Text
																style={{
																	fontSize: 10,
																	color: "#FFF",
																	fontWeight:
																		"300",
																	fontFamily:
																		"Avenir Next",
																	top: -2,
																	padding: 0.8,
																}}>
																<Text
																	style={{
																		fontWeight:
																			"500",
																	}}>
																	{rep.email}
																</Text>{" "}
																{rep.cell}
															</Text>
														</View>
													</View>
												</TouchableOpacity>
											))}
									</View>
								) : (
									<View
										style={{
											flex: 1,
											width: "100%",
										}}>
										{/* <ScrollView> */}
										<View
											style={{
												flex: 1,
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: "#191D24",
											}}>
											<View
												style={{
													flex: 1,
												}}>
												<ScrollView>
													<View
														style={{
															width:
																595 *
																this.state
																	.scale,
															height:
																842 *
																this.state
																	.scale,
														}}>
														{/* <ViewShot
															options={{
																result: this
																	.state
																	.viewshotOption,
															}}
															ref='viewShot'> */}
														<View
															style={{
																width:
																	595 *
																	this.state
																		.scale,
																height:
																	842 *
																	this.state
																		.scale,
																backgroundColor:
																	"#FFF",
																padding:
																	this.state
																		.pageMargin *
																	this.state
																		.scale,
															}}>
															{/* Top Start  */}
															<View
																style={{
																	width: "100%",
																	height: "auto",
																	flexDirection:
																		"row",
																}}>
																{/* Left  */}
																<View
																	style={{
																		flex: 1,
																	}}>
																	<Image
																		style={{
																			width:
																				153 *
																				this
																					.state
																					.scale,
																			height:
																				45 *
																				this
																					.state
																					.scale,
																			resizeMode:
																				"contain",
																		}}
																		source={require("../../../assets/images/logo.png")}
																	/>

																	<View
																		style={{
																			flexDirection:
																				"row",
																			marginBottom:
																				15 *
																				this
																					.state
																					.scale,
																			marginTop:
																				20 *
																				this
																					.state
																					.scale,
																		}}>
																		<View
																			style={{
																				alignItems:
																					"flex-start",
																				marginRight:
																					20 *
																					this
																						.state
																						.scale,
																			}}>
																			<Text
																				style={{
																					fontSize:
																						7 *
																						this
																							.state
																							.scale,
																					color: "#141D31",
																					fontFamily:
																						"Poppins",
																				}}>
																				<Text
																					style={{
																						fontWeight:
																							"800",
																					}}>
																					tetrice
																				</Text>{" "}
																				(pty)
																				ltd
																			</Text>
																			<Text
																				style={{
																					fontSize:
																						7 *
																						this
																							.state
																							.scale,
																					color: "#141D31",
																					fontFamily:
																						"Poppins",
																				}}>
																				Reg.
																				2018/492074/07
																			</Text>

																			<Text
																				style={{
																					fontSize:
																						7 *
																						this
																							.state
																							.scale,
																					color: "#141D31",
																					fontFamily:
																						"Poppins",
																				}}>
																				VAT:
																				4910296310
																			</Text>
																			<Text
																				style={{
																					width:
																						70 *
																						this
																							.state
																							.scale,
																					fontSize:
																						7 *
																						this
																							.state
																							.scale,
																					color: "#141D31",
																					fontFamily:
																						"Poppins",
																				}}>
																				159
																				Rivonia
																				Road,
																				Morningside
																				Ext
																				2,
																				Sandton,
																			</Text>
																			<Text
																				style={{
																					fontSize:
																						7 *
																						this
																							.state
																							.scale,
																					color: "#141D31",
																					fontFamily:
																						"Poppins",
																				}}>
																				www.tetrice.co.za
																			</Text>
																		</View>
																		<View
																			style={{
																				alignItems:
																					"flex-start",
																				marginTop: 4,
																			}}>
																			<Text
																				style={{
																					fontSize:
																						5 *
																						this
																							.state
																							.scale,
																					color: "#141D31",
																					fontFamily:
																						"Poppins",
																				}}>
																				Banking
																				details:
																			</Text>
																			<Text
																				style={{
																					fontSize:
																						7 *
																						this
																							.state
																							.scale,
																					color: "#141D31",
																					fontFamily:
																						"Poppins",
																				}}>
																				FNB
																			</Text>

																			<Text
																				style={{
																					fontSize:
																						7 *
																						this
																							.state
																							.scale,
																					color: "#141D31",
																					fontFamily:
																						"Poppins",
																				}}>
																				62782863354
																			</Text>
																			<Text
																				style={{
																					width:
																						70 *
																						this
																							.state
																							.scale,
																					fontSize:
																						7 *
																						this
																							.state
																							.scale,
																					color: "#141D31",
																					fontFamily:
																						"Poppins",
																				}}>
																				250-655
																			</Text>
																			<Text
																				style={{
																					fontSize:
																						7 *
																						this
																							.state
																							.scale,
																					color: "#141D31",
																					fontFamily:
																						"Poppins",
																				}}>
																				FIRNZAJJ
																			</Text>
																		</View>
																	</View>
																</View>

																{/* Right  */}
																<View
																	style={{
																		flex: 1,
																	}}>
																	<View
																		style={{
																			// flex: 1,
																			// alignItems: 'flex-end',
																			flexDirection:
																				"row",
																			marginTop: 16,
																			paddingTop:
																				5 *
																				this
																					.state
																					.scale,
																			justifyContent:
																				"flex-end",
																		}}>
																		{this.props.selectedClient.map(
																			(
																				data,
																				index,
																			) => (
																				<View
																					key={
																						index
																					}
																					style={{
																						alignItems:
																							"flex-end",
																					}}>
																					<Text
																						style={{
																							fontSize:
																								14 *
																								this
																									.state
																									.scale,
																							color: "#141D31",
																							fontWeight:
																								"600",
																							fontFamily:
																								"Poppins",
																							textAlign:
																								"right",
																						}}>
																						{
																							data.title
																						}
																					</Text>
																					<Text
																						adjustsFontSizeToFit
																						numberOfLines={
																							1
																						}
																						style={{
																							fontSize:
																								10 *
																								this
																									.state
																									.scale,
																							color: "#141D31",
																							fontWeight:
																								"500",
																							fontFamily:
																								"Poppins",
																							textAlign:
																								"right",
																						}}>
																						{
																							data.tradingName
																						}
																					</Text>

																					{Object.entries(
																						data,
																					).map(
																						([
																							key,
																							v,
																						]) => {
																							if (
																								key ==
																									"id" ||
																								key ==
																									"title" ||
																								key ==
																									"tradingName"
																							) {
																							} else
																								return (
																									<Text
																										key={
																											key
																										}
																										style={{
																											maxWidth: 300,
																											fontSize:
																												7 *
																												this
																													.state
																													.scale,
																											color: "#141D31",
																											fontFamily:
																												"Poppins",
																											textAlign:
																												"right",
																										}}>
																										{
																											v
																										}
																									</Text>
																								);
																						},
																					)}
																				</View>
																			),
																		)}

																		<View
																			style={{
																				width: 0.5,
																				marginLeft:
																					5 *
																					this
																						.state
																						.scale,
																				height: "100%",
																				backgroundColor:
																					"#000",
																			}}
																		/>
																	</View>
																</View>
															</View>

															<View
																style={{
																	flexDirection:
																		"row",
																	justifyContent:
																		"flex-end",
																}}>
																<View
																	style={{
																		alignItems:
																			"flex-end",
																		marginTop: 4,
																		width:
																			100 *
																			this
																				.state
																				.scale,
																	}}>
																	<Text
																		style={{
																			fontSize:
																				7 *
																				this
																					.state
																					.scale,
																			color: "#141D31",
																			fontFamily:
																				"Poppins",
																		}}>
																		Invoice
																		Number:
																	</Text>

																	<Text
																		style={{
																			fontSize:
																				7 *
																				this
																					.state
																					.scale,
																			color: "#141D31",
																			fontFamily:
																				"Poppins",
																		}}>
																		Invoice
																		date:
																	</Text>

																	<Text
																		style={{
																			fontSize:
																				7 *
																				this
																					.state
																					.scale,
																			color: "#141D31",
																			fontFamily:
																				"Poppins",
																		}}>
																		Total:
																	</Text>
																</View>
																<View
																	style={{
																		alignItems:
																			"flex-end",
																		marginTop: 4,
																		width:
																			100 *
																			this
																				.state
																				.scale,
																	}}>
																	<Text
																		style={{
																			fontSize:
																				7 *
																				this
																					.state
																					.scale,
																			color: "#141D31",
																			fontFamily:
																				"Poppins",
																		}}>
																		{
																			this
																				.props
																				.docinvoiceNumber
																		}
																	</Text>

																	<Text
																		style={{
																			fontSize:
																				7 *
																				this
																					.state
																					.scale,
																			color: "#141D31",
																			fontFamily:
																				"Poppins",
																		}}>
																		{moment(
																			this
																				.props
																				.docDate,
																		).format(
																			"DD/MM/YYYY",
																		)}
																	</Text>
																	{/* <Text
                                      style={{
                                        fontSize: 7 * this.state.scale,
                                        color: '#141D31',
                                        fontFamily: 'Poppins',
                                      }}>
                                      {moment(this.props.docDate)
                                        .add('days', 14)
                                        .format('DD/MM/YYYY')}
                                    </Text> */}
																	<Text
																		style={{
																			fontSize:
																				7 *
																				this
																					.state
																					.scale,
																			color: "#141D31",
																			fontFamily:
																				"Poppins",
																		}}>
																		{
																			this
																				.props
																				.totalPrice
																		}
																	</Text>
																</View>
															</View>
															{/* Top END */}
															{/* PAGE CONTENT */}
															<View
																style={{
																	flex: 1,
																	marginTop: 20,
																}}>
																<View
																	style={{
																		flexDirection:
																			"row",
																		justifyContent:
																			"space-between",
																		alignItems:
																			"flex-end",
																	}}>
																	<Text
																		style={{
																			fontSize:
																				17 *
																				this
																					.state
																					.scale,
																			fontWeight:
																				"700",
																			color: "#141D31",
																		}}>
																		{
																			this
																				.props
																				.documentTitle
																		}
																	</Text>
																	<Text
																		style={{
																			fontSize:
																				8 *
																				this
																					.state
																					.scale,
																			fontWeight:
																				"400",
																			color: "#141D31",
																		}}>
																		{
																			this
																				.props
																				.documentDescription
																		}
																	</Text>
																</View>
																<View
																	style={{
																		width: "100%",
																		height: 10,
																	}}
																/>
																<View
																	style={{
																		flex: 1,
																		marginBottom:
																			10 *
																			this
																				.state
																				.scale,
																	}}>
																	{
																		this
																			.props
																			.viewContent
																	}
																</View>

																<View
																	style={{
																		width: "100%",
																		height: "auto",
																		marginTop: 2,
																		justifyContent:
																			"center",
																		alignItems:
																			"center",
																	}}>
																	<Text
																		style={{
																			fontSize:
																				5 *
																				this
																					.state
																					.scale,
																			color: "#141D31",
																			fontFamily:
																				"Poppins",
																		}}>
																		<Text
																			style={{
																				fontWeight:
																					"800",
																			}}>
																			tetrice
																		</Text>{" "}
																		(pty)
																		ltd
																	</Text>
																	<Text
																		style={{
																			fontSize:
																				5 *
																				this
																					.state
																					.scale,
																			color: "#141D31",
																		}}>
																		v1.2.17
																	</Text>
																</View>
															</View>
															{/* PAGE CONTENT */}
														</View>
														{/* </ViewShot> */}
													</View>
												</ScrollView>
											</View>
										</View>
										<View
											style={{
												height: 40,
											}}
										/>
										{/* </ScrollView> */}
									</View>
								)}
							</View>
						</View>

						<View
							style={{
								flex: 1,
								display: this.state.loadingPdf
									? "flex"
									: "none",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<View
								style={{
									width: 200,
									height: 200,
									backgroundColor: "#FFF",
									borderRadius: 20,
									alignItems: "center",
									justifyContent: "center",
								}}>
								<Text
									style={{
										color: "#141D31",
										fontWeight: "500",
									}}>
									Creating PDF. . .{" "}
									<ActivityIndicator size='small' />
								</Text>
							</View>
						</View>
					</View>
				</Modal>
			</>
		);
	}
}

export default A4Doc;
