// import React, { Component } from "react";
// import { View, Text } from "react-native";
// import FileBase64 from "react-file-base64";

// class ImageUploader extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			file: [],
// 		};
// 	}

// 	getFiles(files) {
// 		console.log("files", files);
// 		this.setState({ file: files });
// 		// WE USING A FOR LOOP TO SAVE THE FILES INCASE THEY ARE MULTIPLE
// 		for (var i = 0; i < files.length; i++) {
// 			let replace_ = "data:application/pdf;base64,";
// 			// let replace_ = "data:image/png;base64,";

// 			let myStr = files[i].base64;
// 			let cleanString = myStr.replace(replace_, "");
// 			console.log(cleanString);
// 			this.props.base64String(cleanString);
// 			// const objectTosend = {
// 			// 	name: files[i].name,
// 			// 	fileBase64: files[i].base64,
// 			// 	size: files[i].size,
// 			// 	selectedFolder: folderid,
// 			// };
// 		}
// 	}

// 	SaveFile = (objectToSend) => {
// 		this.setState({ loading: true });
// 		let obj = JSON.stringify(objectToSend);
// 		console.log(obj);
// 	};

// 	render() {
// 		return (
// 			<View style={{}}>
// 				<label for='documentUploader'>dsfdsfsd</label>
// 				<div
// 					style={{
// 						display: "none",
// 					}}>
// 					<FileBase64
// 						multiple={true}
// 						onDone={this.getFiles.bind(this)}
// 						id='documentUploader'
// 					/>
//             </div>

// 			</View>
// 		);
// 	}
// }

// export default ImageUploader;

import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import pdfUploader_open from "./../../../assets/images/pdfUploader_open.png";
import pdfUploader_closed from "./../../../assets/images/pdfUploader_closed.png";
import "./Uploader.css";

export default class FileBase64 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			files: [],
			isFile: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isFile !== this.props.isFile) {
			this.setState({
				isFile: this.props.isFile,
			});
		}
	}

	getFiles = async (files) => {
		await console.log("files", files);
		await this.setState({ isFile: true });
		// WE USING A FOR LOOP TO SAVE THE FILES INCASE THEY ARE MULTIPLE

		for (var i = 0; i < files.length; i++) {
			if (files[i].type == "image/png") {
				let replace_ = "data:image/png;base64,";
				// let replace_ = "data:image/png;base64,";

				let myStr = files[i].base64;
				let cleanString = myStr.replace(replace_, "");
				// await console.log("cleanString", cleanString);
				await this.props.base64String(cleanString, files[i]);
				// const objectTosend = {
				// 	name: files[i].name,
				// 	fileBase64: files[i].base64,
				// 	size: files[i].size,
				// 	selectedFolder: folderid,
				// };
			}

			if (files[i].type == "application/pdf") {
				let replace_ = "data:application/pdf;base64,";
				// let replace_ = "data:image/png;base64,";
				let myStr = files[i].base64;
				let cleanString = myStr.replace(replace_, "");
				// await console.log("cleanString", cleanString);
				await this.props.base64String(cleanString, files[i]);
				// const objectTosend = {
				// 	name: files[i].name,
				// 	fileBase64: files[i].base64,
				// 	size: files[i].size,
				// 	selectedFolder: folderid,
				// };
			}
		}
	};

	handleChange(e) {
		// get the files
		let files = e.target.files;

		// Process each file
		var allFiles = [];
		for (var i = 0; i < files.length; i++) {
			let file = files[i];

			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				let fileInfo = {
					name: file.name,
					type: file.type,
					size: Math.round(file.size / 1000) + " kB",
					base64: reader.result,
					file: file,
				};

				// Push it to the state
				allFiles.push(fileInfo);

				// If all files have been proceed
				if (allFiles.length == files.length) {
					// Apply Callback function
					if (this.props.multiple) this.getFiles(allFiles);
					else this.getFiles(allFiles);
				}
			}; // reader.onload
		} // for
	}

	render() {
		return (
			<View>
				<label for='documentUploader'>
					{this.state.isFile ? (
						<Image
							source={pdfUploader_closed}
							style={{
								width: 200,
								height: 45,
								resizeMode: "contain",
								marginRight: 5,
							}}
						/>
					) : (
						<Image
							source={pdfUploader_open}
							style={{
								width: 200,
								height: 45,
								resizeMode: "contain",
								marginRight: 5,
							}}
						/>
					)}
				</label>
				<input
					id='documentUploader'
					type='file'
					style={{
						display: "none",
					}}
					onChange={this.handleChange.bind(this)}
					multiple={this.props.multiple}
				/>
			</View>
		);
	}
}

FileBase64.defaultProps = {
	multiple: false,
};
